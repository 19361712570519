<template>
  <div>
    <BuyerCampaignsList
      :buyer-campaigns="buyerCampaigns"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BuyerCampaignsList from './BuyerCampaignsList.vue'

export default {
  name: 'BuyerCampaigns',

  title: 'Buyer Campaigns',
  
  components: {
    BuyerCampaignsList
  },

  data() {
    return {
      type: '',
      isLoading: false
    }
  },

  computed: {
    ...mapState('campaign', ['buyerCampaigns'])
  },

  async created() {
    await this.getCampaigns()
  },

  methods: {
    ...mapActions('campaign', ['fetchBuyerCampaigns']),

    async getCampaigns() {
      try {
        this.isLoading = true
        await this.fetchBuyerCampaigns({ detailed: true })
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>

<style></style>
