function checkMultipleRouteNames(routeName = '', routeNames, splitBy = '|') {
  const routeNamesSplit = routeNames.split(splitBy)

  return routeNamesSplit.some(route => routeName === route)
}

export function getCustomisedNavBarDropdown(CSidebarNavDropdown) {
  return CSidebarNavDropdown.extend({
    watch: {
      $route: {
        immediate: true,
        handler (route) {
          const mode = this.dropdownMode
          if (mode === 'close') {
            this.open = false
          } else if (mode === 'closeInactive' && this.route) {
            this.open = checkMultipleRouteNames(route.name, this.route)
          }  else if (mode === 'openActive' && !this.open && this.route) {
            this.open = checkMultipleRouteNames(route.name, this.route)
          }
          // else if (mode === 'noAction') {
          //   return
          // }
        }
      }
    }
  })

}