<template>
  <CDropdown
    in-nav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink class="rounded-circle c-account-dropdown-btn bg-secondary justify-content-center">
        {{ getSafe(() => email[0].toUpperCase()) }}
      </CHeaderNavLink>
    </template>

    <CDropdownItem @click="onLogout">
      <CIcon name="cil-lock-locked" />
      Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapActions,mapState } from 'vuex'
export default {
  name: 'TheHeaderDropdownAccnt',

  data() {
    return {
      itemsCount: 42
    }
  },

  computed: {
    ...mapState('auth', ['userDetails']),

    email() {
      return this.userDetails.email
    }
  },

  methods: {
    ...mapActions('auth', [
      'logout'
    ]),

    onLogout() {
      try {
        this.logout()
      } catch (error) {
        //
      } finally {
        this.$router.push({ name: 'LoginEmail' })
      }
    }
  },
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}

.c-account-dropdown-btn {
  width: 30px;
  height: 30px;
}
</style>
