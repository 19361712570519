import moment from 'moment'

const REQUIRED_FIELD_ERROR_MESSAGE = 'Please enter a valid value'

function dateComparatorForDesc(date1, date2) {
  var date1Number = date1 && new Date(date1).getTime()
  var date2Number = date2 && new Date(date2).getTime()

  if (date1Number == null && date2Number == null) {
    return 0
  }

  if (date1Number == null) {
    return 1
  } else if (date2Number == null) {
    return -1
  }

  return date2Number - date1Number
}

function dateComparatorForAsc(date1, date2) {
  var date1Number = date1 && new Date(date1).getTime()
  var date2Number = date2 && new Date(date2).getTime()

  if (date1Number == null && date2Number == null) {
    return 0
  }

  if (date1Number == null) {
    return -1
  } else if (date2Number == null) {
    return 1
  }

  return date1Number - date2Number
}

export function replaceItemInArray(items, item, findItem) {
  const idx = items.findIndex(findItem)

  items[idx] = item
}

export const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'medium',
  timeStyle: 'short'
})

export const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'medium',
})

export function getDateSortOperator(getValue = undefined, sort = 'desc') {
  const sortFunc =
    sort === 'desc' ? dateComparatorForDesc : dateComparatorForAsc

  if (getValue) {
    return function(dataA, dataB) {
      return sortFunc(getValue(dataA), getValue(dataB))
    }
  }

  return sortFunc
}

export function capitlizeFirstLetter(str) {
  return str && str.length > 0 ? `${str[0].toUpperCase()}${str.slice(1)}` : ''
}

export function formatDate(date) {
  return moment(new Date(date)).format('MMM DD, YYYY')
}

export function formatDateTime(date) {
  return moment(new Date(date)).format('MMM DD hh:mm:ss A')
}

export function convertDurationInSecsToTimeFormat(durationInSecs) {
  return moment.utc(durationInSecs * 1000).format('HH:mm:ss')
}

/**
  * Formats a given date (exclusive) to be inclusive by subtracting 1 second from it.
 * @param {*} date 
 * @returns date formatted to US standard
 */
export function formatDateInclusive(date) {
  let result = moment.parseZone(date).subtract(1, 's')
  return result.format('MMM DD, YYYY')
}

export function formatAmount(amount) {
  if (amount === undefined) { 
    return "N/A"
  }
  
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  return formatter.format(amount)
}

export function formatPercentage(amount) {
  if (amount === undefined) { 
    return "N/A"
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2
  })
  return formatter.format(amount)
}

export function sortAlphabeticallyComparison(string1, string2) {
  if (string1 < string2) {
    return -1
  }
  if (string1 > string2) {
    return 1
  }

  // strings must be equal
  return 0
}

export function getDateRangeInTimeZone(timeRange) {
  const standardTimeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
  // Time is sent in UTC
  // If user selects 3PM in PST(UTC-08:00): the time which would be sent is 15:00 - 08:00 = 07:00 
  const startDate = moment.tz(moment(timeRange.startDate).format('YYYY-MM-DD HH:mm:ss'), standardTimeZoneId).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
  const endDate = moment.tz(moment(timeRange.endDate).format('YYYY-MM-DD HH:mm:ss'), standardTimeZoneId).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')

  return { startDate, endDate }
}

export function splitLines(t) { return t.split(/\r\n|\r|\n/) }
 

export { REQUIRED_FIELD_ERROR_MESSAGE }
