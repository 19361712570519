<template>
  <div id="app">
    <notifications group="default" />

    <component :is="layout || 'div'">
      <router-view />
    </component>

    <CToaster
      position="bottom-right"
      title="An update is available"
    >
      <CToast
        v-if="updateExists"
        :key="'updateAppToast'"
        :show="updateExists"
      >
        <h5 class="mb-3">
          An update is available
        </h5>
        <CButton
          color="success"
          variant="outline"
          size="sm"
          @click="refreshApp"
        >
          Update
        </CButton>
      </CToast>
    </CToaster>
  </div>
</template>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>

<script>
import updateAppMixin from './mixins/update-app-mixin'

export default {
  name: 'App',

  mixins: [updateAppMixin],

  computed: {
    layout() {
      return this.$route.matched.find(m => !!m.meta.layout)?.meta?.layout 
    }
  },

}
</script>
