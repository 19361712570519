<template>
  <CCard>
    <CCardHeader>
      Involvement in Seller Offers (Active) <CIcon 
        v-c-tooltip="'Displays a list of active seller offers in which the seller is currently involved'" 
        name="cil-lightbulb" 
        size="custom"
      />
    </CCardHeader>

    <CCardBody>
      <div
        v-if="isLoading"
        :style="{ height: '10vh' }"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <CSpinner
          style="width:4rem;height:4rem;"
          color="info"
          grow
        />
      </div>

      <SellerAccountOffersList
        v-else
        :offers-requests="accountOffersRequests"
        @reload="$emit('reload')"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState } from 'vuex'

import SellerAccountOffersList from './SellerAccountOffersList.vue'

export default {
  name: 'SellerAccountApprovedOffers',

  components: {
    SellerAccountOffersList,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('accounts', ['sellerAccountDetails']),

    accountOffersRequests() {
      return this.sellerAccountDetails?.offersRequests
    }
  },
}
</script>

<style>

</style>