<template>
  <CForm @submit.prevent>
    <h1>Reset password</h1>
    <p class="text-muted">
      Please enter your registered email address
    </p>

    <CInput
      v-model="email"
      placeholder="Email"
      autocomplete="user email"
      type="email"
    >
      <template #prepend-content>
        <CIcon name="cil-user" />
      </template>
    </CInput>

    <CRow>
      <CCol
        col="12"
        class="text-right"
      >
        <ButtonLoading
          color="default"
          class="mr-2"
          @click="
            $router.push({
              name: 'LoginEmail',
            })
          "
        >
          Back
        </ButtonLoading>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          type="submit"
          @click="onResetPassword"
        >
          Submit
        </ButtonLoading>
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

import { showSuccessMessage } from '@/notification-utils'
import authViewMixin from '@/mixins/auth-view-mixin'

export default {
  name: 'PasswordReset',

  mixins: [authViewMixin],

  validations: {
    email: {
      required,
      email,
    },
  },

  data() {
    return {
      email: '',
      isLoading: false,
    }
  },

  methods: {
    ...mapActions('auth', ['forgotPassword']),

    async onResetPassword() {
      this.isLoading = true

      try {
        await this.forgotPassword(this.email)
        showSuccessMessage("We've sent a confirmation code to set your password")

        this.$router.push({
          name: 'SetNewPassword',
          query: {
            email: this.email
          }
        })
      } catch (error) {
        // Already showing error in interceptor
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style></style>
