import BillingAPI from '@/api/billing.api'

const state = {
  buyerAccountPayments: {
    invoices: [],
    summary: {
    }
  },
  sellerAccountPayments: {
    invoices: [],
    summary: {
    }
  },
  paymentDetails: {},
  systemAccountSummary: {
    availableFunds: null,
  },
  systemAccountGrossMargin:{
    totalConversionAmount: null,
    totalPayoutAmount: null
  },
}

const actions = {
  async getBuyerAccountPayments({ commit }) {
    const response = await BillingAPI.getBuyerAccountPayments()    
    commit('SET_BUYER_ACCOUNT_PAYMENTS', response.data || { invoices:[], summary: {} })
  },

  async getSellerAccountPayments({ commit }) {
    const response = await BillingAPI.getSellerAccountPayments()    
    commit('SET_SELLER_ACCOUNT_PAYMENTS', response.data || { invoices:[], summary: {} })
  },

  async generateDownloadLink(context, { accountId, invoiceId }) {
    const response = await BillingAPI.generateDownloadLink({accountId, invoiceId })
    return response.data
  },

  async getSystemAccountSummary({ commit }, {includeTestOnly}) {
    const response = await BillingAPI.getSystemAccountSummary({includeTestOnly})
    commit('SET_ACCOUNT_SUMMARY', response.data)
  },

  async getSystemAccountGrossMargin({ commit }, {startDate, endDate, includeTestOnly}) {
    const response = await BillingAPI.getSystemAccountGrossMargin({startDate, endDate, includeTestOnly})
    commit('SET_ACCOUNT_GROSS_MARGIN', response.data)
  },

  async updatePayment(context, { accountId, invoiceId, data }) {
    const response = await BillingAPI.updatePayment({ accountId, invoiceId, data })
    return response.data
  },

  async markInvoiceAsPaid(context, { data }) {
    const response = await BillingAPI.markInvoiceAsPaid({ data })
    return response.data
  },

  async getPaymentDetails({ commit }, {accountId, invoiceId}) {   
    const response = await BillingAPI.getPaymentDetails({accountId, invoiceId})
    commit('SET_PAYMENT_DETAILS', response.data)
  },
}

const getters = {
}

const mutations = {
  SET_BUYER_ACCOUNT_PAYMENTS(state, payments) {
    state.buyerAccountPayments = payments
  },
  SET_SELLER_ACCOUNT_PAYMENTS(state, payments) {
    state.sellerAccountPayments = payments
  },
  SET_PAYMENT_DETAILS(state, data){
    state.paymentDetails = data
  },
  SET_ACCOUNT_SUMMARY(state, data){
    state.systemAccountSummary = data
  },
  SET_ACCOUNT_GROSS_MARGIN(state, data){
    state.systemAccountGrossMargin = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
