<template>
  <div>
    <h3 class="mb-3">
      Applications
    </h3>
    <RegistrationRequestsList
      :registration-requests="registrationRequests"
      :is-loading="isLoading"
      :type="type"
      @status-change="onStatusChange"
    />

    <h3>
      Final Approval Pending Accounts
    </h3>

    <InactiveAccountsList
      :key="reRender"
      :type="type"
      show-final-approval-pending-accounts
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import RegistrationRequestsList from './RegistrationRequestsList.vue'
import InactiveAccountsList from  '@/views/Accounts/InactiveAccountsList.vue'

export default {
  name: 'RegistrationRequests',

  components: {
    RegistrationRequestsList,
    InactiveAccountsList
  },

  data() {
    return {
      type: '',
      isLoading: false,
      reRender: 0
    }
  },

  computed: {
    ...mapState('registrationRequests', [
      'buyerRegistrationRequests',
      'sellerRegistrationRequests'
    ]),

    registrationRequests() {
      if (this.type === 'buyer') {
        return this.buyerRegistrationRequests
      } else {
        return this.sellerRegistrationRequests
      }
    }
  },

  watch: {
    '$route.params.type': async function (newValue) {
      this.type = newValue

      this.reRender = this.reRender + 1

      document.title = `${this.type.charAt(0).toUpperCase() + this.type.slice(1)} Applications`

      await this.getRequests(this.type, this.status)
    }
    
  },

  async created () {
    this.type = this.$route.params.type
    this.status = this.$route.query.status || "Pending"

    document.title = `${this.type.charAt(0).toUpperCase() + this.type.slice(1)} Applications`

    await this.getRequests(this.type, this.status)
  },

  methods: {
    ...mapActions('registrationRequests', [
      'getRegistrationRequests'
    ]),

    async getRequests(type, status) {
      try {
        this.isLoading = true

        await this.getRegistrationRequests({registrationType:type, status})
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },

    async onStatusChange(status){
      this.status = status
      await this.getRequests(this.type,status)
    }

  },
}
</script>

<style>

</style>