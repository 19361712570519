<template>
  <CCard>
    <CCardHeader>
      Budget
    </CCardHeader>
    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CRow
          v-if="campaignRoutingType !== 'RingbaRTB' && campaignRoutingType !== 'Programmatic'"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Minimum Duration
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="budgetDetailsLocal.minimumDurationSelected"
              color="primary"
              @update:checked="formEdited = true"
            />
          </CCol>
        </CRow>

        <CInput
          v-if="budgetDetailsLocal.minimumDurationSelected"
          v-model="$v.budgetDetailsLocal.minimumDurationInSeconds.$model"
          type="number"
          label="seconds"
          add-label-classes="text-right pr-4"
          add-input-classes="w-25"
          name="Name"
          placeholder="seconds"
          invalid-feedback="Invalid value for minimum duration in seconds"
          :is-valid="checkIfValid(['budgetDetailsLocal', 'minimumDurationInSeconds'], submitted)"
          horizontal
          min="0"
        />

        <CInput
          v-model="$v.budgetDetailsLocal.dailyBudgetInUSD.$model"
          type="number"
          label="Daily Budget"
          add-label-classes="text-right pr-4"
          add-input-classes="w-25"
          name="Daily Budget"
          description="amount in USD"
          placeholder=""
          min="0"
          :is-valid="checkIfValid(['budgetDetailsLocal', 'dailyBudgetInUSD'], submitted)"
          invalid-feedback="Invalid value for daily budget"
          horizontal
        />

        <CInput
          v-if="campaignRoutingType !== 'RingbaRTB' && campaignRoutingType !== 'Programmatic'"
          v-model="$v.budgetDetailsLocal.maxBidPerCallInUSD.$model"
          type="number"
          label="Max Bid Per Call"
          add-label-classes="text-right pr-4"
          add-input-classes="w-25"
          name="Max Bid Per Call"
          description="amount in USD"
          placeholder=""
          min="0"
          :is-valid="checkIfValid(['budgetDetailsLocal', 'maxBidPerCallInUSD'], submitted)"
          invalid-feedback="Invalid value for max bid per call"
          horizontal
        />

        <div class="row">
          <div class="col-sm-3 pr-4" />
          <div class="col-sm-9">
            <ButtonLoading
              color="primary"
              :loading="isLoading"
              :disabled="!formEdited && !isFormEdited"
              horizontal
              size="sm"
              label=""
              @click="updateBudget"
            >
              Update
            </ButtonLoading>
          </div>
        </div>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapActions } from 'vuex'
import { showSuccessMessage } from '@/notification-utils'
import { minValue, required } from 'vuelidate/lib/validators'
import formMixins from '@/mixins/form-mixins'
import formGenericMixin from '@/mixins/form-generic-mixin'

export default {
  name: 'Budget',
  mixins: [formMixins, formGenericMixin],
  props: {
    campaignId: {
      type: String,
      default: ''
    },
    budgetDetails: {
      type: Object,
      default: () => {}
    },
    campaignRoutingType: {
      type: String,
      default: ''
    }
  },
  validations() {
    return {
      budgetDetailsLocal: {
        minimumDurationInSeconds: {
          requiredIf: this.budgetDetailsLocal.minimumDurationSelected,
          minValue: minValue(0),
        },
        dailyBudgetInUSD: {
          required,
          minValue: minValue(0),
        },
        maxBidPerCallInUSD: {
          required,
          minValue: minValue(0),
        }
      }
    }
  },
  data() {
    return {
      formEdited: false,
      submitted: false,
      isLoading: false,
      budgetDetailsLocal: {}
    }
  },
  watch: {
    budgetDetails: {
      immediate: true,
      handler(newValue) {
        this.budgetDetailsLocal = {
          ...newValue,
          minimumDurationSelected: Boolean(newValue.minimumDurationSelected)
        }
      }
    }
  },
  methods: {
    ...mapActions('campaign', ['updateCampaignDetails']),
    async updateBudget() {
      this.submitted = true

      if (this.$v.$invalid) {
        return
      }

      const campaignId = this.campaignId
      const updateObject = {
        budget: { ...this.budgetDetailsLocal }
      }
      try {
        this.isLoading = true
        await this.updateCampaignDetails({id: campaignId, updateObject})
        showSuccessMessage('Updated budget settings')
      } catch(error) {
        console.error(error.message)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
