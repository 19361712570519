<template>
  <div
    class="c-app"
    :class="{ 'c-dark-theme': $store.state.darkMode }"
  >
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <TheAside />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <slot />
            </transition>
          </CContainer>
        </main>
        <TheFooter />
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheAside from 'Components/Layout/TheAside'
import TheSidebar from 'Components/Layout/TheSidebar'
import TheHeader from 'Components/Layout/TheHeader'
import TheFooter from 'Components/Layout/TheFooter'

export default {
  name: 'LayoutDefault',
  components: {
    TheAside,
    TheSidebar,
    TheHeader,
    TheFooter
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
