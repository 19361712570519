<template>
  <div>
    Send a POST request to the following URL using a BODY like the one in the example.
              
    <div class="mt-3">
      <h6>
        URL
      </h6>
      <CodeContainer :code-text="url" />
    </div>
    <div class="mt-2">
      <h6>
        Header
      </h6>
      <CodeContainer :code-text="header" />
    </div>
    <div class="mt-2">
      <h6>
        Body
      </h6>
      <CodeContainer :code-text="parameters" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SellerBidCodeSnippet',

  props: {
    url: {
      type: String,
      default: ''
    },

    header: {
      type: String,
      default: ''
    },

    parameters: {
      type: String,
      default: ''
    }
  },
}
</script>

<style>

</style>