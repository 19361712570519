<template>
  <div>
    <CCard>
      <div
        v-if="isLoading"
        :style="{ height: '80vh' }"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <CSpinner
          style="width:4rem;height:4rem;"
          color="info"
          grow
        />
      </div>
      <div v-else>
        <CCardHeader
          data-cy="header"
        >
          <slot name="header">
            <CIcon
              name="cil-basket"
              class="mr-1"
            /> Seller Offers
            <CButton
              class="float-right"
              size="sm"
              color="info"
              @click="
                $router.push({
                  name: 'SellerOfferCreate',
                  exact: true
                })
              "
            >
              <CIcon
                size="sm"
                name="cil-plus"
              /> Create New
            </CButton>
          </slot>
        </CCardHeader>
        <CCardBody>
          <div class="d-flex align-items-center mb-3">
            <CSwitch
              variant="3d"
              color="primary"
              horizontal
              size="sm"
              :checked.sync="includeTestOnly"
              class="mr-2"
            />

            <h5 class="m-0">
              Include Test Only Offers
            </h5>
          </div>

          <CDataTable
            :hover="hover"
            :striped="striped"
            column-filter
            sorter
            border
            :small="small"
            :fixed="fixed"
            :items="filteredOffers"
            :fields="fields"
            :items-per-page="small ? 20 : 10"
            :pagination="{align: 'center'}"
            :sorter-value="{ column: 'name', asc: false }"
          >
            <template #status="{item}">
              <td>
                <div v-if="!item.editMode">
                  <CBadge :color="getBadge(item.status)">
                    {{ item.status }}
                  </CBadge>
                </div>
                <div v-if="item.editMode">
                  <CSelect
                    :value.sync="item.status"
                    :options="['Active', 'Disabled']"
                  />
                  <span @click="updateSellerOfferStatus(item)">
                    <CIcon
                      style="cursor: pointer;"
                      class="ml-2 my-2 text-success"
                      name="cil-check-circle"
                    />
                  </span>
                  <span @click="cancelStatusUpdateControl(item)">
                    <CIcon
                      style="cursor: pointer;"
                      class="ml-2 my-2 text-danger"
                      name="cil-x-circle"
                    />
                  </span>
                </div>
              </td>
            </template>

            <template #id="{item}">
              <td>
                {{
                  item.id
                }}
                <CBadge
                  v-if="item.isCategoryTestOnly"
                  color="danger"
                  class="ml-1"
                >
                  Test
                </CBadge>
              </td>
            </template>

            <template #createdAt="{item}">
              <td>
                {{ getSafe(() => `${formatDate(item.createdAt)}`) }}
              </td>
            </template>
            <template #updatedAt="{item}">
              <td>
                {{ getSafe(() => `${formatDate(item.updatedAt || item.createdAt)}`) }}
              </td>
            </template>
            <template #createdBy="{item}">
              <td>
                {{ getSafe(() => `${item.createdBy.name} ${item.createdBy.lastName}`) }}
              </td>
            </template>
            <template #updatedBy="{item}">
              <td>
                {{ getSafe(() => `${item.updatedBy.name} ${item.updatedBy.lastName}`) }}
              </td>
            </template>

            <template #Actions="{item}">
              <td>
                <CButton
                  color="info"
                  size="sm"
                  class="mt-2"
                  :to="{
                    name: 'SellerOfferEdit',
                    params: { id: item.id },
                    exact: true
                  }"
                >
                  View Offer
                </CButton>

                <CButton
                  v-if="item.status === 'Active'"
                  size="sm"
                  color="warning"
                  class="ml-2 mr-2 mt-2"
                  @click="showEnableOrDisableConfirmation(false, item)"
                >
                  Disable
                </CButton>

                <CButton
                  v-if="item.status === 'Disabled'"
                  size="sm"
                  color="success"
                  class="ml-2 mr-2 mt-2"
                  @click="showEnableOrDisableConfirmation(true, item)"
                >
                  Enable
                </CButton>

                <CButton
                  color="secondary"
                  size="sm"
                  class="mt-2"
                  @click="copySellerOfferLinkForSellersToClipboard(item.id)"
                >
                  Copy Link for Sellers

                  <CIcon
                    name="cil-copy"
                    size="custom"
                    class="ml-1"
                  />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </div>
    </CCard>

    <CModal
      title="Confirm disable"
      color="warning"
      :show.sync="disableOfferModal"
    >
      Are you sure you want to disable this offer?

      <dl
        v-if="selectedOffer"
        class="mt-3"
      >
        <dt>
          Offer Name
        </dt>

        <dd>
          {{ selectedOffer.name }}
        </dd>

        <dt>
          Category
        </dt>

        <dd>
          {{ selectedOffer.categoryName }}
        </dd>

        <dt>
          Language
        </dt>

        <dd>
          {{ selectedOffer.languageName }}
        </dd>
      </dl>

      <p class="alert alert-warning">
        <strong>Note:</strong>
        This will stop traffic flowing through this offer and notify the participating sellers.
      </p>

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isActionLoading"
          @click="confirmDisableOffer(selectedOffer)"
        >
          Disable
        </ButtonLoading>

        <CButton
          color="danger"
          @click="disableOfferModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm enable"
      color="warning"
      :show.sync="enableOfferModal"
    >
      Are you sure you want to enable this offer?

      <dl
        v-if="selectedOffer"
        class="mt-3"
      >
        <dt>
          Offer Name
        </dt>

        <dd>
          {{ selectedOffer.name }}
        </dd>

        <dt>
          Category
        </dt>

        <dd>
          {{ selectedOffer.categoryName }}
        </dd>
        
        <dt>
          Language
        </dt>

        <dd>
          {{ selectedOffer.languageName }}
        </dd>
      </dl>

      <p class="alert alert-warning">
        <strong>Note:</strong>
        This will resume traffic flowing through this offer and notify the participating sellers.
      </p>

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isActionLoading"
          @click="confirmEnableOffer(selectedOffer)"
        >
          Enable
        </ButtonLoading>

        <CButton
          color="danger"
          @click="enableOfferModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { dateTimeFormat } from '@/utils'
import { cloneDeep } from 'lodash'
import { showSuccessMessage } from '@/notification-utils'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'


export default {
  name: 'SellerOffers',

  title: 'Seller Offers',

  mixins: [copyToClipboardMixin],

  props: {
    hover: Boolean,
    striped: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    icon: {
      type: String,
      default: 'cil-grid'
    },
  },

  data() {
    return {
      isLoading: false,
      isActionLoading: false,

      fields: [
        { key: 'status', sortable: true },
        // { key: 'id', label: 'ID', sortable: true },
        { key: 'categoryName', label: 'Category', sortable: true },
        { key: 'languageName', label: 'Language', sortable: true },
        { key: 'visibility', sortable: true },
        { key: 'name', sortable: true },
        { key: 'updatedAt', label: 'Last Modified', sortable: true },
        { key: 'Actions', filter: false }
      ],

      sellerOffersList: [],

      disableOfferModal: false,
      enableOfferModal: false,

      selectedOffer: null,

      includeTestOnly: false
    }
  },

  computed: {
    ...mapState('sellerOffer', ['sellerOffers', 'trafficSourceTypes']),

    filteredOffers() {
      return this.sellerOffersList.filter(i => this.includeTestOnly ? true : !i.isCategoryTestOnly)
    }
  },

  watch: {
    sellerOffers: {
      immediate: true,
      handler(newValue) {
        this.sellerOffersList = cloneDeep(newValue).map(x => {
          x.editMode = false
          return x
        })
      }
    }
  },

  async created() {
    this.isLoading = true
    await this.list()
    this.isLoading = false
  },

  destroyed() {
    this.$store.dispatch('sellerOffer/reset')
  },

  methods: {
    ...mapActions('sellerOffer', ['list', 'changeStatus']),

    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    },

    getBadge(status) {

      switch (status) {
        case 'Active':
          return 'success'

        case 'Disabled':
          return 'secondary'

        default:
          return 'primary'
      }
    },

    showStatusUpdateControl(item) {
      item.editMode = true
    },
    cancelStatusUpdateControl(item) {
      item.editMode = false
    },

    async updateSellerOfferStatus(item){
      try {
        this.isLoading = true
        await this.changeStatus({ id: item.id, status: item.status })
      } finally {
        item.editMode = false
        this.isLoading = false
      }
    },

    showEnableOrDisableConfirmation(enable, offer) {
      if (enable) {
        this.enableOfferModal = true
      } else {
        this.disableOfferModal = true
      }

      this.selectedOffer = offer
    },

    async confirmDisableOffer(offer) {
      this.isActionLoading = true

      try {
        await this.changeStatus({ id: offer.id, status: 'Disabled' })

        showSuccessMessage('Successfully disabled offer')

        this.disableOfferModal = false

        this.selectedOffer = null

        await this.list()
      } catch {
        //
      } finally {
        this.isActionLoading = false
      }
    },

    async confirmEnableOffer(offer) {
      this.isActionLoading = true

      try {
        await this.changeStatus({ id: offer.id, status: 'Active' })

        showSuccessMessage('Successfully enabled offer')

        this.enableOfferModal = false

        this.selectedOffer = null

        await this.list()
      } catch {
        //
      } finally {
        this.isActionLoading = false
      }
    },

    copySellerOfferLinkForSellersToClipboard(id) {
      this.copyTextToClipboard(`${this.CLIENT_SIDE_BASE_URL}/dashboard/campaigns/seller-available-offers?sellerOfferId=${id}`)
      showSuccessMessage('Link to this seller offer has been copied')
    },
  },
}
</script>
<style lang="scss" scoped></style>
