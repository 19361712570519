import GlobalSettingsAPI from "@/api/global-settings.api"

const CAN_BID_SETTING_ID = 'StopBids'

const state = {
  isBiddingStoppped: false
}

const getters = {
  
}

const actions = {
  async getBiddingStoppedStatus({commit}) {
    const response = await GlobalSettingsAPI.getSettingValue({ type: 'bool', settingId: CAN_BID_SETTING_ID })

    commit('SET_BIDDING_STOPPED_STATUS', response.data)

    return response
  },

  async setBiddingStoppedStatus({commit}, { note, status}) {
    const response = await GlobalSettingsAPI.setSettingValue({ type: 'bool', settingId: CAN_BID_SETTING_ID, value: status, note })

    commit('SET_BIDDING_STOPPED_STATUS', status)
    
    return response
  }
}

const mutations = {
  SET_BIDDING_STOPPED_STATUS(state, status) {
    state.isBiddingStoppped = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}