var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showAccountName)?_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('CSwitch',{staticClass:"mr-2",attrs:{"variant":"3d","color":"primary","horizontal":"","size":"sm","checked":_vm.includeTestOnly},on:{"update:checked":function($event){_vm.includeTestOnly=$event}}}),_c('h5',{staticClass:"m-0"},[_vm._v(" Include Test Only Accounts ")])],1):_vm._e(),_c('CDataTable',{attrs:{"small":"","border":"","sorter":"","column-filter":"","items":_vm.filteredPayments,"fields":_vm.fieldsAdjusted,"sorter-value":{ column: 'paymentDate', asc: false },"items-per-page":10,"pagination":{ align: 'center' }},scopedSlots:_vm._u([{key:"accountName",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{ name: 'SellerAccountDetails', params: { id: item.accountId} },"target":"_blank"}},[_c('strong',[_vm._v(_vm._s(item.accountName))]),_c('CIcon',{staticClass:"ml-1",attrs:{"name":"cil-external-link","size":"custom"}})],1)],1)]}},{key:"amountPaid",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatAmount(item.amountPaid))+" ")])]}},{key:"totalInvoicedAmount",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('strong',[_vm._v(_vm._s(_vm.getSafe(function () { return ("" + (_vm.formatAmount(item.totalInvoicedAmount))); })))])])]}},{key:"paymentMethod",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return ("" + (item.paymentMethod || 'N/A')); }))+" ")])]}},{key:"paymentDate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.formattedPaymentDate)+" ")])]}},{key:"invoiceId",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.invoiceId)?_c('CButton',{attrs:{"size":"sm","color":"info","variant":"outline"},on:{"click":function($event){return _vm.getDownloadLink(item.accountId, item.invoiceId)}}},[_vm._v(" "+_vm._s(item.invoiceId)+" ")]):_vm._e()],1)]}},{key:"transactionType-filter",fn:function(){return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.transactionTypeFilter),expression:"transactionTypeFilter"}],staticClass:"form-control form-control-sm",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.transactionTypeFilter=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":undefined}}),_c('option',{attrs:{"value":"Payment"}},[_vm._v(" Payment ")]),_c('option',{attrs:{"value":"Manual Adjustment"}},[_vm._v(" Manual Adjustment ")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }