import SellerOfferApi from '@/api/sellerOffer.api'

export const thirdPartyTrafficOptions = [{
  value: 'No',
  label: 'No'
},
{
  value: 'Yes',
  label: 'Yes'
},
{
  value: 'Some',
  label: 'Some'
}]

export const offerRegistrationCssClassMapping = {
  'pending': 'warning',
  'approved': 'success',
  'rejected': 'danger',
  'withdrawn': 'danger',
  'waitlisted': 'secondary'
}

function getDefaultRegistrationRequestDetails() {
  return {
    sellerOffer:{
      allowedTrafficSources: [],
    },
    selectedTrafficSources: [],
    bidUrls: null
  }
}

const state = {
  pendingRequests: [],

  approvedSellers: [],

  requestDetails: getDefaultRegistrationRequestDetails(),
}

const getters = {
}

/** 
 * @type {import('vuex').ActionTree<typeof state>} 
 */
const actions = {
  async fetchPendingRequests({ commit }) {
    const response = await SellerOfferApi.getPendingRequests()
    commit('SET_PENDING_REQUESTS', response.data || [])
  },

  async getRequestDetails({ commit }, id) {
    const response = await SellerOfferApi.getRequestDetailsById(id)
    commit('SET_REQUEST_DETAILS', response.data || [])
  },

  async fetchApprovedSellers({ commit }, sellerOfferId) {
    const response = await SellerOfferApi.getApprovedSellersBySellerOfferId(sellerOfferId)
    commit('SET_APPROVED_SELLERS', response.data || [])
  },
  
  async approvePendingRequest(context, payload) {
    const response = await SellerOfferApi.approveSellerOfferRequest(payload)
    return response
  },

  async rejectPendingRequest(context, payload) {
    const response = await SellerOfferApi.rejectSellerOfferRequest(payload)
    return response
  },

  resetRegistrationRequestDetails({ commit }) {
    commit('SET_REQUEST_DETAILS', getDefaultRegistrationRequestDetails())
  },

  async updateOfferRegistration({ commit }, offerRegistration) {
    const response = await SellerOfferApi.updateSellerOfferRequest(offerRegistration)

    commit('SET_REQUEST_DETAILS', offerRegistration)

    return response
  },

  async getOfferRegistrationBidUrls({ commit }, { accountId, offerRegistrationId}) {
    const response = await SellerOfferApi.getSellerOfferRequestBidUrls(accountId, offerRegistrationId)

    commit('SET_REQUEST_BID_URLS', response.data)

    return response
  }
}

/**
* @type {import('vuex').MutationTree<typeof state>}
*/
const mutations = {
  SET_PENDING_REQUESTS(state, data) {
    state.pendingRequests = data
  },

  SET_APPROVED_SELLERS(state, data) {
    state.approvedSellers = data
  },
  
  SET_REQUEST_DETAILS(state, data) {
    state.requestDetails = data
  },

  SET_REQUEST_BID_URLS(state, bidUrls) {
    state.requestDetails = {
      ...state.requestDetails,
      bidUrls
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
