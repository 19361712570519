var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.showInactiveBuyers)?_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',[(!_vm.showSearchBuyerInput)?_c('h5',[_vm._v(" Active Buyers "),_c('CButton',{staticClass:"ml-2",attrs:{"size":"sm","color":"primary"},on:{"click":_vm.onSearchBuyer}},[_vm._v(" Search ")])],1):_vm._e(),(_vm.showSearchBuyerInput)?_c('div',{staticClass:"search-input search-input--margin-bottom-sm w-fixed-200"},[_c('CInput',{attrs:{"placeholder":"Search Buyer","add-wrapper-classes":"mb-2","size":"sm"},model:{value:(_vm.searchBuyer),callback:function ($$v) {_vm.searchBuyer=$$v},expression:"searchBuyer"}}),_c('CIcon',{attrs:{"name":"cil-search","size":"custom"}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column seller-offer-registration-list mr-5 mb-3"},[(_vm.filteredDemandSideData.length)?_c('div',_vm._l((_vm.filteredDemandSideData),function(buyerCampaign){return _c('div',{key:buyerCampaign.id,staticClass:"seller-offer-registration-list__item"},[_c('div',{staticClass:"d-flex mb-3"},[_c('router-link',{staticClass:"text-truncate seller-offer-registration-list__item-account-name h5 mb-0",attrs:{"target":"_blank","title":"View Account","to":{
                  name: 'BuyerAccountDetails',
                  params: { id: buyerCampaign.account.id },
                  exact: true,
                }}},[_vm._v(" "+_vm._s(buyerCampaign.account.name)+" "),_c('CIcon',{staticClass:"ml-1",attrs:{"name":"cil-external-link","size":"custom"}})],1),_c('a',{staticClass:"ml-2 mr-2",attrs:{"href":"","title":"Filter by Buyer Account"},on:{"click":function($event){$event.preventDefault();return _vm.filterByBuyerAccount(buyerCampaign.account)}}},[_c('CIcon',{attrs:{"name":"cil-filter"}})],1)],1),_c('div',[_c('router-link',{attrs:{"target":"_blank","title":"View Buyer Campaign","to":{
                  name: 'BuyerCampaignDetails',
                  params: { id: buyerCampaign.id },
                  exact: true,
                }}},[_c('i',[_vm._v("Campaign: "),_c('strong',{staticClass:"seller-offer-registration-list__item-offer-name"},[_vm._v(" "+_vm._s(buyerCampaign.name)+" ")])]),_c('CIcon',{staticClass:"ml-1",attrs:{"name":"cil-external-link","size":"custom"}})],1),_c('a',{staticClass:"ml-2 mr-2",attrs:{"href":"","title":"Filter by Buyer Campaign"},on:{"click":function($event){$event.preventDefault();return _vm.filterByBuyerCampaign(buyerCampaign)}}},[_c('CIcon',{attrs:{"name":"cil-filter"}})],1),_c('CBadge',{attrs:{"color":_vm.getStatusBadge(buyerCampaign)}},[_vm._v(" "+_vm._s(_vm.getStatus(buyerCampaign))+" ")])],1)])}),0):_c('div',{staticClass:"seller-offer-registration-list__item"},[_c('i',[_vm._v("No Buyer Campaigns")])])])]),_c('TrafficSourceList',{attrs:{"traffic-sources":_vm.demandSideTrafficSources}})],1):_c('CategoryDemandInactive',{attrs:{"buyers-not-participating-actively":_vm.buyersNotParticipatingActively}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }