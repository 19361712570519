<template>
  <div>
    <CCard>
      <CCardHeader>
        Review Notes
      </CCardHeader>

      <CCardBody>
        <div v-show="displayReviewNotes">
          <CForm
            novalidate
            @submit.prevent
          >
            <CRow>
              <CCol
                md="3"
                class="text-right pr-3 mt-2"
              >
                Document
              </CCol>

              <CCol md="3">
                <CInputFile
                  ref="notesAttachment"
                  class="mt-1"
                  add-label-classes="text-right pr-4"
                  rows="5"
                  invalid-feedback=""
                  accept=".jpg,.jpeg,.pdf,.png,.doc,.docx"
                  data-cy="doc"
                  @change="setFile"
                />
              </CCol>
            </CRow>

            <div class="form-group">
              <CTextarea
                v-model.trim="$v.description.$model"
                :is-valid="checkIfValid('description')"
                class="mt-2"
                label="Notes"
                add-label-classes="text-right pr-4"
                placeholder="Enter the notes here"
                horizontal
                rows="5"
                invalid-feedback="Please enter the notes (5 characters minimum)"
                data-cy="notes"
              />
            </div>

            <div class="form-row">
              <CCol
                :md="{ offset: '3', size: '9' }"
                :sm="{ offset: '3', size: '9' }"
              >
                <ButtonLoading
                  type="submit"
                  size="sm"
                  color="primary"
                  :loading="isLoading"
                  data-cy="submit-notes"
                  :disabled="!isValid"
                  @click="onSubmit"
                >
                  Add note
                </ButtonLoading>

                <CButton
                  class="ml-2"
                  size="sm"
                  @click="clearFile"
                >
                  Clear File
                </CButton>
              </CCol>
            </div>
          </CForm>
        </div>
        <div v-show="!displayReviewNotes">
          Can not load the Review notes, please retry or contact support.
        </div>

        <div 
          v-show="displayReviewNotes"
          class="mt-3"
          data-cy="previous-notes"
        >
          <h5>Previous Notes</h5>

          <ReviewNotesList
            :review-notes="reviewNotes"
            :parent-object="parentObject"
            @reload-review-notes="fetchNotes"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'

import formGenericMixin from '@/mixins/form-generic-mixin'
import { dateTimeFormat } from '@/utils'
import ReviewNotesList from './ReviewNotesList.vue'

export default {
  name: 'ReviewNotes',

  components: {
    ReviewNotesList,
  },

  mixins: [formGenericMixin],

  props: {
    parentObject: {
      type: Object,
      default() {
        return {}
      }
    },

    type: {
      type: String,
      default: ''
    }
  },

  validations: {
    description: {
      required,
      minLength: minLength(5)
    }
  },

  data() {
    return {
      isLoading: false,
      isLoadingFailed: false,
      description: '',
      attachment: '',
    }
  },

  computed: {
    ...mapState('notes', ['reviewNotes']),

    displayReviewNotes () {
      return !this.isLoadingFailed && Boolean(this.noteListId)
    },

    noteListId() {
      return this.parentObject?.noteListId
    }
  },

  watch: {
    noteListId: {
      immediate: true,
      async handler() {
        await this.fetchNotes()
      }
    }
  },

  destroyed() {
    this.resetReviewNotes()
  },

  methods: {
    ...mapActions('notes', [
      'getReviewNotes', 
      'addReviewNotes',
      'resetReviewNotes'
    ]),

    async fetchNotes() {
      this.isLoading = true

      try {
        await this.getReviewNotes(this.noteListId)
        this.isLoadingFailed = false
      } catch (error) {
        this.isLoadingFailed = true
      } finally {
        this.isLoading = false
      }
    },

    async onSubmit() {
      this.$v.description.$touch()

      if (this.isValid) {
        this.isLoading = true

        try {

          await this.addReviewNotes({
            noteListId: this.noteListId,
            description: this.description,
            attachment: this.attachment,
            parentObjectId: this.parentObject?.id
          })

          this.resetForm()
        } catch (error) {
          // Handle error
        } finally {
          this.isLoading = false
        }
      }
    },

    checkIfValid(fieldName) {
      const field = this.$v[fieldName]

      if (!field.$dirty) {
        return null
      }

      return !field.$invalid
    },

    formatName(user) {
      if (!user){
        return "Unknown"
      }
      return user
    },

    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    },

    setFile(files) {
      this.attachment = files[0]
    },

    resetForm() {
      this.description = ''
      this.attachment = ''
      this.clearFile()
      
      this.resetFormDirtyState()
    },

    clearFile() {
      this.$refs['notesAttachment'].$el.children[0].value = ''
    }
  }
}
</script>

<style scoped lang="scss">
.notes {
  white-space: break-spaces;
  font-size: 95%;
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
}
</style>
