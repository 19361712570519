<template>
  <div>
    <div v-if="accountDetails">
      <h4>
        Seller Details

        <CButton
          color="info"
          size="sm"
          :to="`/dashboard/accounts/seller/${accountDetails.id}`"
          target="_blank"
          class="ml-2"
        >
          View More Account Details
        </CButton>

        <span
          v-if="getSafe(() => accountDetails.testOnly)"
          class="badge badge-pill badge-danger ml-3"
        >
          <CIcon
            name="cilWarning"
            class="mr-1"
          />

          Test Only Account
        </span>
      </h4>

      <dl class="row">
        <dt class="col-sm-3 text-right">
          Account ID
        </dt>
        <dd class="col-sm-9 d-flex align-items-center">
          {{ accountDetails.id }}
          <a
            href
            class="ml-1"
            @click.prevent="
              copyAccountIdToClipboard(accountDetails.id)
            "
          ><CIcon
            name="cil-copy"
          /></a>
        </dd>
        <dt class="col-sm-3 text-right">
          Seller Name
        </dt>
        <dd class="col-sm-9 d-flex align-items-center">
          {{ accountDetails.name }}
        </dd>

        <dt class="col-sm-3 text-right">
          Ringba Account ID
        </dt>
        <dd class="col-sm-9 d-flex align-items-center">
          {{ accountDetails.ringbaAccountId }}
          <a
            v-if="accountDetails.ringbaAccountId"
            href
            class="ml-1"
            @click.prevent="
              copyAccountIdToClipboard(accountDetails.ringbaAccountId)
            "
          ><CIcon
            name="cil-copy"
          /></a>
        </dd>
      </dl>


      <div class="form-group form-row">
        <dt class="col-sm-3 text-right">
          Allowed Categories
        </dt>
        <div class="col-sm-9 col-xl-5">
          <span
            v-for="(cat, $idx) in getSafe(() => accountDetails.allowedCategories)"
            :key="cat.id"
          >
            {{ cat.name }}
            <span
              v-if="$idx !== accountDetails.allowedCategories.length - 1"
              class="mx-2"
            >|</span>
          </span>
        </div>
      </div>
      <div class="form-group form-row">
        <dt class="col-sm-3 text-right">
          Allowed Traffic Sources
        </dt>
        <div class="col-sm-9 col-xl-5">
          <span
            v-for="(ts, $idx) in getSafe(() => accountDetails.allowedTrafficSources)"
            :key="ts.id"
          >
            {{ ts.name }}
            <i>({{ ts.trafficSourceType }})</i>
            <span
              v-if="$idx !== accountDetails.allowedTrafficSources.length - 1"
              class="mx-2"
            >|</span>
          </span>
        </div>
      </div>


      <h5>
        Representative
      </h5>
      <dl
        v-if="accountDetails.representative"
        class="row"
      >
        <dt class="col-sm-3 text-right">
          Name
        </dt>
        <dd class="col-sm-9">
          {{ accountDetails.representative.firstName }} {{ accountDetails.representative.lastName }}
        </dd>
        <dt
          class="col-sm-3 text-right"
        >
          Email
        </dt>
        <dd class="col-sm-9">
          <a :href="`tel:${accountDetails.representative.email}`">
            {{ accountDetails.representative.email }}
          </a>
        </dd>
        <dt class="col-sm-3 text-right">
          Skype
        </dt>
        <dd class="col-sm-9">
          <a :href="`skype:${accountDetails.representative.skype}?chat`">{{ accountDetails.representative.skype }}</a>
        </dd>
      </dl>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'


import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'SellerAccountBasicDetails',

  mixins: [copyToClipboardMixin],

  props: {
    accountId: {
      type: String,
      required: true
    },
  },

  computed: {
    ...mapState('accounts', {
      accountDetails: 'sellerAccountDetails'
    }),
  },

  async created () {
    if (this.accountId) {
      try {
        await this.getSellerAccountDetails(this.accountId)
      } catch (error) {
        // Exception handled in interceptor
      }
    }
  },

  methods: {
    ...mapActions('accounts', [
      'getSellerAccountDetails',
    ]),

    copyAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Account ID copied to clipboard')
    }
  },
}
</script>
<style>
  
</style>