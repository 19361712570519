<template>
  <div>
    <div
      v-if="!showInactiveBuyers"
      class="d-flex flex-wrap"
    >
      <div>
        <h5 v-if="!showSearchBuyerInput">
          Active Buyers
          <CButton
            size="sm"
            color="primary"
            class="ml-2"
            @click="onSearchBuyer"
          >
            Search
          </CButton>
        </h5>

        <div
          v-if="showSearchBuyerInput"
          class="search-input search-input--margin-bottom-sm w-fixed-200"
        >
          <CInput
            v-model="searchBuyer"
            placeholder="Search Buyer"
            add-wrapper-classes="mb-2"
            size="sm"
          />

          <CIcon
            name="cil-search"
            size="custom"
          />
        </div>

        <div class="d-flex flex-column seller-offer-registration-list mr-5 mb-3">
          <div v-if="filteredDemandSideData.length">
            <div
              v-for="buyerCampaign in filteredDemandSideData"
              :key="buyerCampaign.id"
              class="seller-offer-registration-list__item"
            >
              <div class="d-flex mb-3">
                <router-link
                  target="_blank"
                  title="View Account"
                  class="text-truncate seller-offer-registration-list__item-account-name h5 mb-0"
                  :to="{
                    name: 'BuyerAccountDetails',
                    params: { id: buyerCampaign.account.id },
                    exact: true,
                  }"
                >
                  {{ buyerCampaign.account.name }}
                  <CIcon
                    name="cil-external-link"
                    size="custom"
                    class="ml-1"
                  />
                </router-link>
                <a
                  href
                  title="Filter by Buyer Account"
                  class="ml-2 mr-2"
                  @click.prevent="
                    filterByBuyerAccount(buyerCampaign.account)
                  "
                ><CIcon
                  name="cil-filter"
                /></a>
              </div>
              <div>
                <router-link
                  target="_blank"
                  title="View Buyer Campaign"
                  :to="{
                    name: 'BuyerCampaignDetails',
                    params: { id: buyerCampaign.id },
                    exact: true,
                  }"
                >
                  <i>Campaign:
                    <strong
                      class="seller-offer-registration-list__item-offer-name"
                    >
                      {{
                        buyerCampaign.name
                      }}
                    </strong></i>
                  <CIcon
                    name="cil-external-link"
                    size="custom"
                    class="ml-1"
                  />
                </router-link>
                <a
                  href
                  title="Filter by Buyer Campaign"
                  class="ml-2 mr-2"
                  @click.prevent="
                    filterByBuyerCampaign(buyerCampaign)
                  "
                ><CIcon
                  name="cil-filter"
                /></a>
                <CBadge :color="getStatusBadge(buyerCampaign)">
                  {{ getStatus(buyerCampaign) }}
                </CBadge>
              </div>
            </div>
          </div>
          <div
            v-else
            class="seller-offer-registration-list__item"
          >
            <i>No Buyer Campaigns</i>
          </div>
        </div>
      </div>

      <TrafficSourceList :traffic-sources="demandSideTrafficSources" />
    </div>

    <CategoryDemandInactive
      v-else
      :buyers-not-participating-actively="buyersNotParticipatingActively"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

import buyerCampaignStatusMixin from '@/views/Campaigns/buyer/mixins/buyer-campaign-status-mixin'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { showSuccessMessage } from '@/notification-utils'
import TrafficSourceList from './TrafficSourceList.vue'
import CategoryDemandInactive from './CategoryDemandInactive.vue'

export default {
  name: 'CategoryDemandDetails',

  components: {
    TrafficSourceList,
    CategoryDemandInactive,
  },

  mixins: [copyToClipboardMixin, buyerCampaignStatusMixin],

  props: {
    demandSideData: {
      type: Array,
      default() {
        return []
      }
    },

    demandSideTrafficSources: {
      type: Array,
      default() {
        return []
      }
    },

    showInactiveBuyers: {
      type: Boolean,
      default: false
    },

    buyersNotParticipatingActively: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      showSearchBuyerInput: false,
      searchBuyer: ''
    }
  },

  computed: {
    filteredDemandSideData() {
      return this.demandSideData.filter(bc => !this.searchBuyer || bc.account.name.toLowerCase().includes(this.searchBuyer.toLowerCase()))
    }
  },

  created () {
  },

  methods: {
    ...mapMutations('categorySupplyDemand', [
      'ADD_TO_BUYER_ACCOUNT_FILTERING',
      'ADD_TO_BUYER_CAMPAIGN_FILTERING'
    ]),

    ...mapActions('categorySupplyDemand', [
      'addToBuyerCampaignFilter'
    ]),

    copyAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Account ID copied to clipboard')
    },

    filterByBuyerAccount(buyerAccount) {
      this.ADD_TO_BUYER_ACCOUNT_FILTERING(buyerAccount)
    },

    filterByBuyerCampaign(buyerCampaign) {
      this.addToBuyerCampaignFilter(buyerCampaign)
    },

    onSearchBuyer() {
      this.showSearchBuyerInput = true
    },

  },
}
</script>
<style scoped lang="scss">
</style>