<template>
  <div>
    <CCard>
      <CCardHeader data-cy="header">
        Create Super Admin
      </CCardHeader>

      <CCardBody>
        <CForm
          novalidate
          @submit.prevent
        >
          <CInput
            v-model="$v.user.firstName.$model"
            name="firstName"
            label="First Name"
            horizontal
            :is-valid="checkIfValid('firstName')"
            invalid-feedback="This field is required"
          />

          <CInput
            v-model="$v.user.lastName.$model"
            name="lastName"
            label="Last Name"
            horizontal
            :is-valid="checkIfValid('lastName')"
            invalid-feedback="This field is required"
          />

          <CInput
            v-model="$v.user.email.$model"
            name="email"
            label="Email"
            horizontal
            type="email"
            :is-valid="checkIfValid('email')"
            invalid-feedback="This field is required and should be an email"
          />

          <CInput
            v-model="$v.user.phoneNumber.$model"
            name="phoneNumber"
            label="Mobile Number"
            horizontal
            :is-valid="checkIfValid('phoneNumber')"
            invalid-feedback="This field is required"
          />

          <CInput
            v-model="$v.user.skype.$model"
            name="skype"
            label="Skype"
            horizontal
            :is-valid="checkIfValid('skype')"
            invalid-feedback="This field is required"
          />

          <div class="form-row">
            <CCol
              :md="{ offset: '3', size: '9' }"
              :sm="{offset: '3', size: '9'}"
            >
              <ButtonLoading
                color="primary"
                :loading="isLoading"
                type="submit"
                @click="onSubmit(user)"
              >
                Create
              </ButtonLoading>
            </CCol>
          </div>
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

import { SUPER_ADMIN_ROLE } from '@/store/modules/users.store'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'SuperAdminUserCreate',

  title: 'Create Admin User',

  validations() {
    return {
      user: {
        firstName: {
          required
        },

        lastName: {
          required
        },

        email: {
          required,
          email
        },

        phoneNumber: {
          required
        },

        skype: {
          required
        }
      }
    }
  },

  data() {
    return {
      user: {
        group: SUPER_ADMIN_ROLE,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: 'RingbaX',
        skype: ''
      },

      isLoading: false
    }
  },

  computed: {
    isValid () { return !this.$v.$invalid },
  },

  methods: {
    ...mapActions('users', [
      'createUser'
    ]),

    async onSubmit(user) {
      this.$v.user.$touch()

      if (this.isValid) {
        try {
          this.isLoading = true

          await this.createUser(user)

          showSuccessMessage('Admin user created successfully')

          this.$router.push({
            name: 'SuperAdminUsers'
          })
        } catch (error) {
          //
        } finally {
          this.isLoading = false
        }
      }
    },

    checkIfValid (fieldName) {
      const field = this.$v.user[fieldName]

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
  },
}
</script>

<style>

</style>