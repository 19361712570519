<template>
  <div>
    <CCard>
      <CCardBody>
        <div
          v-if="isLoading"
          :style="{ height: '80vh' }"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <CSpinner
            style="width:4rem;height:4rem;"
            color="info"
            grow
          />
        </div>

        <div v-else>
          <CDataTable
            responsive
            small
            fixed
            border
            sorter
            :items="items"
            :fields="fields"
            :items-per-page="20"
            :pagination="{ align: 'center' }"
          >
            <template #creationDate="{item}">
              <td>
                {{ getSafe(() => `${formatDate(item.creationDate)}`) }}
              </td>
            </template>
            
            <template #amount="{item}">
              <td>
                <strong>{{ getSafe(() => `${formatAmount(item.amount || 0.00)}`) }}</strong>
              </td>
            </template>
            
            <template #amountPaid="{item}">
              <td>
                <strong>{{ getSafe(() => `${formatAmount(item.amountPaid || 0.00)}`) }}</strong>
              </td>
            </template>

            <template #accountName="{item}">
              <td>
                {{ getSafe(() => `${item.accountName}`) }}
                <router-link
                  class="ml-2 align-text-bottom"
                  target="_blank"
                  :to="{
                    name: 'BuyerAccountDetails',
                    params: { id: item.accountId },
                    exact: true
                  }"
                >
                  <CIcon
                    name="cil-external-link"
                    size="custom"
                  />
                </router-link>
              </td>
            </template>
            
            <template #status="{item}">
              <td>
                <CBadge :color="getBadge(item.status)">
                  {{ item.status }}
                </CBadge>
              </td>
            </template>

            <template #id="{item}">
              <td>
                <CButton
                  size="sm"
                  color="info"
                  variant="outline"
                  @click="getDownloadLink(item.accountId, item.id)"
                >
                  {{ item.id }}
                </CButton>
              </td>
            </template>

            <template #actions="{item}">
              <td>
                <CButton
                  v-if="item.status === 'Pending' || item.status === 'Completed'" 
                  color="info"
                  class="ml-2"
                  size="sm"
                  @click="confirmProcessAdvancePayRequest(item)"
                >
                  Process
                </CButton>
              </td>
            </template>
          </CDataTable>
        </div>
      </CCardBody>
    </CCard>

    <CModal
      title="Please login again to verify its you"
      color="primary"
      data-cy="login-modal"
      :show.sync="showLoginModal"
    >
      <LoginInternal
        v-if="showLoginModal"
        @success="onLoginSuccess"
        @error="onLoginFailed"
      />

      <template #footer-wrapper>
        <div />
      </template>
    </CModal>

    <CModal
      :title="`Confirm payment received #${finalizeAdvancePayViewModel.id}`"
      color="success"
      :show.sync="showConfirmationModal"
    >
      <div>
        Are you sure you want to add funds to <strong>{{ getSafe(() => finalizeAdvancePayViewModel.accountName) }}</strong>?
      </div>
      <p />
      <div>
        <CForm>
          <CInput
            label="Current Balance"
            :disabled="true"
            :placeholder="formatAmount(accountBalance.cashBalance)"
          />
          <CInput
            label="Creation Date"
            :disabled="true"
            :placeholder="formatDate(finalizeAdvancePayViewModel.creationDate)"
          />
          <CInput
            type="number"
            label="Expected Amount"
            name="amount"
            :disabled="true"
            :placeholder="formatAmount(finalizeAdvancePayViewModel.amountExpected)"
          />
          <CInput
            v-model="$v.finalizeAdvancePayViewModel.amountPaid.$model"
            type="number"
            label="Actual Amount"
            name="amount"
            :is-valid="checkIfValid(['finalizeAdvancePayViewModel', 'amountPaid'])"
            invalid-feedback="Invalid amount"
          />
          <div
            v-if="isFormEdited && $v.finalizeAdvancePayViewModel.amountPaid.$invalid"
            class="invalid-feedback d-flex mb-3 mt-2"
          >
            Please enter paid amount (greater than zero)
          </div>
        </CForm>
      </div>

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isUpdating"
          @click="onConfirmSubmit"
        >
          Add Funds
        </ButtonLoading>

        <CButton
          color="danger"
          @click="showConfirmationModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import booleanBadgeMixin from '@/mixins/boolean-badge-mixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import { minValue, required } from 'vuelidate/lib/validators'
import { formatDate, formatAmount } from '@/utils'
import formGenericMixin from '@/mixins/form-generic-mixin'
import LoginInternal from '../Login/LoginInternal.vue'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'AdvancePayments',
  components: { LoginInternal },

  mixins: [booleanBadgeMixin, formGenericMixin],

  props: {
     items: {
      type: Array,
      default() {
        return []
      }
    },
    pendingOnly: {
      type: Boolean,
      default(){
        return true
      }
    },
    isLoading: {
      type: Boolean,
      default(){
        return false
      }
    },
  },

  validations() {
    return {
      finalizeAdvancePayViewModel: {
        amountPaid: {
          required,
          minValue: minValue(1)
        }
      }
    }
  },

  data() {
    return {
      fields: [
        { key: 'creationDate', label: 'Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true, filter: true },
        { key: 'amount', label: 'Expected Amount', sortable: true, filter: true },
        { key: 'amountPaid', label: 'Paid Amount', sortable: true, filter: true },
        { key: 'id', label: 'Payment Reference', sortable: true, filter: true },
        { key: 'actions', filter: false }
      ],
      isUpdating: false,
      showConfirmationModal: false,
      showLoginModal: false,
      finalizeAdvancePayViewModel: {},
    }
  },

  computed: {
    ...mapState('accounts', {
      accountDetails: 'buyerAccountDetails',
      accountBalance: 'accountBalance'
    }),

    ...mapGetters('auth', [
      'isUserLoggedInWithinLastXMinutes'
    ]),

    isUserLoggedInWithinLast5Minutes() {
      return this.isUserLoggedInWithinLastXMinutes(5)
    }
  },

  methods: {
    ...mapActions('billing', [
      'generateDownloadLink',
    ]),

    ...mapActions('auth', [
      'logout'
    ]),

    ...mapActions('accounts', [
      'finalizeAdvancePayRequest',
      'getAccountBalance',
    ]),

    getBadge(status) {
      switch (status) {
        case 'Outstanding':
          return 'primary'

        case 'Paid':
          return 'success'

        case 'PartiallyPaid':
          return 'secondary'

        case 'Overdue':
          return 'danger'

        default:
          return 'warning'
      }
    },
    
    onLoginSuccess() {
      this.showLoginModal = false
      this.showConfirmationModal = true
    },

    onLoginFailed() {
      this.onLogout()
    },

    async confirmProcessAdvancePayRequest(item){
      await this.getAccountBalance(item.accountId)

      this.finalizeAdvancePayViewModel = { 
          amountExpected: item.amount,
          amountPaid: item.amount,
          accountName: item.accountName,
          id: item.id,
          creationDate: item.creationDate,
          version: item.version
        }

      if (!this.isUserLoggedInWithinLast5Minutes) {
          this.showLoginModal = true
          return
        }

        this.showConfirmationModal = true
    },

    async onConfirmSubmit() {
      try {
        this.isUpdating = true
        await this.finalizeAdvancePayRequest(this.finalizeAdvancePayViewModel)
        
        this.$emit('reload-advance-pay-requests')
        showSuccessMessage('Successfully completed the request')
      }
      catch(e) {
        showErrorMessage(e.response?.data?.title)
      } 
      finally {
        this.isUpdating = false
        this.showConfirmationModal = false
        this.finalizeAdvancePayViewModel = {}
      }
    },

    async getDownloadLink(accountId, invoiceId) {
      const downloadURL = await this.generateDownloadLink({ accountId, invoiceId })
      window.open(downloadURL)
    },

    formatDate,
    formatAmount
  }
}
</script>

<style></style>
