<template>
  <div>
    <UsersList
      :users="superAdminUsers"
      :is-loading-list="isLoadingList"
    >
      <template #header>
        <CIcon name="cil-people" /> Admin Users
        
        <CButton
          class="float-right"
          size="sm"
          color="info"
          @click="
            $router.push({
              name: 'SuperAdminUserCreate',
              exact: true
            })
          "
        >
          <CIcon
            size="sm"
            name="cil-plus"
          /> Create New
        </CButton>
      </template>
    </UsersList>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UsersList from './UsersList.vue'

export default {
  name: 'SuperAdminUsers',

  title: 'Admin Users',

  components: {
    UsersList,
  },

  data() {
    return {
      isLoadingList: false
    }
  },

  computed: {
    ...mapState('users', [
      'superAdminUsers'
    ])
  },

  async created () {
    try {
      this.isLoadingList = true
      await this.getSuperAdminUsers()
    } catch (error) {
      //
    } finally {
      this.isLoadingList = false
    }
  },


  methods: {
    ...mapActions('users', [
      'getSuperAdminUsers'
    ])
  },
}
</script>

<style>

</style>