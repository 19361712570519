import BidModifiersAPI from '@/api/bidModifiers.api'

export const MARGIN_PERCENT_MINIMUM = 0

const state = {
  bidModifiersState: {}
}

const getters = {
  getBidModifiersState(state) {
    return state.bidModifiersState
  }
}

const actions = {
  async getBidModifiers({ commit }, payload) {
     if (!payload?.parentObjectType || !payload?.parentObjectId) {
       return {}
     }

    const response = await BidModifiersAPI.getBidModifiers(payload.parentObjectType, payload.parentObjectId)
    commit('SET_BID_MODIFIERS', response.data || {})   
  },
  
  async setBidModifiers({ commit }, payload) {
    await BidModifiersAPI.setBidModifiers(payload.bidModifiers, payload.parentObjectType, payload.parentObjectId)
    commit('SET_BID_MODIFIERS', payload.bidModifiers)  
  },

  resetBidModifiers(context){
    context.commit("RESET_BID_MODIFIERS")
  }
}

const mutations = {
    SET_BID_MODIFIERS(state, data) {
      state.bidModifiersState = data
  },

  RESET_BID_MODIFIERS(state){
    state.bidModifiersState = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}