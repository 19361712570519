<template>
  <div v-if="accountDetails">
    <dl class="row">
      <dt class="col-12 col-sm-3 text-sm-right">
        Onboarding Status
      </dt>
      <dd class="col-sm-9">
        <CBadge :color="statusVariant">
          {{ accountDetails.onboardingStatus }}
        </CBadge>
      </dd>
    </dl>

    <h3>
      Company
    </h3>

    <dl class="row">
      <dt class="col-12 col-sm-3 text-sm-right">
        Account ID
      </dt>
      <dd class="col-sm-9 d-flex align-items-center">
        {{ accountDetails.id }}

        <a
          href
          class="ml-1"
          @click.prevent="
            copyAccountIdToClipboard(accountDetails.id)
          "
        ><CIcon
          name="cil-copy"
        /></a>
      </dd>

      <dt class="col-12 col-sm-3 text-sm-right">
        Name
      </dt>
      <dd class="col-sm-9 d-flex align-items-center">
        {{ accountDetails.name }}
      </dd>

      <dt class="col-12 col-sm-3 text-sm-right">
        Ringba Account Id
      </dt>
      <dd class="col-sm-9 d-flex align-items-center">
        {{ accountDetails.ringbaAccountId }}

        <a
          v-if="accountDetails.ringbaAccountId"
          href
          class="ml-1"
          @click.prevent="
            copyAccountIdToClipboard(accountDetails.ringbaAccountId, 'Ringba Account Id copied to clipboard')
          "
        ><CIcon
          name="cil-copy"
        /></a>
      </dd>

      <dt class="col-12 col-sm-3 text-sm-right">
        Street Address
      </dt>
      <dd class="col-sm-9">
        {{ getSafe(() => accountDetails.companyAddress.addressLine1) }}
      </dd>

      <dt class="col-12 col-sm-3 text-sm-right">
        Address 2
      </dt>
      <dd class="col-sm-9">
        {{ getSafe(() => accountDetails.companyAddress.addressLine2) }}
      </dd>


      <dt class="col-12 col-sm-3 text-sm-right mt-4">
        City
      </dt>
      <dd class="col-sm-9 mt-4">
        {{ getSafe(() => accountDetails.companyAddress.city) }}
      </dd>

      <dt class="col-12 col-sm-3 text-sm-right">
        State
      </dt>
      <dd class="col-sm-9">
        {{ getSafe(() => accountDetails.companyAddress.state) }}
      </dd>

      <dt class="col-12 col-sm-3 text-sm-right">
        Zipcode
      </dt>
      <dd class="col-sm-9">
        {{ getSafe(() => accountDetails.companyAddress.zip) }}
      </dd>

      <dt class="col-12 col-sm-3 text-sm-right">
        Country
      </dt>
      <dd class="col-sm-9">
        {{ getSafe(() => accountDetails.companyAddress.countryCode) }}
      </dd>


      <dt class="col-12 col-sm-3 text-sm-right mt-4">
        Phone
      </dt>
      <dd class="col-sm-9 mt-4">
        <a :href="`tel:${accountDetails.companyPhone}`">
          {{ accountDetails.companyPhone }}
        </a>
      </dd>

      <dt class="col-12 col-sm-3 text-sm-right">
        Website
      </dt>
      <dd class="col-sm-9">
        <a
          :href="companyWebsite"
          target="_blank"
          rel="noopener"
        >
          {{ accountDetails.companyWebsite }}
        </a>
      </dd>
      
      <dt class="col-12 col-sm-3 text-sm-right">
        LinkedIn
      </dt>
      <dd class="col-sm-9">
        <a
          v-if="accountDetails.companyLinkedIn"
          :href="companyLinkedIn"
          target="_blank"
        >
          View
        </a>
      </dd>
      
      

      <dt class="col-12 col-sm-3 text-sm-right">
        Twitter
      </dt>
      <dd class="col-sm-9">
        <a
          v-if="!!accountDetails.companyTwitter"
          :href="companyTwitter"
          target="_blank"
        >
          View
        </a>
      </dd>

      <dt class="col-12 col-sm-3 text-sm-right">
        Facebook
      </dt>
      <dd class="col-sm-9">
        <a
          v-if="!!accountDetails.companyFacebook"
          :href="companyFacebook"
          target="_blank"
        >
          View
        </a>
      </dd>

      <dt class="col-12 col-sm-3 text-sm-right mt-4">
        Tracking Technology
      </dt>
      <dd class="col-sm-9 mt-4">
        {{ accountDetails.callTechnology }}
      </dd>
    </dl>


    <h3>
      Representative
    </h3>

    <dl
      v-if="accountDetails.representative"
      class="row"
    >
      <dt class="col-12 col-sm-3 text-sm-right">
        Name
      </dt>
      <dd class="col-sm-9">
        {{ accountDetails.representative.firstName }} {{ accountDetails.representative.lastName }}
      </dd>
      <dt
        class="col-12 col-sm-3 text-sm-right"
      >
        Email
      </dt>
      <dd class="col-sm-9">
        <a
          data-cy="representative-email"
          :href="`mailto:${accountDetails.representative.email}`"
        >
          {{ accountDetails.representative.email }}
        </a>
      </dd>
      <dt class="col-12 col-sm-3 text-sm-right">
        Phone
      </dt>
      <dd class="col-sm-9">
        <a :href="`tel:${accountDetails.representative.cellPhone}`">
          {{ accountDetails.representative.cellPhone }}
        </a>
      </dd>
      <dt class="col-12 col-sm-3 text-sm-right">
        Skype
      </dt>
      <dd class="col-sm-9">
        <a :href="`skype:${accountDetails.representative.skype}?chat`">{{ accountDetails.representative.skype }}</a>
      </dd>

      <dt
        class="col-12 col-sm-3 text-sm-right"
      >
        LinkedIn
      </dt>
      <dd class="col-sm-9">
        <a
          v-if="accountDetails.representative.linkedIn"
          data-cy="representative-linkedin"
          target="_blank"
          :href="`//${accountDetails.representative.linkedIn}`"
        >
          View
        </a>
      </dd>
    </dl>
  </div>
</template>

<script>
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { showSuccessMessage } from '@/notification-utils'

const cssClassMapping = {
  'pending': 'warning',
  'approved': 'success',
  'rejected': 'danger'
}

export default {
  mixins: [copyToClipboardMixin],

  props: {
    accountDetails: {
      type: Object,
      default() {
        return {}
      }
    },

    type: {
      type: String,
      default: 'buyer'
    }
  },

  computed: {
    statusVariant() {
      return cssClassMapping[this.accountDetails?.onboardingStatus?.toLowerCase()]
    },

    companyWebsite() {
      return this.handleURLProtocol(this.accountDetails.companyWebsite)
    },

    companyTwitter() {
      return this.handleURLProtocol(this.accountDetails.companyTwitter)
    },

    companyLinkedIn() {
      return this.handleURLProtocol(this.accountDetails.companyLinkedIn)
    },

    companyFacebook() {
      return this.handleURLProtocol(this.accountDetails.companyFacebook)
    }
  },

  methods: {
    copyAccountIdToClipboard(id, customMessage = '') {
      this.copyTextToClipboard(id)
      showSuccessMessage(customMessage ? customMessage : 'Account ID copied to clipboard')
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
