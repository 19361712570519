<template>
  <div>
    <CTableWrapper
      :items="trafficSources"
      :is-loading="isLoading"
      small
      border
      sorter
      :fields="fields"
      type="TrafficSource"
      icon="cil-tags"
      caption="TrafficSources"
      :sorter-value="{ column: 'name', asc: true }"
      column-filter
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CTableWrapper from '@/components/common/Table.vue'
export default {
  name: 'TrafficSources',
  title: 'Traffic Sources',
  components: {
    CTableWrapper
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'status', sortable: true },
        { key: 'trafficSourceType', label: 'Type', sortable: true },
        { key: 'name', sortable: true },
        { key: 'subId', label: 'Sub ID', sortable: true },
        { key: 'Actions', filter: false }
      ]
    }
  },
  computed: {
    ...mapState('trafficSource', ['trafficSources'])
  },
  async created() {
    this.isLoading = true
    await this.list()
    this.isLoading = false
  },
  methods: {
    ...mapActions('trafficSource', ['list'])
  },
}
</script>
<style lang="scss" scoped></style>
