<template>
  <div>
    <div
      v-if="isLoadingList"
      :style="{ height: '80vh' }"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <CSpinner
        class="custom-spinner"
        color="info"
        grow
      />
    </div>

    <div v-else>
      <CDataTable
        data-cy="table-accounts"
        responsive
        small
        border
        sorter
        :items="accountsCopy"
        :fields="fields"
        :items-per-page="10"
        :pagination="{ align: 'center' }"
      >
        <template #authorizeRingbaToContact="{item}">
          <td>
            <CBadge :color="getBooleanBadge(item.authorizeRingbaToContact)">
              {{ item.authorizeRingbaToContact ? 'Yes' : 'No' }}
            </CBadge>
          </td>
        </template>

        <template #name="{item}">
          <td>
            {{
              item.name
            }}

            <CBadge
              v-if="item.testOnly"
              color="danger"
              class="ml-1"
            >
              Test
            </CBadge>
          </td>
        </template>

        <template #representative="{item}">
          <td>
            {{
              `${item.representative.firstName} ${item.representative.lastName}`
            }}
          </td>
        </template>

        <template #ringbaAccountId="{item}">
          <td>
            {{ item.ringbaAccountId }}
          </td>
        </template>

        <template #country="{item}">
          <td>
            {{ item.companyAddress.countryCode }}
          </td>
        </template>

        <template #registrationDate="{item}">
          <td>
            {{ item.formattedRegistrationDate }}
          </td>
        </template>

        <template #adminApprovalDate="{item}">
          <td>
            {{ item.formattedAdminApprovalDate }}
          </td>
        </template>

        <template #onboardingStatus="{item}">
          <td>
            <div v-if="!item.editMode">
              <CBadge :color="getOnboardingStatusBadge(item.onboardingStatus)">
                {{ item.onboardingStatus }}
              </CBadge>
              <span @click="showOnboardingStatusSelect(item)">
                <CIcon
                  style="cursor: pointer;"
                  class="ml-2 my-2"
                  name="cil-pencil"
                />
              </span>
            </div>
            <div v-if="item.editMode">
              <CSelect
                v-if="type === 'buyer'"
                :value.sync="item.onboardingStatus"
                :options="buyerOnboardingStatusCodes"
                placeholder="Please select"
              />

              <CSelect
                v-if="type === 'seller'"
                :value.sync="item.onboardingStatus"
                :options="sellerOnboardingStatusCodes"
                placeholder="Please select"
              />

              <span @click="updateOnboardingStatus(item)">
                <CIcon
                  style="cursor: pointer;"
                  class="ml-2 my-2 text-success"
                  name="cil-check-circle"
                />
              </span>
              <span @click="cancelUpdate(item)">
                <CIcon
                  style="cursor: pointer;"
                  class="ml-2 my-2 text-danger"
                  name="cil-x-circle"
                />
              </span>
            </div>
          </td>
        </template>

        <template #Actions="{item}">
          <td>
            <CButton
              color="info"
              size="sm"
              :to="`/dashboard/accounts/${type}/${item.id}`"
            >
              View Details
            </CButton>
            <CButton
              v-if="item.enabled === true"
              class="ml-2"
              color="warning"
              size="sm"
              @click="showDisableAccountModal(item)"
            >
              Disable
            </CButton>
            <CButton
              v-if="item.enabled === false"
              class="ml-2"
              color="success"
              size="sm"
              @click="showEnableAccountModal(item)"
            >
              Enable
            </CButton>
          </td>
        </template>
      </CDataTable>
    </div>

    <CModal
      title="Confirm disable"
      color="warning"
      data-cy="disable-account-modal"
      :show.sync="disableAccountModal"
    >
      Are you sure you want to disable this account?      

      <CTextarea
        v-model="remarks"
        class="mt-2"
        name="Remarks"
        label="Remarks"
        :is-valid="areRemarksValid"
        invalid-feedback="Remarks are mandatory for disabling"
        small
        required
        rows="3"
        :debounce="500"
        description="Remarks will be sent in the email notification to the client"
      />

      <CTextarea
        v-if="selectedAccount !== null"
        v-model="accountNameNote"
        class="mt-2"
        name="AccountNameNote"
        :label="'Enter account name for confirmation - ' + selectedAccount.name"
        :is-valid="isAccountNameInputAsExpected(selectedAccount)"
        invalid-feedback="Enter account name (company name) to confirm account disabling"
        small
        required
        rows="1"
        :debounce="500"
      />

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          :disabled="!isAccountNameInputAsExpected(selectedAccount) || !areRemarksValid"
          @click="confirmDisableAccount(selectedAccount);"
        >
          Disable
        </ButtonLoading>

        <CButton
          color="danger"
          @click="disableAccountModal = false; accountNameNote = ''; remarks = '';"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm enable"
      color="success"
      data-cy="enable-account-modal"
      :show.sync="enableAccountModal"
    >
      Are you sure you want to enable this account?      

      <CTextarea
        v-model="remarks"
        class="mt-2"
        name="Remarks"
        label="Remarks"
        :is-valid="areRemarksValid"
        invalid-feedback="Remarks are mandatory for enabling"
        small
        required
        rows="3"
        :debounce="500"
        description="Remarks will be sent in the email notification to the client"
      />

      <CTextarea
        v-if="selectedAccount !== null"
        v-model="accountNameNote"
        class="mt-2"
        name="AccountNameNote"
        :label="'Enter account name for confirmation - ' + selectedAccount.name"
        :is-valid="isAccountNameInputAsExpected(selectedAccount)"
        invalid-feedback="Enter account name (company name) to confirm account enabling"
        small
        required
        rows="1"
        :debounce="500"
      />

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          :disabled="!isAccountNameInputAsExpected(selectedAccount) || !areRemarksValid"
          @click="confirmEnableAccount(selectedAccount);"
        >
          Enable
        </ButtonLoading>

        <CButton
          color="danger"
          @click="enableAccountModal = false; accountNameNote = ''; remarks = '';"
        >
          Cancel
        </CButton>
      </template>
    </CModal>    
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { dateTimeFormat } from '@/utils'
import { cloneDeep } from 'lodash'
import { showSuccessMessage } from '@/notification-utils'

import booleanBadgeMixin from '@/mixins/boolean-badge-mixin'

export default {
  name: 'AccountsList',

  mixins: [booleanBadgeMixin],

  props: {
    accounts: {
      type: Array,
      default() {
        return []
      }
    },

    isLoadingList: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      tableFilterConfig: {
        placeholder:
          'Type to search buyers by company name, representative name or email address',
        label: ' '
      },
      enableAccountModal: false,
      disableAccountModal: false,
      isLoading: false,
      accountsCopy: [],
      enableAutoreload: true,
      searchAccountName: '',
      selectedAccount: null,
      accountNameNote: '',
      accountAction: '',
      remarks: '',
      fields: [
        { key: 'onboardingStatus', label: 'Status' },
        { key: 'name', label: 'Company' },
        { key: 'ringbaAccountId', label: 'Ringba Account Id', sorter: false },
        { key: 'representative', label: 'Representative' },
        { key: 'email', label: 'Email', sorter: true },
        { key: 'country', label: 'Country' },
        { key: 'registrationDate', label: 'Registration Date' },
        { key: 'adminApprovalDate', label: 'Final Approval Date' },
        { key: 'Actions', filter: false }
      ]
    }
  },

  computed: {
    ...mapState('accounts', [
      'buyerOnboardingStatusCodes',
      'sellerOnboardingStatusCodes'      
    ]),
  },

  watch: {
    accounts: {
      immediate: true,

      handler(newValue) {
      this.accountsCopy = cloneDeep(newValue).map(a => {
        a.editMode = false
        a.email = a.representative.email
        a.formattedRegistrationDate = dateTimeFormat.format(new Date(a.registrationDate))
        a.formattedAdminApprovalDate = !a.adminApprovalDate || new Date(a.adminApprovalDate).getFullYear() === 1 ? '' : dateTimeFormat.format(new Date(a.adminApprovalDate))
        return a
      })
    }
    }
  },

  created() {
    document.title = `${this.type.charAt(0).toUpperCase() +
      this.type.slice(1)} Accounts`
  },

  methods: {
    ...mapActions('accounts', [
      'updateBuyerAccountDetails',
      'updateSellerAccountDetails',
      'disableAccount',
      'enableAccount'
    ]),

    getOnboardingStatusBadge(status) {
      switch (status) {
        case 'Completed':
          return 'success'      

        case 'Pending':
        case 'PendingDocumentSigning':
        default:
          return 'warning'
      }
    },

    showOnboardingStatusSelect(item) {
      item.editMode = true
    },

    async updateOnboardingStatus(item) {
      const typeToFunctionMap = {
        buyer: this.updateBuyerAccountDetails,
        seller: this.updateSellerAccountDetails
      }
      const updateFunction = typeToFunctionMap[this.type]
      if (updateFunction) {
        const accountId = item.id
        const onboardingStatus = item.onboardingStatus
        try {
          await updateFunction({
            accountId,
            updateObject: {
              onboardingStatus
            }
          })
        } finally {
          item.editMode = false
        }
      }
    },

    cancelUpdate(item) {
      item.editMode = false
    },

    async confirmDisableAccount(account) {
      try {
        this.isLoading = true
        await this.disableAccount({accountId: account.id, type: this.type, remarks: this.remarks})
        
        showSuccessMessage('Successfully disabled account ' + account.id)
        this.disableAccountModal = false
      } catch (error) {
        //
      } finally {
        this.isLoading = false
        this.accountNameNote = ''
        this.remarks = ''
      }
    },

    async confirmEnableAccount(account) {
      try {
        this.isLoading = true
        await this.enableAccount({accountId: account.id, type: this.type, remarks: this.remarks})

        showSuccessMessage('Successfully enabled account ' + account.id)
        this.enableAccountModal = false
      } catch (error) {
        //
      } finally {
        this.isLoading = false
        this.accountNameNote = ''
        this.remarks = ''
      }
    },

    showDisableAccountModal(account) {
      this.accountAction = 'disable'
      this.selectedAccount = account
      this.disableAccountModal = true
    },

    showEnableAccountModal(account) {
      this.accountAction = 'enable'
      this.selectedAccount = account
      this.enableAccountModal = true
    },

    isAccountNameInputAsExpected(account) {
      if (this.accountAction === 'enable' && this.accountNameNote === account.name) {return true}
      if (this.accountAction === 'disable' && this.accountNameNote === account.name) {return true}
      return false
    },

    areRemarksValid() {
      if (this.remarks.length) {
        return true
      }
      else {      
        return false
      }
    }
  }
}
</script>

<style></style>
