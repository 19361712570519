export default {
    methods: {
        getBadge(status) {
            switch (status) {
                case 'Approved':
                    return 'success'

                case 'Rejected':
                    return 'danger'

                case 'Waitlisted':
                    return 'secondary'

                case 'Pending':
                default:
                    return 'warning'
            }
        }
    },
}