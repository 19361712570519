<template lang="">
  <CCard>
    <CCardHeader>
      Edit Buyer RTB Settings
    </CCardHeader>
  
    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CRow class="mb-3">
          <CCol
            sm="3"
            class="pr-3"
          >
            <label class="col-form-label">
              Caller ID (CID) exposure level
            </label>
          </CCol>  
          
          <CInputRadioGroup
            class="col-sm-9"
            add-label-classes="text-right pr-4"
            add-input-classes="w-25"
            :options="cidExposureOptions"
            :custom="true"
            :checked.sync="rtbSettingsLocal.cidExposure"
            label="CID exposure"
            @update:checked="onRtbSettingsUpdated"       
          />          
        </CRow>
          
        <CCol
          :md="{ offset: '3', size: '9' }"
          :sm="{ offset: '3', size: '9' }"
        >
          <CButton
            type="submit"
            size="sm"
            color="primary"
            :disabled="formEdited === false"
            @click="showConfirmationModal = true"
          >
            Update
          </CButton>
        </CCol>
      </CForm>
    </CCardBody>
  
    <CModal
      title="Confirm changing RTB settings"
      color="success"
      :show.sync="showConfirmationModal"
    >
      Are you sure you want to change buyer RTB settings?
  
      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="updateRtbSettings"
        >
          Update
        </ButtonLoading>
  
        <CButton
          color="danger"
          @click="showConfirmationModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>
  
  <script>
  
  import { mapActions } from 'vuex'
  import formGenericMixin from '@/mixins/form-generic-mixin'
  import { showSuccessMessage } from '@/notification-utils'
  
  export default {
    name: 'BuyerRtbSettings',
  
    mixins: [formGenericMixin],
  
    props: {
      accountId: {
        type: String,
        required: true
      },
  
      rtbSettings: {
        type: Object,
        default() {
          return {}
        }
      }
    },  
   
    data() {
      return {
        isLoading: false,  
        showConfirmationModal: false,
        formEdited: false,  
        rtbSettingsLocal: {
          cidExposure: 'None'         
        },
        cidExposureOptions: [
          {value: 'None', label: 'Do not expose CID', props: { 
            description: 'CID is not being sent in a bid request in buyer RTB campaigns' }},
          {value: 'Send', label: 'Send CID', props: { 
            description: 'Allow to send CID in a bid request in buyer RTB campaigns' }},
          {value: 'SendExposed', label: 'Send and expose CID', props: { 
            description: 'Allow to send CID in a bid request in buyer RTB campaigns and allow exposure of CID within Ringba Account of a buyer' }}]
      }
    },
   
    watch: {
        rtbSettings: {
            immediate: true,
            handler(newValue) {                
                this.rtbSettingsLocal = newValue 
                  ? {...newValue} 
                  : { cidExposure: 'None' }
            }
        }
    },    
  
    methods: {
      ...mapActions('accounts', ['updateBuyerRtbSettings']),

      onRtbSettingsUpdated() {
        this.formEdited = true      
      },
  
      async updateRtbSettings() {
        this.isLoading = true
        this.formEdited = false
  
        try {
          await this.updateBuyerRtbSettings({
            accountId: this.accountId,
            rtbSettings: this.rtbSettingsLocal
          })
  
          this.showConfirmationModal = false
  
          showSuccessMessage('Successfully updated RTB settings')
  
          this.resetFormDirtyState()
        } catch (error) {
          //
        } finally {
          this.isLoading = false
        }
      }
    },
  }
  </script>
  <style lang="">
    
  </style>