<template>
  <div class="card card-sm py-2 mb-2 border">
    <dl class="row mb-0">
      <dt class="col-sm-3 col-12 text-sm-right">
        Approved
      </dt>

      <dd class="col-sm-9 col-12">
        {{ log.satisfied ? 'Yes' : 'No' }}
      </dd>
      
      <dt class="col-sm-3 col-12 text-sm-right">
        Decided by
      </dt>

      <dd class="col-sm-9 col-12">
        {{ decidedBy }}
      </dd>

      <dt
        class="col-sm-3 col-12 text-sm-right"
      >
        Decided on
      </dt>

      <dd
        class="col-sm-9 col-12"
      >
        {{ getSafe(() => formatDate(log.decidedOn)) }}
      </dd>
    </dl>
  </div>
</template>

<script>
import { dateTimeFormat } from '@/utils'

export default {
  name: 'AccountRequirementLog',

  props: {
    log: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    decidedBy() {
      return this.log.decidedBy?.name ?? this.log.decidedBy?.id 
    }
  },

  methods: {
    formatDate(date) {
      if (!date) {
        return ''
      }

      return dateTimeFormat.format(new Date(date))
    },
  },
}
</script>
<style lang="">
  
</style>