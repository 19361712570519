<template>
  <div>
    <table class="table table-fixed table-bordered table-draggable">
      <thead>
        <tr>
          <th>Priority</th>
          <th>Name</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>

      <draggable
        v-model="checklistItems"
        tag="tbody"
      >
        <tr
          v-for="(item, idx) in checklistItems"
          :key="item.id"
        >
          <td>{{ idx + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.description }}</td>
          <td>
            <CButton
              color="info"
              size="sm"
              :to="`/dashboard/registration-checklist-item/${type.toLowerCase()}/edit/${item.id}`"
            >
              Edit
            </CButton>

            <CButton
              color="danger"
              size="sm"
              type="button"
              class="ml-2"
              @click="deleteChecklistItemConfirm(item)"
            >
              Delete
            </CButton>
          </td>
        </tr>
      </draggable>
    </table>

    <CModal
      title="Confirm delete"
      color="danger"
      :show.sync="showDeleteModal"
    >
      Are you sure you want to delete this checklist item for {{ type.toLowerCase() }}?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="confirmDelete"
        >
          Delete
        </ButtonLoading>

        <CButton
          color="danger"
          @click="showDeleteModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'

import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'RegistrationChecklistItemsList',

  components: {
    draggable
  },

  props: {
    type: {
      type: String,
      default: 'Seller'
    },

    checklistItems: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      fields: Object.freeze([
        { key: 'name', label: 'Name', sortable: true },
        { key: 'description', label: 'Description', sortable: false },
        { key:'Actions', filter: false },
      ]),

      selectedChecklistItem: null,

      showDeleteModal: false,

      isLoading: false
    }
  },

  created () {
  },

  methods: {
    ...mapActions('registrationChecklistItem', [
      'deleteRegistrationChecklistItemsConfig'
    ]),

    deleteChecklistItemConfirm(item) {
      this.showDeleteModal = true
      this.selectedChecklistItem = item
    },

    async confirmDelete() {
      try {
        this.isLoading = true

        await this.deleteRegistrationChecklistItemsConfig(this.selectedChecklistItem.id)

        showSuccessMessage('Successfully deleted checklist item')

        this.showDeleteModal = false

        this.$emit('reload-checklist-items')

        this.selectedChecklistItem = null
      } catch {
        //
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  
</style>