<template>
  <div>
    <h4 class="mb-3">
      Select the following fields to view data
    </h4>

    <div>
      <CSelect
        :value.sync="categoryId"
        add-label-classes=""
        label="Category"
        :horizontal="{ label: 'col-sm-2 col-lg-1', input: 'col-sm-3 col-12'}"
        :options="allCategoriesFormatted"
        placeholder="Please select"
        invalid-feedback="Please select a category"
        @change="onCategoryChange"
      />
      <CSelect
        :value.sync="languageId"
        add-label-classes=""
        label="Language"
        :horizontal="{ label: 'col-sm-2 col-lg-1', input: 'col-sm-3 col-12'}"
        :options="languageOptions"
        placeholder="Please select"
        invalid-feedback="Please select a language"
      />
      <CSelect
        :value.sync="countryId"
        add-label-classes=""
        label="Country"
        :horizontal="{ label: 'col-sm-2 col-lg-1', input: 'col-sm-3 col-12'}"
        :options="countriesFormatted"
        placeholder="Please select"
        invalid-feedback="Please select a country"
      />
    </div>

    <hr>

    <div>
      <h4>Filters</h4>

      <CAlert
        color="info"
      >
        <strong>Note:</strong>
        Filters do not apply to inactive seller or buyer information
      </CAlert>

      <div v-if="noFiltersSelected">
        No filters selected
      </div>

      <div v-if="hasTrafficSourcesFilter">
        <h5 class="mt-3">
          Traffic Sources

          <CButton
            color="primary"
            variant="outline"
            size="sm"
            class="ml-2"
            @click="clearAllTrafficSources"
          >
            Clear Traffic Sources
          </CButton>
        </h5>

        <div
          v-if="hasTrafficSourcesFilter"
          class="d-flex flex-wrap flex-gap-sm"
        >
          <span
            v-for="ts in filteredTrafficSourcesWithDetails"
            :key="ts.id"
            class="filter-selection mr-2"
          >
            {{ ts.type }} - {{ ts.name }}
            
            <strong
              class="ml-2 pr-1 clickable"
              @click="removeTrafficSourceFilter(ts.id)"
            >X</strong>
          </span>
        </div>
      </div>

      <div v-if="hasSellerAccountsFilter">
        <h5 class="mt-3">
          Seller Accounts
        </h5>

        <div
          class="d-flex"
        >
          <span
            v-for="sa in filterBySellerAccounts"
            :key="sa.id"
            class="filter-selection mr-2"
          >
            {{ sa.name }}
            
            <strong
              class="ml-2 pr-1 clickable"
              @click="removeSellerAccountFilter(sa.id)"
            >X</strong>
          </span>
        </div>
      </div>

      <div>
        <h5 class="mt-3">
          Seller Offers
        </h5>

        <div class="d-flex align-items-center mb-3 mt-3 fs-1">
          <CSwitch
            color="info"
            :checked.sync="hidePendingReviewOfferRegistrations"
            size="sm"
            class="mr-2"
          />
          Hide Pending Review Offer Participation
        </div>

        <div
          class="d-flex"
        >
          <span
            v-for="so in filterBySellerOffers"
            :key="so.id"
            class="filter-selection mr-2"
          >
            {{ so.name }}
            
            <strong
              class="ml-2 pr-1 clickable"
              @click="removeSellerOfferFilter(so.id)"
            >X</strong>
          </span>
        </div>
      </div>

      <div v-if="hasBuyerAccountsFilter">
        <h5 class="mt-3">
          Buyer Accounts
        </h5>

        <div
          class="d-flex"
        >
          <span
            v-for="ba in filterByBuyerAccounts"
            :key="ba.id"
            class="filter-selection mr-2"
          >
            {{ ba.name }}
            
            <strong
              class="ml-2 pr-1 clickable"
              @click="removeBuyerAccountFilter(ba.id)"
            >X</strong>
          </span>
        </div>
      </div>

      <div>
        <h5 class="mt-3">
          Buyer Campaigns
        </h5>

        <div class="d-flex align-items-center mb-3 mt-3 fs-1">
          <CSwitch
            color="info"
            :checked.sync="hidePendingReviewCampaigns"
            size="sm"
            class="mr-2"
          />
          Hide Pending Review Campaigns
        </div>

        <div
          class="d-flex"
        >
          <span
            v-for="bc in filterByBuyerCampaigns"
            :key="bc.id"
            class="filter-selection mr-2"
          >
            {{ bc.name }}
            
            <strong
              class="ml-2 pr-1 clickable"
              @click="removeBuyerCampaignFilter(bc)"
            >X</strong>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

function sortCategoryNames(ts1, ts2) {
  const nameA = ts1.label.toUpperCase() // ignore upper and lowercase
  const nameB = ts2.label.toUpperCase() // ignore upper and lowercase
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  // names must be equal
  return 0
}

export default {
  name: 'CategorySupplyDemandFilters',

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapState('category', {
      selectedCategory: 'selectedRow',
      categories: 'categories'
    }),

    ...mapState('categorySupplyDemand', [
      'selectedCountry',
      'selectedLanguage',
      'selectedCategoryId',
      'filterByTrafficSources',
      'filterBySellerAccounts',
      'filterByBuyerAccounts',
      'filterBySellerOffers',
      'filterByBuyerCampaigns',
      'hidePendingReviewBuyerCampaigns',
      'hidePendingReviewSellerOfferRegistrations'
    ]),

    ...mapGetters('categorySupplyDemand', [
      'filteredTrafficSourcesWithDetails'
    ]),

    ...mapState('country', ['countries']),

    allCategoriesFormatted() {
      return this.categories.map(category => {
        return {
          value: category.id,
          label: category.name
        }
      }).sort(sortCategoryNames)
    },

    countriesFormatted() {
      return this.countries.map(country => {
        return {
          value: country.id,
          label: country.name
        }
      })
    },

    languageOptions() {
      return this.selectedCategory.languages?.map(l => ({value: l.id, label: l.name})) ?? []
    },

    categoryId: {
      get() {
        return this.selectedCategoryId
      },

      set(value) {
        this.SET_CATEGORY(value)
      }
    },

    languageId: {
      get() {
        return this.selectedLanguage
      },

      set(value) {
        this.SET_LANGUAGE(value)
      }
    },

    countryId: {
      get() {
        return this.selectedCountry
      },

      set(value) {
        this.SET_COUNTRY(value)
      }
    },

    hidePendingReviewCampaigns: {
      get() {
        return this.hidePendingReviewBuyerCampaigns
      },

      set(value) {
        this.SET_HIDE_PENDING_REVIEW_BUYER_CAMPAIGNS(value)
      }
    },

    hidePendingReviewOfferRegistrations: {
      get() {
        return this.hidePendingReviewSellerOfferRegistrations
      },

      set(value) {
        this.SET_HIDE_PENDING_REVIEW_OFFER_REGISTRATIONS(value)
      }
    },

    hasTrafficSourcesFilter() {
      return Object.keys(this.filterByTrafficSources).length
    },

    hasSellerAccountsFilter() {
      return Object.keys(this.filterBySellerAccounts).length
    },

    hasBuyerAccountsFilter() {
      return Object.keys(this.filterByBuyerAccounts).length
    },

    hasBuyerCampaignsFilter() {
      return Object.keys(this.filterByBuyerCampaigns).length
    },

    hasSellerOffersFilter() {
      return Object.keys(this.filterBySellerOffers).length
    },

    noFiltersSelected() {
      return !this.hasTrafficSourcesFilter
        && !this.hasSellerAccountsFilter
        && !this.hasBuyerAccountsFilter
        && !this.hasBuyerCampaignsFilter
        && !this.hasSellerOffersFilter
    }
  },

  watch: {
    categoryId: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue && newValue != oldValue) {
          await this.fetchCategoryDetails(this.categoryId)
        }
      }
    }
  },

  created () {
    this.getCategories()
    this.getCountries()
  },

  methods: {
    ...mapActions('country', { 
      getCountries: 'list' 
    }),

    ...mapActions('category', {
      getCategory: 'findOne',
      getCategories: 'list',
    }),

    ...mapMutations('category', {
      setSelectedCategory: 'SET_SELECTED_ROW'
    }),

    ...mapMutations('categorySupplyDemand', [
      'SET_COUNTRY',
      'SET_LANGUAGE',
      'SET_CATEGORY',
      'REMOVE_FROM_TRAFFIC_SOURCE_FILTERING',
      'CLEAR_TRAFFIC_SOURCE_FILTERING',
      'REMOVE_FROM_SELLER_ACCOUNT_FILTERING',
      'REMOVE_FROM_BUYER_ACCOUNT_FILTERING',
      'REMOVE_FROM_SELLER_OFFER_FILTERING',
      'REMOVE_FROM_BUYER_CAMPAIGN_FILTERING',
      'SET_HIDE_PENDING_REVIEW_BUYER_CAMPAIGNS',
      'SET_HIDE_PENDING_REVIEW_OFFER_REGISTRATIONS'
    ]),

    ...mapActions('categorySupplyDemand', [
      'removeFromBuyerCampaignFilter'
    ]),

    async fetchCategoryDetails(categoryId) {
      try {
        this.isLoading = true

        if (this.selectedCategory?.id !== this.categoryId) {
          await this.getCategory(categoryId)
        }

        this.languageId = 'en'
        this.countryId = 'US'

        this.$emit('category-loaded')
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },

    async onCategoryChange() {
      this.languageId = null
      this.setSelectedCategory({})
      await this.fetchCategoryDetails(this.categoryId)
      this.$emit('update-category')
    },

    removeTrafficSourceFilter(trafficSourceId) {
      this.REMOVE_FROM_TRAFFIC_SOURCE_FILTERING(trafficSourceId)
    },

    removeSellerAccountFilter(sellerAccountId) {
      this.REMOVE_FROM_SELLER_ACCOUNT_FILTERING(sellerAccountId)
    },

    removeBuyerAccountFilter(buyerAccountId) {
      this.REMOVE_FROM_BUYER_ACCOUNT_FILTERING(buyerAccountId)
    },

    removeSellerOfferFilter(sellerOfferId) {
      this.REMOVE_FROM_SELLER_OFFER_FILTERING(sellerOfferId)
    },

    removeBuyerCampaignFilter(buyerCampaign) {
      this.REMOVE_FROM_BUYER_CAMPAIGN_FILTERING(buyerCampaign.id)
    },

    clearAllTrafficSources() {
      this.CLEAR_TRAFFIC_SOURCE_FILTERING()
    }
  },
}
</script>
<style scoped lang="scss">
.filter-selection {
  background: rgb(216, 216, 216);
  border-radius: 4px;
  font-size: 0.9rem;
  padding: 2px 5px;
}
  
</style>