import TrafficSourcesAPI from '@/api/trafficSource.api'

const state = {
  trafficSourceTypes: ['Inbound', 'Outbound', 'Transfer'],
  trafficSources: [],
  selectedRow: {}
}

const getters = {
  activeTrafficSources(state) {
    return state.trafficSources.filter(c => c.status === 'Active')
  },

  trafficSourcesIdMap(state) {
    /**
     * @type {Array<import('../category').TrafficSource>}
     */
    const trafficSources = state.trafficSources

    return new Map(trafficSources.map((ts) => [ts.id, ts]))
  }
}

const actions = {
  async list({ commit }) {
    const response = await TrafficSourcesAPI.list()
    commit('SET_TRAFFICSOURCES', response.data)
  },
  async create(context, newTrafficSource) {
    const response = await TrafficSourcesAPI.create(newTrafficSource)
    return response
  },
  async findOne({ commit }, id) {
    const response = await TrafficSourcesAPI.findOne(id)
    commit('SET_SELECTED_ROW', response.data)
  },
  async update(context, updatedRow) {
    const response = await TrafficSourcesAPI.patch(updatedRow.id, updatedRow)
    return response
  }
}

const mutations = {
  SET_TRAFFICSOURCES(state, trafficSources) {
    state.trafficSources = trafficSources
  },
  SET_SELECTED_ROW(state, row) {
    state.selectedRow = row
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
