import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import { iconsSet as icons } from './assets/icons/icons.js'
import Notifications from 'vue-notification'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Vuelidate from 'vuelidate'
import vMultiselectListbox from 'vue-multiselect-listbox'
import vSelect from 'vue-select'
import VueSync from 'vue-sync'
import DateRangePicker from 'vue2-daterange-picker'
import 'moment-timezone'

import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import ButtonLoading from '@/components/common/ButtonLoading.vue'
import DatePickerInput from '@/components/common/DatePickerInput.vue'
import CodeContainer from '@/components/common/CodeContainer.vue'
import { getCustomisedNavBarDropdown } from '@/components/Layout/CustomSidebarNavDropdown'

import titleMixin from '@/mixins/title-mixin'

import { REQUIRED_FIELD_ERROR_MESSAGE } from '@/utils'

Vue.use(Notifications)
Vue.use(CoreuiVuePro)
Vue.use(Vuelidate)
Vue.use(VueSync)

const debug = process.env.NODE_ENV !== 'production'

if (!debug) {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    dsn:
      'https://75d63a109a00485691ebc49abb6fa5c4@o70049.ingest.sentry.io/5655937',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  })
}

Vue.component('v-multiselect-listbox', vMultiselectListbox)
Vue.component('date-picker', DatePicker)
Vue.component('ButtonLoading', ButtonLoading)
Vue.component('DatePickerInput', DatePickerInput)
Vue.component('CodeContainer', CodeContainer)
Vue.component('DateRangePicker', DateRangePicker)
Vue.component('v-multiselect-listbox', vMultiselectListbox)
Vue.component('v-select', vSelect)
Vue.component('CustomSidebarNavDropdown', getCustomisedNavBarDropdown(Vue.options.components['CSidebarNavDropdown']))

Vue.config.productionTip = false

Vue.mixin(titleMixin)

Vue.mixin({
  data() {
    return {
      get REQUIRED_FIELD_ERROR_MESSAGE() {
        return REQUIRED_FIELD_ERROR_MESSAGE
      },

      get CLIENT_SIDE_BASE_URL() {
        return process.env.VUE_APP_CLIENT_SIDE_BASE_URL
      }
    }
  },
  methods: {
    getSafe(fn, defaultValue) {
      try {
        const val = fn()
        return val != null ? val : defaultValue
      } catch (e) {
        return defaultValue
      }
    },

    handleURLProtocol(url) {
      if (url?.includes('http://') || url?.includes('https://')) {
        return url
      } else {
        return `//${url}`
      }
    }
  }
})

const app = new Vue({
  router,
  store,
  icons,
  render: h => h(App)
})

if (window.Cypress) {
  // only available during E2E tests
  window.app = app
}

app.$mount('#app')
