<template>
  <div>
    <CCard>
      <CCardHeader>
        Review Checklist
      </CCardHeader>

      <CCardBody>
        <template v-for="(item, idx) in checklistItemsForRegistration">
          <RegistrationRequestChecklistItem
            v-if="item.type !== CUSTOM_CHECKLIST_TYPE.IdentityCheck"
            :key="item.checklistItemDetails.id"
            :class="{'mb-4': idx !== checklistItemsForRegistration.length - 1, 'mb-0': idx === checklistItemsForRegistration.length - 1}"
            :checklist-item="item"
            :priority="idx + 1"
            :registration-request-id="registrationRequestId"
          />

          <RegistrationRequestIdentityChecklistItem
            v-if="item.type === CUSTOM_CHECKLIST_TYPE.IdentityCheck"
            :key="`${item.type}-${idx}-${registrationRequestId}`"
            :class="{'mb-4': idx !== checklistItemsForRegistration.length - 1, 'mb-0': idx === checklistItemsForRegistration.length - 1}"
            :checklist-item="item"
            :priority="idx + 1"
            :registration-request-id="registrationRequestId"
          />
        </template>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import { CUSTOM_CHECKLIST_TYPE } from '@/store/modules/registration-requests.store'
import RegistrationRequestChecklistItem from './RegistrationRequestChecklistItem.vue'
import RegistrationRequestIdentityChecklistItem from './RegistrationRequestIdentityChecklistItem.vue'

export default {
  name: 'RegistrationRequestChecklist',

  components: {
    RegistrationRequestChecklistItem,
    RegistrationRequestIdentityChecklistItem,
  },

  props: {
    type: {
      type: String,
      default: ''
    },

    registrationRequestId: {
      type: String,
      default() {
        return ''
      }
    }
  },

  data() {
    return {
      CUSTOM_CHECKLIST_TYPE
    }
  },

  computed: {
    // TODO: Not being used remove it later
    ...mapState('registrationChecklistItem', [
      'sellerRegistrationChecklistItemsConfig',
      'buyerRegistrationChecklistItemsConfig'
    ]),

    ...mapGetters('registrationRequests', [
      'checklistItemsForRegistration'
    ]),

    checklistItemsConfig() {
      return this.type?.toLowerCase() === 'seller' ? this.sellerRegistrationChecklistItemsConfig : this.buyerRegistrationChecklistItemsConfig
    },
  },

  watch: {
    registrationRequestId: {
      immediate: true,
      async handler() {
        await this.RESET_REGISTRATION_REQUEST_CHECKLIST_ITEMS()
        await this.loadChecklistItems()
        await this.loadChecklistItemsForRegistration()
      }
    }
  },

  methods: {
    ...mapActions('registrationChecklistItem', [
      'getRegistrationChecklistItemsConfig'
    ]),

    ...mapActions('registrationRequests', [
      'getRegistrationChecklistItems'
    ]),


    ...mapMutations('registrationRequests', [
      'RESET_REGISTRATION_REQUEST_CHECKLIST_ITEMS'
    ]),

    async loadChecklistItems() {
      try {
        this.isLoading = true
        await this.getRegistrationChecklistItemsConfig(this.type)
      } catch {
        //
      } finally {
        this.isLoading = false
      }
    },

    async loadChecklistItemsForRegistration() {
      try {
        this.isLoading = true
        await this.getRegistrationChecklistItems(this.registrationRequestId)
      } catch {
        //
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
<style lang="">
  
</style>