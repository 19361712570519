<template>
  <div>
    <CCard>
      <CCardHeader>
        Create {{ typeTitle }} Application Checklist Item
      </CCardHeader>
      <CCardBody>
        <CForm
          novalidate
          @submit.prevent
        >
          <CInput
            id="name"
            v-model="$v.form.name.$model"
            label="Name"
            horizontal
            placeholder="Checklist Name"
            :is-valid="checkIfValid(['form', 'name'])"
            :invalid-feedback="cannotBeBlank('Name')"
          />

          <CInput
            id="name"
            v-model="$v.form.description.$model"
            label="Description"
            horizontal
            placeholder="Description"
            :is-valid="checkIfValid(['form', 'description'])"
            :invalid-feedback="cannotBeBlank('Description')"
          />

          <div class="form-row">
            <CCol
              :md="{ offset: '3', size: '9' }"
              :sm="{ offset: '3', size: '9' }"
            >
              <ButtonLoading
                type="submit"
                size="sm"
                color="primary"
                :loading="isLoading"
                @click="onFormSubmit"
              >
                Create
              </ButtonLoading>

              <CButton
                type="button"
                size="sm"
                color="danger"
                class="ml-2"
                @click="$router.back()"
              >
                Cancel
              </CButton>
            </CCol>
          </div>
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required  } from 'vuelidate/lib/validators'

import formGenericMixin from '@/mixins/form-generic-mixin'
import { showSuccessMessage } from '@/notification-utils'
import {capitlizeFirstLetter} from '@/utils'

export default {
  name: 'RegistrationChecklistItemCreate',

  mixins: [formGenericMixin],

  validations() {
    return {
      form: {
        name: {
          required
        },

        description: {
          required
        },
      }
    }
  },

  data() {
    return {
      form: {
        name: '',
        description: ''
      },

      isLoading: false,

      type: ''
    }
  },

  computed: {
    typeTitle() {
      return capitlizeFirstLetter(this.type)
    }
  },

  watch: {
    '$route.params.type': {
      immediate: true,
      async handler() {
        this.type = this.$route.params.type.toLowerCase()
        document.title = `${this.typeTitle} Application Checklist Item`
      }
    }
  },

  methods: {
    ...mapActions('registrationChecklistItem', [
      'createRegistrationChecklistItemsConfig'
    ]),

    async onFormSubmit() {
      this.submitted = true

      if (!this.isValid) {
        return
      }

      try {
        this.isLoading = true

        await this.createRegistrationChecklistItemsConfig({
          ...this.form,
          type: this.type
        })

        showSuccessMessage(`Successfully created checklist item for ${this.type}`)

        this.routeToListPage()
      } catch {
        //
      } finally {
        this.isLoading = false
      }
    },

    routeToListPage() {
      this.$router.push({ 
        name: 'RegistrationChecklistItems', 
        params: {
          type: this.type.toLowerCase()
        } 
      })
    }
  },
}
</script>
<style lang="scss" scoped>
  
</style>