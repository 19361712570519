<template>
  <div>
    <CDataTable
      hover
      column-filter
      sorter
      border
      small
      :items="filteredOffers"
      :fields="fields"
      :items-per-page="15"
      :pagination="{align: 'center'}"
      :sorter-value="{ column: 'name', asc: false }"
    >
      <template #name="{item}">
        <td>
          {{
            item.sellerOffer.name
          }}
          <CBadge
            v-if="item.sellerOffer.isCategoryTestOnly"
            color="danger"
            class="ml-1"
          >
            Test
          </CBadge>
        </td>
      </template>

      <template #isAffiliateTrafficApproved="{item}">
        <td>
          <span v-if="item.thirdPartyTraffic === 'No'">
            Not selling 3rd party traffic
          </span>
          <span v-else>
            {{ item.isAffiliateTrafficApproved ? 'Allowed' : 'Blocked' }}
          </span>
        </td>
      </template>

      <template #Actions="{item}">
        <td>
          <CButton
            color="info"
            size="sm"
            class="mr-2 mt-2"
            :to="`/dashboard/seller-offers/${item.sellerOffer.id}/edit`"
            target="_blank"
          >
            View Seller Offer
          </CButton>

          <CButton
            color="info"
            size="sm"
            class="mr-2 mt-2"
            :to="`/dashboard/seller-offers/${item.sellerOffer.id}/registration/${item.id}`"
            target="_blank"
          >
            View Offer Registration
          </CButton>

          <CButton
            color="info"
            size="sm"
            class="mr-2 mt-2"
            @click="showBidUrlModal(item)"
          >
            View Bid URL
          </CButton>

          <CButton
            v-if="item.status === 'Approved' && item.enabled"
            color="success"
            class="mr-2 mt-2"
            size="sm"
            @click="showPauseParticipationModal(item)"
          >
            Pause
          </CButton>

          <CButton
            v-if="item.status === 'Approved'"
            class="mr-2 mt-2"
            color="secondary"
            size="sm"
            @click="showRemoveParticipationModal(item)"
          >
            Remove
          </CButton>

          <CButton
            v-if="item.status === 'Approved' && !item.enabled"
            color="success"
            class="mr-2 mt-2"
            size="sm"
            @click="showResumeParticipationModal(item)"
          >
            Resume
          </CButton>
        </td>
      </template>
    </CDataTable>

    <!-- Offer bid URL details -->
    <CModal
      title="Bidding URL"
      color="primary"
      :show.sync="showBiddingUrl"
      size="xl"
      @update:show="handleModalClose"
    >
      <SellerOfferBiddingDetails
        v-if="selectedOfferRegistration"
        :offer-registration="selectedOfferRegistration"
      />
    </CModal>
          
    <!-- Remove confirmation modal -->
    <CModal
      title="Confirm remove participation"
      color="warning"
      data-cy="disable-user-modal"
      :show.sync="disableUserModal"
    >
      Are you sure you want to permanently remove this seller from the offer participation?

      <p class="alert alert-warning mt-2">
        <strong>Note:</strong>
        Removal should be used with caution.
        Once removed, the seller cannot send traffic until they reapply and go through the approval process again.
        Consider pausing the traffic instead. It allows to resume the traffic again, without need to reapply.
      </p>

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isActionLoading"
          @click="disableUserModal = false, showPauseParticipationModal(selectedUser)"
        >
          Pause Traffic
        </ButtonLoading>

        <ButtonLoading
          color="danger"
          :loading="isActionLoading"
          @click="confirmRemoveParticipation(selectedUser)"
        >
          Yes, Remove
        </ButtonLoading>
      </template>
    </CModal>


    <!-- Pause participation confirmation modal -->
    <CModal
      title="Confirm pause participation"
      color="warning"
      :show.sync="pauseUserModal"
    >
      Are you sure you want to pause this seller's participation in the offer?
      <p class="alert alert-info mt-1">
        <strong>Note:</strong>
        Use this option to quickly stop traffic if there are problems. You can resume the participation once the issue is resolved.
      </p>

      <CForm
        class="mt-3"
        novalidate
        @submit.prevent
      >
        <CInput
          v-model="pauseReason"
          label="Reason"
          horizontal
          placeholder="Please enter reason"
          :is-valid="isPauseFormValid"
          invalid-feedback="Please enter reason for pausing"
        />
      </CForm>

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isActionLoading"
          type="submit"
          @click="confirmPauseUser(selectedUser)"
        >
          Pause
        </ButtonLoading>

        <CButton
          color="danger"
          @click="pauseUserModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <!-- Resume participation confirmation modal -->
    <CModal
      title="Confirm resume"
      color="warning"
      :show.sync="resumeUserModal"
    >
      Are you sure you want to resume this seller's participation in the offer?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isActionLoading"
          @click="confirmResumeUser(selectedUser)"
        >
          Resume
        </ButtonLoading>

        <CButton
          color="danger"
          @click="resumeUserModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required  } from 'vuelidate/lib/validators'
import { showSuccessMessage } from '@/notification-utils'
import SellerOfferBiddingDetails from '@/views/SellerOffers/SellerOfferBiddingDetails.vue'


export default {
  name: 'SellerAccountOffersList',

  components: {
    SellerOfferBiddingDetails
  },

  validations() {
    return {
      pauseReason: {
        required
      }
    }
  },
  
  props: {
    offersRequests: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      isActionLoading: false,

      fields: [
        { key: 'offerName', sorter: true },
        { key: 'categoryName', label: 'Category', sorter: true },
        { key: 'languageName', label: 'Language', sorter: true },
        { key: 'countryName', label: 'Country', sorter: true },
        { key: 'isAffiliateTrafficApproved', label: '3rd Party Traffic', sorter: false },
        { key: 'Actions', sorter: false, filter: false },
      ],

      disableUserModal: false,
      pauseUserModal: false,
      resumeUserModal: false,
      pauseReason: '',
      selectedOfferRegistration: undefined,
      showBiddingUrl: false,
      selectedUser: null,
    }
  },

  computed: {
    filteredOffers() {
      return this.offersRequests.map(offerRequest => {
        return {
          ...offerRequest,
          offerName: offerRequest.sellerOffer.name,
          categoryName: offerRequest.sellerOffer.category.name,
          languageName: offerRequest.sellerOffer.language.name,
          countryName: offerRequest.sellerOffer.country.name,
        }
      })
    },

    isPauseFormValid() {
      if (!this.$v.$anyDirty) {
        return null
      }

      return !this.$v.$invalid
    },
  },

  methods: {
    ...mapActions('sellerOffer', [
      'removeUser', 
      'resumeUser', 
      'pauseUser'
    ]),

    async confirmRemoveParticipation(row) {
      try {
        this.isActionLoading = true

        await this.removeUser(row.id)
  
        showSuccessMessage('Successfully removed participation')
  
        this.disableUserModal = false
  
        this.$emit('reload')
      } catch {
        // already handled in interceptor
      } finally {
        this.isActionLoading = false
      }
    },

    showRemoveParticipationModal(user) {
      this.selectedUser = user
      this.disableUserModal = true
    },

    showPauseParticipationModal(user) {
      this.selectedUser = user
      this.pauseUserModal = true
    },

    showResumeParticipationModal(user) {
      this.selectedUser = user
      this.resumeUserModal = true
    },

    showBidUrlModal(offerRegistration) {
      this.showBiddingUrl = true
      this.selectedOfferRegistration = offerRegistration
    },

    async confirmPauseUser(row) {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      try {
        this.isActionLoading = true

        await this.pauseUser({
          id: row.id,
          reason: this.pauseReason
        })

        showSuccessMessage('Successfully paused seller participation')

        this.pauseUserModal = false

        this.pauseReason = ''

        this.$emit('reload')

        this.selectedUser = null
      } catch {
        //
      } finally {
        this.isActionLoading = false
      }
    },

    async confirmResumeUser(row) {
      try {
        this.isActionLoading = true

        await this.resumeUser(row.id)

        showSuccessMessage('Successfully resumed seller participation')

        this.resumeUserModal = false

        this.$emit('reload')

        this.selectedUser = null
      } catch {
        //
      } finally {
        this.isActionLoading = false
      }
    },

    handleModalClose(modalOpen) {
      if (!modalOpen) {
        this.selectedOfferRegistration = undefined
      }
    }
  },
}
</script>
<style lang="scss" scoped></style>
