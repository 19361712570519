<template>
  <div>
    <CCard>
      <CCardHeader>
        Edit {{ typeTitle }} Application Checklist Item
      </CCardHeader>
      <CCardBody>
        <CForm
          novalidate
          @submit.prevent
        >
          <CInput
            id="name"
            v-model="$v.form.name.$model"
            label="Name"
            horizontal
            placeholder="Checklist Name"
            :is-valid="checkIfValid(['form', 'name'])"
            :invalid-feedback="cannotBeBlank('Name')"
          />

          <CInput
            id="name"
            v-model="$v.form.description.$model"
            label="Description"
            horizontal
            placeholder="Description"
            :is-valid="checkIfValid(['form', 'description'])"
            :invalid-feedback="cannotBeBlank('Description')"
          />

          <div class="form-row">
            <CCol
              :md="{ offset: '3', size: '9' }"
              :sm="{ offset: '3', size: '9' }"
            >
              <ButtonLoading
                type="submit"
                size="sm"
                color="primary"
                :loading="isLoading"
                @click="onFormSubmit"
              >
                Update
              </ButtonLoading>
            </CCol>
          </div>
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required  } from 'vuelidate/lib/validators'

import formGenericMixin from '@/mixins/form-generic-mixin'
import { showSuccessMessage } from '@/notification-utils'
import {capitlizeFirstLetter} from '@/utils'

export default {
  name: 'RegistrationChecklistItemEdit',

  mixins: [formGenericMixin],

  validations() {
    return {
      form: {
        name: {
          required
        },

        description: {
          required
        },
      }
    }
  },

  data() {
    return {
      form: {
        name: '',
        description: ''
      },

      isLoading: false,

      type: 'seller'
    }
  },

  computed: {
    ...mapState('registrationChecklistItem', [
      'sellerRegistrationChecklistItemsConfig',
      'buyerRegistrationChecklistItemsConfig'
    ]),

    typeTitle() {
      return capitlizeFirstLetter(this.type)
    }
  },

  watch: {
    '$route.params.type': {
      immediate: true,
      async handler() {
        this.type = this.$route.params.type.toLowerCase()
        document.title = `${this.typeTitle} Application Checklist Item`
      }
    }
  },

  async created () {
    this.checkListId = this.$route.params.id

    let checklistItem = this.getChecklistConfig()

    if (!checklistItem) {
      await this.getRegistrationChecklistItemsConfig(this.type)
    }

    checklistItem = this.getChecklistConfig()

    this.form = {
      name: checklistItem.name,
      description: checklistItem.description
    }
  },

  methods: {
    ...mapActions('registrationChecklistItem', [
      'updateRegistrationChecklistItemsConfig',
      'getRegistrationChecklistItemsConfig'
    ]),

    async onFormSubmit() {
      try {
        this.isLoading = true

        await this.updateRegistrationChecklistItemsConfig({
          ...this.form,
          id: this.checkListId,
          type: this.type
        })

        showSuccessMessage(`Successfully updated checklist item`)

        this.routeToListPage()
      } catch {
        //
      } finally {
        this.isLoading = false
      }
    },

    routeToListPage() {
      this.$router.push({ 
        name: 'RegistrationChecklistItems', 
        params: {
          type: this.type.toLowerCase()
        } 
      })
    },

    getChecklistConfig() {
      let configItems = []

      if (this.type === 'buyer') {
        configItems = this.buyerRegistrationChecklistItemsConfig  
      } else {
        configItems = this.sellerRegistrationChecklistItemsConfig
      }

      return configItems.find(item => item.id === this.checkListId)
    }
  },
}
</script>
<style lang="scss" scoped>
  
</style>