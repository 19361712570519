import KYCApi from '@/api/kyc.api'

const state = {
  pendingKYCRequests: []
}

function findRequestById(requests, id) {
  return requests.find(request => request.id === id)
}

const getters = {
  kycRequestById: state => id => {
    return findRequestById(state.pendingKYCRequests, id)
  }
}

const actions = {
  async fetchPendingKYCRequests({ commit }) {
    const response = await KYCApi.getPendingKYCRequests()
    commit('SET_PENDING_KYC_REQUESTS', response.data || [])
  },
  async approveKYCRequest({dispatch}, { paymentMethodId }) {
    await KYCApi.approveKYCRequest({ paymentMethodId })
    await dispatch('kyc/fetchPendingKYCRequests')
  },
  async rejectKYCRequest({dispatch}, { paymentMethodId, declineReason }) {
    await KYCApi.rejectKYCRequest({ paymentMethodId, declineReason })
    await dispatch('kyc/fetchPendingKYCRequests')
  },
}

const mutations = {
  SET_PENDING_KYC_REQUESTS(state, kycRequests) {
    state.pendingKYCRequests = kycRequests
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
