import axios from 'axios'
import https from 'https'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import store from '@/store'
import router from '@/router'
import { showErrorMessage } from '@/notification-utils'

async function logout() {
  await store.dispatch('auth/logout')

  router.push({
    name: 'LoginEmail'
  })
}

// interceptor to catch errors
const errorInterceptor = async (error) => {
  if (!error.response) {
    showErrorMessage(error.message)
    throw error
  }

  const isNotFound = error.response.status === 404

  if (error.response.data.title) {
    let errors = Object
      .values(error.response.data.errors || [])
      .flat()

    const errorMessage = `${error.response.data.title}${errors.length ? `${errors.join(', ')}` : ''}`

    isNotFound ? console.error(errorMessage) : showErrorMessage(errorMessage)
  }

  if (error.response.status === 401) {
    await logout()
  }

  throw error
}

function requestInterceptor(request) {
  const isAuthenticated = store.getters['auth/isAuthenticated']

  if (isAuthenticated) {
    request.headers[
      'Authorization'
    ] = `Bearer ${store.getters['auth/accessToken']}`
  }

  return request
}

// Interceptor for responses
const responseInterceptor = response => {
  return response
}

async function refreshAuthLogic(failedRequest) {
  try {
    await store.dispatch('auth/refreshAccessToken')
    failedRequest.response.headers[
      'Authorization'
    ] = `Bearer ${store.getters['auth/accessToken']}`
  } catch (error) {
    console.error(error)

    await store.dispatch('auth/logout')

    router.push({
      name: 'LoginEmail'
    })

    throw error
  }
}

function createHttpClient(baseURL) {
  const options = {
    baseURL: baseURL,
    timeout: 20000,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  if (process.env.VUE_APP_LOCAL) {
    options.httpsAgent = new https.Agent({
      rejectUnauthorized: false
    })
  }

  const httpClient = axios.create(options)

  if (process.env.NODE_ENV === 'development') {
    createAuthRefreshInterceptor(httpClient, refreshAuthLogic)  
  }

  httpClient.interceptors.request.use(requestInterceptor, errorInterceptor)
  httpClient.interceptors.response.use(responseInterceptor, errorInterceptor)
  return httpClient
}

export default createHttpClient
