<template>
  <div>
    <CCard>
      <CCardHeader data-cy="header">
        Add User
      </CCardHeader>

      <CCardBody>
        <CForm
          novalidate
          @submit.prevent
        >
          <CInput
            v-model="$v.user.firstName.$model"
            name="firstName"
            label="First Name"
            horizontal
            :is-valid="checkIfValid(['user', 'firstName'])"
            invalid-feedback="This field is required"
          />

          <CInput
            v-model="$v.user.lastName.$model"
            name="lastName"
            label="Last Name"
            horizontal
            :is-valid="checkIfValid(['user', 'lastName'])"
            invalid-feedback="This field is required"
          />

          <CInput
            v-model="$v.user.email.$model"
            name="email"
            label="Email"
            horizontal
            type="email"
            :is-valid="checkIfValid(['user', 'email'])"
            invalid-feedback="This field is required and should be an email"
          />

          <CInput
            v-model="$v.user.phoneNumber.$model"
            name="phoneNumber"
            label="Mobile Number"
            horizontal
            :is-valid="checkIfValid(['user', 'phoneNumber'])"
            invalid-feedback="This field is required"
          />

          <CInput
            v-model="$v.user.skype.$model"
            name="skype"
            label="Skype"
            horizontal
            :is-valid="checkIfValid(['user', 'skype'])"
          />

          <div class="form-row">
            <CCol
              :md="{ offset: '3', size: '9' }"
              :sm="{offset: '3', size: '9'}"
            >
              <ButtonLoading
                color="primary"
                :loading="isLoading"
                type="submit"
                @click="onSubmit(user)"
              >
                Create
              </ButtonLoading>

              <CButton
                class="ml-2"
                :disabled="isLoading"
                type="button"
                color="danger"
                @click="$emit('cancel')"
              >
                Cancel
              </CButton>
            </CCol>
          </div>
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email, maxLength } from 'vuelidate/lib/validators'

import formGenericMixin from '@/mixins/form-generic-mixin'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'AccountUserCreate',

  mixins: [formGenericMixin],

  props: {
    accountId: {
      type: String,
      required: true
    },

    accountType: {
      type: String,
      required: true,
      validator(value) {
        return ['Buyer', 'Seller'].includes(value)
      }
    },

    accountName: {
      type: String,
      required: true
    },
  },

  validations() {
    return {
      user: {
        firstName: {
          required
        },

        lastName: {
          required
        },

        email: {
          required,
          email
        },

        phoneNumber: {
          required
        },

        skype: {
          maxLength: maxLength(255)
        }
      }
    }
  },

  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: 'RingbaX',
        skype: ''
      },

    }
  },

  methods: {
    ...mapActions('accounts', [
      'createAccountUser'
    ]),

    async onSubmit(user) {
      this.submitted = true
      this.$v.user.$touch()

      if (this.isValid) {
        try {
          this.isLoading = true

          await this.createAccountUser({ accountId: this.accountId, user: { ...user, accountType: this.accountType, companyName: this.accountName } })

          showSuccessMessage('User added successfully to account')

          this.$emit('success')
        } catch (error) {
          //
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style>

</style>