export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Traffic Overview',
        to: '/dashboard/categories/supply-demand',
        route: 'CategorySupplyDemand',
        icon: 'cil-equalizer'
      },
      {
        _name: 'CustomSidebarNavDropdown',
        name: 'Campaigns',
        route: 'BuyerCampaigns|Seller Offers|SellerOfferCreate|SellerOfferEdit|SellerOfferRegistrationEdit|BuyerCampaignDetails|Creatives',
        _attrs: { 'data-cy': 'sidebar-campaigns-dropdown' },
        icon: 'cil-inbox',
        items: [
          {
            name: 'Buyer Campaigns',
            to: '/dashboard/campaigns/buyer',
          },
          {
            name: 'Seller Offers',
            to: '/dashboard/seller-offers',
          },
          {
            name: 'Creatives',
            to: '/dashboard/creatives',
          }
        ]
      },
      {
        _name: 'CustomSidebarNavDropdown',
        name: 'Applications',
        route: 'RegistrationRequests|RegistrationRequestDetails|KYCRequests|KYCRequestDetails|SellerOfferPendingReviewRequestDetails|SellerOfferPendingReviewRequests|PendingApprovalBuyerCampaigns|ScreeningPendingCreatives',
        _attrs: { 'data-cy': 'sidebar-registration-requests-dropdown' },
        icon: 'cil-history',
        items: [
          {
            name: 'Buyers',
            to: '/dashboard/registration-requests/buyer',
          },
          {
            name: 'Sellers',
            to: '/dashboard/registration-requests/seller',
          },
          {
            name: 'KYC Applications',
            to: '/dashboard/kyc-requests',
          },
          {
            name: 'Seller Offers',
            to: '/dashboard/seller-offers/review',
          },
          {
            name: 'Buyer Campaigns',
            to: '/dashboard/buyer-campaigns/review',
          },
          {
            name: 'Creatives',
            to: '/dashboard/creatives/review',
          }
        ]
      },
      {
        _name: 'CustomSidebarNavDropdown',
        name: 'Accounts',
        route: 'SellerAccounts|BuyerAccounts|BuyerAccountDetails|BuyerAccountAddFunds|SellerAccountAddFunds|SellerAccountDetails',
        _attrs: { 'data-cy': 'sidebar-accounts-dropdown' },
        icon: 'cil-contact',
        items: [
          {
            name: 'Buyer Accounts',
            'data-cy': 'sidebar-link-buyer-accounts',
            to: '/dashboard/accounts/buyer',
          },

          {
            name: 'Seller Accounts',
            'data-cy': 'sidebar-link-seller-accounts',
            to: '/dashboard/accounts/seller',
          }
        ]
      },
      {
        _name: 'CustomSidebarNavDropdown',
        name: 'Billing',
        route: 'BuyerPaymentsList|SellerPaymentsList|AccountSummary|AccountPaymentDetails|SellersPaymentHistory',
        _attrs: { 'data-cy': 'sidebar-billing-dropdown' },
        icon: 'cil-dollar',
        items: [
          {
            name: 'My Account',
            to: '/dashboard/billing/account/summary',
          },
          {
            name: 'Buyer Payments',
            'data-cy': 'sidebar-link-buyer-accounts',
            to: '/dashboard/billing/buyer',
          },
          {
            name: 'Seller Payments',
            'data-cy': 'sidebar-link-seller-accounts',
            to: '/dashboard/billing/seller',
          },
          {
            name: 'Seller Payment History',
            to: '/dashboard/billing/seller/paid',
          },
          {
            name: 'Advance Payments',
            'data-cy': 'sidebar-link-buyer-accounts',
            to: '/dashboard/billing/advance-pay',
          }
        ]
      },
      {
        _name: 'CustomSidebarNavDropdown',
        name: 'Settings',
        route: 'SuperAdminUsers|TwoFactorAuth|ParentCategories|TrafficSources|TrafficSourceCreate|TrafficSourceEdit|Categories|CategoryCreate|CategoryEdit|Languages|LanguageEdit|LanguageCreate|Countries|CountryCreate|CountryEdit|SuperAdminUserCreate|RegistrationChecklistItemEdit|RegistrationChecklistItems|RegistrationChecklistItemCreate',
        _attrs: { 'data-cy': 'sidebar-settings-dropdown' },
        icon: 'cil-settings',
        items: [
          {
            name: 'Super Admin Users',
            'data-cy': 'sidebar-link-users',
            to: '/dashboard/super-admin-users',
          },
          {
            name: 'MFA Setup',
            'data-cy': 'sidebar-link-mfa-setup',
            to: '/dashboard/mfa-setup',
          },
          {
            name: 'Parent Categories',
            'data-cy': 'sidebar-link-parent-categories',
            to: '/dashboard/parent-categories',
          },
          {
            name: 'Categories',
            'data-cy': 'sidebar-link-categories',
            to: '/dashboard/categories',
          },
          {
            name: 'Traffic Sources',
            'data-cy': 'sidebar-link-traffic-sources',
            to: '/dashboard/traffic-sources',
          },
          {
            name: 'Countries',
            'data-cy': 'sidebar-link-countries',
            to: '/dashboard/countries',
          },
          {
            name: 'Languages',
            'data-cy': 'sidebar-link-languages',
            to: '/dashboard/languages',
          },

          {
            name: 'Seller Checklist',
            to: '/dashboard/registration-checklist-items/seller',
          },

          {
            name: 'Buyer Checklist',
            to: '/dashboard/registration-checklist-items/buyer',
          }
        ]
      },
      {
        _name: 'CustomSidebarNavDropdown',
        name: 'Tools',
        _attrs: { 'data-cy': 'sidebar-tools-dropdown' },
        icon: 'cil-search',
        items: [
          {
            name: 'Channel Name Search',
            'data-cy': 'sidebar-channel-name-search',
            to: '/dashboard/channel-name-search',
          },  
          {
            name: 'Call Logs',
            to: '/dashboard/calls',
          },   
        ]
      },

    ]
  }
]
