<template>
  <div>
    <CCard
      v-if="getSafe(() => sellerAccountDetails.testOnly)"
      class="test-account-banner bg-danger p-3 text-white"
    >
      <strong>
        <CIcon
          name="cilWarning"
          class="mr-1"
        />
        This is a test-only account
      </strong>
    </CCard>

    <CCard
      v-if="getSafe(() => isAdminApprovalRequirementNotMet)"
      class="test-account-banner bg-warning px-3 py-3 text-white mb-3"
      :class="{
        'test-banner--position-2': getSafe(() => sellerAccountDetails.testOnly)
      }"
    >
      <strong class="d-flex align-items-center">
        <CIcon
          name="cilWarning"
          class="mr-1"
        />
        Admin Approval is pending
        <CButton
          color="success"
          class="ml-auto"
          size="sm"
          @click="warningApproveModal = true"
        >
          Approve
        </CButton>
      </strong>
    </CCard>

    <CCard>
      <CCardHeader>
        <div class="d-flex justify-content-between">
          <div>Seller Account Details</div>


          <CButton
            size="sm"
            :color="editMode ? 'danger' : 'primary'"
            @click="toggleEditMode"
          >
            {{ editMode ? 'Cancel' : 'Edit Details' }}
          </CButton>
        </div>
      </CCardHeader>

      <CCardBody>
        <div v-if="sellerAccountDetails && sellerAccountDetails.id">
          <div>
            <div v-if="!editMode">
              <AccountDetailsCommonInfoReadOnly
                type="seller"
                :account-details="sellerAccountDetails"
              />
            </div>
            <div v-else>
              <AccountDetailsCommonInfoEdit
                type="seller"
                :account-details="sellerAccountDetails"
                @updated="toggleEditMode"
              />
            </div>
          </div>

          <SellerBankInfo
            v-if="!editMode"
            :seller-account-details="sellerAccountDetails"
          />

          <div v-if="!editMode">
            <h3>
              Other Details
            </h3>

            <dl class="row">
              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                Time Zone
              </dt>
              <dd
                class="col-sm-9 col-12"
              >
                {{ getSafe(() => timezonesMap[sellerAccountDetails.timeZoneId].label) }}
              </dd>

              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                Categories
              </dt>
              <dd
                class="col-sm-9 col-12"
              >
                <span
                  v-for="(category, $idx) in sellerAccountDetails.sellerRegistrationDetails.categories"
                  :key="category.id"
                >
                  {{ getSafe(() => categoriesMap[category.id].name) }}
                  <span
                    v-if="$idx !== sellerAccountDetails.sellerRegistrationDetails.categories.length - 1"
                    class="mx-1"
                  >|</span>
                </span>
              </dd>

              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                Inbound Traffic Sources
              </dt>
              <dd
                class="col-sm-9 col-12"
              >
                <span
                  v-for="(ts, $idx) in sellerAccountDetails.sellerRegistrationDetails.inboundTrafficSources"
                  :key="ts.id"
                >
                  {{ getSafe(() => trafficSourcesMap[ts.id].name) }}
                  <span
                    v-if="$idx !== sellerAccountDetails.sellerRegistrationDetails.inboundTrafficSources.length - 1"
                    class="mx-1"
                  >|</span>
                </span>
              </dd>

              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                Outbound Traffic Sources
              </dt>

              <dd
                class="col-sm-9 col-12"
              >
                <span
                  v-for="(ts, $idx) in sellerAccountDetails.sellerRegistrationDetails.outboundTrafficSources"
                  :key="ts.id"
                >
                  {{ getSafe(() => trafficSourcesMap[ts.id].name) }}
                  <span
                    v-if="$idx !== sellerAccountDetails.sellerRegistrationDetails.outboundTrafficSources.length - 1"
                    class="mx-1"
                  >|</span>
                </span>
              </dd>

              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                Transfer Traffic Sources
              </dt>

              <dd
                class="col-sm-9 col-12"
              >
                <span
                  v-for="(ts, $idx) in sellerAccountDetails.sellerRegistrationDetails.transferTrafficSources"
                  :key="ts.id"
                >
                  {{ getSafe(() => trafficSourcesMap[ts.id].name) }}
                  <span
                    v-if="$idx !== sellerAccountDetails.sellerRegistrationDetails.transferTrafficSources.length - 1"
                    class="mx-1"
                  >|</span>
                </span>
              </dd>


              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                Monthly Calls Generated
              </dt>
              <dd
                class="col-sm-9 col-12"
              >
                {{ sellerAccountDetails.sellerRegistrationDetails.monthlyCallsGeneratedRange }}
              </dd>

              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                Annual PPC Revenue
              </dt>
              <dd
                class="col-sm-9 col-12"
              >
                {{ sellerAccountDetails.sellerRegistrationDetails.annualPPCRevenueRange }}
              </dd>

              <dt
                class="col-12 col-sm-3 text-sm-right"
              >
                Works with Affiliates
              </dt>
              <dd
                class="col-sm-9 col-12"
              >
                {{ sellerAccountDetails.sellerRegistrationDetails.worksWithAffiliates ? 'Yes' : 'No' }}
              </dd>
            </dl>
          </div>
        </div>
        
        <SignedDocumentBundles
          account-type="seller"
          :account-id="getSafe(() => sellerAccountDetails.id)"
          :signed-bundles="getSafe(() => signedBundles)"
        />

        <AccountRequirements
          class="mt-4"
          :account-requirements="sellerAccountRequirements"
        />
      </CCardBody>
    </CCard>

    <AccountTrafficDetails
      v-if="sellerAccountDetails"
      :account="sellerAccountDetails"
      type="seller"
    />

    <SellerBillingDetails
      :account-id="getSafe(() => accountId)"
      :account-billing-config="getSafe(() => sellerAccountDetails.billingConfiguration)"
    />
    
    <SellerBidSettings
      :account-id="getSafe(() => sellerAccountDetails.id)"
      :existing-bid-preferences="getSafe(() => sellerAccountDetails.bidPreferences)"
    />

    <SellerAccountApprovedOffers
      :is-loading="isLoading || loadingOffersRequests"
      @reload="reloadApprovedOffers"
    />

    <ReviewNotes
      v-if="sellerAccountDetails"
      :parent-object="sellerAccountDetails"
    />

    <AccountUsers 
      v-if="sellerAccountDetails"
      :account-id="sellerAccountDetails.id"
      account-type="Seller"
      :account-name="sellerAccountDetails.name"
    />

    <RegistrationRequestChecklist
      v-if="sellerAccountDetails && sellerAccountDetails.registrationId"
      :registration-request-id="sellerAccountDetails.registrationId"
      type="seller"
    />

    <div>
      <CButton 
        v-if="isAdminApprovalRequirementNotMet"
        type="submit"
        size="lg"
        color="success"
        class="mb-3"
        @click="warningApproveModal = true"
      >
        Final Approve 
      </CButton>
    </div>

    <CModal
      title="Confirm approval"
      color="success"
      :show.sync="warningApproveModal"
    >
      Are you sure you want to approve this seller?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="approveAccount"
        >
          Approve
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningApproveModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import isEmpty from 'lodash.isempty'

import SellerBillingDetails from './SellerBillingDetails.vue'
import AccountTrafficDetails from './AccountTrafficDetails.vue'
import SignedDocumentBundles from './SignedDocumentBundles.vue'
import AccountRequirements from './AccountRequirements.vue'
import AccountDetailsCommonInfoReadOnly from './AccountDetailsCommonInfoReadOnly.vue'
import AccountDetailsCommonInfoEdit from './AccountDetailsCommonInfoEdit.vue'
import SellerBankInfo from './SellerBankInfo.vue'
import ReviewNotes from '../ReviewNotes/ReviewNotes.vue'
import SellerBidSettings from './SellerBidSettings.vue'
import AccountUsers from '@/views/Users/AccountUsers.vue'
import SellerAccountApprovedOffers from './SellerAccountApprovedOffers.vue'
import RegistrationRequestChecklist from '@/views/RegistrationRequests/RegistrationRequestChecklist.vue'
import { showSuccessMessage } from '@/notification-utils'
import { COMMON_ACCOUNT_REQUIREMENTS } from '@/store/modules/accounts.store'

export default {
  name: 'SellerAccountDetails',

  components: {
    SellerBillingDetails,
    ReviewNotes,
    AccountTrafficDetails,
    SignedDocumentBundles,
    AccountUsers,
    AccountDetailsCommonInfoReadOnly,
    AccountDetailsCommonInfoEdit,
    SellerBankInfo,
    SellerBidSettings,
    RegistrationRequestChecklist,
    AccountRequirements,
    SellerAccountApprovedOffers
  },

  data() {
    return  {
      editMode: false,
      isLoading: false,
      warningApproveModal: false,
      COMMON_ACCOUNT_REQUIREMENTS,
      loadingOffersRequests: false
    }
  },

  computed: {
    ...mapState('accounts', [
      'sellerAccountDetails',
      'sellerAccountRequirements'
    ]),

    ...mapState('category', [
      'categories'
    ]),

    ...mapGetters('trafficSource', {
      trafficSources: 'activeTrafficSources'
    }),

    ...mapGetters('timezones', [
      'timezonesMap'
    ]),

    categoriesMap() {
      return this.categories.reduce(function(map, obj) {
          map[obj.id] = obj
          return map
      }, {})
    },

    trafficSourcesMap() {
      return this.trafficSources.reduce(function(map, obj) {
          map[obj.id] = obj
          return map
      }, {})
    },

    signedBundles() {
      const bundles = this.sellerAccountDetails?.eSignature?.signedBundles ?? []
      const bundleIds = this.sellerAccountDetails?.eSignature?.signedBundleIds?.map(b => ({ id: b })) ?? []

      return bundles.concat(bundleIds)
    },

    isAdminApprovalRequirementNotMet() {
      return !isEmpty(this.sellerAccountRequirements?.requirements) && !this.sellerAccountRequirements?.requirements[COMMON_ACCOUNT_REQUIREMENTS.ADMIN_APPROVAL_REQUIREMENT]
    }
  },

  async created() {
    this.accountId = this.$route.params.id

    if (this.accountId) {
      try {
        await this.getSellerAccountDetails(this.accountId)
        await this.getSellerAccountRequirements(this.accountId)
        document.title = this.sellerAccountDetails?.name
      } catch (error) {
        // Exception handled in interceptor
      }
    }

    await this.getCategories()
    await this.getTrafficSources()

    this.SET_CURRENT_REGISTRATION_TYPE('seller')
  },

  methods: {
    ...mapActions('accounts', [
      'getSellerAccountDetails',
      'getSellerAccountRequirements',
      'markAccountAdminApprovalRequirement',
      'getApprovedSellerOfferRequestsForAccount',
    ]),

    ...mapActions('category', {
      getCategories: 'list'
    }),

    ...mapActions('trafficSource', {
      getTrafficSources: 'list'
    }),

    ...mapMutations('registrationRequests', [
      'SET_CURRENT_REGISTRATION_TYPE'
    ]),

    toggleEditMode() {
      this.editMode = !this.editMode
    },

    async approveAccount() {
      try {
        this.isLoading = true

        await this.markAccountAdminApprovalRequirement({ 
          accountId: this.sellerAccountDetails.id, 
          approve: true,
          accountType: 'seller'
        })

        showSuccessMessage('Admin Approval done successfully')

        this.warningApproveModal = false

        await this.getSellerAccountRequirements(this.accountId)
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },

    async reloadApprovedOffers() {
      try {
        this.loadingOffersRequests = true

        await this.getApprovedSellerOfferRequestsForAccount(this.accountId)
      } catch (error) {
        //
      } finally {
        this.loadingOffersRequests = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>