export default {
  props: {
    /**
     * This should be set to true when a component is shown 
     * inside the platform to verify the user again before a high-security operation
     */
    internal: {
      type: Boolean,
      default: false
    }
  }
}