<template>
  <CCard>
    <CCardHeader>
      Review Billing Details
    </CCardHeader>

    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CInput
          v-if="type === 'buyer' && reviewDetailsLocal.prePay"
          v-model="$v.reviewDetailsLocal.minimumInitialDeposit.$model"
          type="number"
          label="Minimum Initial Deposit"
          add-label-classes="text-right pr-4"
          add-input-classes="fixed-width-130"
          name="minimumInitialDeposit"
          placeholder="Minimum Initial Deposit"
          :is-valid="checkIfValid('minimumInitialDeposit')"
          horizontal
          :invalid-feedback="minimumInitialDepositInvalid"
        />

        <CInput
          v-if="type === 'buyer'"
          v-model="$v.reviewDetailsLocal.minimumBalance.$model"
          name="minimumBalance"
          type="number"
          label="Minimum Balance"
          add-label-classes="text-right pr-4"
          add-input-classes="fixed-width-130"
          placeholder="Minimum Balance"
          horizontal
          :is-valid="checkIfValid('minimumBalance')"
          :invalid-feedback="minimumBalanceInvalid"
        />
        
        <CRow
          v-if="type === 'buyer'"
          class="form-group form-row"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Pre Pay
          </CCol>
          <CCol>
            <CSwitch
              v-model="$v.reviewDetailsLocal.prePay.$model"
              :checked="true"
              label-off="No"
              label-on="Yes"
              shape="pill"
              color="primary"
              data-cy="pre-pay-checkbox"
              horizontal         
              add-input-classes="w-25"
              size="lg"
              sm="3"
            />
          </CCol>
        </CRow>        

        <CSelect
          v-if="!reviewDetailsLocal.prePay || type === 'seller'"
          v-model="$v.reviewDetailsLocal.billingCycle.$model"
          :value.sync="reviewDetailsLocal.billingCycle"
          label="Billing Period"
          add-label-classes="text-right pr-4"
          horizontal
          :options="billingCycles"
          placeholder="Please select"
          :is-valid="checkIfValid('billingCycle')"
          :invalid-feedback="billingCycleInvalid"
        />

        <CInput
          v-if="!reviewDetailsLocal.prePay || type === 'seller'"
          v-model="$v.reviewDetailsLocal.paymentTermsInDays.$model"
          :value.sync="reviewDetailsLocal.paymentTermsInDays"
          type="number"
          label="Payment Terms"
          add-label-classes="text-right pr-4"
          name="paymentTerms"
          placeholder="Payment terms (in days)"
          horizontal
          :is-valid="checkIfValid('paymentTermsInDays')"
          :invalid-feedback="paymentTermsInDaysInvalid"
        />

        <div class="form-row">
          <CCol
            :md="{ offset: '3', size: '9' }"
            :sm="{ offset: '3', size: '9' }"
          >
            <ButtonLoading
              type="submit"
              size="sm"
              color="primary"
              :loading="isLoading"
              data-cy="submit-billing"
              :disabled="!isFormEdited"
              @click="onSubmit"
            >
              Save
            </ButtonLoading>
          </CCol>
        </div>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { showSuccessMessage } from '@/notification-utils'
import { mapActions, mapState } from 'vuex'
import { minValue, maxValue, required } from 'vuelidate/lib/validators'

import formGenericMixin from '@/mixins/form-generic-mixin'
import {
  MIN_INITIAL_DEPOSIT
} from '@/store/modules/registration-requests.store'
import { REGISTRATION_TYPES } from '@/store/modules/registration-requests.store'
import {
  MIN_BUYER_ACCOUNT_BALANCE_RANGE
} from '@/store/modules/accounts.store'
import { formatAmount } from '@/utils'

export default {
  name: 'RegistrationRequestBillingDetails',

  mixins: [formGenericMixin],

  props: {
    registrationRequest: {
      type: Object,
      default() {
        return {}
      }
    },

    type: {
      type: String,
      default: ''
    }
  },

  validations() {
    return {
      reviewDetailsLocal: {
        minimumInitialDeposit: {
          minValue: minValue(MIN_INITIAL_DEPOSIT),          
          required
        },

        minimumBalance: {
          minValue: minValue(MIN_BUYER_ACCOUNT_BALANCE_RANGE.min),
          maxValue: this.reviewDetailsLocal.prePay ? maxValue(MIN_BUYER_ACCOUNT_BALANCE_RANGE.max) : maxValue(-1),
          required
        },

        prePay: {
          required
        },

        billingCycle: {
          required
        },

        paymentTermsInDays: {
          minValue: 14,
          required
        },
      }
    }
  },

  data() {
    return {
      isLoading: false,
      reviewDetailsLocal: {
        minimumBalance: 0,
        minimumInitialDeposit: 0,      
        billingCycle: 0,
        paymentTermsInDays: 45,
        prePay: true
      },
      paymentTermsInDaysInvalid: `Please enter a valid value, it should be between 14 and 90`,
      billingCycleInvalid: `Please select a valid value from the list`,
      minimumInitialDepositInvalid: `Please enter a valid value, it should not be below $${MIN_INITIAL_DEPOSIT}`,      
      minimumBalanceInvalid: `Please enter a valid value, it should be between ${formatAmount(MIN_BUYER_ACCOUNT_BALANCE_RANGE.min)} and ${this.reviewDetailsLocal?.prePay ? formatAmount(MIN_BUYER_ACCOUNT_BALANCE_RANGE.max) : formatAmount(-1)}`    
    }
  },

  computed: {
    ...mapState(['billingCycles'])
  },

  watch: {
    registrationRequest: {
      immediate: true,
      handler(newValue) {
        this.reviewDetailsLocal = newValue.reviewDetails
          ? { ...newValue.reviewDetails }
          : {
              minimumBalance: 0,
              minimumInitialDeposit: 0,             
              billingCycle: 0,
              paymentTermsInDays: 45,
              prePay: false
            }
      }
    }
  },

  methods: {
    ...mapActions('registrationRequests', [
      'updateBuyerRegistrationWithReviewDetails',
      'updateSellerRegistrationWithReviewDetails'
    ]),

    async onSubmit() {
      this.$v.reviewDetailsLocal.$touch()

      if (this.isValid) {
        this.isLoading = true

        try {
          if (this.type === REGISTRATION_TYPES.buyer) {
            await this.updateBuyerRegistrationWithReviewDetails({
              id: this.registrationRequest.id,
              reviewDetails: this.reviewDetailsLocal,
            })
          } else {
            await this.updateSellerRegistrationWithReviewDetails({
              id: this.registrationRequest.id,
              reviewDetails: this.reviewDetailsLocal,
            })
          }

          showSuccessMessage('Billing configuration updated successfully')

          this.resetFormDirtyState()
        } catch (error) {
          // Handle error
        } finally {
          this.isLoading = false
        }
      }
    },

    checkIfValid(fieldName) {
      const field = this.$v.reviewDetailsLocal[fieldName]

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    }
  }
}
</script>

<style></style>
