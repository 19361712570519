<template>
  <CCard>
    <CCardHeader>
      Destination
    </CCardHeader>
    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Routing
          </CCol>
          
          <CInputRadioGroup
            class="col-sm-9"
            add-label-classes="text-right pr-4"
            add-input-classes="w-25"
            :options="['RingbaRTB', 'Static', 'Programmatic']"
            :custom="true"
            :inline="true"
            :checked.sync="routingDetailsLocal.type"
            label="Routing"
            @update:checked="onRoutingTypeUpdated"
          />
        </CRow>
        Basic Details
        <hr>

        <div
          v-if="routingDetailsLocal.type === 'RingbaRTB'"
        >
          <CInput
            v-model="$v.routingDetailsLocal.ringbaRTB.rtbId.$model"
            type="string"
            label="RTB Id"
            add-label-classes="text-right pr-4"
            add-input-classes="w-50"
            name="RTBId"
            :is-valid="
              checkIfValid(['routingDetailsLocal', 'ringbaRTB', 'rtbId'], submitted)
            "
            placeholder="RTB Id"
            horizontal
            invalid-feedback="RTB Id must be a 32-character alphanumeric string like '344d1395c7fb41c6ac9268af5e79fd58'"
          />
          <CRow class="form-group">
            <CCol
              sm="3"
              class="text-right pr-4"
            >
              Tag mappings
            </CCol>
            <CCol sm="9">
              <CButton
                v-if="
                  routingDetailsLocal.ringbaRTB &&
                    (!routingDetailsLocal.ringbaRTB.tagMappings 
                      || !routingDetailsLocal.ringbaRTB.tagMappings.length)                   
                "
                color="dark"
                size="sm"
                @click="addTagMappings"
              >
                Change Data Mapping
              </CButton>
              <div
                v-if="
                  routingDetailsLocal.ringbaRTB &&
                    routingDetailsLocal.ringbaRTB.tagMappings &&
                    routingDetailsLocal.ringbaRTB.tagMappings.length > 0"
              >          
                <CRow
                  v-for="(tagMapping, index) in routingDetailsLocal.ringbaRTB.tagMappings"
                  :key="`custom-header-${index}`"
                  class="pl-2"
                >
                  <CInput
                    v-model="tagMapping.description"
                    readonly                    
                  />
                  <CInput
                    v-model="tagMapping.value"
                    class="ml-2"                                
                    @input="formEdited = true"
                  />                  
                </CRow>
              </div>
            </CCol>
          </CRow>
        </div>

        <div v-if="showTargetDestinationForm">
          <CRow class="form-group">
            <CCol
              sm="3"
              class="text-right pr-4"
            >
              Type
            </CCol>
            <CInputRadioGroup
              class="col-sm-9"
              add-label-classes="text-right pr-4"
              add-input-classes="w-25"
              :options="['Number', 'SIP']"
              :inline="true"
              :custom="true"
              :checked.sync="routingDetailsLocal.destinationType"
              label="Routing"
              @update:checked="formEdited = true"
            />
          </CRow>

          <CInput
            v-if="routingDetailsLocal.destinationType === 'Number'"
            v-model="$v.routingDetailsLocal.number.$model"
            type="string"
            label="Number"
            add-label-classes="text-right pr-4"
            add-input-classes="w-25"
            name="Number"
            :is-valid="
              checkIfValid(['routingDetailsLocal', 'number'], submitted)
            "
            placeholder="Number"
            horizontal
            invalid-feedback="Phone number cannot be blank"
          />

          <div v-if="routingDetailsLocal.destinationType === 'SIP'">
            <CInput
              v-model="$v.routingDetailsLocal.sipNumber.endpoint.$model"
              type="string"
              label="SIP Endpoint"
              add-label-classes="text-right pr-4"
              add-input-classes="w-25"
              name="SIP Endpoint"
              :is-valid="
                checkIfValid(
                  ['routingDetailsLocal', 'sipNumber', 'endpoint'],
                  submitted
                )
              "
              placeholder="SIP URL"
              horizontal
              invalid-feedback="SIP Endpoint must be a valid URL"
            />
            <CInput
              v-model="$v.routingDetailsLocal.sipNumber.username.$model"
              type="string"
              label="SIP Username"
              add-label-classes="text-right pr-4"
              add-input-classes="w-25"
              name="SIP Username"
              placeholder="SIP Username"
              :is-valid="
                checkIfValid(
                  ['routingDetailsLocal', 'sipNumber', 'username'],
                  submitted
                )
              "
              invalid-feedback="username cannot be blank"
              horizontal
            />
            <CInput
              v-model="$v.routingDetailsLocal.sipNumber.password.$model"
              :type="showSIPPassword ? 'text' : 'password'"
              label="SIP Password"
              add-label-classes="text-right pr-4"
              add-input-classes="w-25 col-sm-3"
              name="SIP Password"
              placeholder="SIP Password"
              :is-valid="
                checkIfValid(
                  ['routingDetailsLocal', 'sipNumber', 'password'],
                  submitted
                )
              "
              invalid-feedback="password cannot be blank"
              horizontal
            >
              <template #append-content>
                <span
                  :style="{ cursor: 'pointer' }"
                  @click="toggleSIPPasswordVisibility"
                >
                  {{ showSIPPassword ? 'hide' : 'show' }}
                </span>
              </template>
            </CInput>
          </div>
        </div>

        <ProgrammaticTargetForm
          v-if="routingDetailsLocal.type === 'Programmatic'"
          ref="programmaticTargetForm"
          :form-submitted="submitted"
          :programmatic-target="routingDetailsLocal.programmaticTarget"
          @update="updateProgrammaticTarget"
        />
      </CForm>

      <div class="row">
        <div class="col-sm-3 pr-4" />
        <div class="col-sm-9">
          <ButtonLoading
            color="primary"
            :loading="isLoading"
            :disabled="!isFormEdited && !formEdited"
            horizontal
            size="sm"
            label=""
            @click="updateDetails"
          >
            Update
          </ButtonLoading>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { showSuccessMessage } from '@/notification-utils'
import ProgrammaticTargetForm from './ProgrammaticTargetForm.vue'
import customURLValidator from '@/validators/customURLValidator'
import { mapActions } from 'vuex'
import { requiredIf, helpers } from 'vuelidate/lib/validators'
import formMixins from '@/mixins/form-mixins'
import formGenericMixin from '@/mixins/form-generic-mixin'
import {  getRtbTagsWithDescription } from '@/store/modules/campaign.store'
import { RTB_ID_REGEX } from '@/store/modules/campaign.store'

const rtbIdStringValidator = helpers.regex('rtbIdString', RTB_ID_REGEX)

export default {
  name: 'Destination',
  components: {
    ProgrammaticTargetForm
  },
  mixins: [formMixins, formGenericMixin],
  props: {
    campaignId: {
      type: String,
      default: ''
    },
    routingDetails: {
      type: Object,
      default: () => {}
    },

    budgetDetails: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  validations() {
    return {
      routingDetailsLocal: {
        ringbaRTB: {
          rtbId: {
            requiredIf: requiredIf(() => {
              return this.routingDetailsLocal.type === 'RingbaRTB'
            }),
            rtbIdStringValidator
          }
        },
        number: {
          requiredIf: requiredIf(() => {
            return this.routingDetailsLocal.type === 'Static' && this.routingDetailsLocal.destinationType === 'Number'
          })
        },
        sipNumber: {
          endpoint: {
            requiredIf: requiredIf(() => {
              return this.routingDetailsLocal.type === 'Static' && this.routingDetailsLocal.destinationType === 'SIP'
            }),
            customURLValidator
          },
          username: {
            requiredIf: requiredIf(() => {
              return this.routingDetailsLocal.type === 'Static' && this.routingDetailsLocal.destinationType === 'SIP' && !!this.routingDetailsLocal.sipNumber?.password
            }),
          },
          password: {
            requiredIf: requiredIf(() => {
              return this.routingDetailsLocal.type === 'Static' && this.routingDetailsLocal.destinationType === 'SIP' && !!this.routingDetailsLocal.sipNumber?.username
            }),
          }
        }
      }
    }
  },

  data() {
    return {
      submitted: false,
      formEdited: false,
      headersCount: 0,
      dynamic: false,
      isLoading: false,
      routingDetailsLocal: {
        type: null,
        destinationType: '',
        ringbaRTB: {
          rtbId: ''
        },
        programmaticTarget: {
          isNumberDynamic: false
        },
        requestSettings: {},
        number: '',
        sipNumber: {}
      },
      showSIPPassword: false,
      tagMappingsJsonString: '',
      RTB_ID_REGEX: Object.freeze(RTB_ID_REGEX)
    }
  },

  computed: {
    showTargetDestinationForm() {
      return this.routingDetailsLocal.type === 'Static' 
    }
  },

  watch: {
    routingDetails: {
      immediate: true,
      handler(newValue) {
        this.routingDetailsLocal = {
          ...newValue,
          sipNumber: {
            ...newValue.sipNumber
          },
          ringbaRTB: newValue.ringbaRTB ? {
            rtbId: newValue.ringbaRTB.rtbId,
            tagMappings: newValue.ringbaRTB.tagMappings
            ? Object.entries(newValue.ringbaRTB.tagMappings).map(entry => ({
                description: getRtbTagsWithDescription().tagDescriptionMapping.get(entry[0]),
                key: entry[0],
                value: entry[1]
              }))
            : null
          } : {
            rtbId: ''
          },
          programmaticTarget: newValue.programmaticTarget
            ? {
                ...newValue.programmaticTarget,
                dynamicDialSettingParser: newValue.programmaticTarget
                  .dynamicDialSettingParser?.length
                  ? [...newValue.programmaticTarget.dynamicDialSettingParser]
                  : [
                      {
                        parserType: 'jpath'
                      }
                    ],
                dynamicBidAmountParser: newValue.programmaticTarget
                  .dynamicBidAmountParser?.length
                  ? [...newValue.programmaticTarget.dynamicBidAmountParser]
                  : [
                      {
                        parserType: 'jpath'
                      }
                    ],

                shouldAcceptCallParser: newValue.programmaticTarget
                  .shouldAcceptCallParser?.length
                  ? [...newValue.programmaticTarget.shouldAcceptCallParser]
                  : [
                      {
                        parserType: 'jpath'
                      }
                    ],

                dynamicCallLengthSettingParser: newValue.programmaticTarget
                  .dynamicCallLengthSettingParser?.length
                  ? [
                      ...newValue.programmaticTarget
                        .dynamicCallLengthSettingParser
                    ]
                  : [
                      {
                        parserType: 'jpath'
                      }
                    ]
              }
            : {
                bidSettings: {},
                destinationType: 'Number',
              }
        }
      }
    }
  }, 

  methods: {
    ...mapActions('campaign', ['updateCampaignDetails']),

    onRoutingTypeUpdated() {
      this.formEdited = true

      if (this.routingDetailsLocal.type === 'RingbaRTB') {
        this.routingDetailsLocal = {
          ...this.routingDetailsLocal,
          ringbaRTB: {
            rtbId: ''
          }
        }
      }
    },

    async updateDetails() {
      this.submitted = true
      const campaignId = this.campaignId

      if (this.$v.$invalid) {
        return
      }

      this.routingDetailsLocal = transformRoutingForAPI(this.routingDetailsLocal)

      const updateObject = {
        routing: {
          ...this.routingDetailsLocal
        },
        budget: {
          ...this.budgetDetails,
          minimumDurationSelected: this.routingDetailsLocal.type === 'RingbaRTB' ? false : this.budgetDetails.minimumDurationSelected,
        }
      }
      
      try {
        this.isLoading = true
        
        await this.updateCampaignDetails({ id: campaignId, updateObject })

        showSuccessMessage('Updated campaign details')

        this.resetFormDirtyState()
        this.formEdited = false
      } catch (error) {
        console.error(error.response.data.errors)
      } finally {
        this.isLoading = false
      }
    },

    updateProgrammaticTarget(value) {
      this.formEdited = true
      
      value = {...value}

      value.bidSettings.isBidDynamic = value.bidSettings.bidType === 'Dynamic'

      value.bidSettings.conversionCallLengthDynamic =
        value.bidSettings.conversionDurationType === 'Dynamic'

      const headers = {}

      value.headers.forEach(header => {
        headers[header.key] = header.value
      })

      value.headers = headers

      this.routingDetailsLocal.programmaticTarget = { ...value }
    },

    toggleSIPPasswordVisibility() {
      this.showSIPPassword = !this.showSIPPassword
    },

    addTagMappings() {
      const defaultRtbTags = getRtbTagsWithDescription()      
      this.routingDetailsLocal.ringbaRTB.tagMappings = defaultRtbTags.rtbTags
      this.formEdited = true
    }
  }
}

function transformRoutingForAPI(value) {  
  var routingDetails = { ...value }

  switch (routingDetails.type) {
        case 'Static':
          routingDetails = { ...transformStaticRoutingForAPI(routingDetails) }
          break
          
        case 'Programmatic':
          routingDetails = { ...transformProgrammaticRoutingForAPI(routingDetails) }
          break       
          
        case 'RingbaRTB':
          routingDetails = { ...transformRingbaRTBRoutingForAPI(routingDetails) }
          break
          
        default:
          break          
      }

    return routingDetails
}

function transformStaticRoutingForAPI(value) {
  let routingDetails = { ...value, 
    programmaticTarget: null,
    ringbaRTB: null 
  } 

  return routingDetails
}

function transformProgrammaticRoutingForAPI(value) {
  let routingDetails = { 
    ...value,
    ringbaRTB: null,
    number: null,
    sipNumber: null
  }

  const programmaticTarget = routingDetails.programmaticTarget

  programmaticTarget.dynamicBidAmountParser = programmaticTarget.dynamicBidAmountParser
    ? programmaticTarget.dynamicBidAmountParser.filter(x => Boolean(x.value))
    : []
  programmaticTarget.dynamicDialSettingParser = programmaticTarget.dynamicDialSettingParser
    ? programmaticTarget.dynamicDialSettingParser.filter(x => Boolean(x.value))
    : []
  programmaticTarget.dynamicCallLengthSettingParser = programmaticTarget.dynamicCallLengthSettingParser
    ? programmaticTarget.dynamicCallLengthSettingParser.filter(x => Boolean(x.value))
    : []

  return routingDetails
}

function transformRingbaRTBRoutingForAPI(value) {
  let routingDetails = { ...value,
    programmaticTarget: null
  }

  if (routingDetails.ringbaRTB.tagMappings && routingDetails.ringbaRTB.tagMappings.length > 0) {
    let temp = {}
    routingDetails.ringbaRTB.tagMappings.forEach(tagMapping => { 
      temp[tagMapping.key] = tagMapping.value
    })
    routingDetails.ringbaRTB.tagMappings = temp 
  }

  return routingDetails
}

</script>

<style lang="scss" scoped></style>
