import NotesAPI from '@/api/notes.api'

const state = {
  reviewNotes: []
}

const getters = {

}

const actions = {
  async getReviewNotes({ commit }, noteListId) {
    if (!noteListId) {
      return []
    }

    const response = await NotesAPI.getReviewNotes(noteListId)
    commit('SET_REVIEW_NOTES', response.data || [])
  },
  
  async addReviewNotes({ commit }, newNote) {
    await NotesAPI.addReviewNotes(newNote)

    const response = await NotesAPI.getReviewNotes(newNote.noteListId)
    commit('SET_REVIEW_NOTES', response.data || [])  
  },

  async deleteReviewNote(context, payload) {
    const response = await NotesAPI.deleteReviewNote(payload)
    return response
  },

  async updateReviewNote(context, payload) {
    const response = await NotesAPI.updateReviewNote(payload)
    return response
  },

  resetReviewNotes(context){
    context.commit("RESET_REVIEW_NOTES")
  }
}

const mutations = {
  SET_REVIEW_NOTES(state, notes) {
    state.reviewNotes = notes
  },

  RESET_REVIEW_NOTES(state){
    state.reviewNotes = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
