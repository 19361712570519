<template>
  <div class="d-flex align-items-center">
    <CSelect
      v-model="min"
      :value.sync="min"
      :options="minOptions"
    />
    <CSelect
      v-model="max"
      :value.sync="max"
      :options="validMaxOptions"
    />
    <div
      v-if="isMaxLessThanMin"
      class="ml-2 mb-3 text-danger"
    >
      <small>Minimum value cannot be greater than maximum value</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },

    minOptions: {
      type: Array,
      default() {
        return []
      }
    },

    maxOptions: {
      type: Array,
      default() {
        return []
      }
    }
  },

  computed: {
    min: {
      get() {
        return this.value.min
      },
      set(value) {
        this.$emit('change', { ...this.value, min: value })
        this.$emit('input', { ...this.value, min: value })
      }
    },

    max: {
      get() {
        return this.value.max
      },
      set(value) {
        this.$emit('change', { ...this.value, max: value })
        this.$emit('input', { ...this.value, max: value })
      }
    },

    isMaxLessThanMin() {
      const minValueIndex = this.maxOptions.indexOf(this.value.min)
      const maxValueIndex = this.maxOptions.indexOf(this.value.max)
      return minValueIndex > maxValueIndex
    },

    validMaxOptions() {
      const minValueIndex = this.maxOptions.indexOf(this.value.min)
      return this.maxOptions.map((option, index) => {
        if (index >= minValueIndex) {
          return { value: option, label: option }
        } else {
          return { value: option, label: option, disabled: true }
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped></style>
