<template>
  <div>
    <CDataTable
      :responsive="false"
      :fields="fields"
      :items="parameters"
      size="sm"
      add-table-classes="table-fixed"
    >
      <template #name="{item}">
        <td><code class="text-nowrap">{{ item.name }}</code></td>
      </template>

      <template #required="{item}">
        <td>{{ item.required ? 'Yes' : 'No' }}</td>
      </template>

      <template #choices="{item}">
        <td>
          <span
            v-for="(choice, idx) in item.choices"
            :key="choice"
          >
            <code>{{ choice }}</code>
            <span
              v-if="idx !== item.choices.length - 1"
              class="ml-1 mr-1"
            >|</span>
          </span>
        </td>
      </template>
    </CDataTable>
  </div>
</template>

<script>
export default {
  name: 'SellerOfferBidParameters',

  props: {
    /**
     * @type {import('vue').PropType<Seller.OfferBidParameters>}
     */
    parameters: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      fields: [
        { key: 'name', style: 'width: 130px;' },
        { key:'description', _classes : 'w-25' },
        { key: 'required' },
        {
          key: 'choices',
          label: 'Valid Values',
          _classes: 'w-50'
        }
      ]
    }
  },
}
</script>

<style>

</style>