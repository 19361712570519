var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap"},[_c('div',[_c('h5',{staticClass:"mr-5"},[_vm._v(" Sellers who cannot participate "),_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Sellers who cannot participate due to various reasons in the selected Category\'s offers',
          placement: 'right',
        }),expression:"{\n          content: 'Sellers who cannot participate due to various reasons in the selected Category\\'s offers',\n          placement: 'right',\n        }"}],staticClass:"ml-1",attrs:{"name":"cil-info","size":"custom"}})],1),_c('div',{staticClass:"d-flex flex-column seller-offer-registration-list mr-5 mb-3"},[(_vm.sellersWithNoVisibilityToOffers.length)?_c('div',_vm._l((_vm.sellersWithNoVisibilityToOffers),function(seller){return _c('div',{key:seller.id,staticClass:"seller-offer-registration-list__item pl-3"},[_c('div',{staticClass:"d-flex mb-2"},[_c('router-link',{staticClass:"text-truncate seller-offer-registration-list__item-account-name h5 mb-0",attrs:{"target":"_blank","title":"View Account","to":{
                name: 'SellerAccountDetails',
                params: { id: seller.id },
                exact: true,
              }}},[_vm._v(" "+_vm._s(seller.name)+" "),_c('CIcon',{staticClass:"ml-1",attrs:{"name":"cil-external-link","size":"custom"}})],1),_c('a',{staticClass:"ml-2",attrs:{"href":"","title":"Copy Account Id"},on:{"click":function($event){$event.preventDefault();return _vm.copyAccountIdToClipboard(seller.id)}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)],1),_vm._v(" Offers not visible: "),_c('ol',{staticClass:"pl-4 mb-0"},_vm._l((seller.inacessibleOffers),function(offerWithoAccess){return _c('li',{key:offerWithoAccess.id},[_c('router-link',{attrs:{"target":"_blank","title":"View Offer Registration","to":{
                  name: 'SellerOfferEdit',
                  params: { id: offerWithoAccess.id },
                  exact: true,
                }}},[_vm._v(" "+_vm._s(offerWithoAccess.name)+" "),_c('i',{staticClass:"text-muted"},[_vm._v("("+_vm._s(offerWithoAccess.visibility)+" offer)")]),_c('CIcon',{staticClass:"ml-1",attrs:{"name":"cil-external-link","size":"custom"}})],1),_vm._l((offerWithoAccess.sellerOfferReasons),function(reason){return _c('ul',{key:reason,staticClass:"pl-4"},[_c('li',[_vm._v(" "+_vm._s(reason)+" ")])])})],2)}),0)])}),0):_c('div',[_vm._m(0)])])]),_c('div',[_c('h5',[_vm._v(" Sellers who're not actively participating "),_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Sellers who can participate but are not an active part of the selected Category\'s offers',
          placement: 'right',
        }),expression:"{\n          content: 'Sellers who can participate but are not an active part of the selected Category\\'s offers',\n          placement: 'right',\n        }"}],staticClass:"ml-1",attrs:{"name":"cil-info","size":"custom"}})],1),_c('div',{staticClass:"d-flex flex-column seller-offer-registration-list mr-5 mb-3"},[(_vm.sellersNotParticipatingActively.length)?_c('div',_vm._l((_vm.sellersNotParticipatingActively),function(seller){return _c('div',{key:seller.id,staticClass:"seller-offer-registration-list__item pl-3"},[_c('div',{staticClass:"d-flex py-1"},[_c('router-link',{staticClass:"text-truncate seller-offer-registration-list__item-account-name h5 mb-0",attrs:{"target":"_blank","title":"View Account","to":{
                name: 'SellerAccountDetails',
                params: { id: seller.id },
                exact: true,
              }}},[_vm._v(" "+_vm._s(seller.name)+" "),_c('CIcon',{staticClass:"ml-1",attrs:{"name":"cil-external-link","size":"custom"}})],1),_c('a',{staticClass:"ml-2",attrs:{"href":"","title":"Copy Account Id"},on:{"click":function($event){$event.preventDefault();return _vm.copyAccountIdToClipboard(seller.id)}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)],1)])}),0):_c('div',[_vm._m(1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"seller-offer-registration-list__item"},[_c('i',[_vm._v("All Sellers can participate")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"seller-offer-registration-list__item"},[_c('i',[_vm._v("All Sellers are actively participating")])])}]

export { render, staticRenderFns }