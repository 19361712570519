import Vue from 'vue'

export function showSuccessMessage(message) {
  if (message) {
    Vue.notify({
      group: 'default',
      title: message,
      type: 'success',
      duration: 3000
    })
  }
}

export function showErrorMessage(message = 'Something went wrong') {
  Vue.notify({
    group: 'default',
    title: message,
    type: 'error',
    duration: 4000
  })
}