<template lang="">
  <CCard>
    <CCardHeader>
      Edit Account Budget Cap
    </CCardHeader>

    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CRow class="mb-3">
          <CCol
            sm="3"
            class="pr-3"
          >
            <label class="col-form-label">
              Admin-controlled Buyer Daily Budget
            </label>
          </CCol>

          <CCol
            sm="9"
          >
            <CSwitch
              shape="pill"
              color="primary"
              horizontal
              add-label-classes=""
              add-input-classes="w-25"
              size="lg"
              :checked.sync="setAdminDailyBudget"
            />

            <CInput
              v-if="setAdminDailyBudget"
              v-model="$v.budget.adminDailyBudgetCapInUSD.$model"
              class="mt-2"
              type="number"
              name="adminDailyBudgetCapInUSD"
              placeholder="Set buyer daily budget, takes precedence over budget set by user"
              :is-valid="checkIfValid(['budget', 'adminDailyBudgetCapInUSD'])"
              :horizontal="{label: 'col-sm-3', input: 'col-sm-5 col-xs-12'}"
              invalid-feedback="Please enter amount greater than 0"
            />
          </CCol>
        </CRow>

        <CRow class="mb-3">
          <CCol
            sm="3"
            class="pr-3"
          >
            <label class="col-form-label">
              User-controlled Buyer Daily Budget
            </label>
          </CCol>

          <CCol
            sm="9"
          >
            <CSwitch
              shape="pill"
              color="primary"
              horizontal
              add-label-classes=""
              add-input-classes="w-25"
              size="lg"
              :checked.sync="setUserDailyBudget"
            />

            <CInput
              v-if="setUserDailyBudget"
              v-model="$v.budget.dailyBudgetInUSD.$model"
              class="mt-2"
              type="number"
              name="dailyBudgetInUSD"
              placeholder="Set buyer daily budget managed by user"
              :is-valid="checkIfValid(['budget', 'dailyBudgetInUSD'])"
              :horizontal="{label: 'col-sm-3', input: 'col-sm-5 col-xs-12'}"
              invalid-feedback="Please enter amount greater than 0"
            />
          </CCol>
        </CRow>

        <CCol
          :md="{ offset: '3', size: '9' }"
          :sm="{ offset: '3', size: '9' }"
        >
          <CButton
            type="submit"
            size="sm"
            color="primary"
            :disabled="!isFormEdited"
            @click="askConfirmation"
          >
            Update
          </CButton>
        </CCol>
      </CForm>
    </CCardBody>

    <CModal
      title="Confirm change budget"
      color="success"
      :show.sync="showConfirmationModal"
    >
      Are you sure you want to change the budget cap?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="updateAccountBudgetCap"
        >
          Update
        </ButtonLoading>

        <CButton
          color="danger"
          @click="showConfirmationModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { minValue, requiredIf,  } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

import formGenericMixin from '@/mixins/form-generic-mixin'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'BuyerAccountBudgetDetails',

  mixins: [formGenericMixin],

  props: {
    accountId: {
      type: String,
      required: true
    },

    buyerAccountBudget: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  validations() {
    return {
      budget: {
        dailyBudgetInUSD: {
          requiredIf: requiredIf(() => {
            return Boolean(this.setUserDailyBudget)
          }),
          minValue: minValue(1)
        },
        adminDailyBudgetCapInUSD: {
          requiredIf: requiredIf(() => {
            return Boolean(this.setAdminDailyBudget)
          }),
          minValue: minValue(1)
        }
      }
    }
  },

  data() {
    return {
      isLoading: false,

      showConfirmationModal: false,

      budget: {
        dailyBudgetInUSD: null,
        adminDailyBudgetCapInUSD: null
      }
    }
  },

  computed: {
    setUserDailyBudget: {
      get() {
        return this.budget.dailyBudgetInUSD != null
      },

      set(value) {
        if (value) {
          this.$set(this.budget, 'dailyBudgetInUSD', '')
          this.$v.budget.dailyBudgetInUSD.$touch()
        } else {
          this.$set(this.budget, 'dailyBudgetInUSD', null)
          this.$v.budget.dailyBudgetInUSD.$touch()
        }
      }
    },

    setAdminDailyBudget: {
      get() {
        return this.budget.adminDailyBudgetCapInUSD != null
      },

      set(value) {
        if (value) {
          this.$set(this.budget, 'adminDailyBudgetCapInUSD', '')
          this.$v.budget.adminDailyBudgetCapInUSD.$touch()
        } else {
          this.$set(this.budget, 'adminDailyBudgetCapInUSD', null)
          this.$v.budget.adminDailyBudgetCapInUSD.$touch()
        }
      }
    }
  },

  watch: {
    buyerAccountBudget: {
        immediate: true,
        handler(newValue) {
        this.budget = {
          ...newValue
        } || {
          dailyBudgetInUSD: null,
          adminDailyBudgetCapInUSD: null
        }
      }
    }
  },

  methods: {
    ...mapActions('accounts', [
      'updateBuyerAccountBudgetCap', 
    ]),

    askConfirmation() {
      this.submitted = true

      if (this.isValid) {
        this.showConfirmationModal = true
      }
    },

    async updateAccountBudgetCap() {
      this.isLoading = true

      try {
        await this.updateBuyerAccountBudgetCap({
          accountId: this.accountId,
          budget: this.budget
        })

        this.showConfirmationModal = false

        showSuccessMessage('Successfully update budget cap')

        this.resetFormDirtyState()
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
<style lang="">
  
</style>