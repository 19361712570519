<template>
  <div>
    <CButton
      size="sm"
      color="warning"
      @click="showConfirmationModal"
    >
      {{ requestDetails.testOnly ? 'Mark Live' : 'Mark Test Only' }}
    </CButton>

    <CModal
      :title="`Confirm change`"
      color="success"
      :show.sync="confirmationModal"
    >
      Are you sure you want to mark request as {{ requestDetails.testOnly ? 'live' : 'test only' }}?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="toggleTestOnly"
        >
          Change
        </ButtonLoading>

        <CButton
          color="danger"
          @click="confirmationModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import { showSuccessMessage } from '@/notification-utils'
import { mapActions } from 'vuex'

export default {
  name: 'RegistrationRequestMarkTestOnlyButton',

  props: {
    requestDetails: {
      type: Object,
      default() {
        return {}
      },
      required: true
    },

    type: {
      type: String,
      default: '',
      required: true,
      validator(value) {
        return ['buyer', 'seller'].includes(value)
      }
    }
  },

  data() {
    return {
      isLoading: false,
      confirmationModal: false
    }
  },

  methods: {
    ...mapActions('registrationRequests', [
      'updateSellerRegistrationDetails',
      'updateSellerRegistrationWithReviewDetails',
      'updateBuyerRegistrationWithReviewDetails',
      'updateBuyerRegistrationDetails'
    ]),

    showConfirmationModal() {
      this.confirmationModal = true
    },

    async toggleTestOnly() {
      const testOnly = !this.requestDetails.testOnly

      try {
        this.isLoading = true
        const request = {
          id: this.requestDetails.id,
          updateObject: {
            testOnly,
          }
        }

        const reviewDetails = { id: this.requestDetails.id, reviewDetails: { allowedCategories: [] } }

        if (this.type === 'seller') {
          await this.updateSellerRegistrationDetails(request)
        } else {
          await this.updateBuyerRegistrationDetails(request)
        }

        this.confirmationModal = false

        showSuccessMessage(`Successfully marked registration request as ${testOnly ? 'test only' : 'live'}`)

        if (this.type === 'seller') {
          await this.updateSellerRegistrationWithReviewDetails(reviewDetails)
        } else {
          await this.updateBuyerRegistrationWithReviewDetails(reviewDetails)
        }
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
<style lang="">
  
</style>