<template>
  <div>
    <BuyerCampaignsList
      :buyer-campaigns="pendingApprovalBuyerCampaigns"
      :is-loading="isLoading"
      page-title="Pending Approval Buyer Campaigns"
      @change-auto-reload="onChangeAutoReload"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BuyerCampaignsList from './BuyerCampaignsList.vue'


const AUTO_RELOAD_INTERVAL = 60 * 2 * 1000 // 2 mins interval

let autoReloadTimer

export default {
  name: 'PendingApprovalBuyerCampaigns',

  title: 'Pending Approval Buyer Campaigns',
  
  components: {
    BuyerCampaignsList
  },

  data() {
    return {
      type: '',
      isLoading: false
    }
  },

  computed: {
    ...mapState('campaign', ['pendingApprovalBuyerCampaigns'])
  },

  async created() {
    await this.getCampaigns()
    this.setRequestTimer()
  },

  beforeDestroy() {
    this.clearAutoReloadInterval()
  },

  methods: {
    ...mapActions('campaign', ['fetchPendingApprovalBuyerCampaigns']),

    async getCampaigns() {
      try {
        this.isLoading = true
        await this.fetchPendingApprovalBuyerCampaigns(true)
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    },

    onChangeAutoReload(enableAutoreload) {
      if (enableAutoreload) {
        this.setRequestTimer()
      } else {
        this.clearAutoReloadInterval()
      }
    },

    clearAutoReloadInterval() {
      if (autoReloadTimer) {
        clearInterval(autoReloadTimer)
        autoReloadTimer = null
      }
    },

    setRequestTimer() {
      if (autoReloadTimer) {
        clearInterval(autoReloadTimer)
      }

      autoReloadTimer = setInterval(async () => {
        await this.getCampaigns()
      }, AUTO_RELOAD_INTERVAL)
    }
  }
}
</script>

<style></style>
