<template>
  <div>
    <GenericList
      caption="Seller Offers Applications"
      :items="pendingRequests"
      :fields="fields"
      :is-loading="isLoading"
      :action-uri="actionUri"
      @change-auto-reload="onChangeAutoReload"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import GenericList from './GenericList.vue'

const AUTO_RELOAD_INTERVAL = 120 * 1000 // 2 mins interval

let autoReloadTimer

export default {
  name: 'SellerOfferRequests',

  title: 'Seller Offers Applications',

  components: {
    GenericList
  },

  data() {
    return {
      type: '',
      isLoading: false
    }
  },

  computed: {
    ...mapState('sellerOfferRegistration', ['pendingRequests']),

    fields () {
      return [
        { key: 'sellerOffer', label: 'Seller Offer', sortable: true },
        { key: 'sellerOfferName', label: 'Name', sortable: true },
        { key: 'createdBy', label: 'Representative', sortable: true },
        { key: 'createdAt', label: 'Applied', sortable: true },
        { key: 'categoryName', label: 'Category', sortable: true },
        { key: 'Actions', filter: false }
      ]
    },

    actionUri () {
      return'/dashboard/seller-offers/review'
    }
  },

  async created() {
    try {
      this.isLoading = true
      await this.fetchPendingRequests()
    } finally {
      this.isLoading = false
    }
  },

  beforeDestroy() {
    this.clearAutoReloadInterval()
  },

  methods: {
    ...mapActions('sellerOfferRegistration', ['fetchPendingRequests']),

    onChangeAutoReload(enableAutoreload) {
      if (enableAutoreload) {
        autoReloadTimer = setInterval(async () => {
          await this.fetchPendingRequests()
        }, AUTO_RELOAD_INTERVAL)
      } else {
        this.clearAutoReloadInterval()
      }
    },

    clearAutoReloadInterval() {
      if (autoReloadTimer) {
        clearInterval(autoReloadTimer)
        autoReloadTimer = null
      }
    }

  }
}
</script>

<style></style>
