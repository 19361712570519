import { render, staticRenderFns } from "./RegistrationRequestChecklistItem.vue?vue&type=template&id=0281bcee&scoped=true&"
import script from "./RegistrationRequestChecklistItem.vue?vue&type=script&lang=js&"
export * from "./RegistrationRequestChecklistItem.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationRequestChecklistItem.vue?vue&type=style&index=0&id=0281bcee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0281bcee",
  null
  
)

export default component.exports