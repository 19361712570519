export default {
  data() {
    return {
      submitted: false,
      isLoading: false
    }
  },
  
  computed: {
    isValid() {
      return !this.$v.$invalid
    },

    isFormEdited() {
      return this.$v.$anyDirty
    },
  },

  methods: {
    resetFormDirtyState() {
      this.$v.$reset()
    },

    checkIfValid(path) {
      let field = this.$v

      for (const subKey of path) {
        field = field[subKey]
      }

      if (!this.submitted) {
        return null
      }

      return !(field.$invalid)
    },

    cannotBeBlank(field) {
      return `${field} cannot be blank`
    },

    pleaseSelect(field) {
      return `Please select ${field}`
    },

    invalidRange(field, minValue, maxValue, unit = '') {
      if (minValue != null && maxValue != null) {
        return `${field} must be between ${minValue}-${maxValue}${unit}`
      } else if (minValue != null) {
        return `${field} must be greater than ${minValue}${unit}`
      } else if (maxValue != null) {
        return `${field} must be less than ${minValue}${unit}`
      }

      return `${field} is invalid`
    }
  },
}