<template>
  <CCard>
    <CCardHeader
      class="d-flex align-items-center clickable"
      @click="collapse = !collapse"
    >
      <div>
        <b>{{ `${priority}.` }} Stripe Identity Verification</b>
        <span
          v-if="checklistItem.lastUpdatedAt"
          class="text-muted ml-2"
        >(Last Updated: {{ getSafe(() => formatDate(checklistItem.lastUpdatedAt)) }})</span>
        <div class="text-muted">
          Verify user's document and check their photo in the document with their selfie
        </div>
      </div>

      <CBadge
        class="ml-auto"
        :color="getBadge(checklistItemStatus)"
      >
        <div class="checklist-item__status">
          {{ checklistItemStatus }}
        </div>
      </CBadge>

      <CIcon
        :name="downIcon"
        class="ml-3"
      />
    </CCardHeader>

    <CCollapse :show="collapse">
      <CCardBody>
        <dl
          v-if="verificationSessionExists && !isVerifiedSession"
          class="row"
        >
          <dt
            class="col-sm-3 text-right"
          >
            URL
          </dt>
          <dd
            class="col-sm-9 "
          >
            <ButtonLoading
              v-if="getSafe(() => !verificationSessionDetails.url)"
              :loading="isSessionDetailsLoading" 
              color="secondary"
              size="sm"
              @click="getVerificationSessionDetails"
            >
              Get Link
            </ButtonLoading>

            <span v-else>
              <a
                href
                class="mr-1"
                @click.prevent="
                  copyStripeUrlToClipboard(verificationSessionDetails.url)
                "
              ><CIcon
                name="cil-copy"
              /></a>
              {{ verificationSessionDetails.url }}
            </span>
          </dd>

          <dt
            v-if="lastFailureReason"
            class="col-sm-3 text-right"
          >
            Last Failure Reason
          </dt>
          <dd
            v-if="lastFailureReason"
            class="col-sm-9"
          >
            {{ lastFailureReason }}
          </dd>
        </dl>

        <div>
          <h5>
            Notes
            <ButtonLoading
              size="sm"
              color="secondary"
              class="ml-2"
              :loading="isLoading && addNotesTrigger"
              :disabled="!verificationSessionExists"
              @click="addNotes"
            >
              Add Notes
            </ButtonLoading>

            <i
              v-if="!verificationSessionExists"
              class="text-muted ml-1"
              style="font-size: 0.8rem;"
            >
              Please request for Stripe Verification Check before adding notes
            </i>
          </h5>
          <ReviewNotesForm
            v-if="showNotesForm"
            :parent-object="checklistItem"
            class="my-4"
            @notes-added="loadNotes(true)"
            @cancel="showNotesForm = false"
          />
          <ReviewNotesList
            class="mt-3"
            :is-loading-notes="isLoadingNotes"
            :review-notes="notes"
            :parent-object="checklistItem"
            @reload-review-notes="loadNotes(true)"
          />
        </div>
      </CCardBody>

      <CCardFooter>
        <ButtonLoading
          v-if="!verificationSessionExists"
          :loading="isLoading" 
          color="primary"
          @click="createVerificationSession"
        >
          Request
        </ButtonLoading>

        <ButtonLoading
          v-if="verificationSessionExists && !isVerifiedSession"
          :loading="isResendLoading" 
          color="primary"
          @click="resendVerificationSessionLink"
        >
          Resend
        </ButtonLoading>
        <!-- <CDropdown
          color="primary"
          :disabled="isLoading "
        >
          <template #toggler-content>
            <CSpinner
              v-if="isLoading && !addNotesTrigger"
              size="sm"
              class="mr-2"
            />
            <span>Change Status</span>
          </template>
          <CDropdownItem @click="setChecklistItemStatus('Requested')">
            Requested
          </CDropdownItem>
          <CDropdownItem @click="setChecklistItemStatus('Received')">
            Received
          </CDropdownItem>
          <CDropdownItem @click="setChecklistItemStatus('Verified')">
            Verified
          </CDropdownItem>
          <CDropdownItem @click="setChecklistItemStatus('Failed')">
            Failed
          </CDropdownItem>
        </CDropdown> -->
      </CCardFooter>
    </CCollapse>
  </CCard>
</template>
<script>
import { mapActions } from 'vuex'

import ReviewNotesForm from '@/views/ReviewNotes/ReviewNotesForm.vue'
import ReviewNotesList from '@/views/ReviewNotes/ReviewNotesList.vue'
import NotesApi from '@/api/notes.api'
import ButtonLoading from '@/components/common/ButtonLoading.vue'
import { dateTimeFormat } from '@/utils'
import RegistrationChecklistItemApi from '@/api/registration-checklist-item.api'
import { showSuccessMessage } from '@/notification-utils'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'


export default {
  name: 'RegistrationRequestIdentityChecklistItem',

  components: {
    ReviewNotesList,
    ReviewNotesForm,
    ButtonLoading
  },

  mixins: [copyToClipboardMixin],

  props: {
    priority: {
      type: [String, Number],
      default: '1'
    },

    checklistItem: {
      type: Object,
      default() {
        return {}
      }
    },

    registrationRequestId: {
      type: String,
      default() {
        return ''
      }
    }
  },

  data() {
    return {
      notes: [],
      isLoadingNotes: false,
      isLoading: false,
      showNotesForm: false,
      addNotesTrigger: false,
      collapse: false,
      isResendLoading: false,
      isSessionDetailsLoading: false,

      verificationSessionDetails: {}
    }
  },

  computed: {
    checklistItemStatus() {
      const status = this.checklistItem?.status ?? 'Not Requested'

      return status === 'NotRequested' ? 'Not Requested' : status
    },

    downIcon() {
      return !this.collapse ? 'cil-chevron-bottom' : 'cil-chevron-top'
    },

    verificationSessionExists() {
      return !!this.checklistItem?.status?.toLowerCase()
    },

    lastFailureReason() {
      return this.checklistItem?.status?.toLowerCase() === 'failed' ? this.checklistItem?.metadata?.['USER_IDENTITY_LAST_FAILURE_REASON'] : ''
    },

    isVerifiedSession() {
      return this.checklistItem?.status?.toLowerCase() === 'verified'
    }
  },

  watch: {
    checklistItem: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (!oldValue?.noteListId && newValue.noteListId) {
          await this.loadNotes()
        }
      }
    }
  },

  async created () {
    await this.loadNotes()
  },

  methods: {
    ...mapActions('registrationRequests', [
      'createIdentityCheckForRegistrationRepresentative',
      'resendIdentityCheckForRegistrationRepresentative'
    ]),

    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    },

    async getVerificationSessionDetails() {
      try {
        this.isSessionDetailsLoading = true

        const response = await RegistrationChecklistItemApi.getUserIdentityCheckVerificationSessionDetails(this.registrationRequestId)

        this.verificationSessionDetails = response.data

        this.copyStripeUrlToClipboard(this.verificationSessionDetails.url)
      } catch (error) {
        // 
      } finally {
        this.isSessionDetailsLoading = false
      }
    },

    async loadNotes(hideNotesForm = false) {
      try {
        if (this.checklistItem?.noteListId) {
          this.isLoadingNotes = true
          const response = await NotesApi.getReviewNotes(this.checklistItem?.noteListId)

          this.notes = response.data || []
        }

        if (hideNotesForm) {
          this.showNotesForm = false
        }
      } catch {
        //
      } finally {
        this.isLoadingNotes = false
      }
    },


    async createVerificationSession() {
      try {
        this.isLoading = true

        await this.createIdentityCheckForRegistrationRepresentative(this.registrationRequestId)

        showSuccessMessage('Successfully created verification session and sent link to user')
      } catch (error) {
        //
      } finally {
        this.isLoading = false 
      }
    },

    async addNotes() {
      try {
        if (!this.checklistItem.id) {
          return
        }

        this.showNotesForm = true
      } catch {
        //
      } finally {
        this.isLoading = false
        this.addNotesTrigger = false
      }
    },

    async resendVerificationSessionLink() {
      try {
        this.isResendLoading = true

        await this.resendIdentityCheckForRegistrationRepresentative(this.registrationRequestId)

        showSuccessMessage('Successfully resent verification session link to user')
      } catch (error) {
        //
      } finally {
        this.isResendLoading = false 
      }
    },

    getBadge(status) {
      switch (status) {
        case 'Not Requested':
        case 'NotRequested':
        case 'Received':
          return 'warning'

        case 'Verified':
          return 'success'

        case 'Failed':
          return 'danger'

        default:
          return 'secondary'
      }
    },

    copyStripeUrlToClipboard(url) {
      this.copyTextToClipboard(url)
      showSuccessMessage('Stripe Verification URL copied to clipboard')
    }
  },
}
</script>
<style lang="scss" scoped>
.checklist-item__status {
  font-size: 1.2rem;
}
</style>