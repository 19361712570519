<template>
  <CCard>
    <CCardHeader>
      <div class="d-flex">
        <div>Buyer Application</div>

        <RegistrationRequestMarkTestOnlyButton
          class="ml-auto"
          :request-details="requestDetails"
          type="buyer"
        />

        <CButton
          size="sm"
          :color="editMode ? 'danger' : 'primary'"
          class="ml-3"
          @click="toggleEditMode"
        >
          {{ editMode ? 'Cancel' : 'Edit Details' }}
        </CButton>
      </div>
    </CCardHeader>

    <div v-if="requestDetails">
      <div v-if="!editMode">
        <RegistrationRequestCommonInfoReadOnly
          type="buyer"
          :request-details="requestDetails"
        />
      </div>
      <div v-else>
        <RegistrationRequestCommonInfoEdit
          type="buyer"
          :request-details="requestDetails"
          @updated="toggleEditMode"
        />
      </div>
    </div>
  </CCard>
</template>

<script>
import RegistrationRequestMarkTestOnlyButton from './RegistrationRequestMarkTestOnlyButton.vue'
import RegistrationRequestCommonInfoReadOnly from './RegistrationRequestCommonInfoReadOnly.vue'
import RegistrationRequestCommonInfoEdit from './RegistrationRequestCommonInfoEdit.vue'

const cssClassMapping = {
  'pending': 'warning',
  'approved': 'success',
  'rejected': 'danger',
  'waitlisted': 'secondary'
}

export default {
  name: 'RegistrationRequestBuyerInfo',

  title: 'Buyer Registration Request',
  
  components: {
    RegistrationRequestCommonInfoReadOnly,
    RegistrationRequestMarkTestOnlyButton,
    RegistrationRequestCommonInfoEdit
  },
  props: {
    requestDetails: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return  {
      editMode: false
    }
  },

  computed: {
    statusVariant() {
      
      return cssClassMapping[this.requestDetails?.status?.toLowerCase()]
    }
  },

  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode
    }
  }
}
</script>

<style>

</style>