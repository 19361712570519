import AuthAPI from '@/api/auth.api'
import UserAPI from '@/api/user.api'

const LOCAL_STORAGE_AUTHORIZED_USER_DETAILS_KEY = 'authenticatedUserDetails'

const ENTER_USER_CREDS = 'ENTER_USER_CREDS'
const SMS_MFA = 'SMS_MFA'
const SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA'
const SELECT_MFA_TYPE = 'SELECT_MFA_TYPE'
const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/ // eslint-disable-line
const MILLISECONDS_PER_MINUTES = 1000 * 60

const CognitoGroups = {
  FINANCIAL_MANAGER: 'FinancialManager',
  ADMIN: 'Admin'
}

const MFA_STAGES = {
  ENTER_USER_CREDS,
  SMS_MFA,
  SOFTWARE_TOKEN_MFA,
  SELECT_MFA_TYPE,
  NEW_PASSWORD_REQUIRED,
  LOGIN_SUCCESS
}

const initialAuthData = {
  accessToken: '',
  refreshToken: '',
  idToken: '',
  cognitoUsername: '',
  email: '',
  name: '',
  authTime: ''
}

function _getInitialAuthorizationUserDetails() {
  try {
    return JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_AUTHORIZED_USER_DETAILS_KEY)
    )
  } catch (error) {
    console.error(error)

    return initialAuthData
  }
}

function _saveAuthenticatedUserDataToLocalStorage(authData) {
  localStorage.setItem(
    LOCAL_STORAGE_AUTHORIZED_USER_DETAILS_KEY,
    JSON.stringify(authData)
  )
}

const state = {
  authenticatedUserDetails: _getInitialAuthorizationUserDetails(),

  redirectToAfterLoginPath: '',

  loginStage: {
    cognitoUsername: '',
    sessionID: '',
    currentAuthChallenge: MFA_STAGES.ENTER_USER_CREDS,
    email: ''
  },

  loginError: {
    errorMessage: '',
    errorCode: null
  },

  userDetails: {
    name: '',
    email: '',
    id: '' // this is the cognito username
  },

  MFASecretKey: '',

  accessTokenIntervalTimer: null
}

const getters = {
  isAuthenticated(state) {
    return !!state.authenticatedUserDetails?.accessToken
  },

  accessToken(state) {
    return state.authenticatedUserDetails?.accessToken
  },

  currentAuthChallenge(state) {
    return state.loginStage?.currentAuthChallenge
  },

  haveUserData(state) {
    return !!state.userDetails?.id
  },

  loggedInUserID(state) {
    return state.userDetails?.id
  },

  isFinancialManager(state) {
    return state.userDetails?.groups?.includes(CognitoGroups.FINANCIAL_MANAGER)
  },

  isSuperAdmin(state) {
    return state.userDetails?.groups?.includes(CognitoGroups.ADMIN)
  },

  isUserLoggedInWithinLastXMinutes: (state) => (mins) => {
    const authTime = state.authenticatedUserDetails?.authTime

    if (!authTime) {
      return false
    }

    const authDateTime = new Date(authTime)

    const diffInMilliseconds = Math.abs(new Date() - authDateTime)

    const diffInMins = Math.floor((diffInMilliseconds/1000)/60)

    if (diffInMins >= mins) {
      return false
    }

    return true
  },

  isMFASetup(state) {
    return Boolean(state.userDetails?.mfaSettings?.enabledMFA?.length)
  }
}

const actions = {
  resetAuthData({ commit }) {
    commit('RESET_AUTHENTICATED_USER_DATA')
  },

  setRedirectToPathAfterLogin({ commit }, path) {
    commit('SET_REDIRECT_TO_AFTER_LOGIN_PATH', path)
  },

  async login({ commit, dispatch }, { email, password }) {
    try {
      commit('RESET_LOGIN_ERROR')

      const response = await AuthAPI.login({ email, password })

      const nextStageData = response.data

      commit('SET_LOGIN_STAGE_STATE', {
        cognitoUsername: nextStageData.username,
        sessionID: nextStageData.sessionID,
        currentAuthChallenge: nextStageData.challengeName,
        email
      })

      if (nextStageData.authenticationResult) {
        dispatch('setAuthData', { authData: nextStageData.authenticationResult } )
      }
    } catch (error) {
      commit('SET_LOGIN_ERROR', {
        errorMessage: error.title,
        errorCode: error.errorCode
      })
    }
  },

  async submitMFATypeSelected({ state, commit }, mfaType) {
    try {
      commit('RESET_LOGIN_ERROR')

      const response = await AuthAPI.respondToAuthChallenge({
        challengeName: state.loginStage.currentAuthChallenge,
        challengeResponseAnswer: mfaType,
        sessionID: state.loginStage.sessionID,
        username: state.loginStage.cognitoUsername
      })

      const nextStageData = response.data

      commit('SET_LOGIN_STAGE_STATE', {
        sessionID: nextStageData.sessionID,
        currentAuthChallenge: mfaType
      })
    } catch (error) {
      commit('SET_LOGIN_ERROR', {
        errorMessage: error.title,
        errorCode: error.errorCode
      })
    }
  },

  async submitMFACode({ state, commit, dispatch }, mfaCode) {
    try {
      commit('RESET_LOGIN_ERROR')

      const response = await AuthAPI.respondToAuthChallenge({
        challengeName: state.loginStage.currentAuthChallenge,
        challengeResponseAnswer: mfaCode,
        sessionID: state.loginStage.sessionID,
        username: state.loginStage.cognitoUsername
      })

      const responseData = response.data

      if (responseData.authenticationResult) {
        dispatch('setAuthData', { authData: responseData.authenticationResult })
      }
    } catch (error) {
      commit('SET_LOGIN_ERROR', {
        errorMessage: error.title,
        errorCode: error.errorCode
      })

      throw error
    }
  },

  /**
   * Once the access token expires, the expiry for which is 20 mins, we logout the user
   * We need to keep refreshing the access token before the token expires, as long as the user is on the dashboard
   * @param {context} param0 
   */
  async refreshAccessToken({ state, commit }) {
    const response = await AuthAPI.refreshAuthDataUsingRefreshToken({
      refreshToken: state.authenticatedUserDetails.refreshToken,
      cognitoUsername: state.authenticatedUserDetails.cognitoUsername
    })

    const authenticationResult = response.data

    if (authenticationResult) {
      commit('SET_AUTH_TOKENS', authenticationResult)

      // TODO: Set more user data in local storage
      localStorage.setItem(
        LOCAL_STORAGE_AUTHORIZED_USER_DETAILS_KEY,
        JSON.stringify(state.authenticatedUserDetails)
      )
    }
  },

  logout({ commit, dispatch }) {
    // TODO: Add and integrate revoke token API
    commit('RESET_AUTHENTICATED_USER_DATA')
    localStorage.clear()
    dispatch('clearPollingForNewAccessToken')
  },

  resetLoginStageState({ commit }) {
    commit('RESET_LOGIN_STAGE_STATE')
  },

  async getLoggedInUserDetails({ commit, state }) {
    try {
      const response = await UserAPI.getLoggedInUserDetails()

      const userDetails = response.data

      commit('SET_USER_DETAILS', userDetails)

      commit('SET_AUTHENTICATED_USER_DATA', {
        cognitoUsername: userDetails.id,
        email: userDetails.email,
      })

      _saveAuthenticatedUserDataToLocalStorage(state.authenticatedUserDetails)
    } catch (error) {
      console.error(error)
    }
  },

  setAuthData({ commit, dispatch, state }, { authData }) {
    commit('SET_AUTH_TOKENS', authData)

    commit('SET_AUTHENTICATED_USER_DATA', {
      cognitoUsername: state.loginStage?.cognitoUsername,
      email: state.loginStage?.email
    })

    commit('SET_USER_AUTH_TIME', new Date())

    _saveAuthenticatedUserDataToLocalStorage(state.authenticatedUserDetails)

    dispatch('getLoggedInUserDetails')

    commit('RESET_LOGIN_STAGE_STATE')
  },

  async setPermanentPassword({ commit, dispatch, state }, newPassword) {
    commit('RESET_LOGIN_ERROR')

    const response = await AuthAPI.respondToAuthChallenge({
      challengeName: state.loginStage.currentAuthChallenge,
      challengeResponseAnswer: newPassword,
      sessionID: state.loginStage.sessionID,
      username: state.loginStage.cognitoUsername
    })

    const responseData = response.data

    commit('SET_LOGIN_STAGE_STATE', {
      sessionID: responseData.sessionID,
      currentAuthChallenge: responseData.challengeName
    })

    if (responseData.authenticationResult) {
      dispatch('setAuthData', { authData: responseData.authenticationResult })
    }
  },

  async setNewPassword(context, { email, newPassword, confirmationCode }) {
    return AuthAPI.setNewPassword({ email, newPassword, confirmationCode })
  },

  resetMFAKey({ commit }) {
    commit('RESET_MFA_KEY')
  },

  async associateSoftwareMFA({ commit, dispatch, state }) {
    try {
      const response = await AuthAPI.associateSoftwareMFA({
        accessToken: state.authenticatedUserDetails.accessToken
      })
      commit('SET_MFA_KEY', response.data.secretKey)
      return response.data.secretKey
    } catch(error) {
      // TODO: once we update backend code to send some specific error
      // code in error.response.data.code, update the below condition
      if (error.response.status === 400) {
        await dispatch('refreshAccessToken')
        return dispatch('associateSoftwareMFA')
      }
      throw error
    }
  },

  async verifySoftwareMFA({ state, dispatch }, softwareToken) {
    const response = await AuthAPI.verifySoftwareMFA({
      accessToken: state.authenticatedUserDetails.accessToken,
      softwareToken
    })

    await dispatch('getLoggedInUserDetails')

    return response.data
  },

  /**
   * We're supposed to logout after certain period of inactivity, so as soon as the access token expires, we logout the user from the platform.
   * This will make sure we keep getting a new access token with the latest expiry, as long as the user is on the page.
   * https://app.shortcut.com/ringbax/story/10020/inactivity-logout
   * @param {*} param0 
   */
  startPollingForNewAccessToken({ state, commit, dispatch }) {
    if (!state.accessTokenIntervalTimer) {
      dispatch('refreshAccessToken')
      commit('SET_ACCESS_TOKEN_INTERVAL_TIMER', () => dispatch('refreshAccessToken'))
    }
  },

  clearPollingForNewAccessToken({ commit }) {
    commit('CLEAR_ACCESS_TOKEN_INTERVAL_TIMER')
  },

  async forgotPassword(context, email) {
    return AuthAPI.forgotPassword({ email })
  },
}

const mutations = {
  RESET_LOGIN_STAGE_STATE(state) {
    state.loginStage = {
      cognitoUsername: '',
      sessionID: '',
      currentAuthChallenge: MFA_STAGES.ENTER_USER_CREDS
    }
  },
  SET_REDIRECT_TO_AFTER_LOGIN_PATH(state, path) {
    state.redirectToAfterLoginPath = path
  },
  SET_LOGIN_STAGE_STATE(state, data) {
    state.loginStage = {
      ...state.loginStage,
      ...data
    }
  },
  RESET_LOGIN_ERROR(state) {
    state.loginError = {
      errorMessage: '',
      errorCode: null
    }
  },
  SET_LOGIN_ERROR(state, { errorCode, errorMessage }) {
    state.loginError = {
      errorMessage,
      errorCode
    }
  },
  SET_AUTH_TOKENS(state, authTokenData) {
    state.authenticatedUserDetails = {
      ...state.authenticatedUserDetails,
      accessToken: authTokenData.accessToken,
      refreshToken:
        authTokenData.refreshToken ||
        state.authenticatedUserDetails.refreshToken,
      idToken: authTokenData.idToken
    }
  },
  RESET_AUTHENTICATED_USER_DATA(state) {
    state.authenticatedUserDetails = {
      ...initialAuthData
    }
  },

  SET_AUTHENTICATED_USER_DATA(state, { email, cognitoUsername }) {
    state.authenticatedUserDetails = {
      ...state.authenticatedUserDetails,
      email,
      cognitoUsername,
    }
  },

  SET_USER_AUTH_TIME(state, authTime) {
    state.authenticatedUserDetails = {
      ...state.authenticatedUserDetails,
      authTime
    }
  },

  SET_USER_DETAILS(state, userDetails) {
    state.userDetails = userDetails
  },

  SET_MFA_KEY(state, secretKey) {
    state.MFASecretKey = secretKey
  },

  RESET_MFA_KEY(state) {
    state.MFASecretKey = ''
  },

  SET_ACCESS_TOKEN_INTERVAL_TIMER(state, callback) {
    state.accessTokenIntervalTimer = setInterval(() => {
      if (callback) {
        callback()
      }
    }, MILLISECONDS_PER_MINUTES * 2)
  },

  CLEAR_ACCESS_TOKEN_INTERVAL_TIMER(state) {
    if (state.accessTokenIntervalTimer) {
      clearInterval(state.accessTokenIntervalTimer)
      state.accessTokenIntervalTimer = null
    }
  }
}

export {
  LOCAL_STORAGE_AUTHORIZED_USER_DETAILS_KEY,
  MFA_STAGES,
  PASSWORD_REGEX,
  CognitoGroups
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
