var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[(_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",style:({ height: '80vh' })},[_c('CSpinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"color":"info","grow":""}})],1):_c('div',[_c('CCardHeader',{attrs:{"data-cy":"header"}},[_vm._t("header",[_c('CIcon',{attrs:{"name":_vm.icon}}),_vm._v(" "+_vm._s(_vm.caption)+" "),_c('CButton',{staticClass:"float-right",attrs:{"data-cy":("button-create-" + _vm.caption),"size":"sm","color":"info"},on:{"click":function($event){return _vm.$router.push({
              name: _vm.typeToComponentNamesMap[_vm.type].createComponent,
              exact: true
            })}}},[_c('CIcon',{attrs:{"size":"sm","name":"cil-plus"}}),_vm._v(" Create New ")],1)])],2),_c('CCardBody',[_vm._t("pre-table"),_c('CDataTable',_vm._b({attrs:{"data-cy":("table-" + _vm.caption),"hover":_vm.hover,"striped":_vm.striped,"border":_vm.border,"fixed":_vm.fixed,"items":_vm.items,"fields":_vm.fields,"items-per-page":_vm.small ? 50 : 5,"dark":_vm.dark,"pagination":{align: 'center'},"sorter-value":_vm.sorterState},on:{"update:sorterValue":function($event){_vm.sorterState=$event},"update:sorter-value":function($event){_vm.sorterState=$event}},scopedSlots:_vm._u([{key:"status",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"id",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.id)+" "),(item.testOnly)?_c('CBadge',{staticClass:"ml-2",attrs:{"color":"danger"}},[_vm._v(" Test ")]):_vm._e()],1)]}},{key:"createdAt",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return ("" + (_vm.formatDate(item.createdAt))); }))+" ")])]}},{key:"updatedAt",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return ("" + (_vm.formatDate(item.updatedAt || item.createdAt))); }))+" ")])]}},{key:"createdBy",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return ((item.createdBy.name) + " " + (item.createdBy.lastName)); }))+" ")])]}},{key:"updatedBy",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return ((item.updatedBy.name) + " " + (item.updatedBy.lastName)); }))+" ")])]}},{key:"Actions",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"data-cy":"link-edit-item","to":{
                name: _vm.typeToComponentNamesMap[_vm.type].editComponent,
                params: { id: item.id },
                exact: true
              }}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1)],1)]}},{key:"parentCategory",fn:function(ref){
              var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.parentCategory.name)+" ")])]}},{key:"parentCategoryStatus",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.parentCategory.status)}},[_vm._v(" "+_vm._s(item.parentCategory.status)+" ")])],1)]}},{key:"languages",fn:function(ref){
              var item = ref.item;
return [_c('td',_vm._l((item.languages),function(ts,$idx){return _c('span',{key:ts.id},[_vm._v(" "+_vm._s(ts.name)+" "),($idx !== item.languages.length - 1)?_c('span',{staticClass:"mx-2"},[_vm._v("|")]):_vm._e()])}),0)]}}])},'CDataTable',_vm.$attrs,false))],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }