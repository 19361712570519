<template>
  <div class="mx-sm-auto login__wrapper">
    <transition
      name="component-fade"
      mode="out-in"
    >
      <keep-alive>
        <component
          :is="loginStepComponent"
          internal
          @submit="onSubmit"
          @success="$emit('success')"
          @error="$emit('error')"
        />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import LoginEmail from '@/views/Login/LoginEmail.vue'
import LoginChooseMFAType from '@/views/Login/LoginChooseMFAType.vue'
import LoginMFA from '@/views/Login/LoginMFA.vue'
import LoginEnterPermanentPassword from '@/views/Login/LoginEnterPermanentPassword.vue'
import { MFA_STAGES } from '@/store/modules/auth.store'

const stageComponents = {
  [MFA_STAGES.ENTER_USER_CREDS]: LoginEmail,
  [MFA_STAGES.SELECT_MFA_TYPE]: LoginChooseMFAType,
  [MFA_STAGES.SMS_MFA]: LoginMFA,
  [MFA_STAGES.SOFTWARE_TOKEN_MFA]: LoginMFA,
  [MFA_STAGES.NEW_PASSWORD_REQUIRED]: LoginEnterPermanentPassword,
}

export default {
  name: 'Login',

  title: 'Login',

  components: {
    LoginEmail,
    LoginChooseMFAType,
    LoginMFA
  },

  computed: {
    ...mapGetters('auth', [
      'currentAuthChallenge'
    ]),

    loginStepComponent() {
      return stageComponents[this.currentAuthChallenge]
    },
  },

  created () {
    this.resetLoginStageState()
  },

  methods: {
    ...mapActions('auth', [
      'resetLoginStageState',
    ]),
    onSubmit() {},
  },
}
</script>

<style lang="scss" scoped>

</style>