<template>
  <div>
    <UsersList
      :users="users"
      :is-loading-list="isLoadingList"
      :account-id="accountId"
      hide-disable      
      hide-status-column      
    >
      <template #header>
        <CIcon name="cil-people" /> Users
        
        <CButton
          class="float-right"
          size="sm"
          color="info"
          @click="
            showCreateForm = true
          "
        >
          <CIcon
            size="sm"
            name="cil-plus"
          /> Add User
        </CButton>
      </template>

      <template #form>
        <AccountUserCreate
          v-if="showCreateForm"
          :account-id="accountId"
          :account-name="accountName"
          :account-type="accountType"
          @success="showCreateForm = false"
          @cancel="showCreateForm = false"
        />
      </template>
    </UsersList>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UsersList from './UsersList.vue'
import AccountUserCreate from '@/views/Users/AccountUserCreate.vue'

export default {
  name: 'AccountUsers',

  components: {
    UsersList,
    AccountUserCreate
  },

  props: {
    accountId: {
      type: String,
      required: true
    },

    accountType: {
      type: String,
      required: true,
      validator(value) {
        return ['Buyer', 'Seller'].includes(value)
      }
    },

    accountName: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      isLoadingList: false,
      showCreateForm: false
    }
  },

  computed: {
    ...mapState('accounts', [
      'accountUsers'
    ]),

    users() {
      return this.accountUsers?.[this.accountId]
    }
  },

  async created () {
    await this.loadUsers()
  },

  methods: {
    ...mapActions('accounts', [
      'getAccountUsers'
    ]),

    async loadUsers() {
      try {
      this.isLoadingList = true
      await this.getAccountUsers(this.accountId)
      
      } catch (error) {
        //
      } finally {
        this.isLoadingList = false
      }
    }

  },
}
</script>

<style>

</style>