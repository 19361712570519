<template>
  <Creative
    :id="getCreativeId"
    :title="`Creative Details`"
  />
</template>

<script>
import Creative from './Creative.vue'

export default {
  name: 'CreativeDetails',  

  components: {
    Creative
  },  

  computed:{
    getCreativeId() {
      return this.$route.params.id
    }
  },

  methods: {    
  }
}
</script>