import createHttpClient from './httpClient'

const END_POINT = 'call'

const baseUrl = new URL('/api/v1/tools', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href

const httpClient = createHttpClient(baseUrl)

const CallLogsApi = { 
  searchForCalls(callerIds, callIds, dateFrom, dateTo) {
    const url = `/${END_POINT}`
    return httpClient.post(url, {
      callIds,
      callerIds,
      dateFrom,
      dateTo
    })
  },
}

export default CallLogsApi
