<template>
  <CCard>
    <CCardHeader
      class="d-flex align-items-center clickable"
      @click="collapse = !collapse"
    >
      <div>
        <b>{{ `${priority}. ${checklistItemConfig.name}` }}</b>
        <span
          v-if="checklistItem.lastUpdatedAt"
          class="text-muted ml-2"
        >(Last Updated: {{ getSafe(() => formatDate(checklistItem.lastUpdatedAt)) }})</span>
        <div class="text-muted">
          {{ checklistItemConfig.description }}
        </div>
      </div>

      <CBadge
        class="ml-auto"
        :color="getBadge(checklistItemStatus)"
      >
        <div class="checklist-item__status">
          {{ checklistItemStatus }}
        </div>
      </CBadge>

      <CIcon
        :name="downIcon"
        class="ml-3"
      />
    </CCardHeader>

    <CCollapse :show="collapse">
      <CCardBody>
        <div>
          <h5>
            Notes
            <ButtonLoading
              size="sm"
              color="secondary"
              class="ml-2"
              :loading="isLoading && addNotesTrigger"
              @click="addNotes"
            >
              Add Notes
            </ButtonLoading>
          </h5>
          <ReviewNotesForm
            v-if="showNotesForm"
            :parent-object="checklistItem"
            class="my-4"
            @notes-added="loadNotes(true)"
            @cancel="showNotesForm = false"
          />
          <ReviewNotesList
            class="mt-3"
            :is-loading-notes="isLoadingNotes"
            :review-notes="notes"
            :parent-object="checklistItem"
            @reload-review-notes="loadNotes(true)"
          />
        </div>
      </CCardBody>

      <CCardFooter>
        <CDropdown
          color="primary"
          :disabled="isLoading "
        >
          <template #toggler-content>
            <CSpinner
              v-if="isLoading && !addNotesTrigger"
              size="sm"
              class="mr-2"
            />
            <span>Change Status</span>
          </template>
          <CDropdownItem @click="setChecklistItemStatus('Requested')">
            Requested
          </CDropdownItem>
          <CDropdownItem @click="setChecklistItemStatus('Received')">
            Received
          </CDropdownItem>
          <CDropdownItem @click="setChecklistItemStatus('Verified')">
            Verified
          </CDropdownItem>
          <CDropdownItem @click="setChecklistItemStatus('Failed')">
            Failed
          </CDropdownItem>
        </CDropdown>
      </CCardFooter>
    </CCollapse>
  </CCard>
</template>
<script>
import { mapActions } from 'vuex'

import ReviewNotesForm from '@/views/ReviewNotes/ReviewNotesForm.vue'
import ReviewNotesList from '@/views/ReviewNotes/ReviewNotesList.vue'
import NotesApi from '@/api/notes.api'
import ButtonLoading from '@/components/common/ButtonLoading.vue'
import { dateTimeFormat } from '@/utils'

export default {
  name: 'RegistrationRequestChecklistItem',

  components: {
    ReviewNotesList,
    ReviewNotesForm,
    ButtonLoading
  },

  props: {
    priority: {
      type: [String, Number],
      default: '1'
    },

    checklistItem: {
      type: Object,
      default() {
        return {}
      }
    },

    registrationRequestId: {
      type: String,
      default() {
        return ''
      }
    }
  },

  data() {
    return {
      notes: [],
      isLoadingNotes: false,
      isLoading: false,
      showNotesForm: false,
      addNotesTrigger: false,
      collapse: false
    }
  },

  computed: {
    checklistItemConfig() {
      return this.checklistItem?.checklistItemDetails 
    },

    checklistItemStatus() {
      const status = this.checklistItem?.status ?? 'Not Requested'

      return status === 'NotRequested' ? 'Not Requested' : status
    },

    downIcon() {
      return !this.collapse ? 'cil-chevron-bottom' : 'cil-chevron-top'
    }
  },

  watch: {
    checklistItem: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (!oldValue?.noteListId && newValue.noteListId) {
          await this.loadNotes()
        }
      }
    }
  },

  async created () {
    await this.loadNotes()
  },

  methods: {
    ...mapActions('registrationRequests', [
      'setRegistrationChecklistItemStatusForRegistration',
      'createRegistrationChecklistItemForRegistration'
    ]),

    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    },

    async loadNotes(hideNotesForm = false) {
      try {
        if (this.checklistItem?.noteListId) {
          this.isLoadingNotes = true
          const response = await NotesApi.getReviewNotes(this.checklistItem?.noteListId)

          this.notes = response.data || []
        }

        if (hideNotesForm) {
          this.showNotesForm = false
        }
      } catch {
        //
      } finally {
        this.isLoadingNotes = false
      }
    },

    async setChecklistItemStatus(status) {
      try {
        this.isLoading = true
        await this.createOrUpdateChecklistItem(status)
      } catch {
        //
      } finally {
        this.isLoading = false
      }
    },

    async createOrUpdateChecklistItem(status) {
      if (this.checklistItem.id) {
        return await this.setRegistrationChecklistItemStatusForRegistration({
          registrationId: this.registrationRequestId,
          checklistItemId: this.checklistItem.id,
          status
        })
      } else {
        return await this.createRegistrationChecklistItemForRegistration({
          registrationId: this.registrationRequestId,
          checklistItemConfigId: this.checklistItemConfig.id,
          status
        })
      }
    },

    async addNotes() {
      try {
        if (!this.checklistItem.id) {
          this.isLoading = true
          this.addNotesTrigger = true

          await this.createRegistrationChecklistItemForRegistration({
            registrationId: this.registrationRequestId,
            checklistItemConfigId: this.checklistItemConfig.id,
            status: 'NotRequested'
          })
        }

        this.showNotesForm = true
      } catch {
        //
      } finally {
        this.isLoading = false
        this.addNotesTrigger = false
      }
    },

    getBadge(status) {
      switch (status) {
        case 'Not Requested':
        case 'NotRequested':
        case 'Received':
          return 'warning'

        case 'Verified':
          return 'success'

        case 'Failed':
          return 'danger'

        default:
          return 'secondary'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.checklist-item__status {
  font-size: 1.2rem;
}
</style>