import UserAPI from '@/api/user.api'

const state = {}

const getters = {}

const actions = {
  async setUIPreferences({ commit }, preferences) {
    await UserAPI.setUIPreferences(preferences)
    commit('toggle', 'darkMode', { root: true })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
