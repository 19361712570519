import CampaignAPI from '@/api/campaign.api'

export const RTB_ID_REGEX = /^[a-z0-9]{32}$/

export function getDefaultParser() {
  return {
    parserType: 'jpath'
  }
}

export function getRtbTagsWithDescription() {
  const rtbTags = [{
    key: "trafficsource",
    value: "trafficsource",
    description: "Traffic Source"
  },
  {
    key: "thirdparty",
    value: "thirdparty",
    description: "Third Party"
  },
  {
    key: "state",
    value: "state",
    description: "US state"
  },
  {
    key: "zipcode",
    value: "zipcode",
    description: "ZIP code"
  },
  {
    key: "age",
    value: "age",
    description: "Age"
  },
  {
    key: "gender",
    value: "gender",
    description: "Gender"
  },
  {
    key: "estimatedhouseholdincome",
    value: "estimatedhouseholdincome",
    description: "Estimated Household Income"
  },
  {
    key: "homeowner",
    value: "homeowner",
    description: "Homeowner"
  },
  {
    key: "channel",
    value: "channel",
    description: "Channel Name"
  }]

  const tagDescriptionMapping = new Map(rtbTags.map(tag => [tag.key, tag.description]))

  return { rtbTags, tagDescriptionMapping }
}

const state = {
  pendingApprovalBuyerCampaigns: [],
  buyerCampaigns: [],
  sellerCampaigns: [],
}

function findCampaignById(campaigns, id) {
  return campaigns.find(campaign => campaign.id === id)
}

const getters = {
  buyerCampaignById: state => id => {
    return findCampaignById(state.buyerCampaigns, id)
  }
}

/** 
 * @type {import('vuex').ActionTree<typeof state>} 
 */
const actions = {
  async fetchBuyerCampaigns({ commit }, { detailed, accountId }) {
    const response = await CampaignAPI.getBuyerCampaigns({detailed, accountId})
    commit('SET_BUYER_CAMPAIGNS', response.data || [])
  },

  async fetchBuyerCampaign({ commit }, id) {
    const response = await CampaignAPI.getBuyerCampaign(id)
    commit('SET_BUYER_CAMPAIGN', response.data || [])
  },
  
  async updateCampaignDetails({ commit }, { id, updateObject }) {
    const response = await CampaignAPI.updateCampaignDetails({
      id,
      updateObject
    })
    const updatedCampaign = response.data
    commit('UPDATE_BUYER_CAMPAIGN', updatedCampaign)
  },

  async approveBuyerCampaign(context, { id, remarks, version }) {
    return await CampaignAPI.approveBuyerCampaign({
      id,
      remarks,
      version
    })
  },

  async rejectBuyerCampaign(context, { id, remarks, version }) {
    return await CampaignAPI.rejectBuyerCampaign({
      id,
      remarks,
      version
    })
  },

  async fetchPendingApprovalBuyerCampaigns({ commit }) {
    const response = await CampaignAPI.getPendingApprovalBuyerCampaigns()
    commit('SET_PENDING_APPROVAL_BUYER_CAMPAIGNS', response.data.filter(c => Boolean(c)) || [])
  },

  async pauseBuyerCampaign({ commit }, { id, remarks }) {
    const response = await CampaignAPI.pauseBuyerCampaign({
      id,
      remarks,
    })

    commit('SET_BUYER_CAMPAIGN_STATUS', { campaignId: id, isPaused: true })

    return response
  },

  async resumeBuyerCampaign({ commit }, { id, remarks, status }) {
    const response = await CampaignAPI.resumeBuyerCampaign({
      id,
      remarks,
    })

    commit('SET_BUYER_CAMPAIGN_STATUS', { campaignId: id, isPaused: false, status: status === 'Paused' ? 'Active' : status })

    return response
  },
}

const mutations = {
  SET_BUYER_CAMPAIGNS(state, campaigns) {
    state.buyerCampaigns = campaigns
  },

  SET_BUYER_CAMPAIGN(state, campaign) {
    const buyerCampaigns = [...state.buyerCampaigns]

    const index = buyerCampaigns.findIndex(c => c.id === campaign.id)
    
    if (index < 0) {
      buyerCampaigns[0] = campaign
    } else {
      buyerCampaigns[index] = campaign
    }
    
    state.buyerCampaigns = buyerCampaigns
  },

  SET_PENDING_APPROVAL_BUYER_CAMPAIGNS(state, campaigns) {
    state.pendingApprovalBuyerCampaigns = campaigns
  },

  UPDATE_BUYER_CAMPAIGN(state, campaign) {
    const index = state.buyerCampaigns.findIndex(c => c.id === campaign.id)
    state.buyerCampaigns.splice(index, 1, campaign)
  },

  SET_BUYER_CAMPAIGN_STATUS(state, { campaignId, status, isPaused }) {
    const buyerCampaigns = [...state.buyerCampaigns]
    const index = buyerCampaigns.findIndex(c => c.id === campaignId)
    buyerCampaigns[index] = {
      ...buyerCampaigns[index],
      isPaused
    }

    if (status) {
      buyerCampaigns[index] = {
        ...buyerCampaigns[index],
        status
      }
    }
    
    state.buyerCampaigns = buyerCampaigns
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
