import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/resources', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

class BasicCrudApi {
  constructor(endpoint) {
    this.END_POINT = endpoint
  }

  list() {
    return httpClient.get(this.END_POINT)
  }

  findOne(id) {
    return httpClient.get(`${this.END_POINT}/${id}`)
  }

  create(newObject) {
    return httpClient.post(this.END_POINT, newObject)
  }

  patch(id, updateObject) {
    return httpClient.patch(`${this.END_POINT}/${id}`, updateObject)
  }

  delete(id) {
    return httpClient.delete(`${this.END_POINT}/${id}`)
  }
}

export default BasicCrudApi
