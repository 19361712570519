<template>
  <div>
    <div>
      <h5 v-if="!showSearchTrafficSourceInput">
        Approved Traffic Sources 
        <CButton
          size="sm"
          color="primary"
          class="ml-2"
          @click="onSearchTrafficSource"
        >
          Search
        </CButton>
      </h5>
      <div
        v-if="showSearchTrafficSourceInput"
        class="search-input search-input--margin-bottom-sm"
      >
        <CInput
          v-model="searchTrafficSource"
          placeholder="Search Traffic Source"
          add-wrapper-classes="mb-2"
          size="sm"
        />

        <CIcon
          name="cil-search"
          size="custom"
        />
      </div>
    </div>
    <div class="d-flex flex-column traffic-sources">
      <div
        class="traffic-sources__item pl-2"
      >
        <strong>Inbound</strong>
      </div>
      <div
        v-for="(trafficSource, $idx) in inboundTrafficSources"
        :key="`${trafficSource.type}-${trafficSource.name}-${$idx}`"
        class="traffic-sources__item"
        :class="{'traffic-sources__item--highlighted': !!filterByTrafficSourcesList[trafficSource.id], 'clickable': allowSelection}"
        @click="onSelectTrafficSource(trafficSource)"
      >
        {{ trafficSource.name }}
      </div>
      <div
        class="traffic-sources__item pl-2"
      >
        <strong>Outbound</strong>
      </div>
      <div
        v-for="(trafficSource, $idx) in outboundTrafficSources"
        :key="`${trafficSource.type}-${trafficSource.name}-${$idx}`"
        class="traffic-sources__item"
        :class="{'traffic-sources__item--highlighted': !!filterByTrafficSourcesList[trafficSource.id], 'clickable': allowSelection}"
        @click="onSelectTrafficSource(trafficSource)"
      >
        {{ trafficSource.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'

function sortTrafficSource(ts1, ts2) {
  const nameA = ts1.name.toUpperCase() // ignore upper and lowercase
  const nameB = ts2.name.toUpperCase() // ignore upper and lowercase
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  // names must be equal
  return 0
}

export default {
  name: 'TrafficSourceList',

  props: {
    trafficSources: {
      type: Array,
      default() {
        return []
      }
    },

    allowSelection: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      showSearchTrafficSourceInput: false,
      searchTrafficSource: '',
    }
  },

  computed: {
    ...mapState('categorySupplyDemand', [
      'filterByTrafficSources'
    ]),

    inboundTrafficSources() {
      return this.trafficSources
        .filter(ts => ts.type.toLowerCase() === 'inbound' && (this.searchTrafficSource ? ts.name.toLowerCase().includes(this.searchTrafficSource.toLowerCase()) : true))
        .sort(sortTrafficSource)
    },

    outboundTrafficSources() {
      return this.trafficSources
        .filter(ts => ts.type.toLowerCase() === 'outbound' && (this.searchTrafficSource ? ts.name.toLowerCase().includes(this.searchTrafficSource.toLowerCase()) : true))
        .sort(sortTrafficSource)
    },

    filterByTrafficSourcesList: {
      get() {
        return this.filterByTrafficSources
      }
    }
  },

  methods: {
    ...mapMutations('categorySupplyDemand', [
      'ADD_TO_TRAFFIC_SOURCE_FILTERING',
      'REMOVE_FROM_TRAFFIC_SOURCE_FILTERING'
    ]),

    onSearchTrafficSource() {
      this.showSearchTrafficSourceInput = true
    },

    onSelectTrafficSource(trafficSource) {
      const selected = !!this.filterByTrafficSources[trafficSource.id]

      if (!selected) {
        this.ADD_TO_TRAFFIC_SOURCE_FILTERING(trafficSource)
      } else {
        this.REMOVE_FROM_TRAFFIC_SOURCE_FILTERING(trafficSource.id)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.traffic-sources {
  border: 1px solid rgba(0, 0, 21, 0.2);
  min-width: 200px;
  height: 400px;
  min-height: min-content;
  overflow-y: auto;
  background: white;
}

.traffic-sources__item {
  border-bottom: 1px solid rgba(0, 0, 21, 0.2);
  padding: 6px 12px;

  &:nth-last-child(0) {
    border: 0px;
  }
}

.traffic-sources__item--highlighted {
  background: #8080806e;
  font-weight: 500;
}
</style>