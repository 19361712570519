<template>
  <div class="mb-4">
    <div
      v-for="(parser, $idx) in parsersCopy"
      :key="$idx"
    >
      <div
        v-if="$idx !== 0"
        class="text-center py-3"
      >
        <CIcon
          name="cil-level-down"
          size="xl"
        />
      </div>

      <div
        class="border border-secondary rounded my-2"
      >
        <CButton
          v-if="$idx !== 0"
          type="button"
          class="bg-transparent parser__remove-btn"
          @click="removeParser($idx)"
        >
          <CIcon name="cil-x" />
        </CButton>


        <ProgrammaticTargetParserForm
          :parser="parser"
          @update="updateParser($idx, ...arguments)"
        />
      </div>
    </div>

    <CButton
      @click="addParser"
      type="button"
      color="primary"
      variant="outline"
      size="sm"
    >
      <CIcon
        name="cil-plus"
      />
      Add New Parsing Step
    </CButton>
  </div>
</template>

<script>
import { getDefaultParser } from '@/store/modules/campaign.store'
import ProgrammaticTargetParserForm from './ProgrammaticTargetParserForm.vue'

export default {
  name: 'ProgrammaticTargetParsersList',

  components: {
    ProgrammaticTargetParserForm,
  },

  props: {
    parsers: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      parsersCopy: []
    }
  },

  watch: {
    parsers: {
      immediate: true,
      handler(newValue) {
        this.parsersCopy = [...newValue]
      }
    }
  },

  methods: {
    updateParser(idx, parser) {
      this.parsersCopy[idx] = parser

      this.$emit('update', this.parsersCopy)
    },

    addParser() {
      this.parsersCopy.push(getDefaultParser())

      this.$emit('update', this.parsersCopy)
    },

    removeParser(idx) {
      this.parsersCopy.splice(idx, 1)

      this.$emit('update', this.parsersCopy)
    }
  },
}
</script>

<style lang="scss" scoped>

.parser {
  position: relative;
  border: 1px solid;
  padding: 20px 0px 5px 5px;
  border-radius: 0.25rem;
}

</style>