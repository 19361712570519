import SellerOfferApi from '@/api/sellerOffer.api'


export function getDefaultSellerOffer() {
  return {
    visibility: OFFER_VISIBILITY_TYPES.Public
  }
}

export const OFFER_VISIBILITY_TYPES = {
  Public: 'Public',
  Private: 'Private'
}

const state = {
  sellerOffers: [],
  selectedRow: getDefaultSellerOffer(),
}

const getters = {
}

const actions = {
  async list({ commit }) {
    const response = await SellerOfferApi.getAllSellerOffers()
    commit('SET_SELLER_OFFERS', response.data || [])
  },
  
  async create(context, payload) {
    const response = await SellerOfferApi.createSellerOffer(payload)
    return response
  },

  async findOne({ commit }, id) {
    const response = await SellerOfferApi.getSellerOfferDetailsById(id)
    commit('SET_SELECTED_ROW', response.data)
  },

  async update(context, payload) {
    const response = await SellerOfferApi.upateSellerOffer(payload.id, {
      name: payload.name,
      description: payload.description,
      allowedTrafficSourceType: payload.allowedTrafficSourceType,
      allowedTrafficSources: payload.allowedTrafficSources,
      isAffiliateTrafficAllowed: payload.isAffiliateTrafficAllowed,
    })
    
    return response
  },

  async changeStatus(context, payload) {
    const response = await SellerOfferApi.changeSellerOfferStatus(payload.id, payload.status)
    return response
  },

  async changeVisibility(context, payload) {
    const response = await SellerOfferApi.changeSellerOfferVisibility(payload.id, payload.visibility)
    return response
  },

  async removeUser(context, id) {
    const response = await SellerOfferApi.removeUser(id)
    return response
  },

  async pauseUser(context, { id, reason }) {
    const response = await SellerOfferApi.pauseUserInOffer(id, reason)
    return response
  },

  async resumeUser(context, id) {
    const response = await SellerOfferApi.resumeUserInOffer(id)
    return response
  },

  reset(context){
    context.commit("RESET_SELLER_OFFERS")
  },
}

const mutations = {
  SET_SELLER_OFFERS(state, data) {
    state.sellerOffers = data
  },
  SET_SELECTED_ROW(state, row) {
    state.selectedRow = row
  },
  RESET_SELLER_OFFERS(state){
    state.sellerOffers = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
