<template>
  <CCard>
    <CCardHeader>
      Edit Seller Bid Settings
    </CCardHeader>

    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CRow
          form
          class="form-group"
        >
          <CCol
            tag="label"
            sm="3"
            class="col-form-label"
          >
            Override Bid Expiration
          </CCol>
          <CCol
            sm="9"
            class="mt-1"
          >
            <CSwitch
              shape="pill"
              :checked.sync="overrideBidExpiration"
              color="primary"
              @update:checked="onToggleOverrideBidExpiration"
            />
          </CCol>
        </CRow>

        <CInput
          v-if="overrideBidExpiration"
          v-model.trim="$v.bidPreferences.bidExpirationInSeconds.$model"
          type="number"
          label="Bid Expiration (in seconds)"
          horizontal
          :is-valid="checkIfValid(['bidPreferences', 'bidExpirationInSeconds'])"
          :invalid-feedback="cannotBeBlank('Bid expiration')"
        /> 

        <legend>Rate Limit</legend>
        <CRow
          form
          class="form-group"
        >
          <CCol
            tag="label"
            sm="3"
            class="col-form-label"
          >
            Override Rate Limit
          </CCol>
          <CCol
            sm="9"
            class="mt-1"
          >
            <CSwitch
              shape="pill"
              :checked.sync="overrideRateLimit"
              color="primary"
              @update:checked="onToggleOverrideRateLimit"
            />
          </CCol>
        </CRow>

        <CInput
          v-if="overrideRateLimit"
          v-model.trim="$v.bidPreferences.rateLimit.maxNumberOfCalls.$model"
          type="number"
          label="Max Calls"
          horizontal
          :is-valid="checkIfValid(['bidPreferences', 'rateLimit', 'maxNumberOfCalls'])"
          :invalid-feedback="invalidRange('Max Calls', $v.bidPreferences.rateLimit.maxNumberOfCalls.$params.minValue.min)"
        /> 

        <CInput
          v-if="overrideRateLimit"
          v-model.trim="$v.bidPreferences.rateLimit.perMinutes.$model"
          type="number"
          label="Per Minute(s)"
          horizontal
          :is-valid="checkIfValid(['bidPreferences', 'rateLimit', 'perMinutes'])"
          :invalid-feedback="invalidRange('Per minutes', $v.bidPreferences.rateLimit.perMinutes.$params.minValue.min)"
        /> 

        <div class="form-row">
          <CCol
            :md="{ offset: '3', size: '9' }"
            :sm="{ offset: '3', size: '9' }"
          >
            <CButton
              type="submit"
              size="sm"
              color="primary"
              :disabled="!isFormEdited"
              @click="onFormSubmit"
            >
              Change
            </CButton>
          </CCol>
        </div>
      </CForm>
    </CCardBody>

    <CModal
      title="Confirm bid preferences change"
      color="success"
      :show.sync="warningBidPreferencesChangeModal"
    >
      Are you sure you want to change the bid preferences?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="onConfirmSubmit"
        >
          Change
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningBidPreferencesChangeModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { minValue, requiredIf } from 'vuelidate/lib/validators'
import _ from 'lodash'
import { mapActions } from 'vuex'

import formMixins from '@/mixins/form-mixins'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'SellerBidSettings',

  mixins: [formMixins],

  props: {
    accountId: {
      type: String,
      default: ''
    },

    existingBidPreferences: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  validations() {
    return {
      bidPreferences: {
        bidExpirationInSeconds: {
          requiredIf: requiredIf(() => {
            return this.bidPreferences.bidExpirationInSeconds != null
          }),
          minValue: minValue(1)
        },

        rateLimit: {
          maxNumberOfCalls: {
            requiredIf: requiredIf(() => {
              return this.bidPreferences.rateLimit != null
            }),
            minValue: minValue(1)
          },

          perMinutes: {
            requiredIf: requiredIf(() => {
              return this.bidPreferences.rateLimit != null
            }),
            minValue: minValue(1)
          }
        }
      }
    }
  },

  data() {
    return {
      bidPreferences: {
        bidExpirationInSeconds: null,

        rateLimit: null
      },
      overrideBidExpiration: false,
      overrideRateLimit: false,

      warningBidPreferencesChangeModal: false,
      isLoading: false
    }
  },

  watch: {
    existingBidPreferences: {
      immediate: true,
      handler(newValue) {
        this.bidPreferences = _.cloneDeep(newValue)

        this.handleBidPreferencesChange()
      }
    }
  },

  methods: {
    ...mapActions('accounts', [
      'updateSellerBidSettings'
    ]),

    handleBidPreferencesChange() {
      if (this.bidPreferences.bidExpirationInSeconds != null) {
        this.overrideBidExpiration = true
      }

      if (this.bidPreferences.rateLimit != null) {
        this.overrideRateLimit = true
      }
    },

    onToggleOverrideBidExpiration(override) {
      this.$v.$touch()

      if (override) {
        this.bidPreferences.bidExpirationInSeconds = 60
      } else {
        this.bidPreferences.bidExpirationInSeconds = null
      }
    },

    onToggleOverrideRateLimit(override) {
      this.$v.$touch()

      if (override) {
        this.bidPreferences.rateLimit = {
          perMinutes: 1,
          maxNumberOfCalls: 100
        }
      } else {
        this.bidPreferences.rateLimit = null
      }
    },

    onFormSubmit() {
      this.submitted = true

      if (this.isValid) {
        this.warningBidPreferencesChangeModal = true
      }
    },

    async onConfirmSubmit() {
      try {
        this.isLoading = true

        await this.updateSellerBidSettings({
          accountId: this.accountId,
          bidPreferences: this.bidPreferences
        })

        this.warningBidPreferencesChangeModal = false

        this.resetFormDirtyState()

        showSuccessMessage('Successfully changed bid preferences')
      } catch(e) {
        showErrorMessage(e.response?.data?.title)
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
<style>
  
</style>