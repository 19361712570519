<template>
  <div>
    <form @submit.prevent="onFormSubmit">
      <CFormGroup
        class="form-row form-group"
        invalid-feedback="Account is empty"
        @submit.prevent="onFormSubmit"
      >
        <template #label>
          <label
            for="Seller Account"
            class="col-form-label col-sm-3"
          >
            Seller Account
          </label>
        </template>

        <template #input>
          <CCol
            md="4"
            sm="4"
          >
            <!-- TODO: Fix invalid input styling -->
            <v-select
              id="Seller Account"
              v-model="$v.selectedAccount.$model"
              label="label"
              :filterable="false"
              :options="accountsOptions"
              placeholder="Search account"
              :class="{'is-invalid': !checkIfValid(['selectedAccount'])}"
              @search="searchAccounts"
            />
          </CCol>
        </template>
      </CFormGroup>

      <div class="form-row">
        <CCol
          :md="{ offset: '3', size: '9' }"
          :sm="{ offset: '3', size: '9' }"
        >
          <ButtonLoading
            color="primary"
            type="submit"
            size="sm"
            @click="onFormSubmit"
          >
            Add Access
          </ButtonLoading>

          <CButton
            size="sm"
            type="button"
            color="danger"
            class="ml-2"
            @click="$emit('close')"
          >
            Cancel
          </CButton>
        </CCol>
      </div>
    </form>
    
    <CModal
      title="Confirm add access"
      color="success"
      :show.sync="warningAddAccessModal"
    >
      Are you sure you want to add access to an offer?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoadingAction"
          @click="onConfirmAddAccess"
        >
          Add Access
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningAddAccessModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { required  } from 'vuelidate/lib/validators'
import debounce from 'lodash.debounce'

import formGenericMixin from '@/mixins/form-generic-mixin'
import AccountsAPI from '@/api/accounts.api'
import SellerOfferApi from '@/api/sellerOffer.api'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'SellerAccountAddAccessToOffer',

  mixins: [formGenericMixin],

  props: {
    sellerOfferId: {
      type: String,
      required: true
    },

    accountsWithAccess: {
      type: Array,
      default() {
        return []
      }
    }
  },

  validations() {
    return {
        selectedAccount: {
          required
        }
    }
  },

  data() {
    return {
      warningAddAccessModal: false,

      selectedAccount: null,

      accountsOptions: [],

      isLoadingAction: false
    }
  },

  computed: {
    accountsWithAccessMap() {
      return this.accountsWithAccess.reduce((final, account) => {
        final[account.id] = true
        return final
      }, {})
    }
  },

  methods: {
    searchAccounts: debounce(async function(searchText, loading) {
      if(!searchText) {
        return
      }

      try {
        loading(true)
        const response = await AccountsAPI.searchSellerAccounts(searchText)

        this.accountsOptions = response.data.filter(account => account.onboardingStatus.toLowerCase() === 'completed' && !this.accountsWithAccessMap[account.id])
        .map(account => {
          return {
            label: account.name,
            id: account.id
          }
        })
      } catch(e) {
        console.error(e)
        showErrorMessage(e.response?.data?.title)
      } finally {
        loading(false)
      }
    }, 350),

    onFormSubmit() {
      this.submitted = true

      this.$v.$touch()

      if(!this.isValid) {
        return
      }

      this.warningAddAccessModal = true
    },

    async onConfirmAddAccess() {
      try {
        this.isLoadingAction = true

        await SellerOfferApi.addSellerAccountAccessToOffer(this.sellerOfferId, this.selectedAccount.id)

        showSuccessMessage('Successfully added access to offer')

        this.warningAddAccessModal = false

        this.$emit('added-access')
      } catch(e) {
        // error handled in interceptor
      } finally {
        this.isLoadingAction = false
      }
    }
  },
}
</script>

<style lang="">
  
</style>