<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    dropdown-mode="closeInactive"
    @update:show="value => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand
      class="d-md-down-none"
      to="/"
    >
      <CImg
        src="/img/ringbax-logo-dark-theme.svg"
      />
    </CSidebarBrand>
    <CRenderFunction
      flat
      :content-to-render="sidebarItems"
    />
  </CSidebar>
</template>

<script>
import NavItems from './_nav'

export default {
  name: 'TheSidebar',
  data() {
    return {
      sidebarItems: NavItems
    }
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
  }
}
</script>

<style lang="scss">
  a.c-active.c-sidebar-nav-link { 
    background: #217fffcf !important;
  }
</style>
