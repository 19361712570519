import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/review', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const END_POINT = 'registration-requests'

const RegistrationAPI = {
  getBuyerRegistrationRequests(status) {
    let url = `${END_POINT}/buyer`

    if (status) {
      url += `?status=${status}`
    }

    return httpClient.get(url)
  },

  getBuyerRegistrationRequestDetails(buyerRegistrationRequestId) {
    const url = `${END_POINT}/buyer/${buyerRegistrationRequestId}`
    return httpClient.get(url)
  },

  getSellerRegistrationRequests(status) {
    let url = `${END_POINT}/seller`

    if (status) {
      url += `?status=${status}`
    }

    return httpClient.get(url)
  },

  getSellerRegistrationRequestDetails(buyerRegistrationRequestId) {
    const url = `${END_POINT}/seller/${buyerRegistrationRequestId}`
    return httpClient.get(url)
  },

  updateBuyerRegistrationWithReviewDetails(id, reviewDetails) {
    const url = `${END_POINT}/buyer/${id}/review-details`
    return httpClient.patch(url, reviewDetails)
  },

  updateSellerRegistrationWithReviewDetails(id, reviewDetails) {
    const url = `${END_POINT}/seller/${id}/review-details`
    return httpClient.patch(url, reviewDetails)
  },

  rejectBuyerRegistrationRequest(id) {
    const url = `${END_POINT}/buyer/${id}/reject`

    return httpClient.post(url)
  },

  rejectSellerRegistrationRequest(id) {
    const url = `${END_POINT}/seller/${id}/reject`

    return httpClient.post(url)
  },

  waitlistBuyerRegistrationRequest(id) {
    const url = `${END_POINT}/buyer/${id}/waitlist`

    return httpClient.post(url)
  },

  waitlistSellerRegistrationRequest(id) {
    const url = `${END_POINT}/seller/${id}/waitlist`

    return httpClient.post(url)
  },

  approveBuyerRegistrationRequest(id) {
    const url = `${END_POINT}/buyer/${id}/approve`

    return httpClient.post(url)
  },

  approveSellerRegistrationRequest(id) {
    const url = `${END_POINT}/seller/${id}/approve`

    return httpClient.post(url)
  },

  updateBuyerRegistrationDetails(id, updateObject) {
    const url = `${END_POINT}/buyer/${id}`
    return httpClient.patch(url, updateObject)
  },

  updateSellerRegistrationDetails(id, updateObject) {
    const url = `${END_POINT}/seller/${id}`
    return httpClient.patch(url, updateObject)
  },

  generateDownloadLink({ s3Key }) {
    const url = `${END_POINT}/review-docs/download-link?s3Key=${s3Key}`
    return httpClient.get(url)
  }
}

export default RegistrationAPI
