<template>
  <div v-if="requestDetails">
    <CCardBody>
      <CForm>
        <dl class="row">
          <dt class="col-sm-3 text-right">
            Status
          </dt>
          <dd class="col-sm-9">
            <CBadge :color="statusVariant">
              {{ requestDetails.status }}
            </CBadge>
          </dd>
        </dl>

        <h3>
          Company
        </h3>

        <CInput
          v-model.trim="$v.form.companyName.$model"
          data-cy="input-company-name"
          label="Name"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="w-25"
          horizontal
          :is-valid="checkIfValid(['form', 'companyName'], submitted)"
          :invalid-feedback="cannotBeBlank('Company Name')"
        />

        <CInput
          v-model.trim="$v.form.ringbaAccountId.$model"
          label="Ringba Account Id"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="w-50"
          horizontal
          :is-valid="checkIfValid(['form', 'ringbaAccountId'])"
        />

        <CInput
          v-model="$v.form.companyAddress.addressLine1.$model"
          data-cy="input-company-address-line-1"
          label="Street Address"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="w-25"
          :is-valid="checkIfValid(['form', 'companyAddress', 'addressLine1'], submitted)"
          :invalid-feedback="cannotBeBlank('Street Address')"
          horizontal
        />

        <CInput
          v-model="$v.form.companyAddress.addressLine2.$model"
          data-cy="input-company-address-line-2"
          label="Address 2"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          :is-valid="checkIfValid(['form', 'companyAddress', 'addressLine2'], submitted)"
          add-input-classes="w-25"
          placeholder="Optional"
          horizontal
        />

        <CSelect
          v-model="$v.form.companyAddress.countryCode.$model"
          :value.sync="form.companyAddress.countryCode"
          data-cy="select-company-address-country"
          horizontal
          label="Country"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="w-25"
          :options="countryOptions"
          :is-valid="checkIfValid(['form', 'companyAddress', 'countryCode'], submitted)"
          :invalid-feedback="pleaseSelect('a country')"
          placeholder="Please select"
        />

        <CSelect
          v-if="form.companyAddress.countryCode === 'US'"
          v-model="$v.form.companyAddress.state.$model"
          data-cy="select-company-address-state-us"
          horizontal
          label="State"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="w-25"
          :value.sync="form.companyAddress.state"
          :is-valid="checkIfValid(['form', 'companyAddress', 'state'], submitted)"
          :invalid-feedback="pleaseSelect('a state')"
          :options="stateOptions"
          placeholder="Please select"
        />

        <CSelect
          v-else-if="form.companyAddress.countryCode === 'CA'"
          v-model="$v.form.companyAddress.state.$model"
          data-cy="select-company-address-state-ca"
          horizontal
          label="Province"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="w-25"
          :is-valid="checkIfValid(['form', 'companyAddress', 'state'], submitted)"
          :invalid-feedback="pleaseSelect('a province')"
          :value.sync="form.companyAddress.state"
          :options="stateOptions"
          placeholder="Please select"
        />

        <CInput
          v-else
          v-model="$v.form.companyAddress.state.$model"
          data-cy="input-company-address-state-others"
          label="State"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="col-sm-1"
          :is-valid="checkIfValid(['form', 'companyAddress', 'state'], submitted)"
          :invalid-feedback="cannotBeBlank('State')"
          horizontal
        />

        <CInput
          v-model="$v.form.companyAddress.city.$model"
          data-cy="input-company-address-city"
          label="City"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="col-sm-2"
          :is-valid="checkIfValid(['form', 'companyAddress', 'city'], submitted)"
          :invalid-feedback="cannotBeBlank('City')"
          horizontal
        />

        <CInput
          v-model="$v.form.companyAddress.zip.$model"
          data-cy="input-company-address-zip"
          label="Zip/Postal Code"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="col-sm-2"
          :is-valid="checkIfValid(['form', 'companyAddress', 'zip'], submitted)"
          :invalid-feedback="cannotBeBlank('Zip/Postal code')"
          horizontal
        />

        <CInput
          v-model="$v.form.companyPhone.$model"
          data-cy="input-company-phone"
          label="Phone"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="w-25"
          :is-valid="checkIfValid(['form', 'companyPhone'], submitted)"
          invalid-feedback="Must be a valid US phone number"
          horizontal
        />

        <CInput
          v-model="$v.form.companyWebsite.$model"
          data-cy="input-company-website"
          label="Website"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="col-sm-5"
          :is-valid="checkIfValid(['form', 'companyWebsite'], submitted)"
          :invalid-feedback="invalidField('URL')"
          horizontal
        >
          <template v-slot:append>
            <div class="input-group-append">
              <span class="input-group-text">
                <a
                  :href="companyWebsite"
                  target="_blank"
                  @click="urlClick($event, $v.form.companyWebsite) "
                >View</a>
              </span>
            </div>
          </template>
        </CInput>

        <CInput
          v-model="$v.form.companyLinkedIn.$model"
          data-cy="input-company-linkedin"
          label="LinkedIn"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="col-sm-5"
          :is-valid="checkIfValid(['form', 'companyLinkedIn'], submitted)"
          :invalid-feedback="invalidField('URL')"
          horizontal
        >
          <template v-slot:append>
            <div 
              class="input-group-append"
            >
              <span class="input-group-text">
                <a
                  :href="companyLinkedIn"
                  target="_blank"
                  @click="urlClick($event, $v.form.companyLinkedIn) "
                >View</a>
              </span>
            </div>
          </template>
        </CInput>

        <CInput
          v-model="$v.form.companyTwitter.$model"
          data-cy="input-company-twitter"
          label="Twitter"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="col-sm-5"
          :is-valid="checkIfValid(['form', 'companyTwitter'], submitted)"
          :invalid-feedback="invalidField('URL')"
          horizontal
        >
          <template v-slot:append>
            <div class="input-group-append">
              <span class="input-group-text">
                <a
                  :href="companyTwitter"
                  target="_blank"
                  @click="urlClick($event, $v.form.companyTwitter) "
                >View</a>
              </span>
            </div>
          </template>
        </CInput>

        <CInput
          v-model="$v.form.companyFacebook.$model"
          data-cy="input-company-facebook"
          label="Facebook"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="col-sm-5"
          :is-valid="checkIfValid(['form', 'companyFacebook'], submitted)"
          :invalid-feedback="invalidField('URL')"
          horizontal
        >
          <template v-slot:append>
            <div class="input-group-append">
              <span class="input-group-text">
                <a
                  :href="companyFacebook"
                  target="_blank"
                  @click="urlClick($event, $v.form.companyFacebook) "
                >View</a>
              </span>
            </div>
          </template>
        </CInput>

        <CSelect
          v-model="$v.form.callTechnology.$model"
          label="Tracking Technology"
          data-cy="select-call-technology"
          horizontal
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="w-25"
          :value.sync="form.callTechnology"
          :is-valid="checkIfValid(['form', 'callTechnology'], submitted)"
          :options="callTechnologies"
          placeholder="Please select"
        />

        <CInput
          v-if="form.callTechnology === 'other'"
          v-model="$v.form.otherCallTechnology.$model"
          data-cy="input-call-technology-other"
          label=" "
          placeholder="Enter other technology name"
          description="specify the name of the call tracking technology"
          add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
          add-input-classes="w-25"
          :is-valid="checkIfValid(['form', 'otherCallTechnology'], submitted)"
          :value.sync="form.otherCallTechnology"
          horizontal
        />
      </CForm>

      <h3>
        Representative
      </h3>

      <CInput
        v-model="$v.form.representative.firstName.$model"
        data-cy="input-representative-first-name"
        label="First Name"
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="col-sm-2"
        :is-valid="checkIfValid(['form', 'representative', 'firstName'], submitted)"
        :invalid-feedback="cannotBeBlank('First name')"
        horizontal
      />

      <CInput
        v-model="$v.form.representative.lastName.$model"
        data-cy="input-representative-last-name"
        label="Last Name"
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="col-sm-2"
        :is-valid="checkIfValid(['form', 'representative', 'lastName'], submitted)"
        :invalid-feedback="cannotBeBlank('Last name')"
        horizontal
      />

      <CInput
        v-model="$v.form.representative.email.$model"
        data-cy="input-representative-email"
        label="Email"
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="col-sm-4"
        :is-valid="checkIfValid(['form', 'representative', 'email'], submitted)"
        :invalid-feedback="invalidField('email address')"
        horizontal
      />

      <CInput
        v-model="$v.form.representative.cellPhone.$model"
        data-cy="input-representative-phone"
        label="Phone"
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="col-sm-2"
        :is-valid="checkIfValid(['form', 'representative', 'cellPhone'], submitted)"
        description="This number is used for MFA authentication"
        invalid-feedback="Invalid mobile number"
        horizontal
      />

      <CInput
        v-model="$v.form.representative.skype.$model"
        data-cy="input-representative-skype"
        label="Skype"
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="col-sm-2"
        :is-valid="checkIfValid(['form', 'representative', 'skype'], submitted)"
        :invalid-feedback="cannotBeBlank('Skype username')"
        horizontal
      />

      <CInput
        v-model="$v.form.representative.linkedIn.$model"
        data-cy="input-representative-linkedin"
        label="LinkedIn"
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="col-sm-5"
        :is-valid="checkIfValid(['form', 'representative', 'linkedIn'], submitted)"
        :invalid-feedback="invalidField('URL')"
        horizontal
      >
        <template v-slot:append>
          <div class="input-group-append">
            <span class="input-group-text">
              <a
                :href="`//${$v.form.representative.linkedIn.$model}`"
                target="_blank"
                @click="urlClick($event, $v.form.representative.linkedIn) "
              >View</a>
            </span>
          </div>
        </template>
      </CInput>

      <h3>
        Other Details
      </h3>

      <CSelect
        v-model="$v.form.timeZoneId.$model"
        label="Time Zone"
        data-cy="select-time-zone"
        horizontal
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="w-25"
        :value.sync="form.timeZoneId"
        :options="timeZonesList"
        :is-valid="checkIfValid(['form', 'timeZoneId'], submitted)"
        :invalid-feedback="pleaseSelect('time zone')"
        placeholder="Please select"
      />

      <CSelect
        v-if="type === 'buyer'"
        v-model="$v.form.numberOfEmployeesRange.$model"
        label="No. of Employees"
        data-cy="select-number-of-employees"
        horizontal
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="w-25"
        :value.sync="form.numberOfEmployeesRange"
        :options="numberOfEmployeesRangeOptions"
        :is-valid="checkIfValid(['form', 'numberOfEmployeesRange'], submitted)"
        :invalid-feedback="pleaseSelect('number of employees')"
        placeholder="Please select"
      />

      <CSelect
        v-if="type === 'buyer'"
        v-model="$v.form.callPurchaseVolumeRange.$model"
        label="Call Purchase Volume"
        data-cy="select-call-purchase-volume"
        horizontal
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="w-25"
        :value.sync="form.callPurchaseVolumeRange"
        :options="monthlyCallVolumeRangeOptions"
        :is-valid="checkIfValid(['form', 'callPurchaseVolumeRange'], submitted)"
        :invalid-feedback="pleaseSelect('call purchase volume')"
        placeholder="Please select"
      />

      <CSelect
        v-if="type === 'seller'"
        v-model="$v.form.monthlyCallsGeneratedRange.$model"
        label="Monthly Calls Generated"
        data-cy="select-monthly-calls-generated"
        horizontal
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="w-25"
        :value.sync="form.monthlyCallsGeneratedRange"
        :options="monthlyCallsGeneratedRangeOptions"
        :is-valid="checkIfValid(['form', 'monthlyCallsGeneratedRange'], submitted)"
        :invalid-feedback="pleaseSelect('monthly calls generated')"
        placeholder="Please select"
      />

      <CRow class="mb-2">
        <CCol
          v-if="type === 'seller'"
          sm="3"
          class="text-right pr-3 font-weight-bold"
        >
          Works with affiliates
        </CCol>
        <CCol>
          <CSwitch
            v-if="type === 'seller'"
            shape="pill"
            color="primary"
            data-cy="toggle-works-with-affiliates"
            horizontal
            add-label-classes=""
            add-input-classes="w-25"
            :checked.sync="form.worksWithAffiliates"
          />
        </CCol>
      </CRow>

      <CSelect
        v-if="type === 'seller'"
        v-model="$v.form.annualPPCRevenueRange.$model"
        label="Annual PPC Revenue"
        data-cy="select-annual-ppc-revenue"
        horizontal
        add-label-classes="col-sm-3 text-right pr-3 font-weight-bold"
        add-input-classes="w-25"
        :value.sync="form.annualPPCRevenueRange"
        :options="annualPPCRevenueRangeOptions"
        :is-valid="checkIfValid(['form', 'annualPPCRevenueRange'], submitted)"
        :invalid-feedback="pleaseSelect('annual PPC revenue')"
        placeholder="Please select"
      />

      <dl class="row">
        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Current Categories
        </dt>
        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          <span
            v-for="(category, $idx) in requestDetails.categories"
            :key="category.id"
          >
            {{ category.name }}
            <span v-if="$idx !== requestDetails.categories.length - 1">|</span>
          </span>
        </dd>

        <dt
          v-if="type === 'buyer'"
          class="col-sm-3 text-right"
        >
          Current Categories
        </dt>
        <dd
          v-if="type === 'buyer'"
          class="col-sm-9"
        >
          <span
            v-for="(category, $idx) in requestDetails.categories"
            :key="category.id"
          >
            {{ category.name }}
            <span v-if="$idx !== requestDetails.categories.length - 1">|</span>
          </span>
        </dd>

        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Inbound Traffic Sources
        </dt>
        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          <span
            v-for="(ts, $idx) in requestDetails.inboundTrafficSources"
            :key="ts.id"
          >
            {{ ts.name }}
            <span
              v-if="$idx !== requestDetails.inboundTrafficSources.length - 1"
            >|</span>
          </span>
        </dd>

        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Outbound Traffic Sources
        </dt>

        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          <span
            v-for="(ts, $idx) in requestDetails.outboundTrafficSources"
            :key="ts.id"
          >
            {{ ts.name }}
            <span
              v-if="$idx !== requestDetails.outboundTrafficSources.length - 1"
            >|</span>
          </span>
        </dd>

        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Transfer Traffic Sources
        </dt>

        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          <span
            v-for="(ts, $idx) in requestDetails.transferTrafficSources"
            :key="ts.id"
          >
            {{ ts.name }}
            <span
              v-if="$idx !== requestDetails.transferTrafficSources.length - 1"
            >|</span>
          </span>
        </dd>

        <dt class="col-sm-3 text-right mt-4">
          IP Address
        </dt>
        <dd class="col-sm-9 mt-4">
          <a
            target="_blank"
            :href="
              `https://www.ip2location.com/demo/${requestDetails.geoIpInfo &&
                requestDetails.geoIpInfo.ipAddress}`
            "
          >
            {{ getSafe(() => requestDetails.geoIpInfo.ipAddress) }}
          </a>
        </dd>

        <dt class="col-sm-3 text-right">
          IP Country
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.geoIpInfo.ipCountry) }}
        </dd>

        <dt class="col-sm-3 text-right">
          IP City
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.geoIpInfo.ipCity) }}
        </dd>

        <dt class="col-sm-3 text-right">
          IP IST
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.geoIpInfo.ipisp) }}
        </dd>
      </dl>

      <CCol
        :md="{ offset: '3', size: '9' }"
        :sm="{ offset: '3', size: '9' }"
      >
        <ButtonLoading
          type="submit"
          size="sm"
          color="primary"
          :loading="isLoading"
          :disabled="!isFormEdited"
          data-cy="submit-details"
          @click="updateRegistrationDetails"
        >
          Save
        </ButtonLoading>
      </CCol>
    </CCardBody>
  </div>
</template>

<script>
const cssClassMapping = {
  pending: 'warning',
  approved: 'success',
  rejected: 'danger',
  waitlisted: 'secondary'
}

import { required, email } from 'vuelidate/lib/validators'
import customURLValidator from '@/validators/customURLValidator'
import { mapState, mapActions } from 'vuex'
import { REGISTRATION_TYPES } from '@/store/modules/registration-requests.store'
import { cloneDeep } from 'lodash'
import {
  USPhoneNumberValidator,
  mobileNumberValidator
} from '@/validators/phoneNumberValidator'
import formGenericMixin from '@/mixins/form-generic-mixin'
import formMixins from '@/mixins/form-mixins'

export default {
  name: 'RegistrationRequestCommonInfoEdit',
  mixins: [formMixins, formGenericMixin],
  props: {
    requestDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'buyer'
    }
  },

  validations() {
    return {
      form: {
        companyName: {
          required
        },
        ringbaAccountId: {},
        companyAddress: {
          addressLine1: {
            required
          },
          addressLine2: {},
          countryCode: {
            required
          },
          state: {
            required
          },
          city: {
            required
          },
          zip: {
            required
          }
        },
        companyPhone: {
          required,
          USPhoneNumberValidator
        },
        companyWebsite: {
          required,
          customURLValidator
        },
        companyLinkedIn: {
          customURLValidator
        },
        companyTwitter: {
          customURLValidator
        },
        companyFacebook: {
          customURLValidator
        },
        callTechnology: {},
        otherCallTechnology: {},
        representative: {
          firstName: {
            required
          },
          lastName: {
            required
          },
          email: {
            required,
            email
          },
          skype: {
            required
          },
          linkedIn: {
            customURLValidator
          },
          cellPhone: {
            required,
            mobileNumberValidator
          }
        },
        numberOfEmployeesRange: {
          requiredIf: this.type === 'buyer'
        },
        callPurchaseVolumeRange: {
          requiredIf: this.type === 'buyer'
        },
        monthlyCallsGeneratedRange: {
          requiredIf: this.type === 'seller'
        },
        annualPPCRevenueRange: {
          requiredIf: this.type === 'seller'
        },
        timeZoneId: {
          required
        }
      }
    }
  },

  data() {
    return {
      form: {},
      submitted: false,
      isLoading: false,
      callTechnologies: ['ringba', 'proprietary', 'other'],
      numberOfEmployeesRangeOptions: [
        { value: null, label: 'Please select' },
        { value: 'I am a team of one', label: 'I am a team of one' },
        { value: '2 - 5', label: '2 - 5' },
        { value: '5 - 10', label: '5 - 10' },
        { value: '10 - 25', label: '10 - 25' },
        { value: '25 - 100', label: '25 - 100' },
        { value: '100 +', label: '100 +' }
      ],
      monthlyCallVolumeRangeOptions: [
        { value: null, label: 'Please select' },
        { value: 'None', label: 'None' },
        { value: '1 - 100', label: '1 - 100' },
        { value: '100 - 1000', label: '100 - 1000' },
        { value: '1000 - 10000', label: '1000 - 10000' },
        { value: '10000 - 25000', label: '10000 - 25000' },
        { value: '25000 +', label: '25000 +' }
      ],

      monthlyCallsGeneratedRangeOptions: [
        { value: null, label: 'Please select' },
        { value: 'Less than 1,000', label: 'Less than 1,000' },
        { value: '1,000 - 10,000', label: '1,000 - 10,000' },
        { value: '10,000 - 25,000', label: '10,000 - 25,000' },
        { value: '25,000 +', label: '25,000 +' }
      ],
      annualPPCRevenueRangeOptions: [
        { value: null, label: 'Please select' },
        { value: 'Less than $100,000', label: 'Less than $100,000' },
        { value: '$100,000 - 500,000', label: '$100,000 - 500,000' },
        { value: '$500,000 - 1,000,000', label: '$500,000 - 1,000,000' },
        { value: '$1,000,000 - 2,500,000', label: '$1,000,000 - 2,500,000' },
        { value: '$2,500,000 - 10,000,000', label: '$2,500,000 - 10,000,000' },
        { value: '$10,000,000 +', label: '$10,000,000 +' },
        { value: 'Prefer not to disclose', label: 'Prefer not to disclose' }
      ]
    }
  },

  computed: {
    ...mapState('registrationRequests', ['registrationStatusCodes']),

    ...mapState('country', ['allCountries', 'usStates', 'canadaProvinces']),

    ...mapState('timezones', ['timezones']),

    timeZonesList() {
      const timeZonesList = [...this.timezones]
      timeZonesList.unshift({ label: 'Please select', value: null })
      return timeZonesList
    },

    statusVariant() {
      return cssClassMapping[this.requestDetails?.status?.toLowerCase()]
    },
    countryOptions() {
      let countriesOptions = this.allCountries.map(country => {
        return {
          value: country.code,
          label: country.name
        }
      })

      countriesOptions.unshift({
        value: null,
        label: 'Please select a country'
      })

      countriesOptions.splice(3, 0, {
        value: '',
        label: '---------------------------------------',
        disabled: true
      })

      return countriesOptions
    },
    stateOptions() {
      let stateOptions = []
      if (this.form.companyAddress.countryCode === 'US') {
        stateOptions = this.usStates.map(state => {
          return {
            value: state.abbreviation,
            label: state.name
          }
        })
        stateOptions.unshift({
          value: null,
          label: 'Select State'
        })
      } else if (this.form.companyAddress.countryCode === 'CA') {
        stateOptions = this.canadaProvinces.map(state => {
          return {
            value: state.short,
            label: state.name
          }
        })
        stateOptions.unshift({
          value: null,
          label: 'Select Province'
        })
      }

      return stateOptions
    },
    isValid() {
      return !this.$v.$invalid
    },

    companyWebsite() {
      return this.handleURLProtocol(this.form.companyWebsite)
    },

    companyTwitter() {
      return this.handleURLProtocol(this.form.companyTwitter)
    },

    companyLinkedIn() {
      return this.handleURLProtocol(this.form.companyLinkedIn)
    },

    companyFacebook() {
      return this.handleURLProtocol(this.form.companyFacebook)
    }
   
  },

  created() {
    this.form = cloneDeep(this.requestDetails)
    if (!this.callTechnologies.includes(this.form.callTechnology)) {
      this.form.otherCallTechnology = this.form.callTechnology
      this.form.callTechnology = 'other'
    }
        
  },
  methods: {
    ...mapActions('registrationRequests', [
      'updateBuyerRegistrationDetails',
      'updateSellerRegistrationDetails'
    ]),
    async updateRegistrationDetails() {

      this.submitted = true

      if (this.$v.$invalid) {
        return
      }

      this.isLoading = true

      try {
        if (this.type === REGISTRATION_TYPES.buyer) {
          await this.updateBuyerRegistrationDetails({
            id: this.requestDetails.id,
            updateObject: {
              ...this.form,
              testOnly: undefined,
              callTechnology:
                this.form.callTechnology === 'other'
                  ? this.form.otherCallTechnology
                  : this.form.callTechnology
            }
          })
        } else {
          await this.updateSellerRegistrationDetails({
            id: this.requestDetails.id,
            updateObject: {
              ...this.form,
              testOnly: undefined,
              callTechnology:
                this.form.callTechnology === 'other'
                  ? this.form.otherCallTechnology
                  : this.form.callTechnology
            }
          })
        }
        this.$emit('updated')
      } catch (error) {
        // Handle error
      } finally {
        this.isLoading = false
      }
    },

    cannotBeBlank(field) {
      return `${field} cannot be blank`
    },

    pleaseSelect(field) {
      return `Please select ${field}`
    },

    invalidField(field) {
      return `Invalid ${field}`
    },

    urlClick: function (event,field) {
     
      if (field.$model ==='' || field.$invalid || field.$model === null) {
        event.preventDefault()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
