<template>
  <div>
    <CModal
      title="Confirm rejection"
      :show.sync="warningRejectModal"
    >
      Are you sure you want to reject this request?

      <template #footer>
        <ButtonLoading
          color="danger"
          :loading="isLoading"
          @click="rejectRequest"
        >
          Yes, Reject
        </ButtonLoading>

        <CButton
          color="secondary"
          @click="warningRejectModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm approval"
      :show.sync="warningApproveModal"
    >
      Are you sure you want to approve this request?

      <template #footer>
        <ButtonLoading
          color="success"
          :loading="isLoading"
          @click="approveRequest"
        >
          Yes, Approve
        </ButtonLoading>

        <CButton
          color="secondary"
          @click="warningApproveModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
    <CCard v-if="kycRequestDetails && kycRequestDetails.id">
      <CCardHeader>
        Review Credit Card KYC :
        {{ getSafe(() => kycRequestDetails.account.name) }}
        <a
          href
          @click.prevent="
            copyAccountIdToClipboard(kycRequestDetails.account.id)
          "
        ><CIcon
          name="cil-copy"
        /></a>
      </CCardHeader>

      <CCardBody>
        <h4>
          Contact
        </h4>
        <dl class="row">
          <dt class="col-sm-3 text-right">
            Company Phone
          </dt>
          <dd class="col-sm-9">
            {{ getSafe(() => kycRequestDetails.account.companyPhone) }}
          </dd>
          
          <dt class="col-sm-3 text-right">
            Company Representative
          </dt>
          <dd class="col-sm-9">
            {{ getSafe(() => kycRequestDetails.account.representative.firstName) }} {{ getSafe(() => kycRequestDetails.account.representative.lastName) }}<br>
            {{ getSafe(() => kycRequestDetails.account.representative.cellPhone) }}<br>
            {{ getSafe(() => kycRequestDetails.account.representative.email) }}
          </dd>

          <dt class="col-sm-3 text-right">
            Company Address
          </dt>
          <dd class="col-sm-9">
            {{ getSafe(() => companyAddressFormatted(kycRequestDetails.account.companyAddress)) }}
          </dd>
        </dl>
        <h4>
          Card
        </h4>
        <dl class="row">
          <dt class="col-sm-3 text-right">
            Number
          </dt>
          <dd class="col-sm-9">
            {{ kycRequestDetails.maskedCC }}
            <div class="text-uppercase">
              <small><b>{{ kycRequestDetails.cardType }}</b></small>
            </div>
          </dd>

          <dt class="col-sm-3 text-right">
            Billing Address
          </dt>
          <dd class="col-sm-9">
            {{ cardAddressFormatted(kycRequestDetails) }}
          </dd>

          <dt class="col-sm-3 text-right">
            Expiration Date
          </dt>
          <dd class="col-sm-9">
            {{ kycRequestDetails.expMonth }} / {{ kycRequestDetails.expYear }}
          </dd>

          <hr>
        </dl>
        <h5 class="mt-3">
          Uploaded Documents
        </h5>
        <div class="d-flex justify-content-around">
          <div
            v-for="(url, index) in kycRequestDetails.kycDocs"
            :key="index"
            class="mx-2"
          >
            <a
              :href="url"
              target="_blank"
            >
              <img
                width="300px"
                :src="url"
                alt=""
              >
            </a>
            
            <br><br>
          </div>
        </div>
        <div>
          <CTextarea
            v-model="declineReason"
            label="Rejection Reason"
            placeholder="enter the reason for rejecting this KYC request"
            horizontal
            rows="5"
            invalid-feedback="Please enter comments to save"
          />
        </div>
        <div class="d-flex justify-content-around">
          <ButtonLoading
            type="button"
            size="sm"
            color="success"
            @click="showConfirmApproveRequestModal"
          >
            Approve
          </ButtonLoading>
          <ButtonLoading
            type="button"
            size="sm"
            color="danger"
            :disabled="!declineReason"
            @click="showConfirmRejectRequestModal"
          >
            Reject
          </ButtonLoading>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { showSuccessMessage } from '@/notification-utils'
import { mapActions, mapGetters } from 'vuex'
import approveRejectModalMixin from '@/mixins/approve-reject-modal-mixin'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'

export default {
  name: 'KYCRequestDetails',
  mixins: [approveRejectModalMixin, copyToClipboardMixin],
  data() {
    return {
      kycRequestId: '',
      declineReason: ''
    }
  },
  computed: {
    ...mapGetters('kyc', ['kycRequestById']),
    kycRequestDetails() {
      return this.kycRequestById(this.kycRequestId)
    }
  },
  async created() {
    this.kycRequestId = this.$route.params.id
    if (!this.kycRequestDetails?.id) {
      await this.fetchPendingKYCRequests()
    }
  },
  methods: {
    ...mapActions('kyc', [
      'fetchPendingKYCRequests',
      'approveKYCRequest',
      'rejectKYCRequest'
    ]),
    addressFieldOrBlank(field, last = false) {
      if (!last) {
        return field ? `${field}, ` : ''
      } else {
        return field ? field : ''
      }
    },

    cardAddressFormatted(kycRequestDetails) {
      if (kycRequestDetails.cardAddress && kycRequestDetails.cardAddress.streetAddress) {
        const addressObject = kycRequestDetails.cardAddress
        return `${this.addressFieldOrBlank(
          addressObject.streetAddress
        )}${this.addressFieldOrBlank(
          addressObject.addressLine2
        )}${this.addressFieldOrBlank(
          addressObject.city
        )}${this.addressFieldOrBlank(
          addressObject.region
        )}${this.addressFieldOrBlank(addressObject.countryName, true)}
        ${this.addressFieldOrBlank(addressObject.countryCode)}`
      } else if (kycRequestDetails.account.companyAddress) {
        return this.companyAddressFormatted(kycRequestDetails.account.companyAddress)        
      }
    },

    companyAddressFormatted(companyAddress) {
      if (companyAddress && companyAddress.addressLine1) {
        return `${this.addressFieldOrBlank(
          companyAddress.addressLine1
        )}${this.addressFieldOrBlank(
          companyAddress.addressLine2
        )}${this.addressFieldOrBlank(
          companyAddress.zip
        )}${this.addressFieldOrBlank(
          companyAddress.city
        )}${this.addressFieldOrBlank(
          companyAddress.state
        )}${this.addressFieldOrBlank(companyAddress.country, true)}
        ${this.addressFieldOrBlank(companyAddress.countryCode)}`
      }
      return ''
    },

    async approveRequest() {
      try {
        this.isLoading = true
        await this.approveKYCRequest({ paymentMethodId: this.kycRequestId })
        this.isLoading = false
      } finally {
        this.hideConfirmApproveRequestModal()
        this.$router.push({ name: 'KYCRequests' })
      }
    },
    async rejectRequest() {
      try {
        this.isLoading = true
        await this.rejectKYCRequest({
          paymentMethodId: this.kycRequestId,
          declineReason: this.declineReason
        })
        this.isLoading = false
      } finally {
        this.hideConfirmRejectRequestModal()
        this.$router.push({ name: 'KYCRequests' })
      }
    },
    verifyKYCRequest() {
      return new Promise(resolve => setTimeout(resolve, 3000))
    },
    copyAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Account ID copied to clipboard')
    }
  }
}
</script>

<style lang="scss" scoped></style>
