<template>
  <div>
    <CForm
      novalidate
      @submit.prevent
    >
      <CInput
        id="new-password"
        v-model="newPassword"
        name="newPassword"
        label="New Password"
        placeholder="Enter your new password"
        type="password"
        vid="new-password"
        :is-valid="checkIfValid('newPassword')"
        :invalid-feedback="REQUIRED_FIELD_ERROR_MESSAGE"
        required
      />

      <CInput
        id="confirm-password"
        v-model="confirmPassword"
        name="confirmPassword"
        label="Confirm Password"
        placeholder="Re-enter password"
        type="password"
        :is-valid="checkIfValid('confirmPassword')"
        invalid-feedback="Passwords must match"
        required
      />

      <div class="mt-3">
        <ButtonLoading
          class="custom-button--white-hover-grad pulse"
          type="submit"
          color="primary"
          data-cy="button-login"
          :loading="isLoading"
          @click="onSubmit"
        >
          Change Password
        </ButtonLoading>
      </div>
    </CForm>
  </div>
</template>

<script>
import { sameAs, required, helpers } from 'vuelidate/lib/validators'

import { PASSWORD_REGEX } from '@/store/modules/auth.store'

export default {
  name: 'ChangePassword',

  validations() {
    return {
      newPassword: {
        required,
        passwordPattern: helpers.regex('password', PASSWORD_REGEX)
      },

      confirmPassword: {
        required,
        sameAs: sameAs('newPassword')
      }
    }
  },

  props: {
    errorMessage: {
      type: String,
      default: ''
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      PASSWORD_REGEX: Object.freeze(PASSWORD_REGEX)
    }
  },

  computed: {
    isValid () { return !this.$v.$invalid },
  },

  methods: {
    onSubmit() {
      this.$v.$touch()

      if (this.isValid) {
        this.$emit('change-password', this.newPassword)
      }
    },

    checkIfValid (fieldName) {
      const field = this.$v[fieldName]

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
  },
}
</script>

<style>

</style>