const allCountries = require('./countries')
const usStates = require('./usStates')
const canadaProvinces = require('./canadaProvinces')

import CountriesAPI from '@/api/country.api'
import PublicCountriesAPI from '@/api/public-countries.api'


const state = {
  countries: [],
  selectedRow: {},
  allCountries,
  usStates,
  canadaProvinces,
  //fetch from api
  publicCountries: []

}

const getters = {
  getActiveCountries(state) {
    return state.countries.filter(c => c.active)
  }
}

const actions = {
  async list({ commit }) {
    const response = await CountriesAPI.list()
    commit('SET_COUNTRIES', response.data)
  },
  async create(context, newCountry) {
    const response = await CountriesAPI.create(newCountry)
    return response
  },
  async findOne({ commit }, id) {
    const response = await CountriesAPI.findOne(id)
    commit('SET_SELECTED_ROW', response.data)
  },
  async update(context, updatedRow) {
    const response = await CountriesAPI.patch(updatedRow.id, updatedRow)
    return response
  },
  async listAllCountries({ commit }) {
    const response = await PublicCountriesAPI.getAvailableCountries()
    commit('SET_PUBLIC_COUNTRIES', response.data)
  },
}

const mutations = {
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
  SET_SELECTED_ROW(state, row) {
    state.selectedRow = row
  },
  SET_PUBLIC_COUNTRIES(state, countries) {
    state.publicCountries = countries
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
