<template>
  <div
    class="c-app flex-row align-items-center"
    :class="{ 'c-dark-theme': $store.state.darkMode }"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <slot />
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'LayoutAuth'
}
</script>
