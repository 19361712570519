import UserAPI from '@/api/users.api'

const SUPER_ADMIN_ROLE = 'Admin'
const ACTIVE_STATUS = 'Active'
const DISABLED_STATUS = 'Disabled'

const state = {
  superAdminUsers: [],
  allUsers: []
}

const getters = {}

const actions = {
  async getSuperAdminUsers({ commit }) {
    const response = await UserAPI.getUsers(SUPER_ADMIN_ROLE)

    commit('SET_SUPER_ADMIN_USERS', response.data)
  },

  async deleteUser({ commit }, userID) {
    await UserAPI.deleteUser(userID)

    commit('REMOVE_USER', userID)
  },

  async createUser(context, user) {
    await UserAPI.createUser(user)
  },

  async disableUser({ commit }, userID) {
    await UserAPI.disableUser(userID)

    commit('SET_USER_STATUS', { userID, status: DISABLED_STATUS })
  },

  async enableUser({ commit }, userID) {
    await UserAPI.enableUser(userID)

    commit('SET_USER_STATUS', { userID, status: ACTIVE_STATUS })
  },

}

const mutations = {
  SET_SUPER_ADMIN_USERS(state, superAdminUsers) {
    state.superAdminUsers = superAdminUsers
  },

  SET_ALL_USERS(state, users) {
    state.allUsers = users
  },

  REMOVE_USER(state, userID) {
    state.superAdminUsers = state.superAdminUsers.filter(user => {
      return user.id !== userID
    })

    state.allUsers = state.allUsers.filter(user => {
      return user.id !== userID
    })
  },

  SET_USER_STATUS(state, { userID, status }) {
    let userInAllUsers = state.allUsers.find(u => u.id === userID)

    let userInAdminUsers = state.superAdminUsers.find(u => u.id === userID)

    if (userInAllUsers) {
      userInAllUsers.status = status
    }

    if (userInAdminUsers) {
      userInAdminUsers.status = status
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export { ACTIVE_STATUS, DISABLED_STATUS, SUPER_ADMIN_ROLE }
