<template>
  <CCard>
    <CCardHeader>
      <div class="d-flex justify-content-between">
        <div>Seller Request Details</div>

        <RegistrationRequestMarkTestOnlyButton
          class="ml-auto"
          :request-details="requestDetails"
          type="seller"
        />

        <CButton
          size="sm"
          class="ml-3"
          :color="editMode ? 'danger' : 'primary'"
          @click="toggleEditMode"
        >
          {{ editMode ? 'Cancel' : 'Edit Details' }}
        </CButton>
      </div>
    </CCardHeader>

    <div v-if="requestDetails">
      <div v-if="!editMode">
        <RegistrationRequestCommonInfoReadOnly
          type="seller"
          :request-details="requestDetails"
        />
      </div>
      <div v-else>
        <RegistrationRequestCommonInfoEdit
          type="seller"
          :request-details="requestDetails"
          @updated="toggleEditMode"
        />
      </div>
    </div>
  </CCard>
</template>

<script>
import RegistrationRequestMarkTestOnlyButton from './RegistrationRequestMarkTestOnlyButton.vue'
import RegistrationRequestCommonInfoReadOnly from './RegistrationRequestCommonInfoReadOnly.vue'
import RegistrationRequestCommonInfoEdit from './RegistrationRequestCommonInfoEdit.vue'


const cssClassMapping = {
  'pending': 'warning',
  'approved': 'success',
  'rejected': 'danger',
  'waitlisted': 'secondary'
}

export default {
  name: 'RegistrationRequestSellerInfo',

  title: 'Seller Registration Request',

  components: {
    RegistrationRequestCommonInfoReadOnly,
    RegistrationRequestCommonInfoEdit,
    RegistrationRequestMarkTestOnlyButton,
  },

  props: {
    requestDetails: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return  {
      editMode: false
    }
  },

  computed: {
    statusVariant() {
      return cssClassMapping[this.requestDetails?.status?.toLowerCase()]
    }
  },
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode
    }
  }

}
</script>

<style>

</style>