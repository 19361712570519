<template>
  <div>
    <CCard>
      <CCardHeader data-cy="header">
        Create Language
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CInput
            v-model="form.name"
            data-cy="input-name"
            label="Name"
            horizontal
            placeholder="Language Name"
          />
          <CTextarea
            v-model="form.description"
            data-cy="input-description"
            label="Description"
            placeholder="Enter description"
            horizontal
            rows="3"
          />
          <DatePickerInput
            id="expiration-date"
            v-model="form.expirationDate"
            :min-date="new Date()"
            data-cy="input-expiration-date"
            label="Expiration Date"
            placeholder="Choose expiration date"
            horizontal
            readonly
          />
          <CSelect
            data-cy="input-status"
            label="Status"
            horizontal
            :value.sync="form.status"
            :options="statusCodesForNewEntry"
            placeholder="Please select"
          />
        </CForm>
      </CCardBody>
      <CCardFooter>
        <CButton
          :disabled="submitted"
          data-cy="button-submit"
          type="submit"
          size="sm"
          color="primary"
          @click.prevent="
            submitCreateForm({ createFn: create, entity: 'languages' })
          "
        >
          <CSpinner
            v-if="submitted"
            color="light"
            size="sm"
          />
          <CIcon
            v-else
            name="cil-check-circle"
          /> Submit
        </CButton>
        <CButton
          data-cy="button-back"
          class="ml-2"
          :disabled="submitted"
          type="reset"
          size="sm"
          color="danger"
          @click="backToListView('languages')"
        >
          <CIcon name="cil-arrow-circle-left" /> Back
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import formMixins from '@/mixins/form-mixins'
export default {
  name: 'LanguageCreate',
  mixins: [formMixins],
  data() {
    return {
      form: {
        name: '',
        description: '',
        expirationDate: null,
        status: null
      }
    }
  },
  computed: {
    ...mapState(['statusCodesForNewEntry'])
  },
  methods: {
    ...mapActions('language', ['create'])
  }
}
</script>

<style lang="scss" scoped></style>
