<template>
  <CCard>
    <div
      v-if="isLoading"
      :style="{ height: '80vh' }"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <CSpinner
        class="custom-spinner"
        color="info"
        grow
      />
    </div>

    <div v-else>
      <CCardHeader>
        KYC Applications
      </CCardHeader>

      <CCardBody>
        <CDataTable
          data-cy="table-kyc-requests"
          small
          fixed
          border
          sorter
          column-filter
          :items="kycRequestsFormatted"
          :fields="fields"
          :items-per-page="10"
          :pagination="{align: 'center'}"
        >
          <template #accountId="{item}">
            <td>{{ item.account.id }}</td>
          </template>
          <template #accountName="{item}">
            <td>{{ item.account.name }}</td>
          </template>
          <template #representative="{item}">
            <td>{{ getSafe(() => item.account.representative.firstName) }} {{ getSafe(() => item.account.representative.lastName) }}</td>
          </template>
          <template #email="{item}">
            <td>{{ getSafe(() => item.account.representative.email) }}</td>
          </template>
          <template #expiration="{item}">
            <td>{{ `${item.expMonth >= 10 ? item.expMonth : `0${item.expMonth}` } / ${item.expYear}` }}</td>
          </template>
          <template #Actions="{item}">
            <td>
              <CButton
                color="info"
                size="sm"
                :to="`/dashboard/kyc-requests/${item.id}`"
              >
                View Request
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </div>
  </CCard>
</template>

<script>

export default {
  name: 'KYCRequestsList',


  props: {
    kycRequests: {
      type: Array,
      default() {
        return []
      }
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      enableAutoreload: false,
      fields: [
        { key: 'accountId', label: 'Account', sortable: true },
        { key: 'accountName', label: 'Account Name', sortable: true },
        { key: 'representative', label: 'Company Representative', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'maskedCC', label: 'Credit Card Number', sortable: true },
        { key: 'expiration', label: 'Expiration', sortable: true },
        { key: 'Actions', filter: false }
      ]
    }
  },

  computed: {
    kycRequestsFormatted() {
      return this.kycRequests
    }
  },

  methods: {
    toggleAutoReload() {
      this.enableAutoreload = !this.enableAutoreload

      this.$emit('change-auto-reload', this.enableAutoreload)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>