<template>
  <div>
    <CCard
      v-if="getSafe(() => requestDetails.testOnly)"
      class="test-request-banner bg-danger p-3 text-white"
    >
      <strong>
        <CIcon
          name="cilWarning"
          class="mr-1"
        />
        This is a test-only registration request
      </strong>
    </CCard>

    <RegistrationRequestBuyerInfo
      v-if="type === REGISTRATION_TYPES.buyer && requestDetails.id"
      :request-details="requestDetails"
    />

    <RegistrationRequestSellerInfo
      v-if="type === REGISTRATION_TYPES.seller"
      :request-details="requestDetails"
    />

    <RegistrationRequestChecklist
      v-if="requestDetails.id"
      :registration-request-id="requestDetails.id"
      :type="type"
    />

    <ReviewNotes
      v-if="requestDetails.id"
      :parent-object="requestDetails"
    />

    <RegistrationRequestBillingDetails
      v-if="requestDetails.id"
      :registration-request="requestDetails"
      :type="type"
    />

    <RegistrationRequestTrafficDetails
      v-if="requestDetails.id"
      :registration-request="requestDetails"
      :type="type"
    />


    <CCard>
      <CCardHeader>
        History
      </CCardHeader>

      <CCardBody />
    </CCard>
    <CModal
      title="Confirm reject"
      color="danger"
      data-cy="reject-modal"
      :show.sync="warningRejectModal"
    >
      Are you sure you want to deny this request?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="rejectRequest"
        >
          Deny
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningRejectModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm approve"
      color="success"
      data-cy="approve-modal"
      :show.sync="warningApproveModal"
    >
      Are you sure you want to pre-approve this request? The account will not be active until the final approval has been granted.

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="approveRequest"
        >
          Pre-Approve
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningApproveModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm waitlist"
      color="success"
      data-cy="waitlist-modal"
      :show.sync="warningWaitlistModal"
    >
      Are you sure you want to put this request to waitlist?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="waitlistRequest"
        >
          Waitlist
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningWaitlistModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <div
      v-if="!trafficSourcesSet"
      class="invalid-feedback d-flex mb-3 mt-2"
    >
      <CIcon
        name="cilWarning"
        class="mr-1"
      />
      Please select and save traffic sources and categories for the {{ type }}
    </div>

    <div
      v-if="!requestIsReviewed"
      class="pb-5"
    >
      <CButton 
        type="submit"
        class="mr-4"
        size="lg"
        color="success"
        data-cy="approve"
        @click="showConfirmApproveRequestModal"
      >
        Pre-Approve
      </CButton>

      <CButton
        type="submit"
        class="mr-4"
        size="lg"
        color="danger"
        data-cy="reject"
        @click="showConfirmRejectRequestModal"
      >
        Deny
      </CButton>
          
      <CButton
        v-if="!requestIsWaitlisted"
        type="submit"
        class="mr-4"
        color="secondary"
        size="lg"
        data-cy="waitlist"
        @click="showConfirmWaitlistRequestModal"
      >
        Waitlist
      </CButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { showSuccessMessage } from '@/notification-utils'
import { REGISTRATION_TYPES } from '@/store/modules/registration-requests.store'
import ReviewNotes from '../ReviewNotes/ReviewNotes.vue'

import RegistrationRequestBillingDetails from './RegistrationRequestBillingDetails.vue'
import RegistrationRequestBuyerInfo from './RegistrationRequestBuyerInfo.vue'
import RegistrationRequestSellerInfo from './RegistrationRequestSellerInfo.vue'
import RegistrationRequestTrafficDetails from './RegistrationRequestTrafficDetails.vue'
import RegistrationRequestChecklist from './RegistrationRequestChecklist.vue'

export default {
  name: 'RegistrationRequestDetails',

  components: {
    ReviewNotes,
    RegistrationRequestBillingDetails,
    RegistrationRequestTrafficDetails,
    RegistrationRequestBuyerInfo,
    RegistrationRequestSellerInfo,
    RegistrationRequestChecklist
  },

  data() {
    return {
      type: '',
      requestId: '',
      isLoading: false,
      warningRejectModal: false,
      warningApproveModal: false,
      warningWaitlistModal: false,
      REGISTRATION_TYPES,
    }
  },

  computed: {
    ...mapState('registrationRequests', [
      'sellerRegistrationRequestDetails',
      'buyerRegistrationRequestDetails',
    ]),

    requestDetails() {
      return this.type === REGISTRATION_TYPES.seller
        ? this.sellerRegistrationRequestDetails
        : this.buyerRegistrationRequestDetails
    },

    requestIsReviewed() {
      return ['approved', 'rejected'].includes(
        this.requestDetails?.status?.toLowerCase()
      )
    },

    requestIsWaitlisted() {
      return this.requestDetails?.status?.toLowerCase() === 'waitlisted'
    },

    trafficSourcesSet() {
      return (
        this.requestDetails?.reviewDetails?.allowedTrafficSources?.length &&
        this.requestDetails?.reviewDetails?.allowedCategories?.length
      )
    },
  },

  async created() {
    this.type = this.$route.params.type.toLowerCase()
    this.requestId = this.$route.params.id

    if (this.requestId) {
      try {
        await this.getRegistrationRequestDetails({
          registrationType: this.type,
          registrationRequestId: this.requestId,
        })
      } catch (error) {
        // Exception handled in interceptor
      }
    }
  },

  methods: {
    ...mapActions('registrationRequests', [
      'getRegistrationRequestDetails',
      'rejectBuyerRegistrationRequest',
      'rejectSellerRegistrationRequest',
      'approveBuyerRegistrationRequest',
      'approveSellerRegistrationRequest',
      'waitlistBuyerRegistrationRequest',
      'waitlistSellerRegistrationRequest',
    ]),

    showConfirmRejectRequestModal() {
      this.warningRejectModal = true
    },

    showConfirmWaitlistRequestModal() {
      this.warningWaitlistModal = true
    },

    showConfirmApproveRequestModal() {
      if (!this.trafficSourcesSet) {
        return
      }

      this.warningApproveModal = true
    },

    async rejectRequest() {
      this.isLoading = true

      try {
        if (this.type === REGISTRATION_TYPES.buyer) {
          await this.rejectBuyerRegistrationRequest(this.requestId)
        } else {
          await this.rejectSellerRegistrationRequest(this.requestId)
        }

        showSuccessMessage('Registration request successfully rejected')

        this.warningRejectModal = false

        this.routeToListPage()
      } finally {
        this.isLoading = false
      }
    },

    async waitlistRequest() {
      this.isLoading = true

      try {
        if (this.type === REGISTRATION_TYPES.buyer) {
          await this.waitlistBuyerRegistrationRequest(this.requestId)
        } else {
          await this.waitlistSellerRegistrationRequest(this.requestId)
        }

        showSuccessMessage('Registration request successfully put on waitlist')

        this.warningWaitlistModal = false

        this.routeToListPage()
      } finally {
        this.isLoading = false
      }
    },

    async approveRequest() {
      this.isLoading = true

      try {
        if (this.type === REGISTRATION_TYPES.buyer) {
          await this.approveBuyerRegistrationRequest(this.requestId)
        } else {
          await this.approveSellerRegistrationRequest(this.requestId)
        }

        showSuccessMessage('Registration request successfully approved')

        this.warningApproveModal = false

        this.routeToListPage()
      } finally {
        this.isLoading = false
      }
    },

    routeToListPage() {
      if (this.type === REGISTRATION_TYPES.buyer) {
        this.$router.push({
          name: 'RegistrationRequests',
          params: {
            type: REGISTRATION_TYPES.buyer,
          },
        })
      } else {
        this.$router.push({
          name: 'RegistrationRequests',
          params: {
            type: REGISTRATION_TYPES.seller,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.test-request-banner {
  position: sticky;
  top: 60px;
  font-size: 1.2rem;
  z-index: 1;
}
</style>
