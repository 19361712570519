<template>
  <CCard>
    <CCardHeader>
      Scheduling
    </CCardHeader>
    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CSelect
          id="buyer-campaign__scheduling__hours-of-operation-timezone"
          v-model="$v.scheduleDetailsLocal.timeZoneId.$model"
          :value.sync="scheduleDetailsLocal.timeZoneId"
          add-label-classes="text-right pr-4"
          add-input-classes="w-50"
          data-cy="buyer-campaign__scheduling__hours-of-operation-timezone"
          label="Timezone"
          horizontal
          :options="timeZonesList"
          placeholder="Please select"
        />

        <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Hours of Operation
          </CCol>
          <CCol sm="1">
            <CSwitch
              shape="pill"
              :checked.sync="useHoursOfOperation"
              color="primary"
              @update:checked="formEdited = true"
            />
          </CCol>
          <CCol
            v-if="useHoursOfOperation"
            sm="2"
          >
            <CButton
              v-if="hoursOfOperationType === 'basic'"
              color="success"
              class="w-100"
              size="sm"
              @click="showAdvanced"
            >
              Show Advanced
            </CButton>
            <CButton
              v-if="hoursOfOperationType === 'advanced'"
              size="sm"
              color="success"
              class="w-100"
              @click="showBasic"
            >
              Show Basic
            </CButton>
          </CCol>
        </CRow>

        <CRow
          v-if="useHoursOfOperation"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          />
          <CCol
            sm="9"
          >
            <CDataTable
              data-cy="table-hours-of-operation"
              hover
              striped
              border
              small
              fixed
              :items="hoursOfOperation"
              :fields="fields"
            >
              <template #dayOpen="{item, index}">
                <td>
                  <CSwitch
                    shape="pill"
                    color="primary"
                    horizontal
                    size="sm"
                    :disabled="hoursOfOperationType === 'basic'"
                    :checked="item.isOpen"
                    @update:checked="handleOpenCloseDay(index, ...arguments)"
                  />
                </td>
              </template>

              <template #open="{item}">
                <td>
                  <div class="d-flex justify-content-center">
                    <CInput
                      v-model="item.open.hour"
                      min="0"
                      max="23"
                      type="number"
                      placeholder="HH"
                      @change="$v.$touch()"
                    >
                      <template #description>
                        <small
                          class="ml-3 form-text text-muted w-100"
                        >Hour</small>
                      </template>
                    </CInput>
                    <CInput
                      v-model="item.open.minute"
                      class="ml-2"
                      min="0"
                      add-input-classes="w-10"
                      max="59"
                      type="number"
                      placeholder="MM"
                      @change="$v.$touch()"
                    >
                      <template #description>
                        <small
                          class="ml-3 form-text text-muted w-100"
                        >Minute</small>
                      </template>
                    </CInput>
                  </div>
                </td>
              </template>

              <template #close="{item}">
                <td>
                  <div class="d-flex justify-content-center">
                    <CInput
                      v-model="item.close.hour"
                      min="0"
                      max="23"
                      type="number"
                      placeholder="HH"
                      @change="$v.$touch()"
                    >
                      <template #description>
                        <small
                          class="ml-3 form-text text-muted w-100"
                        >Hour</small>
                      </template>
                    </CInput>
                    <CInput
                      v-model="item.close.minute"
                      class="ml-2"
                      min="0"
                      max="59"
                      description="MM"
                      type="number"
                      placeholder="MM"
                      @change="$v.$touch()"
                    >
                      <template #description>
                        <small
                          class="ml-3 form-text text-muted w-100"
                        >Minute</small>
                      </template>
                    </CInput>
                  </div>
                </td>
              </template>

              <template #breaks="{item, index}">
                <td>
                  <ul v-if="item.breaks && item.breaks.length > 0">
                    <li
                      v-for="(brk, idx) in item.breaks"
                      :key="`${index}-break-${idx}`"
                    >
                      {{ `${getFormattedTime(brk.startTime)}` }} for
                      {{ brk.lengthInMin }} minutes
                      <span
                        style="cursor: pointer;"
                        @click="removeBreak(index, idx)"
                      >
                        <CIcon
                          class="text-danger"
                          name="cil-x"
                        />
                      </span>
                    </li>
                  </ul>
                  <CButton
                    color="primary"
                    @click="toggleDetails(item, index)"
                  >
                    Add Break
                  </CButton>
                </td>
              </template>

              <template #details="{item, index}">
                <div v-show="Boolean(item._toggled)">
                  <CForm class="my-2">
                    <CInput
                      v-model="newBreakForm.startTime.hour"
                      type="number"
                      add-label-classes="text-right pr-4"
                      add-input-classes="w-25"
                      name="break-start-time"
                      placeholder="HH"
                      horizontal
                      label="Break Start Time (HH)"
                    />

                    <CInput
                      v-model="newBreakForm.startTime.minute"
                      type="string"
                      add-label-classes="text-right pr-4"
                      add-input-classes="w-25"
                      name="break-start-time"
                      placeholder="MM"
                      horizontal
                      label="Break Start Time (MM)"
                    />

                    <CInput
                      v-model="newBreakForm.lengthInMin"
                      type="number"
                      add-label-classes="text-right pr-4"
                      add-input-classes="w-25"
                      name="break-duration"
                      placeholder="1"
                      horizontal
                      label="Duration (minutes)"
                    />

                    <CRow>
                      <CCol sm="3" />
                      <CCol sm="3">
                        <CButton
                          size="sm"
                          color="success"
                          @click="addBreak(item, index)"
                        >
                          Add Break
                        </CButton>
                        <CButton
                          size="sm"
                          class="ml-2"
                          color="danger"
                          @click="cancelAddingBreak(item, index)"
                        >
                          Cancel
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </div>
              </template>
            </CDataTable>
          </CCol>
        </CRow>

        <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Max Concurrency
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="isConcurrencyCapEnabled"
              color="primary"
              @update:checked="updateMaxConcurrency"
            />
          </CCol>
        </CRow>

        <CInput
          v-if="isConcurrencyCapEnabled"
          v-model="$v.scheduleDetailsLocal.concurrencyCap.$model"
          type="text"
          label=" "
          add-label-classes="text-right pr-4"
          add-input-classes="w-25"
          name="Name"
          :is-valid="
            checkIfValid([
              'scheduleDetailsLocal',
              'concurrencyCap'
            ], submitted)
          "
          invalid-feedback="Invalid value for concurrency cap"

          placeholder="max concurrency"
          horizontal
        />

        <!-- <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Hourly Concurrency
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="isHourlyConcurrencyEnabled"
              color="primary"
              @update:checked="updateHourlyConcurrency"
            />
          </CCol>
        </CRow>

        <CInput
          v-if="isHourlyConcurrencyEnabled"
          v-model="$v.scheduleDetailsLocal.hourlyConcurrencyCap.$model"
          :is-valid="
            checkIfValid([
              'scheduleDetailsLocal',
              'hourlyConcurrencyCap'
            ], submitted)
          "
          invalid-feedback="Invalid value for concurrency cap"
          type="text"
          label=" "
          add-label-classes="text-right pr-4"
          add-input-classes="w-25"
          name="Name"
          placeholder="hourly concurrency"
          horizontal
        /> -->

        <div class="row">
          <div class="col-sm-3 pr-4" />
          <div class="col-sm-9">
            <ButtonLoading
              color="primary"
              :loading="isLoading"
              :disabled="!isFormEdited && !formEdited"
              horizontal
              size="sm"
              label=""
              @click="updateScheduling"
            >
              Update
            </ButtonLoading>
          </div>
        </div>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { showSuccessMessage } from '@/notification-utils'
import { required, minValue } from 'vuelidate/lib/validators'
import _ from 'lodash'
import moment from 'moment'
import formMixins from '@/mixins/form-mixins'
import formGenericMixin from '@/mixins/form-generic-mixin'

const indexToDayMapping = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export default {
  name: 'Scheduling',
  mixins: [formMixins, formGenericMixin],
  props: {
    campaignId: {
      type: String,
      default: ''
    },
    routingDetails: {
      type: Object,
      default: () => {}
    },

    accountTimeZoneId: {
      type: String,
      default: ''
    }
  },
  validations() {
    return {
      scheduleDetailsLocal: {
        timeZoneId: {
          required
        },
        concurrencyCap: {
          required,
          minValue: minValue(1)
        },
        hourlyConcurrencyCap: {
          required,
          minValue: minValue(1)
        }
      },
      isConcurrencyCapEnabled: {},
      isHourlyConcurrencyEnabled: {},
    }
  },
  data() {
    return {
      formEdited: false,
      isLoading: false,
      submitted: false,
      isConcurrencyCapEnabled: false,
      isHourlyConcurrencyEnabled: false,
      newBreakForm: {
        startTime: {
          hour: 9,
          minute: 0
        },
        lengthInMin: 1
      },
      scheduleDetailsLocal: {},
      hoursOfOperationType: 'basic',
      items: [
        {
          days: 'Monday - Sunday',
          open: '09:00',
          close: '21:00',
          breaks: []
        }
      ],
      fields: [
        { key: 'days', label: 'Days' },
        { key: 'dayOpen', label: 'Open' },
        { key: 'open', label: 'Open' },
        { key: 'close', label: 'Close' },
        { key: 'breaks', label: 'Breaks' },
      ],
    }
  },
  computed: {
    ...mapState('timezones', ['timezones']),
    timeZonesList() {
      const timeZonesList = [...this.timezones]
      timeZonesList.unshift({ label: 'Please select', value: null })
      return timeZonesList
    },
    hoursOfOperation() {
      const backendStructure = this.scheduleDetailsLocal.hoursOfOperation
      
      if (backendStructure == null) {
        return null
      }

      
      if (this.hoursOfOperationType === 'basic') {
        const firstEntry = _.cloneDeep(backendStructure[0])

        return [
          {
            days: 'Monday - Sunday',
            isOpen: true,
            open: firstEntry?.openTime || { hour: 9, minute: 0 },
            close: firstEntry?.closeTime || { hour: 23, minute: 0 },
            breaks: firstEntry?.breaks || [],
            _toggled: this.scheduleDetailsLocal.hoursOfOperation[0]?._toggled
          }
        ]
      } else {
        return backendStructure.map((ops, idx) => {
          return {
            days: indexToDayMapping[idx],
            isOpen: !ops?.isClosed,
            open: ops?.openTime || { hour: 9, minute: 0 },
            close: ops?.closeTime || { hour: 23, minute: 0 },
            breaks: ops?.breaks || [],
            _toggled: this.scheduleDetailsLocal.hoursOfOperation[idx]._toggled
          }
        })
      }
    },

    useHoursOfOperation: {
      get() {
        return this.scheduleDetailsLocal.hoursOfOperation != null
      },
      set(value) {
        if (value) {
          this.scheduleDetailsLocal.hoursOfOperation = this.scheduleDetailsLocal.hoursOfOperation != null && this.scheduleDetailsLocal.hoursOfOperation.length ? this.scheduleDetailsLocal.hoursOfOperation : [
            {
              openTime: { hour: 9, minute: 0 },
              closeTime: { hour: 23, minute: 0 },
              breaks: [],
              isClosed: false
            }
          ]
        } else {
          this.scheduleDetailsLocal.hoursOfOperation = null
        }
      }
    }
  },
  watch: {
    routingDetails: {
      immediate: true,
      handler(newValue) {
        this.scheduleDetailsLocal = _.cloneDeep(newValue.schedule)
        this.scheduleDetailsLocal.timeZoneId = this.scheduleDetailsLocal.timeZoneId ?? this.accountTimeZoneId

        this.hoursOfOperationType = extractHoursOfOperationTypeFromStructure(
          this.scheduleDetailsLocal.hoursOfOperation
        )
      }
    },

    'scheduleDetailsLocal.hoursOfOperation'(newValue) {
      this.hoursOfOperationType = extractHoursOfOperationTypeFromStructure(
          newValue
        )
    }
  },
  created() {
    this.isConcurrencyCapEnabled =
      this.routingDetails.schedule.concurrencyCap != null
    this.isHourlyConcurrencyEnabled =
      this.routingDetails.schedule.hourlyConcurrencyCap != null
    this.formEdited = false
  },
  methods: {
    ...mapActions('campaign', ['updateCampaignDetails']),
    async updateScheduling() {
      this.submitted = true
      const campaignId = this.campaignId
      const updateObject = {
        routing: {
          ...this.routingDetails,
          schedule: {
            ...this.scheduleDetailsLocal,
            hoursOfOperation: transformHoursOfOperationToBackendStructure(
              _.cloneDeep(this.hoursOfOperation),
              this.hoursOfOperationType
            )
          }
        }
      }
      try {
        this.isLoading = true
        await this.updateCampaignDetails({ id: campaignId, updateObject })
        showSuccessMessage('Updated schedule settings')
        this.resetFormDirtyState()
      } catch (error) {
        console.error(error.message)
      } finally {
        this.isLoading = false
      }
    },
    showAdvanced() {
      this.$v.$touch()
      this.hoursOfOperationType = 'advanced'

      const firstEntry = this.scheduleDetailsLocal.hoursOfOperation[0]

      indexToDayMapping.forEach((day, idx) => {
        this.scheduleDetailsLocal.hoursOfOperation[idx] = _.cloneDeep(firstEntry)
      })
    },
    showBasic() {
      this.$v.$touch()
      this.hoursOfOperationType = 'basic'
    },
    toggleDetails(item, index) {
      this.$set(this.scheduleDetailsLocal.hoursOfOperation[index], '_toggled', !item._toggled)
    },
    addBreak(item, index) {
      this.$v.$touch()
      this.scheduleDetailsLocal.hoursOfOperation[index].breaks.push({
        startTime: this.newBreakForm.startTime,
        lengthInMin: this.newBreakForm.lengthInMin
      })
      this.resetNewBreakForm()
      this.toggleDetails(item, index)
    },
    cancelAddingBreak(item, index) {
      this.resetNewBreakForm()
      this.toggleDetails(item, index)
    },
    resetNewBreakForm() {
      this.newBreakForm = {
        startTime: {
          hour: 9,
          minute: 0
        },
        lengthInMin: 1
      }
    },
    getFormattedTime({ minute, hour }) {
      return `${moment(hour, 'HH').format('HH')}:${moment(minute, 'mm').format(
        'mm'
      )}`
    },
    removeBreak(dayIndex, breakIdx) {
      this.$v.$touch()
      return this.scheduleDetailsLocal.hoursOfOperation[dayIndex].breaks.splice(breakIdx, 1)
    },
    updateHourlyConcurrency(value) {
      this.formEdited = true
      this.scheduleDetailsLocal.hourlyConcurrencyCap = value ? 1 : null
    },
    updateMaxConcurrency(value) {
      this.formEdited = true
      this.scheduleDetailsLocal.concurrencyCap = value ? 1 : null
    },

    handleOpenCloseDay(index, open) {
      this.scheduleDetailsLocal.hoursOfOperation[index].isClosed = !open
      this.$v.$touch()
    }
  }
}

function extractHoursOfOperationTypeFromStructure(hoursOfOperationBackend) {
  if (!hoursOfOperationBackend || hoursOfOperationBackend.length == 0) {
    return 'basic'
  }
  const firstEntry = hoursOfOperationBackend[0]

  let type = 'basic'

  for (const entry of hoursOfOperationBackend) {
    if (!_.isEqualWith(entry, firstEntry, function (objValue, othValue) {
      let modObjValue = {
        ...objValue,
        _toggled: undefined
      }

      let modOthValue = {
        ...othValue,
        _toggled: undefined
      }

      return _.isEqual(modObjValue, modOthValue)
    })) {
      type = 'advanced'
    }
  }

  return type
}

function transformHoursOfOperationToBackendStructure(hoursOfOperation, type) {
  if (!hoursOfOperation) {
    return null
  }
  
  let entryValue = hoursOfOperation[0]
  
  if (type === 'advanced') {
    entryValue = null
  }

  const hoursOfOperationBackend = []


  for (let i = 0; i < 7; i++) {
    const finalEntry = entryValue || hoursOfOperation[i]

    const entry = {
      openTime: finalEntry.open,
      closeTime: finalEntry.close,
      breaks: finalEntry.breaks,
      inverted: false,
      isClosed: type === 'advanced' ? !finalEntry.isOpen : false
    }
    hoursOfOperationBackend.push(entry)
  }

  return hoursOfOperationBackend
}
</script>

<style lang="scss" scoped></style>
