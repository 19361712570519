<template>
  <div>  
    <CCard
      v-if="hasBlockedChannels(accountId)"
    >
      <CCardHeader>
        <slot name="header">        
          <CIcon
            name="cil-LockLocked"
            class="mr-1"
          /> {{ pageTitle }}
        </slot>      
      </CCardHeader>
  
      <CCardBody>
        <div
          v-if="isLoading"
          :style="{ height: '80vh' }"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <CSpinner
            class="custom-spinner"
            color="info"
            grow
          />
        </div>
        <div v-else>        
          <CDataTable                  
            small
            fixed
            border          
            :items="blockedChannels"
            :fields="fields"
            :items-per-page="10"       
            :pagination="{align: 'center'}"
          >
            <template #name="{item}">
              <td>
                {{ item.encodedName }}
              </td>
            </template>
            <template #buyerAccount="{item}">
              <td>
                {{ item.buyerAccountName }}
              </td>
            </template>
            <template #sellerAccount="{item}">
              <td>
                {{ item.sellerAccountName }}
              </td>
            </template>
            <template #category="{item}">
              <td>
                {{ item.categoryName }}
              </td>
            </template>
            <template #trafficSource="{item}">
              <td>
                {{ item.trafficSourceName }}
              </td>
            </template>
            <template #country="{item}">
              <td>
                {{ item.countryCode }}
              </td>
            </template>
            <template #language="{item}">
              <td>
                {{ item.languageCode }}
              </td>
            </template>
            <template #Actions="{item}">
              <td>
                <CButton
                  color="success"
                  size="sm"
                  @click="selectedChannelItem = item; showUnblockChannelModal = true"
                >
                  Unblock
                </CButton>              
              </td>
            </template>
          </CDataTable>
        </div>
      </CCardBody>   
    </CCard>

    <CModal
      
      title="Confirm unblock channel"
      color="warning"
      :show.sync="showUnblockChannelModal"
    >
      <p class="alert alert-warning">
        Are you sure you want to unblock <strong>{{ selectedChannelItem.encodedName }}</strong> channel for seller <strong>{{ selectedChannelItem.sellerAccountName }}</strong>?
      </p>

      <template #footer>
        <CButton
          color="light"
          @click="showUnblockChannelModal = false"
        >
          Cancel
        </CButton>

        <ButtonLoading
          color="success"
          @click="confirmUnblockChannel"
        >
          Unblock
        </ButtonLoading>        
      </template>
    </CModal>
  </div>  
</template>  
<script>

import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import { mapActions, mapGetters } from 'vuex'
  
  export default {
    name: 'BuyerBlockedChannels',
  
    props: {
      pageTitle: {
        type: String,
        default: 'Blocked Channels'
      },

      accountId: {
        type: String,
        default: ''
      },
    },
  
    data() {
      return {
        isLoading: false,
        blockedChannels: [],
        fields: Object.freeze([
          { key: 'encodedName', label: 'Channel', },
          { key: 'buyerAccount', label: 'Buyer',  },
          { key: 'sellerAccount', label: 'Seller',  },
          { key: 'category', label: 'Category',  },
          { key: 'trafficSource', label: 'TrafficSource',  },
          { key: 'country', label: 'Country' },
          { key: 'language', label: 'Language' },
          { key: 'Actions', filter: false }
        ]),
        showUnblockChannelModal: false,
        selectedChannelItem: {}
      }
    },
  
    computed: {
        ...mapGetters('salesChannel', [
            'hasBlockedChannels'
        ]),  
    },

    async created() {
        this.isLoading = true
        try {
            this.blockedChannels = await this.getChannels(this.accountId)
        }
        catch {
            showErrorMessage('Failed to load buyer blocked channels')
        }
        finally {
            this.isLoading = false
        }
    },

    async destroyed() {
        this.resetBlockedChannels()
    },
  
    methods: {
        ...mapActions('salesChannel', ['getChannels', 'resetBlockedChannels','unblock']),

        async confirmUnblockChannel() {      
        try { 
          let request = {
            ...this.selectedChannelItem,
            country: this.selectedChannelItem.countryCode,
            language: this.selectedChannelItem.languageCode
          }
          await this.unblock({ payload: request })
          showSuccessMessage('Successfully unblocked channel')
          this.blockedChannels = await this.getChannels(this.accountId)
        } catch(error) {
          showErrorMessage(error)
        }
        finally {
          this.showUnblockChannelModal = false
        }
      },   

    },
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>