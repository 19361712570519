<template>
  <CForm @submit.prevent>
    <h1>Multi-factor Authentication</h1>
    <p class="text-muted">
      Select MFA type
    </p>


    <div class="form-group">
      <div class="mb-2">
        MFA Type
      </div>
      <CInputRadioGroup
        :options="options"
        :custom="true"
        :checked.sync="mfaType"
        :label="'MFA Type'"
      />
    </div>

    <CRow>
      <CCol
        col="6"
        class="text-left"
      >
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          type="submit"
          @click="onSubmit"
        >
          Next
        </ButtonLoading>
        <ButtonLoading
          class="ml-3"
          color="secondary"
          type="button"
          @click="goBackToLoginPage"
        >
          Back
        </ButtonLoading>
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import authViewMixin from '@/mixins/auth-view-mixin'
import { MFA_STAGES } from '@/store/modules/auth.store'
import loginMixin  from '@/mixins/login-mixin'

export default {
  name: 'LoginChooseMFAType',

  mixins: [authViewMixin, loginMixin],

  data() {
    return {
      options: [
        {
          label: 'SMS',
          value: MFA_STAGES.SMS_MFA
        },
        {
          label: 'Authenticator App',
          value: MFA_STAGES.SOFTWARE_TOKEN_MFA
        }
      ],
      mfaType: MFA_STAGES.SMS_MFA,
      isLoading: false
    }
  },

   computed: {
    ...mapGetters('auth', [
      'currentAuthChallenge'
    ])
  },

  methods: {
    ...mapActions('auth', ['submitMFATypeSelected']),
    async onSubmit() {
      try {
        this.isLoading = true

        await this.submitMFATypeSelected(this.mfaType)

        if (!this.internal) {
          this.$router.push({
            name: 'LoginMFA'
          })
        }
      } finally {
        this.isLoading = false
      }
    },

  }
}
</script>

<style></style>
