import moment from 'moment'

import CallLogsApi from '@/api/call-logs.api'
import { getDateRangeInTimeZone, splitLines } from '@/utils'

function getValues(input) {
  if (!input) {
    return []
  }

  if (input.includes(',')) {
    return input.split(',').map(i => i.trim())
  }

  return splitLines(input).map(i => i.trim())
}

const state = {
  /**
   * @type {Array<import('../call').Call>}
   */
  calls: [],

  /**
   * Comma separated caller Ids which admins want to search
   */
  searchForCallerIds: '',

  /**
   * Comma separated call Ids which admins want to search
   */
  searchForCallIds: '',

  dateFrom: moment().subtract(1, 'week').toDate(),
  dateTo: moment().endOf('day').toDate(),

  isLoading: false
}


/**
* @type {import('vuex').GetterTree<typeof state>}
*/
const getters = {
  callerIds(state) {
    return getValues(state.searchForCallerIds)
  },

  callIds(state) {
    return getValues(state.searchForCallIds)
  }
}


/**
* @type {import('vuex').ActionTree<typeof state>}
*/
const actions = {
  async searchForCalls({ getters, state, commit }) {
    const dateRange = getDateRangeInTimeZone({
      startDate: state.dateFrom,
      endDate: state.dateTo
    })

    try {
      commit('SET_LOADING', true)

      const response = await CallLogsApi.searchForCalls(getters.callerIds, getters.callIds, dateRange.startDate, dateRange.endDate)

      commit('SET_CALLS', response.data)

      return response
    } finally {
      commit('SET_LOADING', false)
    }
  }
}


/**
* @type {import('vuex').MutationTree<typeof state>}
*/
const mutations = {
  SET_CALLS(state, calls) {
    state.calls = calls || []
  },

  SET_CALLER_IDS(state, callerIds) {
    state.searchForCallerIds = callerIds
  },

  SET_CALL_IDS(state, callIds) {
    state.searchForCallIds = callIds
  },

  SET_DATE_FROM(state, dateFrom) {
    state.dateFrom = dateFrom
  },

  SET_DATE_TO(state, dateTo) {
    state.dateTo = dateTo
  },

  SET_LOADING(state, loading) {
    state.isLoading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}