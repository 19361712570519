<template>
  <div>
    <CCard
      v-if="selectedRow.testOnly"
      class="test-only-banner bg-danger p-3 text-white"
    >
      <strong>
        <CIcon
          name="cilWarning"
          class="mr-1"
        />
        This is a test-only category
      </strong>
    </CCard>

    <CCard>
      <CCardHeader data-cy="header">
        <div class="d-flex align-content-center">
          Edit Category

          <CButton
            color="info"
            size="sm"
            class="ml-auto"
            target="_blank"
            :to="`/dashboard/categories/supply-demand?categoryId=${selectedRow.id}`"
          >
            View Supply Demand

            <CIcon
              name="cil-external-link"
              size="custom"
              class="ml-1"
            />
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CInput
            data-cy="input-item-id"
            label="ID"
            :value="selectedRow.id"
            horizontal
            plaintext
          />
          <CInput
            v-model="form.name"
            data-cy="input-name"
            label="Name"
            name="Name"
            placeholder="Name"           
            :is-valid="checkIfValid(['form', 'name'])"
            :invalid-feedback="nameRequired"
            horizontal                     
          />
          <CInput
            id="item-sub-id"
            data-cy="input-sub-id"
            label="SubId"
            :value="selectedRow.subId"
            horizontal
            plaintext
          />
          <CInput
            data-cy="input-parent-category"
            label="Parent Category"
            :value="getSafe(() => selectedRow.parentCategory.name)"
            horizontal
            plaintext
          />
          <CInput
            data-cy="input-parent-category"
            label="Parent Category Status"
            horizontal
            plaintext
          >
            <template
              #prepend
            >
              <CBadge
                style="font-size: 0.9rem;"
                :color="getBadge(getSafe(() => selectedRow.parentCategory.status))"
              >
                {{ getSafe(() => selectedRow.parentCategory.status) }}
              </CBadge>
            </template>
          </CInput>

          <div class="form-group form-row">
            <label class="col-form-label col-sm-3 pr-4">Languages</label>

            <div class="col-sm-5">
              <v-multiselect-listbox
                v-if="languages.length"
                v-model="form.languages"
                :options="languages"
                :reduce-display-property="(option) => option.name"
                :reduce-value-property="(option) => option.id"
                search-input-class="custom-form-input custom-form-input--sm"
                search-options-placeholder="Search Language"
                selected-options-placeholder="Search selected"
                selected-no-options-text="No language selected"
                hide-search-inputs
                @change="handleLanguageChange"
              />

              <div
                v-if="showRemoveLanguageWarning"
                class="invalid-feedback d-flex mb-3 mt-2"
              >
                You cannot remove existing language.
              </div>
            </div>
          </div>

          <CTextarea
            v-model="form.description"
            data-cy="input-description"
            label="Description"
            placeholder="Enter description"
            horizontal
            rows="3"
          />
          <DatePickerInput
            id="expiration-date"
            v-model="form.expirationDate"
            :min-date="new Date()"
            data-cy="input-expiration-date"
            label="Expiration Date"
            placeholder="Choose expiration date"
            horizontal
            readonly
          />

          <CSelect
            data-cy="input-status"
            label="Status"
            horizontal
            :value.sync="$v.form.status.$model"
            :options="categoryStatusCodes"
            placeholder="Please select"
            :is-valid="checkIfValid(['form', 'status'])"
            :invalid-feedback="pleaseSelect('status')"
          />

          <legend>Bid Preferences</legend>          

          <CInput
            v-model="$v.form.bidPreferences.minProfitAmount.$model"
            type="number"
            label="Minimum Profit Amount"
            name="Minimum Profit Amount"
            placeholder="Minimum Profit Amount"
            horizontal
            :is-valid="checkIfValid(['form', 'bidPreferences', 'minProfitAmount'])"
            :invalid-feedback="minProfitAmountInvalid"
          />

          <CInput
            v-model="$v.form.bidPreferences.maxBid.$model"
            type="number"
            label="Max Bid"
            name="Max Bid"
            placeholder="Max Bid"
            horizontal
            :is-valid="checkIfValid(['form', 'bidPreferences', 'maxBid'])"
            :invalid-feedback="maxBidInvalid"
          />

          <legend>Adjustment of payout minimum required duration</legend>

          <CRow
            form
            class="form-group"
          >
            <CCol
              tag="label"
              sm="3"
              class="col-form-label"
            >
              Set Maximum Required Duration
            </CCol>
            <CCol
              sm="9"
              class="mt-1"
            >
              <CSwitch
                shape="pill"
                :checked.sync="setMaxRequiredDuration"
                color="primary"
                @update:checked="onToggleSetMaxRequiredDuration"
              />
            </CCol>
          </CRow>

          <CInput
            v-if="setMaxRequiredDuration"
            v-model.number="$v.form.maxDurationRequiredForConversionInSeconds.$model"
            type="number"
            label="Maximum Required Duration in seconds"
            name="Maximum Required Duration"
            placeholder="Enter duration in seconds"
            :horizontal="{ label: 'col-sm-3', input: 'col-sm-3 col-12'}"
            :is-valid="checkIfValid(['form', 'maxDurationRequiredForConversionInSeconds'])"
            :description="form.maxDurationRequiredForConversionInSeconds && `${(form.maxDurationRequiredForConversionInSeconds/60).toFixed(2)} minutes`"
            invalid-feedback="Maximum Required Duration should be greater than 1 second"
            @change="form.maxDurationRequiredForConversionInSeconds = parseInt(form.maxDurationRequiredForConversionInSeconds) || 1"
          />

          <CInput
            v-model="$v.form.callRequiredDurationAdjustment.minimumIncreaseInSeconds.$model"
            type="number"
            label="Minimum number of seconds to increase by"
            name="Minimum number of seconds to increase by"
            placeholder="Minimum number of seconds to increase by"
            horizontal
            :is-valid="checkIfValid(['form', 'callRequiredDurationAdjustment', 'minimumIncreaseInSeconds'])"
            :invalid-feedback="minimumIncreaseInSecondsInvalid"
          />

          <CInput
            v-model="$v.form.callRequiredDurationAdjustment.maximumIncreaseInSeconds.$model"
            type="number"
            label="Maximum number of seconds to increase by"
            name="Maximum number of seconds to increase by"
            placeholder="Maximum number of seconds to increase by"
            horizontal
            :is-valid="checkIfValid(['form', 'callRequiredDurationAdjustment', 'maximumIncreaseInSeconds'])"
            :invalid-feedback="maximumIncreaseInSecondsInvalid"
          />

          <legend>Flags</legend>

          <div>
            <div
              v-if="flags && flags.length > 0"
            >          
              <CRow
                v-for="(flag, index) in flags"
                :key="`custom-header-${index}`"
                form
                class="form-group"
              >
                <CInputCheckbox
                  :label="flag.name"
                  :checked.sync="flag.checked"
                  horizontal
                  class="mt-2 ml-1"
                />
              </CRow>
            </div>
          </div>
        </CForm>
      </CCardBody>
      <CCardFooter>
        <CButton
          :disabled="isLoading"
          type="submit"
          data-cy="button-submit"
          size="sm"
          color="primary"
          @click.prevent="
            submitUpdateForm({ updateFn: update, entity: 'categories' })
          "
        >
          <CSpinner
            v-if="isLoading"
            color="light"
            size="sm"
          />
          <CIcon
            v-else
            name="cil-check-circle"
          /> Submit
        </CButton>
        <CButton
          class="ml-2"
          :disabled="isLoading"
          data-cy="button-back"
          type="reset"
          size="sm"
          color="danger"
          @click="backToListView('categories')"
        >
          <CIcon name="cil-arrow-circle-left" /> Back
        </CButton>
      </CCardFooter>
    </CCard>

    <BidModifiers
      :parent-object="bidModifiersParentObject"
    />    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapActions, mapState } from 'vuex'
import { minValue, required, between } from 'vuelidate/lib/validators'

import formMixins from '@/mixins/form-mixins'

import { getBadge } from '@/components/common/Table.vue'
import { MAX_BID_MINIMUM, MIN_PROFIT_AMOUNT_MINIMUM,
  MINIMUM_INCREASE_IN_SECONDS, MAXIMUM_INCREASE_IN_SECONDS, CATEGORY_FLAGS } from '@/store/modules/category.store'

import BidModifiers from '@/views/BidModifiers/BidModifiers.vue'
import BidModifierParentType from '@/enums/BidModifierParentType.js'

export default {
  name: 'CategoryEdit',

  components: {
    BidModifiers
  },

  mixins: [formMixins],

  validations() {
    return {
      form: {
        status: {
          required
        },

        name: {
          required
        },

        languages: {
          required
        },

        bidPreferences: {          

          minProfitAmount: {
            required,
            minValue: minValue(MIN_PROFIT_AMOUNT_MINIMUM)
          },

          maxBid: {
            required,
            minValue: minValue(MAX_BID_MINIMUM)
          }
        },

        callRequiredDurationAdjustment: {
          minimumIncreaseInSeconds: {
            required,
            between: between(MINIMUM_INCREASE_IN_SECONDS, MAXIMUM_INCREASE_IN_SECONDS)
          },

          maximumIncreaseInSeconds: {
            required,
            between: between(this.form.callRequiredDurationAdjustment.minimumIncreaseInSeconds, MAXIMUM_INCREASE_IN_SECONDS) 
          }
        },

        maxDurationRequiredForConversionInSeconds: {
          minValue() {
            if (this.setMaxRequiredDuration) {
              return minValue(1)
            }

            return minValue(0)
          }
        }
      }
    }
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        expirationDate: null,
        status: null,
        bidPreferences: {          
          minProfitAmount: 1,
          maxBid: 250
        },
        callRequiredDurationAdjustment: {
          minimumIncreaseInSeconds: 0,
          maximumIncreaseInSeconds : 0
        },
        maxDurationRequiredForConversionInSeconds: 0,
        languages: [],
        flags: []
      },
      flags:[],

      setMaxRequiredDuration: false,
      
      showRemoveLanguageWarning: false,

      minProfitAmountInvalid: `Minimum Profit Amount should be more than $${MIN_PROFIT_AMOUNT_MINIMUM}`,

      maxBidInvalid: `Max Bid should be more than $${MAX_BID_MINIMUM}`,

      nameRequired: `Category name is required`,

      minimumIncreaseInSecondsInvalid: `Minimum increase in seconds should be between ${MINIMUM_INCREASE_IN_SECONDS} and ${MAXIMUM_INCREASE_IN_SECONDS}`,

      maximumIncreaseInSecondsInvalid: `Maximum increase in seconds should be between minimum increase in seconds and ${MAXIMUM_INCREASE_IN_SECONDS}`
    }
  },
  computed: {
    ...mapState('category', ['selectedRow']),
    ...mapState(['statusCodes']),
    ...mapState('language', ['languages']),

    bidModifiersParentObject() {
      return {
        parentObjectType: BidModifierParentType.CATEGORY,
        id: this.selectedRow.id,
        alwaysOn: true
      }
    },

    categoryStatusCodes() {
      return [...this.statusCodes, 'InReview']
    }
  },
  async created() {
    this.isLoading = true

    await this.findOne(this.$route.params.id)
    await this.listLanguages()

    this.fillFormValues()

    this.isLoading = false
  },
  methods: {
    ...mapActions('category', ['findOne', 'update']),
    ...mapActions('language', { listLanguages: 'list' }),

    fillFormValues() {
      Object.keys(this.form).forEach(key => {
        if (key === 'expirationDate') {
          this.form[key] = this.selectedRow[key]
            ? this.selectedRow[key].slice(0, 10)
            : null
        } else {
          this.form[key] = cloneDeep(this.selectedRow[key])
        }
      })

      this.setMaxRequiredDuration = (this.form.maxDurationRequiredForConversionInSeconds != undefined && this.form.maxDurationRequiredForConversionInSeconds > 0) ?? false

      this.form.languages = this.selectedRow.languages.map(l => l.id)
      
      this.setFlags()
    },

    onToggleSetMaxRequiredDuration(setMaxRequiredDuration) {
      if (setMaxRequiredDuration) {
        this.form.maxDurationRequiredForConversionInSeconds = 60 * 10 // 10 mins
      } else {
        this.form.maxDurationRequiredForConversionInSeconds = 0
      }
    },

    async submitUpdateForm({ updateFn, entity }) {
      this.submitted = true

      if (this.$v && this.$v.form) {
        this.$v.form.$touch()

        if (!this.isValid) {
          return
        }
      }

      this.handleLanguageChange()

      if (this.showRemoveLanguageWarning) {
        return
      }

      this.mapToFormFlags()

      const updatedObject = Object.assign({}, this.selectedRow, this.form)
      
      try {
        this.isLoading = true
        await updateFn(updatedObject)
        this.$router.push(`/dashboard/${entity}`)
      } catch (error) {
        console.error(error)
        this.submitted = false
      } finally {
        this.isLoading = false
      }
    },

    handleLanguageChange() {
      if (this.selectedRow.languages.length > this.form.languages.length || this.selectedRow.languages.filter(x => !this.form.languages.includes(x.id)).length) {
        this.showRemoveLanguageWarning = true
      } else {
        this.showRemoveLanguageWarning = false
      }
    },

    setFlags() {
      const defaultFlags = CATEGORY_FLAGS.map(x => {
        return {
          name: x, 
          checked: false
        }
      })

      defaultFlags.forEach(x => {
        const flag = this.form.flags?.find(el => el === x.name)
        if (flag) {
          x.checked = true
        }
      })

      this.flags = defaultFlags
      this.formEdited = true
    },

    mapToFormFlags() {      
      this.form.flags = this.flags.filter(x => x.checked).map(x => x.name)
    },

    getBadge
  },
}
</script>

<style scoped lang="scss">
.test-only-banner {
  position: sticky;
  top: 60px;
  font-size: 1.2rem;
  z-index: 1;
}
</style>
