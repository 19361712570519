<template>
  <div>
    <CCard>
      <CCardHeader>
        <div class="d-flex align-items-center mb-3">
          <CSwitch
            variant="3d"
            color="primary"
            horizontal
            size="sm"
            class="mr-2"
            :checked.sync="includeTestOnly"
            @update:checked="loadSystemAccountSummary"
          />
          <h5 class="m-0">
            {{ includeTestOnly ? 'Test-Only Account' : 'Main Account' }} 
          </h5>
        </div>
      </CCardHeader>
      <CCardBody>
        <div
          v-if="isLoading"
          :style="{ height: '80vh' }"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <CSpinner
            style="width:4rem;height:4rem;"
            color="info"
            grow
          />
        </div>
        <div v-else>
          <CRow>
            <CCol 
              col="4" 
              sm="3"
            >
              <CWidgetProgressIcon
                :header="formatAmount(systemAccountSummary.availableBalance)"
                text="Total Account Balance"
                color="info"
              />
            </CCol>
            <CCol 
              col="4" 
              sm="3"
            >
              <CWidgetProgressIcon
                :header="isLoadingSystemAccountGrossMargin ? 'Loading...': formatPercentage(grossMargin)"
                text="Gross margin"
                color="info"
              />
            </CCol>
            <CCol 
              col="4" 
              sm="3"
            >
              <CWidgetProgressIcon
                :header="isLoadingSystemAccountGrossMargin ? 'Loading...': formatAmount(systemAccountGrossMargin.totalConversionAmount)"
                text="Amount call conversions"
                color="success"
              />
            </CCol>
            <CCol 
              col="4" 
              sm="3"
            >
              <CWidgetProgressIcon
                :header="isLoadingSystemAccountGrossMargin ? 'Loading...': formatAmount(systemAccountGrossMargin.totalPayoutAmount)"
                text="Amount call payouts"
                color="danger"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <CWidgetIcon
                :header="formatAmount(systemAccountSummary.buyerPendingBalance)"
                text="To Receive: Buyer Pending Payments"
                color="success"
              >
                <CIcon name="cil-arrow-thick-top" />
              </CWidgetIcon>
            </CCol>
            <CCol 
              col="12" 
              sm="6"
            >
              <CWidgetIcon
                :header="formatAmount(systemAccountSummary.sellerPendingBalance)"
                text="To Payout: Seller Pending Payments"
                color="danger"
              >
                <CIcon name="cil-arrow-thick-bottom" />
              </CWidgetIcon>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CWidgetIcon
                :header="formatAmount(systemAccountSummary.buyerHoldingBalance)"
                text="To receive: Dispute Holding"
                color="warning"
              >
                <CIcon name="cil-arrow-thick-to-top" />
              </CWidgetIcon>
            </CCol>
            <CCol 
              col="12" 
              sm="6"
            >
              <CWidgetIcon
                :header="formatAmount(systemAccountSummary.sellerHoldingBalance)"
                text="To payout: Dispute Holding"
                color="warning"
              >
                <CIcon name="cil-arrow-thick-from-top" />
              </CWidgetIcon>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <CWidgetDropdown 
                :header="formatAmount(systemAccountSummary.buyerCashBalance)" 
                text="Buyer Total Account Balances" 
                color="info"
              >
                <footer>
                  <CIcon name="cil-scrubber" />
                </footer>
              </CWidgetDropdown>
            </CCol>
            <CCol 
              col="12" 
              sm="6"
            >
              <CWidgetDropdown 
                :header="formatAmount(systemAccountSummary.sellerCashBalance)" 
                text="Seller Total Earnings" 
                color="primary"
              >
                <footer>
                  <CIcon name="cil-audio" />
                </footer>
              </CWidgetDropdown>
            </CCol>
          </CRow>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import formGenericMixin from '@/mixins/form-generic-mixin'
import { formatDate, formatAmount, formatPercentage } from '@/utils'

export default {
  name: 'AccountSummary',

  mixins: [formGenericMixin],

  data() {
    return {
      isLoading: false,
      isLoadingSystemAccountGrossMargin: false,
      startDate: new Date(),
      endDate: new Date(),
      includeTestOnly: false
    }
  },

  computed: {
    ...mapState('billing', [
      'systemAccountSummary',
      'systemAccountGrossMargin'
    ]),

    grossMargin(){
      if (!this.systemAccountGrossMargin){
        return null
      }

      let receivableAmount = this.systemAccountGrossMargin.totalConversionAmount
      let payableAmount = this.systemAccountGrossMargin.totalPayoutAmount
      let delta = (receivableAmount - payableAmount)/receivableAmount || 0
      return delta
    },
  },

  async created() {
      var date = new Date()
      this.startDate = new Date(date.getFullYear(), 1, 1)
      this.endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)

      await this.loadSystemAccountSummary()
  },

  methods: {
    ...mapActions('billing', [
      'getSystemAccountSummary',
      'getSystemAccountGrossMargin'
    ]),

    async loadSystemAccountSummary(){
      this.isLoading = true
      this.isLoadingSystemAccountGrossMargin = true

      try {
        await Promise.any([
          this.getSystemAccountSummary({includeTestOnly: this.includeTestOnly}),
          this.getSystemAccountGrossMargin({ 
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString(),
            includeTestOnly: this.includeTestOnly
          }).then(() => this.isLoadingSystemAccountGrossMargin = false)
        ])
      }
      finally {
        this.isLoading = false
      }
    },

    formatDate,
    formatAmount,
    formatPercentage
  }
}
</script>

<style></style>
