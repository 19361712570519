<template>
  <div>
    <CCard>
      <CCardHeader>
        Create Seller Offer
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CInput
            v-model="form.name"
            data-cy="input-name"
            label="Name"
            horizontal
            placeholder="Seller Offer Name"
          />
          <div
            v-if="!nameSet"
            class="invalid-feedback d-flex mb-3 mt-2"
          >
            <CIcon
              name="cilWarning"
              class="mr-1"
            />
            Please enter meaningful name for the offer
          </div>

          <CTextarea
            v-model="form.description"
            data-cy="input-description"
            label="Description"
            placeholder="Enter description"
            horizontal
            rows="3"
          />

          <CSelect
            label="Category"
            horizontal
            :value.sync="form.categoryId"
            :options="allowedCategoriesFormatted"
            placeholder="Please select"
            invalid-feedback="Please select a category"
            @change="onCategoryChange"
          />

          <CSelect
            label="Language"
            horizontal
            :value.sync="form.languageId"
            :options="languagesList"
            placeholder="Please select"
            invalid-feedback="Please select a language"
            :is-valid="languageSet"
          />

          <CRow
            form
            class="form-group"
          >
            <CCol sm="3">
              Visibility
            </CCol>

            <CInputRadioGroup
              class="col-sm-9"
              :options="visibilityOptions"
              :checked.sync="form.visibility"
              inline
            />
          </CRow>

          <div
            v-if="!categorySet"
            class="invalid-feedback d-flex mb-3 mt-2"
          >
            <CIcon
              name="cilWarning"
              class="mr-1"
            />
            Please select the category for the offer
          </div>
          <CRow>
            <CCol
              sm="3"
              class="pr-3"
            >
              Affiliate Traffic Allowed?
            </CCol>
            <CCol sm="3">
              <CSwitch
                label-off="No"
                label-on="Yes"
                shape="pill"
                color="primary"
                data-cy="toggle-works-with-affiliates"
                horizontal
                add-label-classes=""
                add-input-classes="w-25"
                size="lg"
                :checked.sync="form.isAffiliateTrafficAllowed"
              />
            </CCol>
          </CRow>
          <CSelect
            label="Traffic Source Type"
            horizontal
            :value.sync="form.allowedTrafficSourceType"
            :options="trafficSourceTypes"
            placeholder="Please select"
            @input="changeTrafficSourceType"
          />
          <div
            v-if="!trafficSourcesTypeSet"
            class="invalid-feedback d-flex mb-3 mt-2"
          >
            <CIcon
              name="cilWarning"
              class="mr-1"
            />
            Please select allowed traffic source type for the offer
          </div>
          <div class="form-group form-row">
            <label class="col-form-label col-sm-3 pr-4">Allowed Traffic Sources</label>
            <div class="col-sm-9">
              <v-multiselect-listbox
                v-model="allowedTrafficSources"
                :options="trafficSourcesFiltered"
                :reduce-display-property="(option) => option.name"
                :reduce-value-property="(option) => option.id"
                :reduce-group-property="(option) => option.trafficSourceType"
                search-input-class="custom-form-input custom-form-input--sm"
                search-options-placeholder="Search source"
                selected-options-placeholder="Search selected"
                selected-no-options-text="No traffic source selected"
                highlight-diff
              />
              <div
                v-if="!trafficSourcesSet"
                class="invalid-feedback d-flex mb-3 mt-2"
              >
                <CIcon
                  name="cilWarning"
                  class="mr-1"
                />
                Please select allowed traffic sources for the offer
              </div>
            </div>
          </div>
        </CForm>
      </CCardBody>
      <CCardFooter>
        <CButton
          :disabled="!isFormValid"
          type="submit"
          data-cy="button-submit"
          size="sm"
          color="primary"
          @click.prevent="
            submitUpdateForm({ updateFn: create, entity: 'seller-offers' })
          "
        >
          <CSpinner
            v-if="submitted"
            color="light"
            size="sm"
          />
          <CIcon
            v-else
            name="cil-check-circle"
          /> Submit
        </CButton>
        <CButton
          class="ml-2"
          :disabled="submitted"
          data-cy="button-back"
          type="reset"
          size="sm"
          color="danger"
          @click="backToListView('seller-offers')"
        >
          <CIcon name="cil-arrow-circle-left" /> Back
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import {getDefaultSellerOffer} from '@/store/modules/sellerOffer.store'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import formMixins from '@/mixins/form-mixins'
import formGenericMixin from '@/mixins/form-generic-mixin'
import { OFFER_VISIBILITY_TYPES } from '@/store/modules/sellerOffer.store'

export default {
  name: 'SellerOfferEdit',
  mixins: [copyToClipboardMixin, formMixins, formGenericMixin],
  data() {
    return {
      form: {
        name: '',
        description: '',
        visibility: OFFER_VISIBILITY_TYPES.Public,
        status: null, //{"Disabled", "Active"}
        categoryId: null, //{name: '', id: ''}
        isAffiliateTrafficAllowed: false,
        languageId: null,
        allowedTrafficSourceType: '',
        allowedTrafficSources: [], //{name: '', id: ''}
      },

      trafficSourcesFiltered: [],

      visibilityOptions: [OFFER_VISIBILITY_TYPES.Public, OFFER_VISIBILITY_TYPES.Private],
    }
  },
  computed: {
    ...mapState('sellerOffer', ['selectedRow', 'trafficSourceTypes']),
    ...mapState('category', ['categories']),
    ...mapState('language', ['languages']),
    ...mapState('trafficSource', ['trafficSourceTypes']),

    ...mapGetters('trafficSource', {
      trafficSources: 'activeTrafficSources'
    }),
    
    nameSet() {
      return (
        this.form.name
      )
    },

    trafficSourcesSet() {
      return (
        this.form.allowedTrafficSources?.length
      )
    },

    trafficSourcesTypeSet() {
      return (
        this.form.allowedTrafficSourceType
      )
    },

    categorySet() {
      return (
        this.form.categoryId
      )
    },

    languageSet() {
      return Boolean(
        this.form.languageId
      )
    },

    isFormValid() {
      return (
        this.nameSet &&
        this.trafficSourcesSet && 
        this.trafficSourcesTypeSet &&
        this.categorySet &&
        this.languageSet
      )
    },

    allowedCategoriesFormatted() {
      return this.categories.map(category => {
        return {
          value: category.id,
          label: category.name
        }
      })
    },

    languagesList() {
      return this.categories?.find(c => c.id === this.form.categoryId)?.languages.map(l => ({ value: l.id, label: l.name }))
    },

    allowedTrafficSources: {
      get() {
        return this.form.allowedTrafficSources?.map(
          (ts) => ts.id
        )
      },

      set(value) {
        this.form.allowedTrafficSources = value.map((id) => {
          return {
            id,
          }
        })
      },
    },

  },

  async created() {
    this.isLoading = true

    await this.getAllCategories()
    await this.getAllTrafficSources()
    await this.getAllLanguages()

    if (this.$route.params.id) {
      await this.findOne(this.$route.params.id)
    } else {
      this.SET_SELECTED_ROW(getDefaultSellerOffer())
    }

    Object.keys(this.form).forEach(key => {
      this.form[key] = this.selectedRow[key]
    })

    this.trafficSourcesFiltered = this.trafficSources

    this.isLoading = false
  },
  methods: {
    ...mapActions('sellerOffer', ['findOne', 'create']),

    ...mapMutations('sellerOffer', [
      'SET_SELECTED_ROW'
    ]),

    ...mapActions('category', {
      getAllCategories: 'list',
    }),

    ...mapActions('language', {
      getAllLanguages: 'list',
    }),

    ...mapActions('trafficSource', {
      getAllTrafficSources: 'list',
    }),

    getStatusBadge(status) {
      switch (status) {
        case 'Active':
          return 'success'

        case 'Disabled':
        default:
          return 'secondary'
      }
    },

    changeTrafficSourceType(event) {
      const trafficSourceType = event.target.value.toString()
      const data = this.trafficSources.filter(x => {
        return x.trafficSourceType === trafficSourceType
      })
      this.trafficSourcesFiltered = data
      this.form.allowedTrafficSources = []
    },

    onCategoryChange() {
      this.form.languageId = null
    }

  }
}
</script>

<style lang="scss" scoped></style>