<template>
  <div>
    <h4>Buyer Accounts</h4>
    <CCard>
      <CInput
        v-model="filterString"
        class="mt-2 ml-2"
        placeholder="Type to search accounts by account Id, company name, representative name or email address"
        add-input-classes="col-6"
        :lazy="500"
        @update:value="searchBuyerAccountsWrapper"
      />

      <AccountsList
        v-if="buyerAccounts && buyerAccounts.length > 0"
        :is-loading="isLoading"
        :accounts="buyerAccounts"
        type="buyer"
      />
    </CCard>

    <h4 class="mt-5">
      Inactive Buyer Accounts
    </h4>

    <InactiveAccountsList
      type="buyer"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

import AccountsList from './AccountsList.vue'
import InactiveAccountsList from  '@/views/Accounts/InactiveAccountsList.vue'

export default {
  name: 'BuyerAccounts',

  title: 'Buyer Accounts',

  components: {
    AccountsList,
    InactiveAccountsList
  },

  props: {
    type: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapState('accounts', [
      'buyerAccounts',
      'buyerAccountsSearchString',
    ]),

    filterString: {
      get() {
        return this.buyerAccountsSearchString
      },
      set(value) {
        this.SET_BUYER_ACCOUNT_SEARCH_STRING(value)
      }
    }
  },

  async created () {
    this.isLoading = true
    try {
      await this.getApprovedBuyerAccounts()
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions('accounts', [
      'searchBuyerAccounts',
      'getApprovedBuyerAccounts'
    ]),

    ...mapMutations('accounts', [
      'SET_BUYER_ACCOUNT_SEARCH_STRING'
    ]),

    async searchBuyerAccountsWrapper() {
      this.isLoading = true
      try {
        if (this.filterString) {
          await this.searchBuyerAccounts(this.filterString)
        }
        else {
          await this.getApprovedBuyerAccounts()
        }

      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>