const state = {
  timezones: [
    {
      label: '(UTC-12:00) International Date Line West',
      value: 'Dateline Standard Time',
      supportDaylightSavings: false,
      utc_Offset: -12
    },
    {
      label: '(UTC-11:00) Coordinated Universal Time-11',
      value: 'UTC-11',
      supportDaylightSavings: false,
      utc_Offset: -11
    },
    {
      label: '(UTC-10:00) Aleutian Islands',
      value: 'Aleutian Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -10
    },
    {
      label: '(UTC-10:00) Hawaii',
      value: 'Hawaiian Standard Time',
      supportDaylightSavings: false,
      utc_Offset: -10
    },
    {
      label: '(UTC-09:30) Marquesas Islands',
      value: 'Marquesas Standard Time',
      supportDaylightSavings: false,
      utc_Offset: -9
    },
    {
      label: '(UTC-09:00) Alaska',
      value: 'Alaskan Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -9
    },
    {
      label: '(UTC-09:00) Coordinated Universal Time-09',
      value: 'UTC-09',
      supportDaylightSavings: false,
      utc_Offset: -9
    },
    {
      label: '(UTC-08:00) Baja California',
      value: 'Pacific Standard Time (Mexico)',
      supportDaylightSavings: true,
      utc_Offset: -8
    },
    {
      label: '(UTC-08:00) Coordinated Universal Time-08',
      value: 'UTC-08',
      supportDaylightSavings: false,
      utc_Offset: -8
    },
    {
      label: '(UTC-08:00) Pacific Time (US & Canada)',
      value: 'Pacific Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -8
    },
    {
      label: '(UTC-07:00) Arizona',
      value: 'US Mountain Standard Time',
      supportDaylightSavings: false,
      utc_Offset: -7
    },
    {
      label: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
      value: 'Mountain Standard Time (Mexico)',
      supportDaylightSavings: true,
      utc_Offset: -7
    },
    {
      label: '(UTC-07:00) Mountain Time (US & Canada)',
      value: 'Mountain Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -7
    },
    {
      label: '(UTC-06:00) Central America',
      value: 'Central America Standard Time',
      supportDaylightSavings: false,
      utc_Offset: -6
    },
    {
      label: '(UTC-06:00) Central Time (US & Canada)',
      value: 'Central Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -6
    },
    {
      label: '(UTC-06:00) Easter Island',
      value: 'Easter Island Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -6
    },
    {
      label: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
      value: 'Central Standard Time (Mexico)',
      supportDaylightSavings: true,
      utc_Offset: -6
    },
    {
      label: '(UTC-06:00) Saskatchewan',
      value: 'Canada Central Standard Time',
      supportDaylightSavings: false,
      utc_Offset: -6
    },
    {
      label: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
      value: 'SA Pacific Standard Time',
      supportDaylightSavings: false,
      utc_Offset: -5
    },
    {
      label: '(UTC-05:00) Chetumal',
      value: 'Eastern Standard Time (Mexico)',
      supportDaylightSavings: true,
      utc_Offset: -5
    },
    {
      label: '(UTC-05:00) Eastern Time (US & Canada)',
      value: 'Eastern Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -5
    },
    {
      label: '(UTC-05:00) Haiti',
      value: 'Haiti Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -5
    },
    {
      label: '(UTC-05:00) Havana',
      value: 'Cuba Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -5
    },
    {
      label: '(UTC-05:00) Indiana (East)',
      value: 'US Eastern Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -5
    },
    {
      label: '(UTC-05:00) Turks and Caicos',
      value: 'Turks And Caicos Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -5
    },
    {
      label: '(UTC-04:00) Asuncion',
      value: 'Paraguay Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -4
    },
    {
      label: '(UTC-04:00) Atlantic Time (Canada)',
      value: 'Atlantic Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -4
    },
    {
      label: '(UTC-04:00) Caracas',
      value: 'Venezuela Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -4
    },
    {
      label: '(UTC-04:00) Cuiaba',
      value: 'Central Brazilian Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -4
    },
    {
      label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
      value: 'SA Western Standard Time',
      supportDaylightSavings: false,
      utc_Offset: -4
    },
    {
      label: '(UTC-04:00) Santiago',
      value: 'Pacific SA Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -4
    },
    {
      label: '(UTC-03:30) Newfoundland',
      value: 'Newfoundland Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -3
    },
    {
      label: '(UTC-03:00) Araguaina',
      value: 'Tocantins Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -3
    },
    {
      label: '(UTC-03:00) Brasilia',
      value: 'E. South America Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -3
    },
    {
      label: '(UTC-03:00) Cayenne, Fortaleza',
      value: 'SA Eastern Standard Time',
      supportDaylightSavings: false,
      utc_Offset: -3
    },
    {
      label: '(UTC-03:00) City of Buenos Aires',
      value: 'Argentina Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -3
    },
    {
      label: '(UTC-03:00) Greenland',
      value: 'Greenland Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -3
    },
    {
      label: '(UTC-03:00) Montevideo',
      value: 'Montevideo Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -3
    },
    {
      label: '(UTC-03:00) Punta Arenas',
      value: 'Magallanes Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -3
    },
    {
      label: '(UTC-03:00) Saint Pierre and Miquelon',
      value: 'Saint Pierre Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -3
    },
    {
      label: '(UTC-03:00) Salvador',
      value: 'Bahia Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -3
    },
    {
      label: '(UTC-02:00) Coordinated Universal Time-02',
      value: 'UTC-02',
      supportDaylightSavings: false,
      utc_Offset: -2
    },
    {
      label: '(UTC-02:00) Mid-Atlantic - Old',
      value: 'Mid-Atlantic Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -2
    },
    {
      label: '(UTC-01:00) Azores',
      value: 'Azores Standard Time',
      supportDaylightSavings: true,
      utc_Offset: -1
    },
    {
      label: '(UTC-01:00) Cabo Verde Is.',
      value: 'Cape Verde Standard Time',
      supportDaylightSavings: false,
      utc_Offset: -1
    },
    {
      label: '(UTC) Coordinated Universal Time',
      value: 'UTC',
      supportDaylightSavings: false,
      utc_Offset: 0
    },
    {
      label: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
      value: 'GMT Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 0
    },
    {
      label: '(UTC+00:00) Monrovia, Reykjavik',
      value: 'Greenwich Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 0
    },
    {
      label: '(UTC+00:00) Sao Tome',
      value: 'Sao Tome Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 0
    },
    {
      label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      value: 'W. Europe Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 1
    },
    {
      label: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      value: 'Central Europe Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 1
    },
    {
      label: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
      value: 'Romance Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 1
    },
    {
      label: '(UTC+01:00) Casablanca',
      value: 'Morocco Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 1
    },
    {
      label: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
      value: 'Central European Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 1
    },
    {
      label: '(UTC+01:00) West Central Africa',
      value: 'W. Central Africa Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 1
    },
    {
      label: '(UTC+02:00) Amman',
      value: 'Jordan Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Athens, Bucharest',
      value: 'GTB Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Beirut',
      value: 'Middle East Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Cairo',
      value: 'Egypt Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Chisinau',
      value: 'E. Europe Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Damascus',
      value: 'Syria Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Gaza, Hebron',
      value: 'West Bank Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Harare, Pretoria',
      value: 'South Africa Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      value: 'FLE Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Jerusalem',
      value: 'Israel Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Kaliningrad',
      value: 'Kaliningrad Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Khartoum',
      value: 'Sudan Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Tripoli',
      value: 'Libya Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+02:00) Windhoek',
      value: 'Namibia Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 2
    },
    {
      label: '(UTC+03:00) Baghdad',
      value: 'Arabic Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 3
    },
    {
      label: '(UTC+03:00) Istanbul',
      value: 'Turkey Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 3
    },
    {
      label: '(UTC+03:00) Kuwait, Riyadh',
      value: 'Arab Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 3
    },
    {
      label: '(UTC+03:00) Minsk',
      value: 'Belarus Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 3
    },
    {
      label: '(UTC+03:00) Moscow, St. Petersburg',
      value: 'Russian Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 3
    },
    {
      label: '(UTC+03:00) Nairobi',
      value: 'E. Africa Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 3
    },
    {
      label: '(UTC+03:30) Tehran',
      value: 'Iran Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 3
    },
    {
      label: '(UTC+04:00) Abu Dhabi, Muscat',
      value: 'Arabian Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 4
    },
    {
      label: '(UTC+04:00) Astrakhan, Ulyanovsk',
      value: 'Astrakhan Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 4
    },
    {
      label: '(UTC+04:00) Baku',
      value: 'Azerbaijan Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 4
    },
    {
      label: '(UTC+04:00) Izhevsk, Samara',
      value: 'Russia Time Zone 3',
      supportDaylightSavings: true,
      utc_Offset: 4
    },
    {
      label: '(UTC+04:00) Port Louis',
      value: 'Mauritius Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 4
    },
    {
      label: '(UTC+04:00) Saratov',
      value: 'Saratov Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 4
    },
    {
      label: '(UTC+04:00) Tbilisi',
      value: 'Georgian Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 4
    },
    {
      label: '(UTC+04:00) Volgograd',
      value: 'Volgograd Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 4
    },
    {
      label: '(UTC+04:00) Yerevan',
      value: 'Caucasus Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 4
    },
    {
      label: '(UTC+04:30) Kabul',
      value: 'Afghanistan Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 4
    },
    {
      label: '(UTC+05:00) Ashgabat, Tashkent',
      value: 'West Asia Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 5
    },
    {
      label: '(UTC+05:00) Ekaterinburg',
      value: 'Ekaterinburg Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 5
    },
    {
      label: '(UTC+05:00) Islamabad, Karachi',
      value: 'Pakistan Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 5
    },
    {
      label: '(UTC+05:00) Qyzylorda',
      value: 'Qyzylorda Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 5
    },
    {
      label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      value: 'India Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 5
    },
    {
      label: '(UTC+05:30) Sri Jayawardenepura',
      value: 'Sri Lanka Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 5
    },
    {
      label: '(UTC+05:45) Kathmandu',
      value: 'Nepal Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 5
    },
    {
      label: '(UTC+06:00) Astana',
      value: 'Central Asia Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 6
    },
    {
      label: '(UTC+06:00) Dhaka',
      value: 'Bangladesh Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 6
    },
    {
      label: '(UTC+06:00) Omsk',
      value: 'Omsk Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 6
    },
    {
      label: '(UTC+06:30) Yangon (Rangoon)',
      value: 'Myanmar Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 6
    },
    {
      label: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
      value: 'SE Asia Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 7
    },
    {
      label: '(UTC+07:00) Barnaul, Gorno-Altaysk',
      value: 'Altai Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 7
    },
    {
      label: '(UTC+07:00) Hovd',
      value: 'W. Mongolia Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 7
    },
    {
      label: '(UTC+07:00) Krasnoyarsk',
      value: 'North Asia Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 7
    },
    {
      label: '(UTC+07:00) Novosibirsk',
      value: 'N. Central Asia Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 7
    },
    {
      label: '(UTC+07:00) Tomsk',
      value: 'Tomsk Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 7
    },
    {
      label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
      value: 'China Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 8
    },
    {
      label: '(UTC+08:00) Irkutsk',
      value: 'North Asia East Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 8
    },
    {
      label: '(UTC+08:00) Kuala Lumpur, Singapore',
      value: 'Singapore Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 8
    },
    {
      label: '(UTC+08:00) Perth',
      value: 'W. Australia Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 8
    },
    {
      label: '(UTC+08:00) Taipei',
      value: 'Taipei Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 8
    },
    {
      label: '(UTC+08:00) Ulaanbaatar',
      value: 'Ulaanbaatar Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 8
    },
    {
      label: '(UTC+08:45) Eucla',
      value: 'Aus Central W. Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 8
    },
    {
      label: '(UTC+09:00) Chita',
      value: 'Transbaikal Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 9
    },
    {
      label: '(UTC+09:00) Osaka, Sapporo, Tokyo',
      value: 'Tokyo Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 9
    },
    {
      label: '(UTC+09:00) Pyongyang',
      value: 'North Korea Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 9
    },
    {
      label: '(UTC+09:00) Seoul',
      value: 'Korea Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 9
    },
    {
      label: '(UTC+09:00) Yakutsk',
      value: 'Yakutsk Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 9
    },
    {
      label: '(UTC+09:30) Adelaide',
      value: 'Cen. Australia Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 9
    },
    {
      label: '(UTC+09:30) Darwin',
      value: 'AUS Central Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 9
    },
    {
      label: '(UTC+10:00) Brisbane',
      value: 'E. Australia Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 10
    },
    {
      label: '(UTC+10:00) Canberra, Melbourne, Sydney',
      value: 'AUS Eastern Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 10
    },
    {
      label: '(UTC+10:00) Guam, Port Moresby',
      value: 'West Pacific Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 10
    },
    {
      label: '(UTC+10:00) Hobart',
      value: 'Tasmania Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 10
    },
    {
      label: '(UTC+10:00) Vladivostok',
      value: 'Vladivostok Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 10
    },
    {
      label: '(UTC+10:30) Lord Howe Island',
      value: 'Lord Howe Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 10
    },
    {
      label: '(UTC+11:00) Bougainville Island',
      value: 'Bougainville Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 11
    },
    {
      label: '(UTC+11:00) Chokurdakh',
      value: 'Russia Time Zone 10',
      supportDaylightSavings: true,
      utc_Offset: 11
    },
    {
      label: '(UTC+11:00) Magadan',
      value: 'Magadan Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 11
    },
    {
      label: '(UTC+11:00) Norfolk Island',
      value: 'Norfolk Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 11
    },
    {
      label: '(UTC+11:00) Sakhalin',
      value: 'Sakhalin Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 11
    },
    {
      label: '(UTC+11:00) Solomon Is., New Caledonia',
      value: 'Central Pacific Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 11
    },
    {
      label: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
      value: 'Russia Time Zone 11',
      supportDaylightSavings: true,
      utc_Offset: 12
    },
    {
      label: '(UTC+12:00) Auckland, Wellington',
      value: 'New Zealand Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 12
    },
    {
      label: '(UTC+12:00) Coordinated Universal Time+12',
      value: 'UTC+12',
      supportDaylightSavings: false,
      utc_Offset: 12
    },
    {
      label: '(UTC+12:00) Fiji',
      value: 'Fiji Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 12
    },
    {
      label: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
      value: 'Kamchatka Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 12
    },
    {
      label: '(UTC+12:45) Chatham Islands',
      value: 'Chatham Islands Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 12
    },
    {
      label: '(UTC+13:00) Coordinated Universal Time+13',
      value: 'UTC+13',
      supportDaylightSavings: false,
      utc_Offset: 13
    },
    {
      label: "(UTC+13:00) Nuku'alofa",
      value: 'Tonga Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 13
    },
    {
      label: '(UTC+13:00) Samoa',
      value: 'Samoa Standard Time',
      supportDaylightSavings: true,
      utc_Offset: 13
    },
    {
      label: '(UTC+14:00) Kiritimati Island',
      value: 'Line Islands Standard Time',
      supportDaylightSavings: false,
      utc_Offset: 14
    }
  ]
}

const getters = {
  timezonesMap(state) {
    return state.timezones.reduce(function(map, obj) {
      map[obj.value] = obj
      return map
  }, {})
  }
}

export default {
  namespaced: true,
  state,
  getters
}
