export default {
  data() {
    return {
      isLoading: false,
      warningRejectModal: false,
      warningApproveModal: false,
      warningWaitListModal: false,

    }
  },
  methods: {
    showConfirmRejectRequestModal() {
      this.warningRejectModal = true
    },
    hideConfirmRejectRequestModal() {
      this.warningRejectModal = false
    },

    showConfirmApproveRequestModal() {
      this.warningApproveModal = true
    },
    hideConfirmApproveRequestModal() {
      this.warningApproveModal = false
    },

    showConfirmWaitlistRequestModal() {
      this.warningWaitListModal = true
    },
    hideConfirmwWaitlistRequestModal() {
      this.warningWaitListModal = false
    }

  }
}
