<template>
  <div>
    <CCard>
      <CCardHeader data-cy="header">
        Create Country
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CSelect
            id="country"
            :value.sync="form.id"
            data-cy="input-country"
            label="Country"
            horizontal
            :options="countryOptions"
            placeholder="Please select"
            @change="onCountryChange($event)"
          />

          <CTextarea
            v-model="form.description"
            data-cy="input-description"
            label="Description"
            placeholder="Enter description"
            horizontal
            rows="3"
          />
          <DatePickerInput
            id="expiration-date"
            v-model="form.expirationDate"
            :min-date="new Date()"
            data-cy="input-expiration-date"
            label="Expiration Date"
            placeholder="Choose expiration date"
            horizontal
            readonly
          />
          <CSelect
            data-cy="input-status"
            label="Status"
            horizontal
            :value.sync="form.status"
            :options="statusCodesForNewEntry"
            placeholder="Please select"
          />
        </CForm>
      </CCardBody>
      <CCardFooter>
        <CButton
          :disabled="submitted"
          data-cy="button-submit"
          type="submit"
          size="sm"
          color="primary"
          @click.prevent="
            submitCreateForm({ createFn: create, entity: 'countries' })
          "
        >
          <CSpinner
            v-if="submitted"
            color="light"
            size="sm"
          />
          <CIcon
            v-else
            name="cil-check-circle"
          /> Submit
        </CButton>
        <CButton
          data-cy="button-back"
          class="ml-2"
          :disabled="submitted"
          type="reset"
          size="sm"
          color="danger"
          @click="backToListView('countries')"
        >
          <CIcon name="cil-arrow-circle-left" /> Back
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import formMixins from '@/mixins/form-mixins'
export default {
  name: 'CountryCreate',
  mixins: [formMixins],
  data() {
    return {
      form: {
        id: '',
        name:'',
        description: '',
        expirationDate: null,
        status: null,
      },
    }
  },
  computed: {
    ...mapState(['statusCodesForNewEntry']),
    ...mapState('country', ['publicCountries']),
    countryOptions() {

      let countriesOptions = Object.entries(this.publicCountries).map(([value, label]) => ({ label, value }))

      countriesOptions.unshift({
        value: null,
        label: 'Please select a country',
      })

      return countriesOptions
    },
  },
  async created() {
    await this.listAllCountries()
  },
  methods: {
    ...mapActions('country', ['create', 'listAllCountries']),

    async onCountryChange(event){

      // could not think of a better way at the moment
      this.form.name = this.countryOptions.find(x => x.value === event.target.value).label

    }

  },
}
</script>

<style lang="scss" scoped></style>
