import RegistrationChecklistItemApi from '@/api/registration-checklist-item.api'
import RegistrationRequestsAPI from '@/api/registration-requests.api'
import { replaceItemInArray } from '@/utils'

const MIN_INITIAL_DEPOSIT = 2000
const MIN_AUTO_RECHARGE = 250
const MIN_CREDIT_CARD_CHARGE_AMOUNT = 250
const MAX_CREDIT_CARD_CHARGE_AMOUNT = 100000
const MIN_RECHARGE_BALANCE = 10
const REGISTRATION_TYPES = {
  buyer: 'buyer',
  seller: 'seller'
}

export const CUSTOM_CHECKLIST_TYPE = {
  'IdentityCheck': 'IdentityVerification'
}

function findRequestById(requests, id) {
  return requests.find(request => request.id === id)
}

const state = {
  buyerRegistrationRequests: [],
  sellerRegistrationRequests: [],
  buyerRegistrationRequestDetails: {},
  sellerRegistrationRequestDetails: {},
  registrationStatusCodes: [
    'Pending',
    'Approved',
    'Rejected',
    'Waitlisted'
  ],

  registrationRequestChecklistItems: [],

  /**
   * @type {('buyer|seller')}
   */
  currentRegistrationRequestType: 'seller'
}

/**
* @type {import('vuex').GetterTree<typeof state>}
*/
const getters = {
  buyerRequestById: state => id => {
    return findRequestById(state.buyerRegistrationRequests, id)
  },

  sellerRequestById: state => id => {
    return findRequestById(state.buyerRegistrationRequests, id)
  },

  checklistItemsForRegistration(state, getters, rootState) {
    let checklistItemsConfig = []
    let finalList = []

    if (state.currentRegistrationRequestType === 'buyer') {
      checklistItemsConfig = rootState['registrationChecklistItem'].buyerRegistrationChecklistItemsConfig
    } else {
      checklistItemsConfig = rootState['registrationChecklistItem'].sellerRegistrationChecklistItemsConfig
    }

    checklistItemsConfig = checklistItemsConfig ?? []

    checklistItemsConfig.forEach(itemConfig => {
      let itemFound = state.registrationRequestChecklistItems.find(item => item.checklistItemDetails.id === itemConfig.id)

      finalList.push({
        ...itemFound,
        checklistItemDetails: itemConfig
      })
    })

    const identityChecklistItem = state.registrationRequestChecklistItems.find(item => item.type === CUSTOM_CHECKLIST_TYPE.IdentityCheck)

    if (identityChecklistItem) {
      finalList.push(identityChecklistItem)
    } else {
      finalList.push({
        type: CUSTOM_CHECKLIST_TYPE.IdentityCheck,
      })
    }

    return finalList
  }
}

const actions = {
  async getRegistrationRequests({ commit }, {registrationType, status}) {
    let apiCall = RegistrationRequestsAPI.getBuyerRegistrationRequests

    let mutation = 'SET_BUYER_REGISTRATION_REQUESTS'

    if (registrationType === REGISTRATION_TYPES.seller) {
      apiCall = RegistrationRequestsAPI.getSellerRegistrationRequests
      mutation = 'SET_SELLER_REGISTRATION_REQUESTS'
    }

    try {
      const response = await apiCall(status)
      const result = response.data?.filter(Boolean)
      commit(mutation, result)
    } catch (error) {
      // TODO: handle error
    }
  },

  async getRegistrationRequestDetails(
    { commit },
    { registrationRequestId, registrationType }
  ) {
    let apiCall = RegistrationRequestsAPI.getBuyerRegistrationRequestDetails

    let mutation = 'SET_BUYER_REGISTRATION_REQUEST_DETAILS'

    if (registrationType === REGISTRATION_TYPES.seller) {
      apiCall = RegistrationRequestsAPI.getSellerRegistrationRequestDetails
      mutation = 'SET_SELLER_REGISTRATION_REQUEST_DETAILS'
    }

    commit(mutation, {})
    const response = await apiCall(registrationRequestId)
    commit(mutation, response.data || {})
    commit('SET_CURRENT_REGISTRATION_TYPE', registrationType)
  },

  async updateBuyerRegistrationWithReviewDetails(
    { commit },
    { id, reviewDetails }
  ) {
    const response = await RegistrationRequestsAPI.updateBuyerRegistrationWithReviewDetails(
      id,
      reviewDetails
    )

    commit('UPDATE_BUYER_REGISTRATION_REQUEST_IN_LIST', response.data)
    commit('UPDATE_BUYER_REGISTRATION_REQUEST', response.data)
  },

  async updateSellerRegistrationWithReviewDetails(
    { commit },
    { id, reviewDetails }
  ) {
    const response = await RegistrationRequestsAPI.updateSellerRegistrationWithReviewDetails(
      id,
      reviewDetails
    )

    commit('UPDATE_SELLER_REGISTRATION_REQUEST_IN_LIST', response.data)
    commit('UPDATE_SELLER_REGISTRATION_REQUEST', response.data)
  },

  async rejectBuyerRegistrationRequest({ commit }, id) {
    const response = await RegistrationRequestsAPI.rejectBuyerRegistrationRequest(
      id
    )

    commit('UPDATE_BUYER_REGISTRATION_REQUEST_IN_LIST', response.data)
    commit('UPDATE_BUYER_REGISTRATION_REQUEST', response.data)
  },

  async rejectSellerRegistrationRequest({ commit }, id) {
    const response = await RegistrationRequestsAPI.rejectSellerRegistrationRequest(
      id
    )

    commit('UPDATE_SELLER_REGISTRATION_REQUEST_IN_LIST', response.data)
    commit('UPDATE_SELLER_REGISTRATION_REQUEST', response.data)
  },


  async waitlistBuyerRegistrationRequest({ commit }, id) {
    const response = await RegistrationRequestsAPI.waitlistBuyerRegistrationRequest(
      id
    )

    commit('UPDATE_BUYER_REGISTRATION_REQUEST_IN_LIST', response.data)
    commit('UPDATE_BUYER_REGISTRATION_REQUEST', response.data)
  },

  async waitlistSellerRegistrationRequest({ commit }, id) {
    const response = await RegistrationRequestsAPI.waitlistSellerRegistrationRequest(
      id
    )

    commit('UPDATE_SELLER_REGISTRATION_REQUEST_IN_LIST', response.data)
    commit('UPDATE_SELLER_REGISTRATION_REQUEST', response.data)
  },

  async approveBuyerRegistrationRequest({ commit }, id) {
    const response = await RegistrationRequestsAPI.approveBuyerRegistrationRequest(
      id
    )

    commit('UPDATE_BUYER_REGISTRATION_REQUEST_IN_LIST', response.data)
    commit('UPDATE_BUYER_REGISTRATION_REQUEST', response.data)
  },

  async approveSellerRegistrationRequest({ commit }, id) {
    const response = await RegistrationRequestsAPI.approveSellerRegistrationRequest(
      id
    )

    commit('UPDATE_SELLER_REGISTRATION_REQUEST_IN_LIST', response.data)
    commit('UPDATE_SELLER_REGISTRATION_REQUEST', response.data)
  },

  async updateBuyerRegistrationDetails({ commit }, { id, updateObject }) {
    const response = await RegistrationRequestsAPI.updateBuyerRegistrationDetails(
      id,
      updateObject
    )
    commit('UPDATE_BUYER_REGISTRATION_REQUEST_IN_LIST', response.data)
    commit('UPDATE_BUYER_REGISTRATION_REQUEST', response.data)
  },

  async updateSellerRegistrationDetails({ commit }, { id, updateObject }) {
    const response = await RegistrationRequestsAPI.updateSellerRegistrationDetails(
      id,
      updateObject
    )
    commit('UPDATE_SELLER_REGISTRATION_REQUEST_IN_LIST', response.data)
    commit('UPDATE_SELLER_REGISTRATION_REQUEST', response.data)
  },

  async generateDownloadLink(context, { s3Key }) {
    const response = await RegistrationRequestsAPI.generateDownloadLink({
      s3Key
    })
    return response.data
  },

  async getRegistrationChecklistItems({ commit }, registrationId) {
    const response = await RegistrationChecklistItemApi.getRegistrationChecklistItemsForRegistration(registrationId)

    commit('SET_REGISTRATION_REQUEST_CHECKLIST_ITEMS', response.data)

    return response
  },

  async createRegistrationChecklistItemForRegistration({ dispatch }, { registrationId, checklistItemConfigId, status }) {
    const response = await RegistrationChecklistItemApi.createRegistrationChecklistItemForRegistration(registrationId, { registrationChecklistConfigId: checklistItemConfigId, status })

    dispatch('getRegistrationChecklistItems', registrationId)

    return response
  },

  async setRegistrationChecklistItemStatusForRegistration({ dispatch }, { registrationId, checklistItemId, status }) {
    const response = await RegistrationChecklistItemApi.setRegistrationChecklistItemStatusForRegistration(registrationId, { checklistItemId, status })

    dispatch('getRegistrationChecklistItems', registrationId)

    return response
  },

  async createIdentityCheckForRegistrationRepresentative({ dispatch }, registrationId) {
    const response = await RegistrationChecklistItemApi.createUserIdentityCheckVerificationSession(registrationId)

    dispatch('getRegistrationChecklistItems', registrationId)

    return response
  },

  async resendIdentityCheckForRegistrationRepresentative({ dispatch }, registrationId) {
    const response = await RegistrationChecklistItemApi.resendUserIdentityCheckVerificationSession(registrationId)

    dispatch('getRegistrationChecklistItems', registrationId)

    return response
  },
}


/**
* @type {import('vuex').MutationTree<typeof state>}
*/
const mutations = { 
  SET_BUYER_REGISTRATION_REQUESTS(state, requests) {
    state.buyerRegistrationRequests = requests
  },

  SET_BUYER_REGISTRATION_REQUEST_DETAILS(state, registrationRequestDetails) {
    state.buyerRegistrationRequestDetails = registrationRequestDetails
  },

  SET_SELLER_REGISTRATION_REQUESTS(state, requests) {
    state.sellerRegistrationRequests = requests
  },

  SET_SELLER_REGISTRATION_REQUEST_DETAILS(state, registrationRequestDetails) {
    state.sellerRegistrationRequestDetails = registrationRequestDetails
  },

  UPDATE_BUYER_REGISTRATION_REQUEST_IN_LIST(state, request) {
    replaceItemInArray(
      state.buyerRegistrationRequests,
      request,
      r => r.id === request.id
    )
  },

  UPDATE_BUYER_REGISTRATION_REQUEST(state, request) {
    if (state.buyerRegistrationRequestDetails?.id === request.id) {
      state.buyerRegistrationRequestDetails = request
    }
  },

  UPDATE_SELLER_REGISTRATION_REQUEST_IN_LIST(state, request) {
    replaceItemInArray(
      state.sellerRegistrationRequests,
      request,
      r => r.id === request.id
    )
  },

  UPDATE_SELLER_REGISTRATION_REQUEST(state, request) {
    if (state.sellerRegistrationRequestDetails?.id === request.id) {
      state.sellerRegistrationRequestDetails = request
    }
  },

  SET_REGISTRATION_REQUEST_CHECKLIST_ITEMS(state, items) {
    state.registrationRequestChecklistItems = items
  },

  RESET_REGISTRATION_REQUEST_CHECKLIST_ITEMS(state) {
    state.registrationRequestChecklistItems = []
  },

  SET_CURRENT_REGISTRATION_TYPE(state, type) {
    state.currentRegistrationRequestType = type
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export {
  MIN_INITIAL_DEPOSIT,
  MIN_AUTO_RECHARGE,
  MIN_CREDIT_CARD_CHARGE_AMOUNT,
  MAX_CREDIT_CARD_CHARGE_AMOUNT,
  REGISTRATION_TYPES,
  MIN_RECHARGE_BALANCE
}
