var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[(_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",style:({ height: '20vh' })},[_c('CSpinner',{staticClass:"custom-spinner",attrs:{"color":"info","grow":""}})],1):_c('div',[_c('CDataTable',{attrs:{"data-cy":"table-registration-requests","small":"","fixed":"","border":"","sorter":"","column-filter":"","items":_vm.requestsFormatted,"fields":_vm.fields,"items-per-page":10,"pagination":{align: 'center'}},scopedSlots:_vm._u([{key:"email-filter",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchEmail),expression:"searchEmail"}],staticClass:"form-control form-control-sm",domProps:{"value":(_vm.searchEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchEmail=$event.target.value}}})]},proxy:true},{key:"status-filter",fn:function(){return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.status),expression:"status"}],staticClass:"form-control form-control-sm",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.status=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.onStatusChange]}},[_c('option',{attrs:{"value":"All","selected":"selected"}}),_c('option',{attrs:{"value":"Pending"}},[_vm._v(" Pending ")]),_c('option',{attrs:{"value":"Waitlisted"}},[_vm._v(" Waitlisted ")])])]},proxy:true},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.representative.email))])]}},{key:"companyName",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.companyName)+" "),(item.testOnly)?_c('CBadge',{staticClass:"ml-1",attrs:{"color":"danger"}},[_vm._v(" Test ")]):_vm._e()],1)]}},{key:"requestedOn",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(item.formattedRequestedOn)+" ")])]}},{key:"lastUpdatedAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.formattedLastUpdatedAt)+" ")])]}},{key:"technology",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.callTechnology; }))+" ")])]}},{key:"country",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.companyAddress.countryCode; }))+" ")])]}},{key:"ipCountry",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.geoIpInfo.ipCountry; }))+" ")])]}},{key:"representative",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.getSafe(function () { return ((item.representative.firstName) + " " + (item.representative.lastName)); }))+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"Actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"info","size":"sm","to":("/dashboard/registration-requests/" + _vm.type + "/" + (item.id))}},[_vm._v(" View Application ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }