<template>
  <div class="d-flex flex-wrap">
    <div>
      <h5 class="mr-5">
        Sellers who cannot participate
        <CIcon
          v-c-tooltip="{
            content: 'Sellers who cannot participate due to various reasons in the selected Category\'s offers',
            placement: 'right',
          }"
          name="cil-info"
          size="custom"
          class="ml-1"
        />
      </h5>
      <div class="d-flex flex-column seller-offer-registration-list mr-5 mb-3">
        <div v-if="sellersWithNoVisibilityToOffers.length">
          <div
            v-for="seller in sellersWithNoVisibilityToOffers"
            :key="seller.id"
            class="seller-offer-registration-list__item pl-3"
          >
            <div class="d-flex mb-2">
              <router-link
                target="_blank"
                title="View Account"
                class="text-truncate seller-offer-registration-list__item-account-name h5 mb-0"
                :to="{
                  name: 'SellerAccountDetails',
                  params: { id: seller.id },
                  exact: true,
                }"
              >
                {{ seller.name }}
                <CIcon
                  name="cil-external-link"
                  size="custom"
                  class="ml-1"
                />
              </router-link>
              <a
                href
                title="Copy Account Id"
                class="ml-2"
                @click.prevent="
                  copyAccountIdToClipboard(seller.id)
                "
              ><CIcon
                name="cil-copy"
              /></a>
            </div>
            Offers not visible:
            <ol class="pl-4 mb-0">
              <li
                v-for="offerWithoAccess in seller.inacessibleOffers"
                :key="offerWithoAccess.id"
              >
                <router-link
                  target="_blank"
                  title="View Offer Registration"
                  :to="{
                    name: 'SellerOfferEdit',
                    params: { id: offerWithoAccess.id },
                    exact: true,
                  }"
                >
                  {{ offerWithoAccess.name }} <i class="text-muted">({{ offerWithoAccess.visibility }} offer)</i>
                  <CIcon
                    name="cil-external-link"
                    size="custom"
                    class="ml-1"
                  />
                </router-link>
      
                <ul
                  v-for="reason in offerWithoAccess.sellerOfferReasons"
                  :key="reason"
                  class="pl-4"
                >
                  <li>
                    {{ reason }}
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
        <div
          v-else
        >
          <div class="seller-offer-registration-list__item">
            <i>All Sellers can participate</i>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h5>
        Sellers who're not actively participating
        <CIcon
          v-c-tooltip="{
            content: 'Sellers who can participate but are not an active part of the selected Category\'s offers',
            placement: 'right',
          }"
          name="cil-info"
          size="custom"
          class="ml-1"
        />
      </h5>
      <div class="d-flex flex-column seller-offer-registration-list mr-5 mb-3">
        <div v-if="sellersNotParticipatingActively.length">
          <div
            v-for="seller in sellersNotParticipatingActively"
            :key="seller.id"
            class="seller-offer-registration-list__item pl-3"
          >
            <div class="d-flex py-1">
              <router-link
                target="_blank"
                title="View Account"
                class="text-truncate seller-offer-registration-list__item-account-name h5 mb-0"
                :to="{
                  name: 'SellerAccountDetails',
                  params: { id: seller.id },
                  exact: true,
                }"
              >
                {{ seller.name }}
                <CIcon
                  name="cil-external-link"
                  size="custom"
                  class="ml-1"
                />
              </router-link>
              <a
                href
                title="Copy Account Id"
                class="ml-2"
                @click.prevent="
                  copyAccountIdToClipboard(seller.id)
                "
              ><CIcon
                name="cil-copy"
              /></a>
            </div>
          </div>
        </div>
        <div
          v-else
        >
          <div class="seller-offer-registration-list__item">
            <i>All Sellers are actively participating</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'CategorySupplyInactive',

  mixins: [copyToClipboardMixin],

  props: {
    sellersNotParticipatingActively: {
      type: Array,
      default() {
        return []
      }
    },

    sellersWithNoVisibilityToOffers: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
    }
  },

  computed: {
  },

  methods: {
    copyAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Account ID copied to clipboard')
    },
  }
}
</script>
<style lang="scss" scoped>
</style>