<template>
  <div>
    <div v-if="isLoadingNotes">
      <CSpinner
        style="width:1rem;height:1rem;"
        color="info"
      />

      <i class="ml-2 text-muted">Loading notes</i>
    </div>

    <CRow
      v-for="(note, idx) in reviewNotesCopy"
      :key="idx"
      :class="{'mt-4': idx !== 0}"
    >
      <CCol
        md="3"
        class="text-right"
      >
        <div class="d-flex flex-column">
          <div>
            <strong>{{ formatName(note.createdBy) }}</strong>
          </div>
          <div>
            <small class="text-muted">
              {{ formatDate(note.createdOn) }}
            </small>
          </div>
          <div>
            <small class="text-muted">
              <CButton
                v-if="note.createdById === loggedInUserID"
                class="ml-2"
                size="sm"
                color="info"
                @click="onSelectEditMode(note)"
              >
                Edit
              </CButton>
              <CButton
                v-if="note.createdById === loggedInUserID"
                class="ml-2"
                size="sm"
                color="warning"
                @click="onDeleteReviewNote(note)"
              >
                Delete
              </CButton>
            </small>
          </div>
        </div>
      </CCol>
      <CCol>
        <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        <div
          v-if="!note.editMode"
        >
          <div
            class="notes mb-1"
          >
            {{ note.description }}
          </div>

          <a
            v-if="note.attachmentUri"
            href
            target="_blank"
            rel="noreferrer noopener"
            @click.prevent="getDownloadLink(note.attachmentUri)"
          >
            <small><em>View attachment</em></small>
          </a>
        </div>
        <div
          v-if="note.editMode"
        >
          <CTextarea
            v-model="note.description"
            class="mt-2"
            horizontal
            rows="5"
            invalid-feedback="Please enter the notes (5 characters minimum)"
          />

          <a
            v-if="note.attachmentUri"
            href
            target="_blank"
            rel="noreferrer noopener"
            @click.prevent="getDownloadLink(note.attachmentUri)"
          >
            <small><em>View attachment</em></small>
          </a>
          <span
            v-if="note.attachmentUri" 
            title="Remove attachment"
            @click="deleteAttachment(note)"
          >
            <CIcon
              style="cursor: pointer;"
              class="ml-2 my-2 text-danger"
              name="cil-x-circle"
            />
          </span>

          <CInputFile
            ref="notesAttachment"
            class="mt-1"
            add-label-classes="text-right pr-4"
            rows="5"
            invalid-feedback=""
            accept=".jpg,.jpeg,.pdf,.png,.doc,.docx"
            @change="setFile"
          />

          <ButtonLoading
            class="ml-2"
            size="sm"
            color="primary"
            :loading="isUpdating"
            @click="onUpdateReviewNote(note)"
          >
            Submit
          </ButtonLoading>
          <CButton
            class="ml-2"
            size="sm"
            color="danger"
            @click="hideEditMode(note)"
          >
            Cancel
          </CButton>
        </div>
      </CCol>
    </CRow>

    <i
      v-if="!isLoadingNotes && !reviewNotesCopy.length"
      class="text-muted"
    >
      No Notes Added
    </i>

    <CModal
      title="Confirm delete"
      color="danger"
      :show.sync="showDeleteModal"
    >
      Are you sure you want to delete this note?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isUpdating"
          @click="onConfirmDeleteReviewNote"
        >
          Delete
        </ButtonLoading>

        <CButton
          color="danger"
          @click="showDeleteModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showSuccessMessage, showErrorMessage } from '@/notification-utils'
import { dateTimeFormat } from '@/utils'
import { cloneDeep } from 'lodash'
import formMixins from '@/mixins/form-mixins'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'ReviewNotesList',

  mixins: [formMixins],

  props: {
    isLoadingNotes: {
      type: Boolean,
      default: false
    },

    reviewNotes: {
      type: Array,
      default() {
        return []
      }
    },

    parentObject: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  validations: {
    selectedNote: {
      description: {
        required,
        minLength: minLength(5)
      }
    }
  },

  data() {
    return {
      isUpdating: false,
      selectedNote: null,
      showDeleteModal: false,
      reviewNotesCopy: [],
      attachment: '',
    }
  },

  computed: {
    ...mapGetters('auth', [
      'loggedInUserID'
    ])
  },

  watch: {
    reviewNotes: {
      immediate: true,

      handler(newValue) {
        this.reviewNotesCopy = cloneDeep(newValue).map(item => {
          item.editMode = false
          return item
        })
      }
    }
  },


  methods: {
    ...mapActions('registrationRequests', [
      'generateDownloadLink'
    ]),

    ...mapActions('notes', [
      'updateReviewNote',
      'deleteReviewNote'
    ]),

    formatName(user) {
      if (!user){
        return "Unknown"
      }
      return user
    },

    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    },

    async getDownloadLink(s3Key) {
      const downloadURL = await this.generateDownloadLink({ s3Key })
      window.open(downloadURL)
    },

    onSelectEditMode(item) {
      if (this.selectedNote){
        this.selectedNote.editMode = false
      }

      item.editMode = true
      this.selectedNote = item
    },

    hideEditMode(item) {
      item.editMode = false
      this.selectedNote = null
      this.$emit('reload-review-notes')
    },

    deleteAttachment(item){
      this.clearFile()

      item.attachmentUri = null
      item.changeAttachment = true
    },

    onDeleteReviewNote(item) {
      this.showDeleteModal = true
      this.selectedNote = item
    },

    async onConfirmDeleteReviewNote() {
      this.isUpdating = true
      
      try {
        const payload = {
          noteListId: this.parentObject?.noteListId, 
          noteId: this.selectedNote.id
        }
        await this.deleteReviewNote(payload)
        this.$emit('reload-review-notes')
        showSuccessMessage('Successfully deleted a review note')

        this.showDeleteModal = false
        this.selectedNote = null

      } catch(error) {
        showErrorMessage(error.response.data.title)
      } finally {
        this.isUpdating = false
      }
    },

    async onUpdateReviewNote(item){
      this.isUpdating = true

      try {        
        const payload = {
          noteListId: this.parentObject?.noteListId,
          noteId: item.id,
          description: item.description,
          attachment: this.attachment,
          changeAttachment: item.changeAttachment || this.attachment != null
        }
        await this.updateReviewNote(payload)
        
        showSuccessMessage('Successfully updated a review note')
      }
      finally{
        this.isUpdating = false
        this.hideEditMode(item)
      }
    },

    setFile(files) {
      this.attachment = files[0]
    },
    
    clearFile() {
      this.$refs['notesAttachment'].$el.children[0].value = ''
    }
  }
}
</script>

<style scoped lang="scss">
.notes {
  white-space: break-spaces;
  font-size: 95%;
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
}
</style>
