import SalesChannelApi from '@/api/salesChannel.api'

const state = {
  salesChannelName: '',
  blockedChannels: [],
  blockedChannel: {}
}

const getters = {
  hasBlockedChannels: (state) => (accountId) => {
    if (state.blockedChannels) {
      const channels = (state.blockedChannels.find(item => item.id === accountId))?.channels
      if (channels && channels.length > 0) {
        return true
      }
    }
    return false
  }
}

const actions = {
  async getEncodedName({ commit }, payload) {
    const response = await SalesChannelApi.getEncodedName(payload)
    if (response.data) {
      commit('SET_SALES_CHANNEL_NAME', response.data.encodedName)
    }     
  },

  async block(context, {payload}) {
    return await SalesChannelApi.block(payload)        
  },

  async getChannels({commit}, accountId) {
    const response = await SalesChannelApi.getChannels(accountId)
    if (response.data)
    {
      commit('SET_BLOCKED_CHANNELS', { 
        channels: response.data.channels, 
        accountId: accountId })
      return response.data.channels
    }    
  },

  async getChannel({commit}, searchId) {
    const response = await SalesChannelApi.getChannel(searchId)
    if (response.data)
    {
      commit('SET_BLOCKED_CHANNEL', response.data)
      
      return response.data
    }    
  },

  async unblock(context, {payload}) {
    return await SalesChannelApi.unblock(payload)        
  },

  resetBlockedChannels({commit}) {
    commit.RESET_BLOCKED_CHANNELS
  },  
}

const mutations = {
  SET_SALES_CHANNEL_NAME(state, data) {
    state.salesChannelName = data
  },

  RESET_SALES_CHANNEL_NAME(state) {
    state.salesChannelName = ''
  },

  SET_BLOCKED_CHANNELS(state, {channels, accountId}) {
    if (state.blockedChannels.find(item => item.id === accountId)) {
      const updatedItems = state.blockedChannels.map(el => el.id === accountId
        ? {
            id: accountId,
            channels: channels
          } 
        : el)
        state.blockedChannels = updatedItems
    }
    else {
      state.blockedChannels.push({
        id: accountId,
        channels: channels
      })
    }    
  },

  SET_BLOCKED_CHANNEL(state, data) {
    state.blockedChannel = data
  },

  RESET_BLOCKED_CHANNELS(state) {
    state.blockedChannels = []
    state.blockedChannel = {}
  }, 
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
