<template>
  <div>
    <CCard>
      <CCardHeader>
        Add funds
      </CCardHeader>

      <CCardBody>
        <dl class="row">
          <dt class="col-sm-3">
            <h6>Account Name</h6>
          </dt>
          <dd class="col-sm-9 d-flex align-items-center">
            <h6>{{ getSafe(() => accountDetails.name) }}</h6>
          </dd>

          <dt class="col-sm-3">
            <h6>Current Balance</h6>
          </dt>
          <dd class="col-sm-9 d-flex align-items-center">
            {{ getSafe(() => formatAmount(currentBalance, 0)) }}
          </dd>
        </dl>

        <CForm
          novalidate
          @submit.prevent
        >
          <CInput
            v-model="$v.addFundsDetails.amount.$model"
            type="number"
            label="Amount"
            name="amount"
            placeholder="Amount to be added"
            horizontal
            :is-valid="checkIfValid(['addFundsDetails', 'amount'])"
            :invalid-feedback="invalidAmount"
          />

          <CTextarea
            id="notes"
            v-model="$v.addFundsDetails.notes.$model"
            data-cy="input-notes"
            label="Notes"
            placeholder="Enter notes"
            horizontal
            rows="3"
            :is-valid="checkIfValid(['addFundsDetails', 'notes'])"            
            :invalid-feedback="invalidNotes"
          />

          <div class="form-row">
            <CCol
              :md="{ offset: '3', size: '9' }"
              :sm="{ offset: '3', size: '9' }"
            >
              <CButton
                type="submit"
                size="sm"
                color="primary"
                data-cy="submit-add-funds"
                @click="onFormSubmit"
              >
                Add Funds
              </CButton>

              <CButton
                type="button"
                size="sm"
                class="ml-3"
                color="danger"
                @click="cancel"
              >
                Cancel
              </CButton>
            </CCol>
          </div>
        </CForm>

        <CModal
          title="Please login again to verify its you"
          color="primary"
          data-cy="login-modal"
          :show.sync="showLoginModal"
        >
          <LoginInternal
            v-if="showLoginModal"
            @success="onLoginSuccess"
            @error="onLoginFailed"
          />

          <template #footer-wrapper>
            <div />
          </template>
        </CModal>

        <CModal
          title="Confirm add funds"
          color="success"
          data-cy="add-funds-modal"
          :show.sync="showConfirmationModal"
        >
          Are you sure you want to add funds to {{ getSafe(() => accountDetails.name) }}?

          <template #footer>
            <ButtonLoading
              color="primary"
              :loading="isLoading"
              @click="onConfirmSubmit"
            >
              Add Funds
            </ButtonLoading>

            <CButton
              color="danger"
              @click="showConfirmationModal = false"
            >
              Cancel
            </CButton>
          </template>
        </CModal>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import {
  SELLER_ACCOUNT_BALANCE_RANGE
} from '@/store/modules/accounts.store'

import { mapActions, mapGetters, mapState } from 'vuex'
import { minValue, required, maxValue } from 'vuelidate/lib/validators'
import { formatAmount } from '@/utils'

import formGenericMixin from '@/mixins/form-generic-mixin'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import LoginInternal from '../Login/LoginInternal.vue'

const ADD_FUNDS_TITLE = 'Add Funds'

export default {
  name: 'SellerAccountAddFunds',

  title: ADD_FUNDS_TITLE,

  components: { LoginInternal },

  mixins: [formGenericMixin],

  props: {
    accountId: {
      type: String,
      default: ''
    },
  },

  validations() {
    return {
      addFundsDetails: {
        amount: {
          required,
          minValue: minValue(SELLER_ACCOUNT_BALANCE_RANGE.min),
          maxValue: maxValue(SELLER_ACCOUNT_BALANCE_RANGE.max),
        },

        notes: {
          required
        }
      }
    }
  },

  data() {
    return {
      addFundsDetails: {
        amount: 0,
        notes: ''
      },

      showConfirmationModal: false,
      showLoginModal: false,

      isLoading: false,

      invalidAmount: "Please enter amount",
      invalidNotes: "Please enter notes for reference"
    }
  },

  computed: {
    ...mapState('accounts', {
      accountDetails: 'sellerAccountDetails',
      accountBalance: 'accountBalance'
    }),

    ...mapGetters('auth', [
      'isUserLoggedInWithinLastXMinutes'
    ]),

    isUserLoggedInWithinLast5Minutes() {
      return this.isUserLoggedInWithinLastXMinutes(5)
    },

    isValid() {      
      return !this.$v.$invalid
    },

    currentBalance() {
      return this.accountBalance.cashBalance + this.accountBalance.pendingBalance + this.accountBalance.holdingBalance
    },
  },

  watch: {
    showLoginModal(newValue, oldValue) {
      if (!newValue && oldValue) {
        document.title = ADD_FUNDS_TITLE
      }
    }
  },

  async created () {
    if (this.accountId) {
      try {
        await this.getSellerAccountDetails(this.accountId)

        await this.getAccountBalance(this.accountId)
      } catch (e) {
        showErrorMessage(e.response.data.title)
      }
    }
  },

  methods: {
    formatAmount,

    ...mapActions('accounts', [
      'getSellerAccountDetails',
      'getAccountBalance',
      'addFundsToSellerAccount'
    ]),

    ...mapActions('auth', [
      'logout'
    ]),

    onFormSubmit() {
      this.$v.addFundsDetails.$touch()

      this.submitted = true

      if (this.isValid) {
        if (!this.isUserLoggedInWithinLast5Minutes) {
          this.showLoginModal = true
          return
        }

        this.showConfirmationModal = true
      }
    },

    async onConfirmSubmit() {
      try {
        this.isLoading = true

        await this.addFundsToSellerAccount({ accountId: this.accountId, ...this.addFundsDetails})

        showSuccessMessage('Funds added to seller account and will be processed in next payout.')

        this.showConfirmationModal = false

        this.$router.go(-1)
      } catch (error) {
        if (error.response.status === 403) {
          this.showLoginModal = true 
          this.showConfirmationModal = false
        }

        showErrorMessage(error.response.data.title)
      } finally {
        this.isLoading = false
      }
    },

    onLoginSuccess() {
      this.showLoginModal = false

      this.showConfirmationModal = true
    },

    onLoginFailed() {
      this.onLogout()
    },

    cancel() {
      this.$router.push({ 
        name: 'SellerAccountDetails', 
        params: {
          id: this.accountId
        } 
      })
    },

    onLogout() {
      try {
        this.logout()
      } catch (error) {
        //
      } finally {
        this.$router.push({ name: 'LoginEmail' })
      }
    }
  },
}
</script>

<style>
</style>