<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CHeaderBrand
      class="mx-auto d-lg-none"
    >
      <CImg
        width="140"
        :src="imageSwitch"
        alt="Ringba X"
      />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <SystemStatus />
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav>
      <CHeaderNavItem class="px-3">
        <button
          class="c-header-nav-btn"
          @click="toggleDarkMode"
        >
          <CIcon
            v-if="darkMode"
            name="cil-sun"
          />
          <CIcon
            v-else
            name="cil-moon"
          />
        </button>
      </CHeaderNavItem>

      <TheHeaderDropdownAccnt class="mr-4" />

      <CHeaderNavItem
        v-if="false"
        class="px-3"
      >
        <button
          class="c-header-nav-btn"
          @click="$store.commit('toggle', 'asideShow')"
        >
          <CIcon
            size="lg"
            name="cil-applications-settings"
            class="mr-2"
          />
        </button>
      </CHeaderNavItem>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt.vue'
import SystemStatus from '@/components/SystemStatus.vue'

export default {
  name: 'TheHeader',

  components: {
    TheHeaderDropdownAccnt,
    SystemStatus
  },

  computed: {
    ...mapState(['darkMode']),

    ...mapState('auth', ['userDetails']),

    imageSwitch() {
      return this.darkMode ? '/img/ringbax-logo-dark-theme.svg' : '/img/ringbax-logo-light-theme.svg'
    }
  },

  watch: {
    userDetails: function(newValue) {
      if (newValue?.adminSitePreferences?.preferredTheme) {
        const darkMode = newValue.adminSitePreferences.preferredTheme === 'Dark'
        this.set(['darkMode', darkMode])
      }
    }
  },

  methods: {
    ...mapMutations(['set']),

    ...mapActions('user', ['setUIPreferences']),

    ...mapActions('auth', ['getLoggedInUserDetails']),

    async toggleDarkMode() {
      const preferences = {
        preferredTheme: this.darkMode ? 'Light' : 'Dark'
      }
      
      await this.setUIPreferences(preferences)

      await this.getLoggedInUserDetails()
    },
  }
}
</script>
