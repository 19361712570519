import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/customerresources', process.env.VUE_APP_PUBLIC_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const END_POINT = '/user'

const UserAPI = {
  getLoggedInUserDetails() {
    return httpClient.get(END_POINT)
  },

  setUIPreferences(preferences) {
    const url = `${END_POINT}/preferences/admin`
    return httpClient.post(url, preferences)
  }
}

export default UserAPI