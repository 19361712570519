import CategoryAPI from '@/api/category.api'

export const MAX_BID_MINIMUM = 1
export const MARGIN_PERCENT_MINIMUM = 5
export const MIN_PROFIT_AMOUNT_MINIMUM = 1
export const MINIMUM_INCREASE_IN_SECONDS = 0
export const MAXIMUM_INCREASE_IN_SECONDS = 10000
export const CATEGORY_FLAGS = ['MEDICARE']

const state = {
  categories: [],
  selectedRow: {}
}

const getters = {
  activeCategories(state) {
    return state.categories.filter(c => c.status === 'Active')
  }
}

const actions = {
  async list({ commit }) {
    const response = await CategoryAPI.list()
    commit('SET_CATEGORIES', response.data || [])
  },
  async findOne({ commit }, id) {
    const response = await CategoryAPI.findOne(id)
    commit('SET_SELECTED_ROW', response.data)
  },
  async create(context, newCategory) {
    const transformObj = {
      ...newCategory,
      languages: newCategory.languages.map(l => ({ id: l }))
    }

    const response = await CategoryAPI.create(transformObj)

    return response
  },
  async update(context, updatedCategory) {
    delete updatedCategory.testOnly
    
    const response = await CategoryAPI.patch(
      updatedCategory.id,
      updatedCategory
    )
    return response
  }
}

const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_SELECTED_ROW(state, category) {
    state.selectedRow = category
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
