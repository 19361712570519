<template>
  <CCard>
    <CCardHeader>
      Traffic Sources
    </CCardHeader>
    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CRow class="form-group">
          <CCol
            col="12"
            sm="3"
            class="text-sm-right pr-4"
          >
            Call Type
          </CCol>
          <CInputRadioGroup
            class="col-sm-9"
            add-label-classes="text-right pr-4"
            add-input-classes="w-25"
            :options="['Inbound', 'Outbound']"
            :custom="true"
            :inline="true"
            :checked.sync="trafficSourcesDetailsLocal.callType"
            label="Routing"
            @update:checked="onCallTypeChange"
          />
        </CRow>

        <CRow
          v-if="false && trafficSourcesDetailsLocal.callType === 'Outbound'"
          class="form-group"
        >
          <CCol
            col="12"
            sm="3"
            class="text-sm-right pr-4"
          >
            Routing
          </CCol>
          <CInputRadioGroup
            class="col-sm-9"
            add-label-classes="text-right pr-4"
            add-input-classes="w-25"
            :options="['None', 'Jornaya', 'TrustedForm', 'Either']"
            :custom="true"
            :inline="true"
            :checked.sync="trafficSourcesDetailsLocal.requirements"
            label="Routing"
            @update:checked="formEdited = true"
          />
        </CRow>

        <CRow class="form-group">
          <CCol
            sm="3"
            col="12"
            class="text-sm-right pr-4"
          >
            Allowed Sources
          </CCol>
          <CCol>
            <v-multiselect-listbox
              id="input-traffic-sources"
              v-model="trafficSourcesDetailsLocal.allowedSources"
              data-cy="multiselect-traffic-sources"
              class="msl-multi-select--full-width"
              :options="trafficSourcesFiltered"
              :reduce-display-property="option => option.name"
              :reduce-value-property="option => option.id"
              :reduce-group-property="(option) => option.trafficSourceType"
              search-input-class=""
              search-options-placeholder="Search"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No source selected"
              :show-select-all-buttons="true"
              @change="formEdited = true"
            />
          </CCol>
        </CRow>

        <CRow class="form-group">
          <CCol
            sm="3"
            col="12"
            class="text-sm-right pr-4"
          >
            Allow Toll Free Callers
          </CCol>
          <CCol
            sm="3"
            col="12"
          >
            <CSwitch
              shape="pill"
              :checked.sync="trafficSourcesDetailsLocal.allowTollFreeCallers"
              color="primary"
              @update:checked="formEdited = true"
            />
          </CCol>
        </CRow>

        <CRow class="form-group">
          <CCol
            sm="3"
            col="12"
            class="text-sm-right pr-4"
          >
            Allow Duplicate Callers
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="trafficSourcesDetailsLocal.allowDuplicateCallers"
              color="primary"
              @update:checked="formEdited = true"
            />
          </CCol>
        </CRow>

        <CRow class="form-group">
          <CCol
            sm="3"
            col="12"
            class="text-sm-right pr-4"
          >
            Allow Third Parties
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="trafficSourcesDetailsLocal.allowThirdParties"
              color="primary"
              @update:checked="formEdited = true"
            />
          </CCol>
        </CRow>

        <div class="row">
          <div class="col-sm-3 pr-4" />
          <div class="col-sm-9">
            <ButtonLoading
              color="primary"
              :loading="isLoading"
              :disabled="!formEdited"
              horizontal
              size="sm"
              label=""
              @click="updateTrafficSources"
            >
              Update
            </ButtonLoading>
          </div>
        </div>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapActions } from 'vuex'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'TrafficSources',
  props: {
    campaignId: {
      type: String,
      default: ''
    },
    trafficSourcesDetails: {
      type: Object,
      default: () => {}
    },
    trafficSources: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      formEdited: false,
      isLoading: false,
      trafficSourcesDetailsLocal: {}
    }
  },
  computed: {
    trafficSourcesFiltered() {
      if (this.trafficSourcesDetailsLocal.callType === 'Inbound') {
        return this.trafficSources.filter(
          s => s.trafficSourceType === 'Inbound'
        )
      } else if (this.trafficSourcesDetailsLocal.callType === 'Outbound') {
        return this.trafficSources.filter(
          s => s.trafficSourceType === 'Outbound'
        )
      } else {
        return this.trafficSources
      }
    }
  },
  watch: {
    trafficSourcesDetails: {
      immediate: true,
      handler(newValue) {
        this.trafficSourcesDetailsLocal = {
          ...newValue,
          allowedSources: newValue.allowedSources
            ? [...newValue.allowedSources].map(ts => ts.id)
            : [],
          allowTollFreeCallers: Boolean(newValue.allowTollFreeCallers),
          allowDuplicateCallers: Boolean(newValue.allowDuplicateCallers),
          allowThirdParties: Boolean(newValue.allowThirdParties)
        }
      }
    }
  },
  methods: {
    ...mapActions('campaign', ['updateCampaignDetails']),

    onCallTypeChange() {
      this.trafficSourcesDetailsLocal.allowedSources = []
    },

    async updateTrafficSources() {
      const campaignId = this.campaignId

      const updateObject = {
        trafficSources: {
          ...this.trafficSourcesDetailsLocal,
          allowedSources: this.trafficSourcesDetailsLocal.allowedSources.map(
            id => ({ id })
          )
        }
      }

      try {
        this.isLoading = true
        await this.updateCampaignDetails({ id: campaignId, updateObject })
        showSuccessMessage('Updated traffic sources settings')
      } catch (error) {
        console.error(error.message)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
