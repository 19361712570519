<template>
  <CCard>
    <CCardHeader>
      Review Traffic Details
    </CCardHeader>

    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3 text-right pr-4">Allowed Categories</label>

          <div class="col-sm-9">
            <v-multiselect-listbox
              v-if="categories.length"
              v-model="$v.allowedCategories.$model"
              :options="filteredCategories"
              :reduce-display-property="(option) => option.name"
              :reduce-value-property="(option) => option.id"
              :reduce-group-property="(option) => option.parentCategory.name"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search category"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No category selected"
            />

            <div
              v-if="isFormEdited && $v.allowedCategories.$invalid"
              class="invalid-feedback d-flex mb-3 mt-2"
            >
              Please select categories
            </div>
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-form-label col-sm-3 text-right pr-4">Allowed Traffic Sources</label>

          <div class="col-sm-9">
            <v-multiselect-listbox
              v-if="trafficSources.length"
              v-model="$v.allowedTrafficSources.$model"
              :options="trafficSources"
              :reduce-display-property="(option) => option.name"
              :reduce-value-property="(option) => option.id"
              :reduce-group-property="(option) => option.trafficSourceType"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search source"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No traffic source selected"
            />

            <div
              v-if="isFormEdited && $v.allowedTrafficSources.$invalid"
              class="invalid-feedback d-flex mb-3 mt-2"
            >
              Please select traffic sources
            </div>
          </div>
        </div>

        <div class="form-row">
          <CCol
            :md="{ offset: '3', size: '9' }"
            :sm="{ offset: '3', size: '9' }"
          >
            <ButtonLoading
              type="submit"
              size="sm"
              color="primary"
              :loading="isLoading"
              data-cy="submit-billing"
              :disabled="!isFormEdited"
              @click="onSubmit"
            >
              Save
            </ButtonLoading>
          </CCol>
        </div>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import formGenericMixin from '@/mixins/form-generic-mixin'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import { REGISTRATION_TYPES } from '@/store/modules/registration-requests.store'

export default {
  name: 'RegistrationRequestTrafficDetails',

  mixins: [formGenericMixin],

  props: {
    registrationRequest: {
      type: Object,
      default() {
        return {}
      },
    },

    type: {
      type: String,
      default: ''
    }
  },

  validations() {
    return {
      allowedCategories: {
        required,
      },

      allowedTrafficSources: {
        required,
      },
    }
  },

  data() {
    return {
      reviewDetailsLocal: {
        allowedCategories: [],
        allowedTrafficSources: [],
      },

      isLoading: false
    }
  },

  computed: {
    ...mapState('category', ['categories']),

    ...mapGetters('trafficSource', {
      trafficSources: 'activeTrafficSources'
    }),

    categoriesToTestOnlyMap() {
      return this.categories.reduce((finalMap, category) => {
        finalMap[category.id] = category.testOnly
        return finalMap
      }, {})
    },

    filteredCategories() {
      return this.categories.filter(category => category.testOnly === this.registrationRequest?.testOnly)
    },

    allowedCategories: {
      get() {
        return this.reviewDetailsLocal.allowedCategories
        ?.filter((category) => this.registrationRequest.testOnly === this.categoriesToTestOnlyMap[category.id])
        .map(
          (category) => category.id
        ) || []
      },

      set(value) {
        this.reviewDetailsLocal.allowedCategories = 
        value.filter((id) => this.registrationRequest.testOnly === this.categoriesToTestOnlyMap[id])
        .map((id) => {
          return {
            id,
          }
        })
      },
    },

    allowedTrafficSources: {
      get() {
        return this.reviewDetailsLocal.allowedTrafficSources?.map(
          (ts) => ts.id
        )
      },

      set(value) {
        this.reviewDetailsLocal.allowedTrafficSources = value.map((id) => {
          return {
            id,
          }
        })
      },
    },
  },

  watch: {
    registrationRequest: {
      immediate: true,
      handler(newValue) {
        this.reviewDetailsLocal = newValue.reviewDetails
          ? { ...newValue.reviewDetails }
          : {
              allowedCategories: [],
              allowedTrafficSources: [],
            }

        if (!this.reviewDetailsLocal.allowedCategories?.length) {
          this.reviewDetailsLocal.allowedCategories = newValue.categories
          this.$v.allowedCategories.$touch()
        }

        if (!this.reviewDetailsLocal.allowedTrafficSources?.length) {
          this.setAllowedTrafficSources(newValue)
        }
      },
    },
  },

  async created() {
    await this.getAllCategories()

    await this.getAllTrafficSources()
  },

  methods: {
    ...mapActions('registrationRequests', [
      'updateBuyerRegistrationWithReviewDetails',
      'updateSellerRegistrationWithReviewDetails'
    ]),

    ...mapActions('category', {
      getAllCategories: 'list',
    }),

    ...mapActions('trafficSource', {
      getAllTrafficSources: 'list',
    }),

    async onSubmit() {
      this.$v.allowedCategories.$touch()
      this.$v.allowedTrafficSources.$touch()

      if (this.isValid) {
        this.isLoading = true

        try {
          if (this.type === REGISTRATION_TYPES.buyer) {
            await this.updateBuyerRegistrationWithReviewDetails({
              id: this.registrationRequest.id,
              reviewDetails: this.reviewDetailsLocal,
            })
          } else {
            await this.updateSellerRegistrationWithReviewDetails({
              id: this.registrationRequest.id,
              reviewDetails: this.reviewDetailsLocal,
            })
          }

          showSuccessMessage('Traffic details updated successfully')

          this.resetFormDirtyState()
        } catch (error) {
          // Handle error
          showErrorMessage(error)
        } finally {
          this.isLoading = false
        }
      }
    },

    setAllowedTrafficSources(registrationRequest) {
      this.reviewDetailsLocal.allowedTrafficSources = [
        ...(registrationRequest.inboundTrafficSources || []),
        ...(registrationRequest.outboundTrafficSources || []),
        ...(registrationRequest.transferTrafficSources || [])
      ]

      this.$v.allowedTrafficSources.$touch()
    }
  },
}
</script>

<style>
</style>