export default {
  data() {
    return {
      includeTestOnly: false,
    }
  },

  computed: {
    filteredItems() {
      return this.items.filter(i => this.includeTestOnly ? true : !i.testOnly)
    }
  },
}