<template>
  <div>
    <CRow
      class="form-group"
    >
      <CCol
        sm="3"
        class="text-right pr-4"
      >
        Dynamic Number
      </CCol>
      <CCol sm="3">
        <CSwitch
          shape="pill"
          :checked.sync="
            programmaticTargetCopy.isNumberDynamic
          "
          color="primary"
          @update:checked="$emit('update', programmaticTargetCopy)"
        />
      </CCol>
    </CRow>

    <div v-if="!programmaticTargetCopy.isNumberDynamic">
      <CRow class="form-group">
        <CCol
          sm="3"
          class="text-right pr-4"
        >
          Type
        </CCol>
        <CInputRadioGroup
          class="col-sm-9"
          add-label-classes="text-right pr-4"
          add-input-classes="w-25"
          :options="['Number', 'SIP']"
          :inline="true"
          :custom="true"
          :checked.sync="programmaticTargetCopy.destinationType"
          label="Routing"
          @update:checked="onDestinationTypeChanged"
        />
      </CRow>

      <CInput
        v-if="programmaticTargetCopy.destinationType === 'Number'"
        v-model="$v.programmaticTargetCopy.number.$model"
        type="string"
        label="Number"
        add-label-classes="text-right pr-4"
        add-input-classes="w-25"
        name="Number"
        :is-valid="
          checkIfValid(['programmaticTargetCopy', 'number'], submitted)
        "
        placeholder="Number"
        horizontal
        invalid-feedback="Phone number cannot be blank"
        @input="$emit('update', programmaticTargetCopy)"
      />

      <div v-if="programmaticTargetCopy.destinationType === 'SIP'">
        <CInput
          v-model="$v.programmaticTargetCopy.sipNumber.endpoint.$model"
          type="string"
          label="SIP Endpoint"
          add-label-classes="text-right pr-4"
          add-input-classes="w-25"
          name="SIP Endpoint"
          :is-valid="
            checkIfValid(
              ['programmaticTargetCopy', 'sipNumber', 'endpoint'],
              submitted
            )
          "
          placeholder="SIP URL"
          horizontal
          invalid-feedback="SIP Endpoint must be a valid URL"
          @input="$emit('update', programmaticTargetCopy)"
        />
        <CInput
          v-model="$v.programmaticTargetCopy.sipNumber.username.$model"
          type="string"
          label="SIP Username"
          add-label-classes="text-right pr-4"
          add-input-classes="w-25"
          name="SIP Username"
          placeholder="SIP Username"
          :is-valid="
            checkIfValid(
              ['programmaticTargetCopy', 'sipNumber', 'username'],
              submitted
            )
          "
          invalid-feedback="username cannot be blank"
          horizontal
          @input="$emit('update', programmaticTargetCopy)"
        />
        <CInput
          v-model="$v.programmaticTargetCopy.sipNumber.password.$model"
          :type="showSIPPassword ? 'text' : 'password'"
          label="SIP Password"
          add-label-classes="text-right pr-4"
          add-input-classes="w-25 col-sm-3"
          name="SIP Password"
          placeholder="SIP Password"
          :is-valid="
            checkIfValid(
              ['programmaticTargetCopy', 'sipNumber', 'password'],
              submitted
            )
          "
          invalid-feedback="password cannot be blank"
          horizontal
          @input="$emit('update', programmaticTargetCopy)"
        >
          <template #append-content>
            <span
              :style="{ cursor: 'pointer' }"
              @click="toggleSIPPasswordVisibility"
            >
              {{ showSIPPassword ? 'hide' : 'show' }}
            </span>
          </template>
        </CInput>
      </div>
    </div>


    <div>
      Bid Settings
      <hr>

      <CRow class="form-group">
        <CCol
          sm="3"
          class="text-right pr-4"
        >
          Bid Type
        </CCol>

        <CInputRadioGroup
          class="col-sm-9"
          add-label-classes="text-right pr-4"
          add-input-classes="w-25"
          :options="['Dynamic', 'Static']"
          :custom="true"
          :inline="true"
          :checked.sync="programmaticTargetCopy.bidSettings.bidType"
          @update:checked="$emit('update', programmaticTargetCopy)"
        />
      </CRow>

      <CInput
        v-if="!programmaticTargetCopy.bidSettings.isBidDynamic"
        v-model="$v.programmaticTargetCopy.bidSettings.staticBidAmount.$model"
        type="number"
        label="Static Bid"
        add-label-classes="text-right pr-4"
        add-input-classes="w-25"
        description="amount in USD"
        name="Static Bid"
        placeholder=""
        horizontal
        min="0"
        :is-valid="
          checkIfValid([
            'programmaticTargetCopy',
            'bidSettings',
            'staticBidAmount'
          ], formSubmitted)
        "
        invalid-feedback="Invalid bid amount"
        @input="$emit('update', programmaticTargetCopy)"
      />

      <CInput
        v-model="$v.programmaticTargetCopy.bidSettings.bidOnFailure.$model"
        type="number"
        label="Failure Bid"
        add-label-classes="text-right pr-4"
        add-input-classes="w-25"
        name="Failure Bid"
        description="amount in USD"
        placeholder=""
        horizontal
        min="0"
        :is-valid="
          checkIfValid([
            'programmaticTargetCopy',
            'bidSettings',
            'bidOnFailure'
          ], formSubmitted)
        "
        invalid-feedback="Invalid failure bid amount"
        @input="$emit('update', programmaticTargetCopy)"
      />

      <CRow class="form-group">
        <CCol
          sm="3"
          class="text-right pr-4"
        >
          Conversion Duration
        </CCol>

        <CInputRadioGroup
          class="col-sm-9"
          add-label-classes="text-right pr-4"
          add-input-classes="w-25"
          :options="['Dynamic', 'Static']"
          :custom="true"
          :inline="true"
          :checked.sync="
            programmaticTargetCopy.bidSettings.conversionDurationType
          "
          @update:checked="$emit('update', programmaticTargetCopy)"
        />
      </CRow>

      <CInput
        v-if="!programmaticTargetCopy.bidSettings.conversionCallLengthDynamic"
        v-model="
          $v.programmaticTargetCopy.bidSettings.conversionCallLengthInSeconds
            .$model
        "
        type="number"
        label="Call Length"
        add-label-classes="text-right pr-4"
        add-input-classes="w-25"
        name="Call Length"
        placeholder=""
        horizontal
        description="call duration in seconds"
        min="0"
        :is-valid="
          checkIfValid([
            'programmaticTargetCopy',
            'bidSettings',
            'conversionCallLengthInSeconds'
          ], formSubmitted)
        "
        invalid-feedback="Invalid call length"
        @input="$emit('update', programmaticTargetCopy)"
      />

      <CInput
        v-if="programmaticTargetCopy.bidSettings.conversionCallLengthDynamic"
        v-model="
          $v.programmaticTargetCopy.bidSettings.maxConversionCallDurationSeconds
            .$model
        "
        type="number"
        label="Duration Limit"
        add-label-classes="text-right pr-4"
        add-input-classes="w-25"
        name="Duration Limit"
        placeholder=""
        horizontal
        min="0"
        :is-valid="
          checkIfValid([
            'programmaticTargetCopy',
            'bidSettings',
            'maxConversionCallDurationSeconds'
          ], formSubmitted)
        "
        invalid-feedback="Invalid value for duration"
        @input="$emit('update', programmaticTargetCopy)"
      />
    </div>

    <div>
      Request Settings
      <hr>

      <CInput
        v-model="$v.programmaticTargetCopy.url.$model"
        type="string"
        label="URL"
        add-label-classes="text-right pr-4"
        add-input-classes="w-50"
        name="URL"
        placeholder=""
        :is-valid="checkIfValid(['programmaticTargetCopy', 'url'], formSubmitted)"
        horizontal
        invalid-feedback="Invalid URL"
        @input="$emit('update', programmaticTargetCopy)"
      />

      <CSelect
        id="buyer-campaign__destination__request-settings__request-type"
        :value.sync="$v.programmaticTargetCopy.method.$model"
        add-label-classes="text-right pr-4"
        add-input-classes="w-25"
        data-cy="buyer-campaign__destination__request-settings__request-type"
        label="Method"
        horizontal
        :options="requestTypes"
        placeholder="Please select"
        :is-valid="checkIfValid(['programmaticTargetCopy', 'method'], formSubmitted)"
        invalid-feedback="Please select method"
        @update:value="$emit('update', programmaticTargetCopy)"
      />

      <div v-if="canRequestHaveBody">
        <CInput
          v-model="programmaticTargetCopy.contentType"
          type="text"
          label="Content Type"
          add-label-classes="text-right pr-4"
          add-input-classes="w-50"
          name="Content Type"
          placeholder=""
          horizontal
          invalid-feedback=""
          @input="$emit('update', programmaticTargetCopy)"
        />

        <CTextarea
          v-model="programmaticTargetCopy.body"
          type="text"
          rows="7"
          label="Request Body"
          add-label-classes="text-right pr-4"
          add-input-classes="w-75"
          name="Request Body"
          placeholder=""
          horizontal
          invalid-feedback=""
          @input="$emit('update', programmaticTargetCopy)"
        />
      </div>

      <CRow class="form-group">
        <CCol
          sm="3"
          class="text-right pr-4"
        >
          Headers
        </CCol>
        <CCol sm="9">
          <CButton
            v-if="
              programmaticTargetCopy &&
                programmaticTargetCopy.headers &&
                programmaticTargetCopy.headers.length === 0
            "
            color="dark"
            size="sm"
            @click="addRequestHeader"
          >
            Add Headers
          </CButton>
          <div
            v-if="
              programmaticTargetCopy &&
                programmaticTargetCopy.headers &&
                programmaticTargetCopy.headers.length > 0
            "
          >
            <CRow
              v-for="(header, index) in programmaticTargetCopy.headers"
              :key="`custom-header-${index}`"
              class="pl-2"
            >
              <CInput
                v-model="header.key"
                @input="$emit('update', programmaticTargetCopy)"
              />
              <CInput
                v-model="header.value"
                class="ml-2"
                @input="$emit('update', programmaticTargetCopy)"
              />
              <span @click="addRequestHeader">
                <CIcon
                  style="cursor: pointer;"
                  class="ml-2 my-2"
                  name="cil-plus"
                />
              </span>
              <span @click="removeRequestHeader(index)">
                <CIcon
                  style="cursor: pointer;"
                  class="ml-2 my-2"
                  name="cil-minus"
                />
              </span>
            </CRow>
          </div>
        </CCol>
      </CRow>
    </div>

    <div v-if="programmaticTargetCopy.isNumberDynamic">
      Dynamic Number Parsing
      <hr>

      <ProgrammaticTargetParsersList
        :submitted="formSubmitted"
        :parsers="programmaticTargetCopy.dynamicDialSettingParser"
        @update="updateDynamicNumberParser"
      />
    </div>

    <div v-if="programmaticTargetCopy.bidSettings.isBidDynamic">
      Bid Parsing
      <hr>

      <ProgrammaticTargetParsersList
        :submitted="formSubmitted"
        :parsers="programmaticTargetCopy.dynamicBidAmountParser"
        @update="updateDynamicBidAmountParser"
      />
    </div>

    <div>
      Call Acceptance Parsing
      <hr>

      <ProgrammaticTargetParsersList
        :submitted="formSubmitted"
        :parsers="programmaticTargetCopy.shouldAcceptCallParser"
        @update="updateDynamicCallAcceptParser"
      />
    </div>

    <div v-if="programmaticTargetCopy.bidSettings.conversionCallLengthDynamic">
      Dynamic Duration Parsing
      <hr>

      <ProgrammaticTargetParsersList
        :submitted="formSubmitted"
        :parsers="programmaticTargetCopy.dynamicCallLengthSettingParser"
        @update="updateDynamicDurationParser"
      />
    </div>
  </div>
</template>

<script>
import ProgrammaticTargetParsersList from './ProgrammaticTargetParsersList.vue'
import customURLValidator  from '@/validators/customURLValidator'
import { required, minValue, requiredIf } from 'vuelidate/lib/validators'
import formMixins from '@/mixins/form-mixins'

export default {
  name: 'ProgrammaticTargetForm',
  components: {
    ProgrammaticTargetParsersList
  },
  mixins: [formMixins],
  props: {
    formSubmitted: {
      type: Boolean,
      default: false
    },
    programmaticTarget: {
      type: Object,
      default: () => {}
    }
  },

  validations() {
    return {
      programmaticTargetCopy: {
        bidSettings: {
          staticBidAmount: {
            required,
            minValue: minValue(0)
          },
          bidOnFailure: {
            required,
            minValue: minValue(0)
          },
          conversionCallLengthInSeconds: {
            required,
            minValue: minValue(0)
          },
          maxConversionCallDurationSeconds: {
            minValue: minValue(0)
          }
        },
        url: {
          required,
          customURLValidator
        },
        method: { required },
        number: {
          requiredIf: requiredIf(() => {
            return this.programmaticTargetCopy.destinationType === 'Number'
          })
        },
        sipNumber: {
          endpoint: {
            requiredIf: requiredIf(() => {
              return this.programmaticTargetCopy.destinationType === 'SIP'
            }),
            customURLValidator
          },
          username: {
            requiredIf: requiredIf(() => {
              return this.programmaticTargetCopy.destinationType === 'SIP' && !!this.programmaticTargetCopy.sipNumber?.password
            }),
          },
          password: {
            requiredIf: requiredIf(() => {
              return this.programmaticTargetCopy.destinationType === 'SIP' && !!this.programmaticTargetCopy.sipNumber?.username
            }),
          }
        }
      }
    }
  },

  data() {
    return {
      showSIPPassword: false,
      requestTypes: [
        { value: 'get', label: 'GET' },
        { value: 'post', label: 'POST' },
        { value: 'put', label: 'PUT' },
        { value: 'patch', label: 'PATCH' },
        { value: 'delete', label: 'DELETE' }
      ],
      programmaticTargetCopy: {
        bidSettings: {}
      }
    }
  },
  computed: {
    canRequestHaveBody() {
      const methodsThatCanHaveBody = new Set(['post', 'put', 'patch', 'delete'])
      return methodsThatCanHaveBody.has(this.programmaticTargetCopy.method)
    }
  },
  watch: {
    programmaticTarget: {
      immediate: true,
      handler(newValue) {
        this.programmaticTargetCopy = {
          ...newValue,
          bidSettings: {
            ...newValue.bidSettings,
            bidType: newValue.bidSettings.isBidDynamic ? 'Dynamic' : 'Static',
            conversionDurationType: newValue.bidSettings
              .conversionCallLengthDynamic
              ? 'Dynamic'
              : 'Static'
          },
          headers: newValue.headers
            ? Object.entries(newValue.headers).map(entry => ({
                key: entry[0],
                value: entry[1]
              }))
            : []
        }
      }
    }
  },
  methods: {
    onDestinationTypeChanged() {
      if (this.programmaticTargetCopy.destinationType === 'SIP') {
        this.programmaticTargetCopy.sipNumber = {}
      }

      this.$emit('update', this.programmaticTargetCopy)
    },

    addRequestHeader() {
      this.programmaticTargetCopy.headers.push({
        key: '',
        value: ''
      })
    },
    removeRequestHeader(index) {
      this.programmaticTargetCopy.headers.splice(index, 1)
      this.$emit('update', this.programmaticTargetCopy)
    },
    updateDynamicNumberParser(parsers) {
      this.programmaticTargetCopy.dynamicDialSettingParser = parsers
      this.$emit('update', this.programmaticTargetCopy)
    },
    updateDynamicBidAmountParser(parsers) {
      this.programmaticTargetCopy.dynamicBidAmountParser = parsers
      this.$emit('update', this.programmaticTargetCopy)
    },
    updateDynamicCallAcceptParser(parsers) {
      this.programmaticTargetCopy.shouldAcceptCallParser = parsers
      this.$emit('update', this.programmaticTargetCopy)
    },
    updateDynamicDurationParser(parsers) {
      this.programmaticTargetCopy.dynamicCallLengthSettingParser = parsers
      this.$emit('update', this.programmaticTargetCopy)
    },
    toggleSIPPasswordVisibility() {
      this.showSIPPassword = !this.showSIPPassword
    },
  }
}
</script>

<style lang="scss" scoped></style>
