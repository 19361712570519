<template>
  <CCard>
    <CCardHeader>
      Two Factor Auth
    </CCardHeader>
    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          horizontal
          size="sm"
          label=""
          @click="initiateMFASetup"
        >
          Update
        </ButtonLoading>


        <CRow v-if="MFASecretKey">
          <CCol
            offset-sm="4"
            offset-lg="3"
            sm="5"
            lg="4"
          >
            <canvas
              id="canvas"
              class="my-2"
            />

            <div class="mb-2">
              <div
                class="form-input-label mr-2"
              >
                Secret Key
              </div>
              <div>{{ MFASecretKey }}</div>
            </div>

            <CInput
              id="software-token"
              v-model="details.softwareToken"
              name="Software Token"
              placeholder="Enter the 6 digit MFA code"
              class="mt-4 d-block"
              label="Code"
              type="text"
              required
            >
              <template v-slot:helpText>
                <b-form-text>
                  <div>
                    Enter the code from Google Authenticator below and click
                    'Verify Code' button to complete the MFA setup
                  </div>
                </b-form-text>
              </template>
            </CInput>

            <ButtonLoading
              id="button-complete-mfa"
              data-cy="button-complete-mfa"
              :loading="verifyButtonLoading"
              button-class="mt-2"
              type="submit"
              color="primary"
              :disabled="!details.softwareToken"
              @click="completeMFASetup"
            >
              Verify Code
            </ButtonLoading>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import QRCode from 'qrcode'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

function qrCodeToCanvas(canvas, value) {
  return new Promise((resolve, reject) => {
    try {
      QRCode.toCanvas(canvas, value)
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export default {
  name: 'TwoFactorAuth',

  title: 'Setup MFA',

  components: {},

  data() {
    return {
      isLoading: false,
      verifyButtonLoading: false,
      details: {
        softwareToken: ''
      }
    }
  },
  computed: {
    ...mapState('auth', ['MFASecretKey', 'authenticatedUserDetails'])
  },
  created() {
    this.resetMFAKey()
  },
  methods: {
    ...mapActions('auth', [
      'associateSoftwareMFA',
      'verifySoftwareMFA',
      'resetMFAKey'
    ]),
    async initiateMFASetup() {
      try {
        this.loading = true
        await this.associateSoftwareMFA()
        const canvas = document.getElementById('canvas')
        const email = this.authenticatedUserDetails.email
        const value = `otpauth://totp/RingbaX:${email}?secret=${this.MFASecretKey}&issuer=RingbaX`
        return qrCodeToCanvas(canvas, value)
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.loading = false
      }
    },

    async completeMFASetup() {
      try {
        this.verifyButtonLoading = true
        const softwareToken = this.details.softwareToken
        await this.verifySoftwareMFA(softwareToken)
        showSuccessMessage('Software MFA Setup Completed')
        this.resetMFAKey()
      } catch (error) {
        showErrorMessage(error.response?.data?.title)
      } finally {
        this.verifyButtonLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
