<template>
  <CCard>
    <CCardHeader>
      Audience
    </CCardHeader>
    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <!-- <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Line Type
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="audienceDetailsLocal.lineTypeSelected"
              color="primary"
              @update:checked="formEdited = true"
            />
          </CCol>
        </CRow>

        <CRow
          v-if="audienceDetailsLocal.lineTypeSelected"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Line Types
          </CCol>
          <CCol>
            <v-multiselect-listbox
              id="input-line-types"
              v-model="audienceDetailsLocal.lineTypes"
              data-cy="multiselect-line-types"
              class="msl-multi-select--full-width"
              :options="lineTypesOptions"
              search-input-class=""
              search-options-placeholder="Search"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No line type selected"
              :show-select-all-buttons="true"
              @change="formEdited = true"
            />
          </CCol>
        </CRow> -->

        <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Location
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="audienceDetailsLocal.searchLocation"
              color="primary"
              @update:checked="handleSearchLocationChange"
            />
          </CCol>
        </CRow>

        <CRow 
          v-if="audienceDetailsLocal.searchLocation"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            No ICP
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="audienceDetailsLocal.noIcp"
              color="primary"
              @update:checked="handleNoIcpChange"
            />            
          </CCol>
        </CRow>

        <CRow 
          v-if="audienceDetailsLocal.searchLocation && !audienceDetailsLocal.noIcp"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Location Type
          </CCol>
          <CCol sm="3">
            <CInputRadioGroup
              class="col-sm-9 mt-1"
              add-label-classes="text-right pr-4"
              add-input-classes="w-25"
              :options="['States', 'ZipCodes']"
              :custom="true"
              :inline="true"
              :checked.sync="audienceDetailsLocal.locationFilterType"
              @update:checked="formEdited = true"
            />
          </CCol>
        </CRow>        

        <CRow
          v-if="showLocationStates"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          />
          <CCol>
            <v-multiselect-listbox              
              id="input-search-locations"
              v-model="audienceDetailsLocal.selectedLocations"
              data-cy="multiselect-search-locations"
              class="msl-multi-select--full-width"
              :options="stateOptions"
              :reduce-display-property="(option) => option.label"
              :reduce-value-property="(option) => option.value"
              search-input-class=""
              search-options-placeholder="Search states"
              selected-options-placeholder="Search selected states"
              selected-no-options-text="No location selected"
              :show-select-all-buttons="true"
              no-options-text="No states"            
              @change="formEdited = true"
            />
          </CCol>          
        </CRow>

        <CRow 
          v-if="showLocationZipCodes"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Upload zip codes list
          </CCol>
          <CCol sm="5">
            <CInputFile
              v-if="showLocationZipCodes"        
              id="upload-zip-codes"
              class="mt-1"
              custom
              add-label-classes="text-right pr-4"
              rows="1"
              invalid-feedback=""
              accept=".txt,.csv,.csv"
              data-cy="doc"
              :horizontal="true"
              :placeholder="zipCodeFileNamePlaceholder"
              @change="parseZipCodes"
            />
          </CCol>
        </CRow>                    

        <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Gender
          </CCol>

          <CCol
            sm="9"
            class="ml"
          >
            <CSwitch
              shape="pill"
              color="primary"                           
              :checked.sync="enableGender"
              @update:checked="formEdited = true"
            />

            <CInputRadioGroup
              v-if="enableGender"
              class="col-sm-9 mt-1"
              add-label-classes="text-right pr-4"
              add-input-classes="w-25"
              :options="['All', 'Men', 'Women']"
              :custom="true"
              :inline="true"
              :checked.sync="audienceDetailsLocal.gender"
              label="Routing"
              @update:checked="formEdited = true"
            />
          </CCol>
        </CRow>

        <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Age
          </CCol>
          
          <CCol
            sm="9"
            class="ml"
          >
            <CSwitch
              shape="pill"
              color="primary"                           
              :checked.sync="enableAge"
              @update:checked="formEdited = true"
            />

            <RangeSelector
              v-if="enableAge"
              v-model="audienceDetailsLocal.ageRange"
              :min-options="minAges"
              :max-options="maxAges"
              class="mt-2"
              @change="formEdited = true"
            />
          </CCol>
        </CRow>

        <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Estimated Household Income
          </CCol>
          <CCol
            sm="9"
            class="ml"
          >
            <CSwitch
              shape="pill"
              color="primary"             
              :checked.sync="enableEstimatedHouseholdIncome"
              @update:checked="formEdited = true"
            />

            <RangeSelector
              v-if="enableEstimatedHouseholdIncome"
              v-model="audienceDetailsLocal.estimatedHouseholdIncomeRangeInDollars"
              :min-options="minIncomeOptions"
              :max-options="maxIncomeOptions"
              class="mt-2"
              @change="formEdited = true"
            />
          </CCol>
        </CRow>

        <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Homeowner
          </CCol>

          <CCol
            sm="9"
            class="ml"
          >
            <CSwitch
              shape="pill"
              color="primary"                           
              :checked.sync="enableHomeowner"
              @update:checked="formEdited = true"
            />
            
            <CInputRadioGroup
              v-if="enableHomeowner"
              class="col-sm-9 mt-1"
              add-label-classes="text-right pr-4"
              add-input-classes="w-25"
              :options="['Owner', 'Renter']"
              :custom="true"
              :inline="true"
              :checked.sync="audienceDetailsLocal.homeowner"
              label="Routing"
              @update:checked="formEdited = true"
            />        
          </CCol>
        </CRow>

        <!-- <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Telecom Carrier
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="audienceDetailsLocal.wirelessCarrierSelected"
              color="primary"
              @update:checked="formEdited = true"
            />
          </CCol>
        </CRow>

        <CRow
          v-if="audienceDetailsLocal.wirelessCarrierSelected"
          class="form-group"
        >
          <CCol
            sm="3"
            class="text-right pr-4"
          />
          <CCol>
            <v-multiselect-listbox
              id="input-wireless-carriers"
              v-model="audienceDetailsLocal.wirelessCarriers"
              data-cy="multiselect-wireless-carriers"
              class="msl-multi-select--full-width"
              :options="wirelessCarriersOptions"
              search-input-class=""
              search-options-placeholder="Search"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No carrier selected"
              :show-select-all-buttons="true"
              @change="formEdited = true"
            />
          </CCol>
        </CRow> -->

        <!-- <CRow class="form-group">
          <CCol
            sm="3"
            class="text-right pr-4"
          >
            Audience Expansion
          </CCol>
          <CCol sm="3">
            <CSwitch
              shape="pill"
              :checked.sync="audienceDetailsLocal.expandAudience"
              color="primary"
              @update:checked="formEdited = true"
            />
          </CCol>
        </CRow> -->

        <div class="row">
          <div class="col-sm-3 pr-4" />
          <div class="col-sm-9">
            <ButtonLoading
              color="primary"
              :loading="isLoading"
              :disabled="!formEdited"
              horizontal
              size="sm"
              label=""
              @click="updateAudience"
            >
              Update
            </ButtonLoading>
          </div>
        </div>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { showSuccessMessage } from '@/notification-utils'
import RangeSelector from '@/components/common/RangeSelector.vue'

export default {
  name: 'Audience',
  components: {
    RangeSelector
  },
  props: {
    campaignId: {
      type: String,
      default: ''
    },
    audienceDetails: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formEdited: false,
      isLoading: false,

      minAges: Array.from({ length: 48 }, (_, i) => (i + 18).toString()),

      minIncomeOptions: [
        '$0',
        '$20,000',
        '$30,000',
        '$40,000',
        '$50,000',
        '$75,000',
        '$100,000',
        '$125,000'
      ],
      maxIncomeOptions: [
        '$20,000',
        '$30,000',
        '$40,000',
        '$50,000',
        '$75,000',
        '$100,000',
        '$125,000+'
      ],           
      zipCodesFile: null,
      lineTypesOptions: ['Land Line', 'Pre-paid Wireless', 'VoIP', 'Wireless'],
      wirelessCarriersOptions: ['Verizon', 'AT&T', 'T-Mobile'],
      audienceDetailsLocal: {}
    }
  },
  computed: {

    ...mapState('country', ['usStates']),

    maxAges() {
      const arr = Array.from({ length: 48 }, (_, i) => (i + 18).toString())
      arr.push('65+')
      return arr
    },

    enableGender: {
      get() {
        return this.audienceDetailsLocal.gender != null
      },

      set(value) {
        if (value) {
          this.$set(this.audienceDetailsLocal, 'gender', 'All')          
        } else {
          this.$set(this.audienceDetailsLocal, 'gender', null)          
        }
      }
    },
    
    enableAge: {
      get() {
        return this.audienceDetailsLocal.ageRange != null
      },

      set(value) {
        if (value) {
          this.$set(this.audienceDetailsLocal, 'ageRange',  {
            min: this.minAges[0], 
            max: this.maxAges[this.maxAges.length - 1], 
            greaterThanEqualToMax: true})          
        } else {
          this.$set(this.audienceDetailsLocal, 'ageRange', null)          
        }
      }
    },

    enableEstimatedHouseholdIncome: {
      get() {
        return this.audienceDetailsLocal.estimatedHouseholdIncomeRangeInDollars != null
      },

      set(value) {
        if (value) {
          this.$set(this.audienceDetailsLocal, 'estimatedHouseholdIncomeRangeInDollars',  {
            min: this.minIncomeOptions[0], 
            max: this.maxIncomeOptions[this.maxIncomeOptions.length - 1], 
            greaterThanEqualToMax: true})          
        } else {
          this.$set(this.audienceDetailsLocal, 'estimatedHouseholdIncomeRangeInDollars', null)          
        }
      }
    },

    enableHomeowner: {
      get() {
        return this.audienceDetailsLocal.homeowner != null
      },

      set(value) {
        if (value) {
          this.$set(this.audienceDetailsLocal, 'homeowner',  'Owner')          
        } else {
          this.$set(this.audienceDetailsLocal, 'homeowner', null)
        }
      }
    },

    showLocationStates() {
      return this.audienceDetailsLocal.searchLocation && this.audienceDetailsLocal.locationFilterType === 'States'
    },

    showLocationZipCodes() {
      return this.audienceDetailsLocal.searchLocation && this.audienceDetailsLocal.locationFilterType === 'ZipCodes'
    },

    showPreviouslyUploadedZipCodes() {
      return !!this.audienceDetailsLocal.zipCodes && !!this.audienceDetailsLocal.zipCodes.length
    },

    zipCodeFileNamePlaceholder() {
      if (this.audienceDetailsLocal.zipCodesFileName) {
        return this.audienceDetailsLocal.zipCodesFileName
      } else {
        return "Upload file"
      }
    },
    
    stateOptions() {
      let stateOptions = []

      stateOptions = this.usStates.map(state => {
          return {
            value: state.abbreviation,
            label: state.name
          }
        })
      
      return stateOptions      
    }
  },
  watch: {
    audienceDetails: {
      immediate: true,
      handler(newValue) {
        this.audienceDetailsLocal = {
          ...newValue,
          lineTypeSelected: Boolean(newValue.lineTypes.length),
          searchLocation: Boolean(newValue.searchLocation),
          wirelessCarrierSelected: Boolean(newValue.wirelessCarriers.length),
          expandAudience: Boolean(newValue.expandAudience),
          ageRange: newValue.ageRange === null ? null : {
            ...newValue.ageRange,
            min: newValue.ageRange.min.toString(),
            max: newValue.ageRange.greaterThanEqualToMax
              ? `${newValue.ageRange.max}+`
              : newValue.ageRange.max.toString()
          },
          estimatedHouseholdIncomeRangeInDollars: newValue.estimatedHouseholdIncomeRangeInDollars === null ? null : {
            min: `$${newValue.estimatedHouseholdIncomeRangeInDollars.min.toLocaleString(
              'en-US'
            )}`,
            max: newValue.estimatedHouseholdIncomeRangeInDollars
              .greaterThanEqualToMax
              ? `$${newValue.estimatedHouseholdIncomeRangeInDollars.max.toLocaleString(
                  'en-US'
                )}+`
              : `$${newValue.estimatedHouseholdIncomeRangeInDollars.max.toLocaleString(
                  'en-US'
                )}`
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('campaign', ['updateCampaignDetails']),
    async updateAudience() {
      const campaignId = this.campaignId

      const updateObject = {
        audience: {
          ...this.audienceDetailsLocal,
          ageRange: tranformRangeFilterForAPI(this.audienceDetailsLocal.ageRange),
          estimatedHouseholdIncomeRangeInDollars: tranformRangeFilterForAPI(this.audienceDetailsLocal.estimatedHouseholdIncomeRangeInDollars)
        }
      }
      try {
        this.isLoading = true
        await this.updateCampaignDetails({ id: campaignId, updateObject })
        showSuccessMessage('Updated audience settings')
      } catch (error) {
        console.error(error.message)
      } finally {
        this.isLoading = false
        this.formEdited = false
      }
    },

    handleSearchLocationChange() {
      this.formEdited = true

      if (this.audienceDetailsLocal.selectedLocations == null) {
        this.audienceDetailsLocal.selectedLocations = []
      }
    },

    handleNoIcpChange() {
      this.formEdited = true
      if (this.audienceDetailsLocal.noIcp) {
        this.audienceDetailsLocal.locationFilterType = 'States'
      }
    },

    parseZipCodes(files) {
      this.zipCodesFile = files[0]
      const reader = new FileReader()
      reader.onload = file => {      
        const zipCodes = file.target.result.split(/[\s\t,]+/)        
        this.$set(this.audienceDetailsLocal, 'zipCodes', zipCodes) 
        this.$set(this.audienceDetailsLocal, 'zipCodesFileName', this.zipCodesFile.name)         
      }
      reader.readAsText(this.zipCodesFile)
      this.formEdited = true 
    }
  }
}

function tranformRangeFilterForAPI(range) {
  if (range === null){
    return null
  }

  return {
    min: Number(range.min.replace(/[$,]/g, '')),
    max: Number(range.max.replace(/[$+,]/g, '')),
    greaterThanEqualToMax: range.max.includes('+')
  }
}

</script>

<style lang="scss" scoped></style>
