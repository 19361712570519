import LanguagesAPI from '@/api/language.api'

const state = {
  languages: [],
  selectedRow: {}
}

const getters = {
  getActiveLanguages(state) {
    return state.languages.filter(c => c.active)
  }
}

const actions = {
  async list({ commit }) {
    const response = await LanguagesAPI.list()
    commit('SET_LANGUAGES', response.data)
  },
  async create(context, newLanguage) {
    const response = await LanguagesAPI.create(newLanguage)
    return response
  },
  async findOne({ commit }, id) {
    const response = await LanguagesAPI.findOne(id)
    commit('SET_SELECTED_ROW', response.data)
  },
  async update(context, updatedRow) {
    const response = await LanguagesAPI.patch(updatedRow.id, updatedRow)
    return response
  }
}

const mutations = {
  SET_LANGUAGES(state, languages) {
    state.languages = languages
  },
  SET_SELECTED_ROW(state, row) {
    state.selectedRow = row
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
