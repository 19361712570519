<template>
  <div>
    <vue-easy-lightbox
      :visible="lightboxVisible"
      :loop="false"      
      :imgs="getSelectedCreativeUrls"
      :index="lightboxIndex"
      @hide="lightboxVisible = false"
    />

    <CModal
      title="Confirm approval"
      :show.sync="warningApproveModal"
    >
      Are you sure you want to approve this creative?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="approveCreativeModal"
        >
          Approve
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningApproveModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm rejection"
      :show.sync="warningRejectModal"
    >
      Are you sure you want to reject this creative?
      <CTextarea        
        v-model="rejectionReason"
        class="mt-3"
        label="Rejection Reason"
        placeholder=""
        horizontal
        rows="5"
        required
        invalid-feedback="Please enter comments to save"
      />
      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          :disabled="!rejectionReason"
          @click="rejectCreativeModal"
        >
          Reject
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningRejectModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CCard>
      <div
        v-if="isLoading"
        :style="{ height: '80vh' }"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <CSpinner
          style="width:4rem;height:4rem;"
          color="info"
          grow
        />
      </div>
      <div v-else>
        <CCardHeader>
          Creative {{ selectedCreative.id }}
          <a
            href
            class="ml-1"
            @click.prevent="
              copyIdToClipboard(getSafe(() => selectedCreative.id))
            "
          ><CIcon
            name="cil-copy"
          /></a> 
        </CCardHeader>
        <CCardBody>
          <CForm>
            <dl class="row">
              <dt class="col-12 col-sm-3 text-sm-right">
                Account
              </dt>
              <dd class="col-sm-9">                
                <router-link
                  :to="{ name: 'SellerAccountDetails', params: { id: getSafe(() => selectedCreative.account.id)} }"
                  target="_blank"                 
                >
                  {{ getSafe(() => selectedCreative.account.name) }}
                </router-link>

                <a
                  href
                  class="ml-1"
                  @click.prevent="
                    copyAccountIdToClipboard(getSafe(() => selectedCreative.account.id))
                  "
                ><CIcon
                  name="cil-copy"
                /></a>  
              </dd>             
              <dt class="col-12 col-sm-3 text-sm-right">
                Name
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.name) }}
              </dd>
              <dt class="col-12 col-sm-3 text-sm-right">
                Status
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.status) }}
              </dd>            
              <dt 
                v-if="selectedCreative.externalId" 
                class="col-12 col-sm-3 text-sm-right"
              >
                External ID
              </dt>
              <dd 
                v-if="selectedCreative.externalId" 
                class="col-sm-9"
              >
                {{ getSafe(() => selectedCreative.externalId) }}
              </dd> 
              <dt 
                v-if="selectedCreative.cmsId" 
                class="col-12 col-sm-3 text-sm-right"
              >
                CMS ID
              </dt>
              <dd 
                v-if="selectedCreative.cmsId" 
                class="col-sm-9"
              >
                {{ getSafe(() => selectedCreative.cmsId) }}
              </dd>                       
              <dt 
                v-if="selectedCreative.landingPageUrl" 
                class="col-12 col-sm-3 text-sm-right"
              >
                Landing Page Url
              </dt>
              <dd 
                v-if="selectedCreative.landingPageUrl" 
                class="col-sm-9"
              >
                <a
                  v-if="Boolean(selectedCreative.landingPageUrl)"
                  :href="landingPage"
                  target="_blank"
                >
                  {{ selectedCreative.landingPageUrl }}
                </a>               
              </dd>           
              <dt class="col-12 col-sm-3 text-sm-right">
                Category
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.category.name) }}
              </dd>
              <dt class="col-12 col-sm-3 text-sm-right">
                Traffic source
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.trafficSource.name) }}
              </dd>
              <dt class="col-12 col-sm-3 text-sm-right">
                Country
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.country.name) }}
              </dd>
              <dt class="col-12 col-sm-3 text-sm-right">
                Language
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.language.name) }}
              </dd>  
              
              <dt class="mt-2 col-12 col-sm-3 text-sm-right">
                Images uploaded
              </dt>
              <dd class="col-sm-9 mt-2">
                <ButtonLoading
                  v-if="getSelectedCreativeUrls && getSelectedCreativeUrls.length"                  
                  type="button"
                  size="sm"
                  color="primary"
                  @click="lightboxVisible = true"
                >
                  Show images
                </ButtonLoading>
                <div 
                  v-else
                  class="mt-2 text-muted"
                >
                  No images
                </div>
              </dd>

              <dt
                v-if="selectedCreative && selectedCreative.status === 'ReviewPending'"
                class="mt-3 offset-sm-3 col-12 col-sm-3"
              >
                <ButtonLoading                  
                  type="button"
                  color="success"
                  @click="warningApproveModal = true"
                >
                  Approve
                </ButtonLoading>

                <ButtonLoading                  
                  type="button"
                  color="danger"
                  class="ml-3"            
                  @click="warningRejectModal = true"
                >
                  Reject
                </ButtonLoading>
              </dt>
            </dl>
            <ReviewNotes            
              :parent-object="selectedCreative"
            />
          </CForm>
        </CCardBody>        
      </div>
    </CCard>
  </div>
</template>

<script>

import {  mapState,  mapActions, mapGetters } from 'vuex'
import { showSuccessMessage, showErrorMessage } from '@/notification-utils'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import VueEasyLightbox from 'vue-easy-lightbox'
import ReviewNotes from '../ReviewNotes/ReviewNotes.vue'

export default {
  name: 'Creative',

  components: {
    VueEasyLightbox,
    ReviewNotes
  },

  mixins: [copyToClipboardMixin],

  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      isLoading: false,
      rejectionReason: '',
      warningApproveModal: false,
      warningRejectModal: false,    
      lightboxVisible: false,
      lightboxIndex: 0
    }
  },

  computed: {    
    ...mapState('creative', ['selectedCreative']), 
    ...mapGetters('creative', ['getSelectedCreativeUrls']),

    landingPage() {
      return this.handleURLProtocol(this.selectedCreative.landingPageUrl)
    },    
  },

  async created() {
    this.isLoading = true    
    await this.getCreativeById(this.id)   
    this.isLoading = false
  },

  methods: {    
    ...mapActions('creative', ['getCreativeById','approveCreative','rejectCreative']),

    async approveCreativeModal() {
      console.log('approveCreative')
      try {
        this.isLoading = true
        await this.approveCreative(this.selectedCreative.id)
        this.isLoading = false
        showSuccessMessage("Successfully approved creative")
      } catch(error) {
        showErrorMessage("Failed to approve creative")
      } finally {
        this.warningApproveModal = false
        this.$router.push({ name: 'ScreeningPendingCreatives' })
      }
    },

    async rejectCreativeModal() {
      try {
        this.isLoading = true
        await this.rejectCreative({ id: this.selectedCreative.id, reason: this.rejectionReason })
        this.isLoading = false
        showSuccessMessage("Successfully rejected creative")
      } catch(error) {
        showErrorMessage("Failed to reject creative")
      } finally {
        this.warningRejectModal = false
        this.$router.push({ name: 'ScreeningPendingCreatives' })
      }
    },

    copyIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Creative ID copied to clipboard')
    },

    copyAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Account ID copied to clipboard')
    },

    
  }
}
</script>