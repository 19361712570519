import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/resources/settings', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const GlobalSettingsAPI = {
  async getSettingValue({type, settingId}) {
    const url = `${type}/${settingId}`
    return httpClient.get(url)
  },

  async setSettingValue({type, settingId, value, note}) {
    const url = `${type}/${settingId}`
    return httpClient.put(url, {
      value,
      note
    })
  }
}

export default GlobalSettingsAPI

