import { mapActions } from 'vuex'

export default {
  methods: {

    ...mapActions('auth', ['resetLoginStageState', 'logout']),

    goBackToLoginPage() {
      this.resetLoginStageState()
      this.logout()
      this.$router.push('/login')
    }
  }
}
