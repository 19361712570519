<template>
  <div>
    <div
      v-if="showAccountName"
      class="d-flex align-items-center mb-3"
    >
      <CSwitch
        variant="3d"
        color="primary"
        horizontal
        size="sm"
        :checked.sync="includeTestOnly"
        class="mr-2"
      />
      <h5 class="m-0">
        Include Test Only Accounts
      </h5>
    </div>

    <CDataTable
      small
      border
      sorter
      column-filter
      :items="filteredPayments"
      :fields="fieldsAdjusted"
      :sorter-value="{ column: 'paymentDate', asc: false }"
      :items-per-page="10"
      :pagination="{ align: 'center' }"
    >
      <template #accountName="{ item }">
        <td>
          <router-link
            :to="{ name: 'SellerAccountDetails', params: { id: item.accountId} }"
            target="_blank"
          >
            <strong>{{ item.accountName }}</strong>

            <CIcon
              name="cil-external-link"
              size="custom"
              class="ml-1"
            />
          </router-link>
        </td>
      </template>
      <template #amountPaid="{ item }">
        <td class="text-right">
          {{
            formatAmount(item.amountPaid)
          }}
        </td>
      </template>

      <template #totalInvoicedAmount="{ item }">
        <td>
          <strong>{{
            getSafe(() => `${formatAmount(item.totalInvoicedAmount)}`)
          }}</strong>
        </td>
      </template>

      <template #paymentMethod="{ item }">
        <td>
          {{ getSafe(() => `${item.paymentMethod || 'N/A'}`) }}
        </td>
      </template>

      <template #paymentDate="{item}">
        <td class="text-nowrap">
          {{ item.formattedPaymentDate }}
        </td>
      </template>

      <template #invoiceId="{ item }">
        <td>
          <CButton
            v-if="item.invoiceId"
            size="sm"
            color="info"
            variant="outline"
            @click="getDownloadLink(item.accountId, item.invoiceId)"
          >
            {{ item.invoiceId }}
          </CButton>
        </td>
      </template>

      <template #transactionType-filter>
        <select
          v-model="transactionTypeFilter"
          class="form-control form-control-sm"
        >
          <option :value="undefined" />
          <option value="Payment">
            Payment
          </option>
          <option value="Manual Adjustment">
            Manual Adjustment
          </option>
        </select>
      </template>
    </CDataTable>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

import { formatAmount } from '@/utils'

export default {
  name: 'SellerPaymentHistoryList',

  props: {
    payments: {
      type: Array,
      default() { 
        return []
      }
    },

    showAccountName: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      transactionTypeFilter: undefined,
      includeTestOnly: false,
      fields: [
        { key: 'amountPaid', label: 'Amount Paid', _classes: 'text-right' },
        { key: 'paymentDate', label: 'Paid On' },
        { key: 'paidByName', label: 'Paid By' },
        { key: 'paymentDescription',  label: 'Description' },
        { key: 'invoiceId', label: 'Invoice #' },
        { key: 'transactionType' },
      ],
    }
  },

  computed: {
    fieldsAdjusted() {
      return this.showAccountName ? [{ key: 'accountName', label: 'Account' }, ...this.fields] : [...this.fields]
    },

    filteredPayments() {
      return this.payments.filter(p => {
        if (!this.includeTestOnly && p.isAccountTestOnly) {
          return false
        }

        if (this.transactionTypeFilter) {
          return p.transactionType === this.transactionTypeFilter
        }

        return true
      })
    }
  },

  methods: {
    ...mapActions('billing', [
      'generateDownloadLink', 
    ]),

    async getDownloadLink(accountId, invoiceId) {
      const downloadURL = await this.generateDownloadLink({
        accountId,
        invoiceId,
      })
      window.open(downloadURL)
    },

    formatAmount
  },
}
</script>
<style>
  
</style>