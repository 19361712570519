<template>
  <div
    class="code-container clickable"
    @click="copyCodeToClipboard"
  >
    <a
      class="code-container__copy btn btn-function btn-xs"
    >
      <CIcon
        name="cil-copy"
      />
    </a>

    <pre
      class="code-container__code"
    ><code>{{ codeText }}</code></pre>
  </div>
</template>

<script>
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'CodeContainer',

  mixins: [copyToClipboardMixin],

  props: {
    codeText: {
      type: String,
      default: ''
    }
  },

  methods: {
    copyCodeToClipboard() {
      this.copyTextToClipboard(this.codeText)
      showSuccessMessage('Copied')
    }
  },
}
</script>

<style lang="scss" scoped>
.code-container {
  position: relative;
}

.code-container__copy {
  position: absolute;
  right: 10px;
  top: 8px;
  padding: 3px 8px;
  background: #fff;
}

.code-container__code {
  background: #fff;
  padding: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}
</style>