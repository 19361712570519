<template>
  <div>
    <h4>Advance Pay Requests</h4>
    <CCard>
      <CCardHeader>
        <div class="d-flex align-items-center mb-3">
          <CSwitch
            variant="3d"
            color="primary"
            horizontal
            size="sm"
            class="mr-2"
            :checked.sync="pendingOnly"
            @update:checked="loadAdvancePayRequests"
          />
          <h5 class="m-0">
            Show Pending Only
          </h5>
        </div>

        <div>
          <CInput
            v-if="false"
            class="mt-2 ml-2"
            placeholder="Type to search by Advance Pay reference, company representative or email address"
            add-input-classes="col-6"
            :lazy="500"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <AdvancePayments
          :items="advancePayRequests"
          :is-loading="isLoading"
          @reload-advance-pay-requests="loadAdvancePayRequests"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AdvancePayments from './AdvancePayments.vue'

export default {
  name: 'AdvancePaymentsList',

  title: 'Advance Payments',

  components: {
    AdvancePayments
  },

  data() {
    return {
      isLoading: false,
      pendingOnly: true,
    }
  },

  computed: {
    ...mapState('accounts', [
      'advancePayRequests'
    ]),

  },

  async created() {
    await this.loadAdvancePayRequests()
  },

  methods: {
    ...mapActions('accounts', [
      'getAdvancePayRequests'
    ]),
    
    async loadAdvancePayRequests() {
      try {
        this.isLoading = true
        await this.getAdvancePayRequests({pendingOnly: this.pendingOnly})
      }
      finally {
        this.isLoading = false
      }
  },
  },
}
</script>
<style></style>
