import RegistrationChecklistItemConfigApi from '@/api/registration-checklist-item.api'
import { capitlizeFirstLetter } from '@/utils'

const state = {
  /**
   * @type {Array<import('./registration-checklist-item').RegistrationChecklistItemConfig>}
   */
  buyerRegistrationChecklistItemsConfig: [],

  /**
   * @type {Array<import('./registration-checklist-item').RegistrationChecklistItemConfig>}
   */
  sellerRegistrationChecklistItemsConfig: [],
}


/**
* @type {import('vuex').GetterTree<typeof state>}
*/
const getters = {
  
}


/**
* @type {import('vuex').ActionTree<typeof state>}
*/
const actions = {
  async getRegistrationChecklistItemsConfig({ commit }, type) {
    const response = await RegistrationChecklistItemConfigApi.getRegistrationChecklistItemsConfig(type.toLowerCase())

    if (type.toLowerCase() === 'buyer') {
      commit('SET_BUYER_REGISTRATION_CHECKLIST_ITEMS_CONFIG', response.data)
    } else {
      commit('SET_SELLER_REGISTRATION_CHECKLIST_ITEMS_CONFIG', response.data)
    }
  },

  async createRegistrationChecklistItemsConfig(context, config) {
    return await RegistrationChecklistItemConfigApi.createRegistrationChecklistItemsConfig({
      ...config,
      type: capitlizeFirstLetter(config.type)
    })
  },

  async updateRegistrationChecklistItemsConfig(context, config) {
    return await RegistrationChecklistItemConfigApi.updateRegistrationChecklistItemsConfig({
      ...config,
      type: capitlizeFirstLetter(config.type)
    })
  },

  async deleteRegistrationChecklistItemsConfig(context, id) {
    return await RegistrationChecklistItemConfigApi.deleteRegistrationChecklistItemsConfig(id)
  }
}


/**
* @type {import('vuex').MutationTree<typeof state>}
*/
const mutations = {
  SET_BUYER_REGISTRATION_CHECKLIST_ITEMS_CONFIG(state, items) {
    state.buyerRegistrationChecklistItemsConfig = items
  },
  SET_SELLER_REGISTRATION_CHECKLIST_ITEMS_CONFIG(state, items) {
    state.sellerRegistrationChecklistItemsConfig = items
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}