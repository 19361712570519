var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap"},[_c('div',[_c('h5',[_vm._v(" Buyers who're not actively participating "),_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: 'Buyers who do not have a buyer campaign',
          placement: 'right',
        }),expression:"{\n          content: 'Buyers who do not have a buyer campaign',\n          placement: 'right',\n        }"}],staticClass:"ml-1",attrs:{"name":"cil-info","size":"custom"}})],1),_c('div',{staticClass:"d-flex flex-column seller-offer-registration-list mr-5 mb-3"},[(_vm.buyersNotParticipatingActively.length)?_c('div',_vm._l((_vm.buyersNotParticipatingActively),function(buyer){return _c('div',{key:buyer.id,staticClass:"seller-offer-registration-list__item pl-3"},[_c('div',{staticClass:"d-flex py-1"},[_c('router-link',{staticClass:"text-truncate seller-offer-registration-list__item-account-name h5 mb-0",attrs:{"target":"_blank","title":"View Account","to":{
                name: 'BuyerAccountDetails',
                params: { id: buyer.id },
                exact: true,
              }}},[_vm._v(" "+_vm._s(buyer.name)+" "),_c('CIcon',{staticClass:"ml-1",attrs:{"name":"cil-external-link","size":"custom"}})],1),_c('a',{staticClass:"ml-2",attrs:{"href":"","title":"Copy Account Id"},on:{"click":function($event){$event.preventDefault();return _vm.copyAccountIdToClipboard(buyer.id)}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)],1)])}),0):_c('div',[_vm._m(0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"seller-offer-registration-list__item"},[_c('i',[_vm._v("All Buyers are actively participating")])])}]

export { render, staticRenderFns }