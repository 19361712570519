import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/tools', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const END_POINT = 'sales-channels'

const SalesChannelApi = {
    async getEncodedName(request) {
        const url = `${END_POINT}/encode`
        return await httpClient.post(url, request)
    },

    async block(request) {
        const url = `${END_POINT}/block`
        return await httpClient.post(url, request)
    },

    async unblock(request) {
        const url = `${END_POINT}/unblock`
        return await httpClient.post(url, request)
    },

    async getChannels(accountId) {
        const url = `${END_POINT}/${accountId}`
        return await httpClient.get(url)
    },

    async getChannel(searchId) {
        const url = `${END_POINT}/search/${searchId}`
        return await httpClient.get(url)
    }
}

export default SalesChannelApi