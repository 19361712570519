import formGenericMixin from "./form-generic-mixin"

export default {
  // This mixin has the following 'data' defined:
  // `isLoading` and `submitted`
  mixins: [formGenericMixin],

  data() {
    return {
      isLoadingStatusChange: false
    }
  },
  
  methods: {
    backToListView(entity) {
      this.$router.push(`/dashboard/${entity}`)
    },

    async submitUpdateForm({ updateFn, entity }) {
      this.submitted = true

      if (this.$v && this.$v.form) {
        this.$v.form.$touch()

        if (!this.isValid) {
          return
        }
      }

      const updatedObject = Object.assign({}, this.selectedRow, this.form)
      
      try {
        this.isLoading = true
        await updateFn(updatedObject)
        this.$router.push(`/dashboard/${entity}`)
      } catch (error) {
        console.error(error)
        this.submitted = false
      } finally {
        this.isLoading = false
      }
    },

    async submitCreateForm({ createFn, entity }) {
      this.submitted = true

      if (this.$v) {
        this.$v.form.$touch()

        if (!this.isValid) {
          return
        }
      }

      try {
        this.isLoading = true

        await createFn({
          ...this.form
        })

        this.$router.push(`/dashboard/${entity}`)
      } catch (error) {
        console.error(error)
        this.submitted = false
      } finally {
        this.isLoading = false
      }
    },
  }
}
