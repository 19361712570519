<template>
  <div>
    <h4>Buyer Payments</h4>
    <CCard>
      <AccountPayments
        :is-loading="isLoading"
        :payments="buyerAccountPayments"
        account-type="buyer"
      />
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { formatDate, formatAmount } from '@/utils'

import AccountPayments from './AccountPayments.vue'

export default {
  name: 'BuyerPayments',

  title: 'Buyer Payments',

  components: {
    AccountPayments
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapState('billing', [
      'buyerAccountPayments'
    ]),

  },

  async created() {
    try {
      this.isLoading = true
      await this.getBuyerAccountPayments()
    }
    finally {
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions('billing', [
      'getBuyerAccountPayments'
    ]),

    formatDate,
    formatAmount
  },
}
</script>
<style></style>
