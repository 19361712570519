import ParentCategoryAPI from '@/api/parentCategory.api'

const state = {
  parentCategories: [],
  selectedRow: {}
}

const getters = {
  getActiveParentCategories(state) {
    return state.parentCategories.filter(c => c.active)
  }
}

const actions = {
  async list({ commit }) {
    const response = await ParentCategoryAPI.list()
    commit('SET_PARENTCATEGORIES', response.data)
  },
  async findOne({ commit }, id) {
    const response = await ParentCategoryAPI.findOne(id)
    commit('SET_SELECTED_ROW', response.data)
  },
  async create(context, newParentCategory) {
    const response = await ParentCategoryAPI.create(newParentCategory)
    return response
  },
  async update(context, updatedRow) {
    const response = await ParentCategoryAPI.patch(updatedRow.id, updatedRow)
    return response
  }
}

const mutations = {
  SET_PARENTCATEGORIES(state, parentCategories) {
    state.parentCategories = parentCategories
  },
  SET_SELECTED_ROW(state, parentCategory) {
    state.selectedRow = parentCategory
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
