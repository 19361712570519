var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[(_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",style:({ height: '80vh' })},[_c('CSpinner',{staticClass:"custom-spinner",attrs:{"color":"info","grow":""}})],1):_c('div',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.caption)+" "),_c('CButton',{staticClass:"ml-2",attrs:{"size":"sm","color":"info","variant":"outline","pressed":_vm.enableAutoreload},on:{"click":_vm.toggleAutoReload}},[_vm._v(" Auto Reload ")])],1),_c('CCardBody',[_c('CDataTable',{attrs:{"small":"","fixed":"","border":"","sorter":"","items":_vm.items,"fields":_vm.fields,"items-per-page":10,"pagination":{align: 'center'}},scopedSlots:_vm._u([{key:"sellerOffer",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getStatusBadge(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"updatedAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return ("" + (_vm.formatDate(item.updatedAt))); }))+" ")])]}},{key:"createdBy",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return ((item.createdBy.name) + " " + (item.createdBy.lastName)); }))+" ")])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return ("" + (_vm.formatDate(item.createdAt))); }))+" ")])]}},{key:"Actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"info","size":"sm","to":(_vm.actionUri + "/" + (item.id))}},[_vm._v(" View Application ")])],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }