<template>
  <div>
    <CCard>
      <CCardHeader data-cy="header">
        Edit Country
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CInput
            data-cy="input-item-id"
            label="ID"
            :value="selectedRow.id"
            horizontal
            plaintext
          />
          <CInput
            data-cy="input-name"
            label="Name"
            :value="selectedRow.name"
            horizontal
            plaintext
          />
          <CInput
            data-cy="input-sub-id"
            label="SubId"
            :value="selectedRow.subId"
            horizontal
            plaintext
          />
          <CTextarea
            v-model="form.description"
            data-cy="input-description"
            label="Description"
            placeholder="Enter description"
            horizontal
            rows="3"
          />
          <DatePickerInput
            id="expiration-date"
            v-model="form.expirationDate"
            :min-date="new Date()"
            data-cy="input-expiration-date"
            label="Expiration Date"
            placeholder="Choose expiration date"
            horizontal
            readonly
          />
          
          <CSelect
            data-cy="input-status"
            label="Status"
            :horizontal="{
              label: 'col-sm-3',
              input: 'col-sm-4 col-lg-3'
            }"
            :value.sync="form.status"
            :options="statusCodes"
            placeholder="Please select"
          >
            <template
              #label-after-input
            >
              <div class="mt-2 d-flex">
                <CButton
                  v-if="form.status === 'Active'"
                  type="button"
                  color="warning"
                  size="sm"
                  @click="onStatusChange(statusActions.pause)"
                >
                  Pause
                </CButton>

                <CButton
                  v-if="form.status === 'Paused'"
                  type="button"
                  color="success"
                  size="sm"
                  @click="onStatusChange(statusActions.resume)"
                >
                  Resume
                </CButton>
              </div>
            </template>
          </CSelect>
        </CForm>
      </CCardBody>

      <CCardFooter>
        <CButton
          :disabled="submitted"
          type="submit"
          data-cy="button-submit"
          size="sm"
          color="primary"
          @click.prevent="
            submitUpdateForm({ updateFn: update, entity: 'countries' })
          "
        >
          <CSpinner
            v-if="submitted"
            color="light"
            size="sm"
          />
          <CIcon
            v-else
            name="cil-check-circle"
          /> Submit
        </CButton>
        <CButton
          class="ml-2"
          :disabled="submitted"
          data-cy="button-back"
          type="reset"
          size="sm"
          color="danger"
          @click="backToListView('countries')"
        >
          <CIcon name="cil-arrow-circle-left" /> Back
        </CButton>
      </CCardFooter>
    </CCard>

    <CModal
      :title="`Confirm ${newStatusAction}`"
      color="warning"
      :show.sync="confirmStatusChangeModal"
    >
      Are you sure you want to {{ newStatusAction }} traffic on this country?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoadingStatusChange"
          class="text-capitalize"
          @click="confirmActionChange"
        >
          {{ newStatusAction }}
        </ButtonLoading>

        <CButton
          color="danger"
          @click="confirmStatusChangeModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import formMixins from '@/mixins/form-mixins'

const statusActions = {
  pause: 'pause',
  resume: 'resume'
}

export default {
  name: 'CountryEdit',

  title: 'Edit country',

  mixins: [formMixins],

  data() {
    return {
      form: {
        description: '',
        expirationDate: null,
        status: null
      },

      statusActions,

      newStatusAction: '',

      confirmStatusChangeModal: false,
    }
  },
  computed: {
    ...mapState('country', ['selectedRow']),
    ...mapState(['statusCodes'])
  },
  async created() {
    this.isLoading = true

    await this.findOne(this.$route.params.id)

    Object.keys(this.form).forEach(key => {
      if (key === 'expirationDate') {
        this.form[key] = this.selectedRow[key]
          ? this.selectedRow[key].slice(0, 10)
          : null
      } else {
        this.form[key] = this.selectedRow[key]
      }
    })

    this.isLoading = false
  },
  methods: {
    ...mapActions('country', ['findOne', 'update']),

    onStatusChange(newAction) {
      this.newStatusAction = newAction

      this.confirmStatusChangeModal = true
    },

    // TODO: Move this to mixin
    async confirmActionChange() {
      this.isLoadingStatusChange = true

      try {
        if (this.newStatusAction === this.statusActions.pause) {
          await this.update({
            ...this.selectedRow,
            status: 'Paused'
          })
        } else if (this.newStatusAction === this.statusActions.resume) {
          await this.update({
            ...this.selectedRow,
            status: 'Active'
          })
        }

        this.confirmStatusChangeModal = false

        this.isLoadingStatusChange = false

        this.$router.push(`/dashboard/countries`)
      } catch(e) {
        console.error(e)
      }
    }
  },
}
</script>

<style lang="scss" scoped></style>
