import Vue from 'vue'
import VueRouter from 'vue-router'

// Import store
import store from '@/store'

import LayoutDefault from '@/layouts/LayoutDefault.vue'
import Dashboard from '@/views/Dashboard.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import Categories from '@/views/Categories.vue'
import TrafficSources from '@/views/TrafficSources.vue'
import ParentCategories from '@/views/ParentCategories.vue'
import Countries from '@/views/Countries.vue'
import Languages from '@/views/Languages.vue'
import RegistrationRequests from '@/views/RegistrationRequests/RegistrationRequests.vue'
import RegistrationRequestDetails from '@/views/RegistrationRequests/RegistrationRequestDetails.vue'
import SellerOfferPendingReviewRequests from '@/views/SellerOffers/PendingRegistrations.vue'
import SellerOfferPendingReviewRequestDetails from '@/views/SellerOffers/PendingRegistrationDetails.vue'
import SellerOfferApprovedRegistrationDetails from '@/views/SellerOffers/SellerOfferApprovedRegistrationDetails.vue'

import SellerOffers from '@/views/SellerOffers.vue'
import SellerOfferCreate from '@/components/creation-forms/SellerOfferCreate.vue'
import SellerOfferEdit from '@/components/editing-forms/SellerOfferEdit.vue'

import KYCRequests from '@/views/KYCRequests/KYCRequests.vue'
import KYCRequestDetails from '@/views/KYCRequests/KYCRequestDetails.vue'
import CategoryCreate from '@/components/creation-forms/CategoryCreate.vue'
import LanguageCreate from '@/components/creation-forms/LanguageCreate.vue'
import TrafficSourceCreate from '@/components/creation-forms/TrafficSourceCreate.vue'
import CountryCreate from '@/components/creation-forms/CountryCreate.vue'
import ParentCategoryCreate from '@/components/creation-forms/ParentCategoryCreate.vue'
import SuperAdminUsers from '@/views/Users/SuperAdminUsers.vue'
import SuperAdminUserCreate from '@/views/Users/SuperAdminUserCreate.vue'
import SellerAccounts from '@/views/Accounts/SellerAccounts.vue'
import BuyerAccounts from '@/views/Accounts/BuyerAccounts.vue'
import BuyerAccountDetails from '@/views/Accounts/BuyerAccountDetails.vue'
import BuyerAccountAddFunds from '@/views/Accounts/BuyerAccountAddFunds.vue'
import SellerAccountAddFunds from '@/views/Accounts/SellerAccountAddFunds.vue'
import SellerAccountDetails from '@/views/Accounts/SellerAccountDetails.vue'

import BuyerPaymentsList from '@/views/Billing/BuyerPaymentsList.vue'
import SellerPaymentsList from '@/views/Billing/SellerPaymentsList.vue'
import SellersPaymentHistory from '@/views/Billing/SellersPaymentHistory.vue'
import AccountPaymentDetails from '@/views/Billing/AccountPaymentDetails.vue'
import AdvancePaymentDetails from '@/views/Billing/AdvancePaymentDetails.vue'
import AdvancePaymentsList from '@/views/Billing/AdvancePaymentsList.vue'
import AccountSummary from '@/views/Billing/AccountSummary.vue'
import CategorySupplyDemand from '@/views/Categories/CategorySupplyDemand.vue'

import CategoryEdit from '@/components/editing-forms/CategoryEdit.vue'
import ParentCategoryEdit from '@/components/editing-forms/ParentCategoryEdit.vue'
import CountryEdit from '@/components/editing-forms/CountryEdit.vue'
import LanguageEdit from '@/components/editing-forms/LanguageEdit.vue'
import TrafficSourceEdit from '@/components/editing-forms/TrafficSourceEdit.vue'

import LayoutAuth from '@/layouts/LayoutAuth.vue'
import Login from '@/views/Login/Login.vue'
import LoginEmail from '@/views/Login/LoginEmail.vue'
import PasswordReset from '@/views/Login/PasswordReset.vue'
import SetNewPassword from '@/views/Login/SetNewPassword.vue'
import LoginChooseMFAType from '@/views/Login/LoginChooseMFAType.vue'
import LoginMFA from '@/views/Login/LoginMFA.vue'
import LoginEnterPermanentPassword from '@/views/Login/LoginEnterPermanentPassword.vue'
import { MFA_STAGES, CognitoGroups } from '@/store/modules/auth.store'

import BuyerCampaigns from '@/views/Campaigns/buyer/BuyerCampaigns.vue'
import PendingApprovalBuyerCampaigns from '@/views/Campaigns/buyer/PendingApprovalBuyerCampaigns.vue'
import BuyerCampaignDetails from '@/views/Campaigns/buyer/BuyerCampaignDetails.vue'

import TwoFactorAuth from '@/views/Auth/TwoFactorAuth.vue'

import RegistrationChecklistItemCreate from '@/views/RegistrationChecklistItem/RegistrationChecklistItemCreate.vue'
import RegistrationChecklistItemEdit from '@/views/RegistrationChecklistItem/RegistrationChecklistItemEdit.vue'
import RegistrationChecklistItems from '@/views/RegistrationChecklistItem/RegistrationChecklistItems.vue'

import ChannelNameSearch from '@/views/Tools/ChannelNameSearch.vue'
import SellerAccountPaymentHistory from '@/views/Billing/SellerAccountPaymentHistory.vue'

import ScreeningPendingCreatives from '@/views/Creatives/ScreeningPendingCreatives.vue'
import ScreenCreative from '@/views/Creatives/ScreenCreative.vue'
import Creatives from '@/views/Creatives/Creatives.vue'
import CreativeDetails from '@/views/Creatives/CreativeDetails.vue'
import CallLogs from '@/views/Calls/CallLogs.vue'

function checkIfRouteHasGroup(isAuthenticated, to) {
  if (isAuthenticated && to.meta.accessUserGroups?.length) {
    const userData = store.state.auth.userDetails

    return checkUserGroup(userData?.groups, to.meta.accessUserGroups)
  }

  return true
}

function checkUserGroup(userGroups, allowedAccessGroup) {
  return userGroups?.some(r=> allowedAccessGroup.includes(r))
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: Login,
    meta: {
      requiresAuth: false,
      redirectIfAlreadyLoggedIn: true,
      layout: LayoutAuth
    },
    children: [
      {
        path: '',
        name: 'LoginEmail',
        component: LoginEmail
      },
      {
        path: 'forgot-password',
        name: 'PasswordReset',
        component: PasswordReset
      },
      {
        path: 'set-new-password',
        name: 'SetNewPassword',
        component: SetNewPassword,
        props: route => ({ email: route.query.email })
      },
      {
        path: 'choose-mfa',
        name: 'LoginChooseMFAType',
        component: LoginChooseMFAType,
        beforeEnter(to, from, next) {
          const currentAuthChallenge = store.getters['auth/currentAuthChallenge']
          if (currentAuthChallenge !== MFA_STAGES.SELECT_MFA_TYPE) {
            next({
              name: 'LoginEmail'
            })
          } else {
            next()
          }
        }
      },
      {
        path: 'enter-mfa',
        name: 'LoginMFA',
        component: LoginMFA,
        beforeEnter(to, from, next) {
          const currentAuthChallenge = store.getters['auth/currentAuthChallenge']
          if (currentAuthChallenge !== MFA_STAGES.SMS_MFA && currentAuthChallenge !== MFA_STAGES.SOFTWARE_TOKEN_MFA) {
            next({
              name: 'LoginEmail'
            })
          } else {
            next()
          }
        }
      },
      {
        path: 'enter-permanent-password',
        name: 'LoginEnterPermanentPassword',
        component: LoginEnterPermanentPassword,
        beforeEnter(to, from, next) {
          const currentAuthChallenge = store.getters['auth/currentAuthChallenge']

          if (currentAuthChallenge !== MFA_STAGES.NEW_PASSWORD_REQUIRED) {
            next({
              name: 'LoginEmail'
            })
          } else {
            next()
          }
        }
      }
    ]
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/dashboard',
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      layout: LayoutDefault,
    },
    beforeEnter: async (to, from, next) => {
      checkIsMFASetup(next, to)
    },
    children: [
      {
        path: 'languages/:id/edit',
        name: 'LanguageEdit',
        component: LanguageEdit
      },
      {
        path: 'languages/create',
        name: 'LanguageCreate',
        component: LanguageCreate
      },
      {
        path: 'traffic-sources/:id/edit',
        name: 'TrafficSourceEdit',
        component: TrafficSourceEdit
      },
      {
        path: 'traffic-sources/create',
        name: 'TrafficSourceCreate',
        component: TrafficSourceCreate
      },
      {
        path: 'countries/:id/edit',
        name: 'CountryEdit',
        component: CountryEdit
      },
      {
        path: 'countries/create',
        name: 'CountryCreate',
        component: CountryCreate
      },
      {
        path: 'parent-categories/:id/edit',
        name: 'ParentCategoryEdit',
        component: ParentCategoryEdit
      },
      {
        path: 'mfa-setup',
        name: 'TwoFactorAuth',
        component: TwoFactorAuth
      },
      {
        path: 'parent-categories/create',
        name: 'ParentCategoryCreate',
        component: ParentCategoryCreate
      },
      {
        path: 'parent-categories',
        name: 'ParentCategories',
        component: ParentCategories
      },
      {
        path: 'categories/:id/edit',
        name: 'CategoryEdit',
        component: CategoryEdit
      },
      {
        path: 'categories/supply-demand([\\d]*)',
        name: 'CategorySupplyDemand',
        component: CategorySupplyDemand,
      },
      {
        path: 'categories/create',
        name: 'CategoryCreate',
        component: CategoryCreate
      },
      {
        path: 'categories',
        name: 'Categories',
        component: Categories
      },
      {
        path: 'traffic-sources',
        name: 'TrafficSources',
        component: TrafficSources
      },
      {
        path: 'seller-offers',
        name: 'Seller Offers',
        component: SellerOffers
      },
      {
        path: 'seller-offers/:id/edit',
        name: 'SellerOfferEdit',
        component: SellerOfferEdit
      },
      {
        path: 'seller-offers/:id/registration/:registrationId',
        name: 'SellerOfferRegistrationEdit',
        component: SellerOfferApprovedRegistrationDetails
      },
      {
        path: 'seller-offers/create',
        name: 'SellerOfferCreate',
        component: SellerOfferCreate
      },
      {
        path: 'countries',
        name: 'Countries',
        component: Countries
      },
      {
        path: 'languages',
        name: 'Languages',
        component: Languages
      },

      {
        path: 'seller-offers/review',
        name: 'SellerOfferPendingReviewRequests',
        component: SellerOfferPendingReviewRequests
      },

      {
        path: 'seller-offers/review/:id',
        name: 'SellerOfferPendingReviewRequestDetails',
        component: SellerOfferPendingReviewRequestDetails
      },

      {
        path: 'buyer-campaigns/review',
        name: 'PendingApprovalBuyerCampaigns',
        component: PendingApprovalBuyerCampaigns
      },

      {
        path: 'registration-requests/:type',
        name: 'RegistrationRequests',
        component: RegistrationRequests
      },

      {
        path: 'registration-requests/:type/:id',
        name: 'RegistrationRequestDetails',
        component: RegistrationRequestDetails
      },

      {
        path: 'kyc-requests',
        name: 'KYCRequests',
        component: KYCRequests
      },

      {
        path: 'kyc-requests/:id',
        name: 'KYCRequestDetails',
        component: KYCRequestDetails
      },

      {
        path: 'super-admin-users',
        name: 'SuperAdminUsers',
        component: SuperAdminUsers
      },

      {
        path: 'super-admin-users/create',
        name: 'SuperAdminUserCreate',
        component: SuperAdminUserCreate
      },

      {
        path: 'accounts/buyer',
        name: 'BuyerAccounts',
        component: BuyerAccounts
      },

      {
        path: 'accounts/seller',
        name: 'SellerAccounts',
        component: SellerAccounts
      },

      {
        path: 'billing/buyer',
        name: 'BuyerPaymentsList',
        component: BuyerPaymentsList
      },

      {
        path: 'billing/seller',
        name: 'SellerPaymentsList',
        component: SellerPaymentsList
      },

      {
        path: 'billing/seller/paid',
        name: 'SellersPaymentHistory',
        component: SellersPaymentHistory
      },

      {
        path: 'billing/advance-pay',
        name: 'AdvancePaymentsList',
        component: AdvancePaymentsList
      },

      {
        path: 'billing/payment/:accountId/:invoiceId',
        name: 'AccountPaymentDetails',
        component: AccountPaymentDetails
      },

      {
        path: 'billing/payment/:accountId/:advancePaymentId',
        name: 'AdvancePaymentDetails',
        component: AdvancePaymentDetails
      },

      {
        path: 'billing/account/summary',
        name: 'AccountSummary',
        component: AccountSummary
      },

      {
        path: 'accounts/buyer/:id',
        name: 'BuyerAccountDetails',
        component: BuyerAccountDetails
      },

      {
        path: 'accounts/buyer/add-funds/:accountId',
        name: 'BuyerAccountAddFunds',
        component: BuyerAccountAddFunds,
        props: true,
        meta: {
          accessUserGroups: [CognitoGroups.FINANCIAL_MANAGER, CognitoGroups.ADMIN]
        },
      },

      {
        path: 'accounts/seller/add-funds/:accountId',
        name: 'SellerAccountAddFunds',
        component: SellerAccountAddFunds,
        props: true,
        meta: {
          accessUserGroups: [CognitoGroups.FINANCIAL_MANAGER, CognitoGroups.ADMIN]
        },
      },

      {
        path: 'accounts/seller/:id',
        name: 'SellerAccountDetails',
        component: SellerAccountDetails
      },

      {
        path: 'accounts/seller/:id/payments',
        name: 'SellerAccountPaymentHistory',
        component: SellerAccountPaymentHistory
      },

      {
        path: 'campaigns/buyer',
        name: 'BuyerCampaigns',
        component: BuyerCampaigns
      },

      {
        path: 'campaigns/buyer/:id',
        name: 'BuyerCampaignDetails',
        component: BuyerCampaignDetails
      },

      {
        path: 'registration-checklist-items/:type',
        name: 'RegistrationChecklistItems',
        component: RegistrationChecklistItems,
      },

      {
        path: 'registration-checklist-item/:type/create',
        name: 'RegistrationChecklistItemCreate',
        component: RegistrationChecklistItemCreate,
      },

      {
        path: 'registration-checklist-item/:type/edit/:id',
        name: 'RegistrationChecklistItemEdit',
        component: RegistrationChecklistItemEdit,
      },

      {
        path: 'channel-name-search',
        name: 'ChannelNameSearch',
        component: ChannelNameSearch
      },

      {
        path: 'calls',
        name: 'Reporting',
        component: CallLogs
      },

      {
        path: 'creatives/review',
        name: 'ScreeningPendingCreatives',
        component: ScreeningPendingCreatives
      },

      {
        path: 'creatives/review/:id',
        name: 'ScreenCreative',
        component: ScreenCreative
      },
      {
        path: 'creatives',
        name: 'Creatives',
        component: Creatives
      },
      {
        path: 'creatives/:id',
        name: 'CreativeDetails',
        component: CreativeDetails
      },

    ]
  },

  { path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function checkIsMFASetup(next, to) {
  const isMFASetup = store.getters['auth/isMFASetup']

  if (!isMFASetup && to.name !== 'TwoFactorAuth' && to.matched.some((record) => record.meta.requiresAuth)) {
    next({
      name: 'TwoFactorAuth'
    })
  } else {
    next()
  }
}

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  let haveUserData = store.getters['auth/haveUserData']

  if (isAuthenticated && !haveUserData) {
    await store.dispatch('auth/getLoggedInUserDetails')
    haveUserData = store.getters['auth/haveUserData']
  }
  
  if (isAuthenticated && haveUserData) {
    await store.dispatch('auth/startPollingForNewAccessToken')
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    next({
      name: 'LoginEmail',
      query: { redirect: to.fullPath },
    })
  } else if (to.matched.some((record) => record.meta.redirectIfAlreadyLoggedIn) && isAuthenticated) {
    next({
      name: 'Dashboard'
    })
  } else {
    if (!checkIfRouteHasGroup(isAuthenticated, to)) {
      next({
        name: 'Dashboard'
      })
    }

    checkIsMFASetup(next, to)
  }
})

export default router
