<template>
  <div>
    <div
      v-if="!showInactiveSellers"
      class="d-flex flex-wrap"
    >
      <div>
        <h5 v-if="!showSearchSellerInput">
          Active Sellers
          <CButton
            size="sm"
            color="primary"
            class="ml-2"
            @click="onSearchSeller"
          >
            Search
          </CButton>
        </h5>

        <div
          v-if="showSearchSellerInput"
          class="search-input search-input--margin-bottom-sm w-fixed-200"
        >
          <CInput
            v-model="searchSeller"
            placeholder="Search Seller"
            add-wrapper-classes="mb-2"
            size="sm"
          />

          <CIcon
            name="cil-search"
            size="custom"
          />
        </div>

        <div class="d-flex flex-column seller-offer-registration-list mr-5 mb-3">
          <div class="seller-offer-registration-list__item pl-2">
            <strong>Offer Registrations</strong>
          </div>
          <div v-if="filteredSellerSideData.length">
            <div
              v-for="offerRegistration in filteredSellerSideData"
              :key="offerRegistration.sellerOfferRegistration.id"
              class="seller-offer-registration-list__item"
            >
              <div class="d-flex mb-3">
                <router-link
                  target="_blank"
                  title="View Account"
                  class="text-truncate seller-offer-registration-list__item-account-name h5 mb-0"
                  :to="{
                    name: 'SellerAccountDetails',
                    params: { id: offerRegistration.account.id },
                    exact: true,
                  }"
                >
                  {{ offerRegistration.account.name }}
                  <CIcon
                    name="cil-external-link"
                    size="custom"
                    class="ml-1"
                  />
                </router-link>
                <a
                  href
                  title="Filter by Seller Account"
                  class="ml-2 mr-2"
                  @click.prevent="
                    filterBySellerAccount(offerRegistration.account)
                  "
                ><CIcon
                  name="cil-filter"
                /></a>
              </div>
              <div>
                <router-link
                  target="_blank"
                  title="View Offer"
                  :to="{
                    name: 'SellerOfferEdit',
                    params: { id: offerRegistration.sellerOfferRegistration.sellerOffer.id },
                    exact: true,
                  }"
                >
                  <i>Offer:
                    <strong
                      class="seller-offer-registration-list__item-offer-name"
                    >
                      {{
                        offerRegistration.sellerOfferRegistration.sellerOffer.name
                      }}
                    </strong></i>
                  <CIcon
                    name="cil-external-link"
                    size="custom"
                    class="ml-1"
                  />
                </router-link>
                <a
                  href
                  title="Filter by Seller Offer"
                  class="ml-2 mr-2"
                  @click.prevent="
                    filterBySellerOffer(offerRegistration.sellerOfferRegistration.sellerOffer)
                  "
                ><CIcon
                  name="cil-filter"
                /></a>
                <CBadge :color="getStatusBadge(offerRegistration.sellerOfferRegistration)">
                  {{ getStatus(offerRegistration.sellerOfferRegistration) }}
                </CBadge>
              </div>

              <div>
                <CButton
                  size="sm"
                  color="primary"
                  variant="outline"
                  class="mt-3 mb-2 mr-2"
                  @click="filterByOfferRegistrationTrafficSources(offerRegistration.sellerOfferRegistration)"
                >
                  Filter by Offer Reg. Traffic Sources

                  <CIcon
                    v-c-tooltip="{
                      content: 'Traffic sources which have been approved for the seller for this seller offer',
                      placement: 'right',
                    }"
                    name="cil-info"
                    size="custom"
                    class="ml-1"
                  />
                </CButton>

                <CButton
                  v-if="offerRegistration.sellerOfferRegistration.status === 'Pending'"
                  color="info"
                  size="sm"
                  class="mt-3 mb-2"
                  variant="outline"
                  target="_blank"
                  :to="{
                    name: 'SellerOfferPendingReviewRequestDetails',
                    params: { id: offerRegistration.sellerOfferRegistration.id },
                    exact: true,
                  }"
                >
                  Review Offer Reg.

                  <CIcon
                    name="cil-external-link"
                    size="custom"
                    class="ml-1"
                  />
                </CButton>

                <CButton
                  v-else
                  color="info"
                  size="sm"
                  class="mt-3 mb-2"
                  variant="outline"
                  target="_blank"
                  :to="{
                    name: 'SellerOfferRegistrationEdit',
                    params: { id: offerRegistration.sellerOfferRegistration.sellerOffer.id, registrationId: offerRegistration.sellerOfferRegistration.id },
                    exact: true,
                  }"
                >
                  View Offer Reg.

                  <CIcon
                    name="cil-external-link"
                    size="custom"
                    class="ml-1"
                  />
                </CButton>
              </div>
            </div>
          </div>
          <div
            v-else
          >
            <div class="seller-offer-registration-list__item">
              <i>No Offer Registrations</i>
            </div>
          </div>
          <div class="seller-offer-registration-list__item pl-2">
            <strong>Seller Offers</strong>
          </div>
          <div v-if="sellerOffers.length">
            <div
              v-for="offer in sellerOffers"
              :key="offer.id"
              class="seller-offer-registration-list__item"
            >
              <router-link
                target="_blank"
                title="View Offer Registration"
                :to="{
                  name: 'SellerOfferEdit',
                  params: { id: offer.id },
                  exact: true,
                }"
              >
                {{ offer.name }}
                <CIcon
                  name="cil-external-link"
                  size="custom"
                  class="ml-1"
                />
              </router-link>
              <CBadge
                class="ml-3"
                :color="getOfferStatusBadge(offer.status)"
              >
                {{ offer.status }}
              </CBadge>

              <CButton
                color="info"
                size="sm"
                class="ml-3"
                @click="copySellerOfferLinkForSellersToClipboard(offer.id)"
              >
                Copy Link for Sellers

                <CIcon
                  name="cil-copy"
                  size="custom"
                  class="ml-1"
                />
              </CButton>
              <div
                class="mt-1"
              >
                <i>
                  {{ offer.visibility }} Offer
                  <CIcon
                    v-c-tooltip="{
                      content: getOfferVisibilityInfoTooltip(offer.visibility),
                      placement: 'right',
                      html: true
                    }"
                    name="cil-info"
                    size="custom"
                    class="ml-1"
                  />
                </i>
              </div>
            </div>
          </div>
          <div
            v-else
            class="seller-offer-registration-list__item"
          >
            <i>No Seller Offers</i>
          </div>
        </div>
      </div>

      <TrafficSourceList
        :traffic-sources="supplySideTrafficSources"
        allow-selection
      />
    </div>

    <CategorySupplyInactive
      v-else
      :sellers-with-no-visibility-to-offers="sellersWithNoVisibilityToOffers"
      :sellers-not-participating-actively="sellersNotParticipatingActively"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { showSuccessMessage } from '@/notification-utils'
import TrafficSourceList from './TrafficSourceList.vue'
import CategorySupplyInactive from './CategorySupplyInactive.vue'

export default {
  name: 'CategorySupplyDetails',

  components: {
    TrafficSourceList,
    CategorySupplyInactive
  },

  mixins: [copyToClipboardMixin],

  props: {
    sellerOffers: {
      type: Array,
      default() {
        return []
      }
    },
        
    supplySideData: {
      type: Array,
      default() {
        return []
      }
    },

    supplySideTrafficSources: {
      type: Array,
      default() {
        return []
      }
    },

    showInactiveSellers: {
      type: Boolean,
      default: false
    },

    sellersNotParticipatingActively: {
      type: Array,
      default() {
        return []
      }
    },

    sellersWithNoVisibilityToOffers: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      showSearchSellerInput: false,
      searchSeller: ''
    }
  },

  computed: {
    filteredSellerSideData() {
      return this.supplySideData.filter(so => !this.searchSeller || so.account.name.toLowerCase().includes(this.searchSeller.toLowerCase()))
    }
  },

  created () {
  },

  methods: {
    ...mapMutations('categorySupplyDemand', [
      'ADD_TO_SELLER_ACCOUNT_FILTERING',
      'ADD_TO_SELLER_OFFER_FILTERING',
      'ADD_MULTIPLE_TO_TRAFFIC_SOURCE_FILTERING'
    ]),

    copyAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Account ID copied to clipboard')
    },

    copySellerOfferLinkForSellersToClipboard(id) {
      this.copyTextToClipboard(`${this.CLIENT_SIDE_BASE_URL}/dashboard/campaigns/seller-available-offers?sellerOfferId=${id}`)
      showSuccessMessage('Link to this seller offer has been copied')
    },

    filterBySellerAccount(sellerAccount) {
      this.ADD_TO_SELLER_ACCOUNT_FILTERING(sellerAccount)
    },

    filterBySellerOffer(sellerOffer) {
      this.ADD_TO_SELLER_OFFER_FILTERING(sellerOffer)
    },

    /**
     * 
     * @param {import('@/store/category').OfferRegistration} offerRegistration 
     */
    filterByOfferRegistrationTrafficSources(offerRegistration) {
      this.ADD_MULTIPLE_TO_TRAFFIC_SOURCE_FILTERING(offerRegistration.approvedTrafficSources.map(ts => ({ id: ts.id, name: ts.name, type: ts.trafficSourceType })))
    },

    /**
     * @param {import('@/store/category').OfferRegistration} offerRegistration 
     */
    getStatusBadge(offerRegistration) {
      if (!offerRegistration.enabled && offerRegistration.status === 'Approved') {
        return 'warning'
      }

      switch (offerRegistration.status) {
      case 'Approved':
        return 'success'

      case 'Rejected':
      case 'ManuallyBlocked':
        return 'danger'

      case 'Withdrawn':
        return 'secondary'

      case 'Pending':
      default:
        return 'warning'
      }
    },

    /**
     * @param {import('@/store/category').OfferRegistration} offerRegistration 
     */
    getStatus(offerRegistration) {
      if (!offerRegistration.enabled && offerRegistration.status === 'Approved') {
        return 'Paused'
      }

      if (offerRegistration.status === 'ManuallyBlocked') {
        return 'Removed by Admin'
      }

      if (offerRegistration.status === 'Pending') {
        return 'Review Pending'
      }

      return offerRegistration.status
    },

    getOfferStatusBadge(status) {
      switch (status) {
      case 'Active':
        return 'success'

      case 'Disabled':
      default:
        return 'secondary'
      }
    },

    getOfferVisibilityInfoTooltip(visibility) {
      if (visibility === 'Public') {
        return `Offer will be visible to sellers who have been approved for the category.`
      } else {
        return `Offer will only be visible to sellers who have been approved for the category and have been <strong>explicitly given access to it.</strong>`
      }
    },

    onSearchSeller() {
      this.showSearchSellerInput = true
    },

  },
}
</script>
<style lang="scss">
.seller-offer-registration-list {
  border: 1px solid rgba(0, 0, 21, 0.2);
  min-width: 200px;
  height: 400px;
  min-height: min-content;
  overflow-y: auto;
  background: white;
}

.seller-offer-registration-list__item {
  border-bottom: 1px solid rgba(0, 0, 21, 0.2);
  padding: 8px 18px;

  &:nth-last-child(0) {
    border: 0px;
  }
}

.seller-offer-registration-list__item-account-name {
  max-width: calc(100%-30px);
}

.seller-offer-registration-list__item-offer-name {
  font-weight: 500;
}
</style>