<template>
  <div>
    <CCard>
      <CCardHeader>
        Seller Payment History for All Sellers
      </CCardHeader>

      <CCardBody>
        <div
          v-if="isLoading"
          :style="{ height: '80vh' }"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <CSpinner
            style="width: 4rem; height: 4rem"
            color="info"
            grow
          />
        </div>

        <div v-else>
          <SellerPaymentHistoryList
            :payments="paymentHistory"
            show-account-name
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import startCase from 'lodash.startcase'

import BillingAPI from '@/api/billing.api'
import SellerPaymentHistoryList from './SellerPaymentHistoryList.vue'
import { dateTimeFormat } from '@/utils'

function getPaidByFromDescriptionForManualAdjustment(description) {
  return description.split('(')?.[0]
}

export default {
  name: 'SellerAccountPaymentHistory',

  components: {
    SellerPaymentHistoryList,
  },

  data() {
    return {
      accountId: '',
      isLoading: false,

      paymentHistory: []
    }
  },

  computed: {
    ...mapState('accounts', [
      'sellerAccountDetails',
    ]),
  },

  async created () {
    document.title = `Seller Payment History`

    await this.getSellersPaymentHistory()
  },

  methods: {
    ...mapActions('accounts', [
      'getSellerAccountDetails',
    ]),

    async getSellersPaymentHistory() {
      try {
        this.isLoading = true
        const response = await BillingAPI.getSellersPaymentHistory()
  
        this.paymentHistory = response.data.map(payment => {
          return {
            ...payment,
            paidByName: payment.transactionType === 'ManualAdjustment' ? getPaidByFromDescriptionForManualAdjustment(payment.paymentDescription) : payment.paidBy?.name,
            formattedPaymentDate: dateTimeFormat.format(new Date(payment.paymentDate)),
            transactionType: startCase(payment.transactionType)
          }
        })
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
<style>
  
</style>