import createHttpClient from './httpClient'

const END_POINT = 'manage'

const baseUrl = new URL('/api/v1/creatives', process.env.VUE_APP_PUBLIC_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const CreativeApi = { 
  getScreeningPendingCreatives() {
    const url = `/${END_POINT}/pending`
    return httpClient.get(url)
  },
  getReviewedCreatives(showRejected) {
    const url = `/${END_POINT}?showRejected=${showRejected}`
    return httpClient.get(url)
  },
  getCreativeById(id) {
    const url = `/${END_POINT}/${id}`
    return httpClient.get(url)
  },  
  reviewCreative(id, payload) {
    const url = `/${END_POINT}/${id}/review`
    return httpClient.post(url, payload)
  },
}

export default CreativeApi
