<template>
  <div>
    <CForm
      novalidate
      @submit.prevent
    >
      <CRow>
        <CCol
          md="3"
          class="text-right pr-3 mt-2"
        >
          Document
        </CCol>

        <CCol md="3">
          <CInputFile
            ref="notesAttachment"
            class="mt-1"
            add-label-classes="text-right pr-4"
            rows="5"
            invalid-feedback=""
            accept=".jpg,.jpeg,.pdf,.png,.doc,.docx"
            data-cy="doc"
            @change="setFile"
          />
        </CCol>
      </CRow>

      <div class="form-group">
        <CTextarea
          v-model.trim="$v.description.$model"
          :is-valid="checkIfValid('description')"
          class="mt-2"
          label="Notes"
          add-label-classes="text-right pr-4"
          placeholder="Enter the notes here"
          horizontal
          rows="5"
          invalid-feedback="Please enter the notes (5 characters minimum)"
          data-cy="notes"
        />
      </div>

      <div class="form-row">
        <CCol
          :md="{ offset: '3', size: '9' }"
          :sm="{ offset: '3', size: '9' }"
        >
          <ButtonLoading
            type="submit"
            size="sm"
            color="primary"
            :loading="isLoading"
            data-cy="submit-notes"
            :disabled="!isValid"
            @click="onSubmit"
          >
            Add note
          </ButtonLoading>

          <CButton
            class="ml-2"
            size="sm"
            @click="clearFile"
          >
            Clear File
          </CButton>

          <CButton
            class="ml-2"
            size="sm"
            color="danger"
            @click="$emit('cancel')"
          >
            Cancel
          </CButton>
        </CCol>
      </div>
    </CForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'

import NotesApi from '@/api/notes.api'
import formGenericMixin from '@/mixins/form-generic-mixin'

export default {
  name: 'ReviewNotesForm',

  mixins: [formGenericMixin],

  props: {
    parentObject: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  validations: {
    description: {
      required,
      minLength: minLength(5)
    }
  },

  data() {
    return {
      isLoading: false,
      description: '',
      attachment: '',
    }
  },

  computed: {
    noteListId() {
      return this.parentObject?.noteListId
    }
  },

  methods: {
    ...mapActions('registrationRequests', [
      'generateDownloadLink'
    ]),
    
    async onSubmit() {
      this.$v.description.$touch()

      if (this.isValid) {
        this.isLoading = true

        try {

          await NotesApi.addReviewNotes({
            noteListId: this.noteListId,
            description: this.description,
            attachment: this.attachment,
            parentObjectId: this.parentObject?.id
          })

          this.resetForm()

          this.$emit('notes-added')
        } catch (error) {
          // Handle error
        } finally {
          this.isLoading = false
        }
      }
    },

    checkIfValid(fieldName) {
      const field = this.$v[fieldName]

      if (!field.$dirty) {
        return null
      }

      return !field.$invalid
    },

    setFile(files) {
      this.attachment = files[0]
    },

    async getDownloadLink(s3Key) {
      const downloadURL = await this.generateDownloadLink({ s3Key })
      window.open(downloadURL)
    },

    resetForm() {
      this.description = ''
      this.attachment = ''
      this.clearFile()
      
      this.resetFormDirtyState()
    },

    clearFile() {
      this.$refs['notesAttachment'].$el.children[0].value = ''
    }
  }
}
</script>

<style scoped lang="scss">

</style>
