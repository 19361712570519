<template>
  <div>
    <CTableWrapper
      :is-loading="isLoading"
      :items="customSortedItems"
      small
      fixed
      border
      sorter
      :fields="fields"
      icon="cil-tags"
      type="Category"
      caption="Categories"
      :sorter-value.sync="sorterColumn"
      column-filter
    >
      <template v-slot:pre-table>
        <div class="d-flex align-items-center mb-3">
          <CSwitch
            variant="3d"
            color="primary"
            horizontal
            size="sm"
            :checked.sync="includeTestOnly"
            class="mr-2"
          />

          <h5 class="m-0">
            Include Test Only Categories
          </h5>
        </div>
      </template>
    </CTableWrapper>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import testOnlyItemsMixin from '@/mixins/test-only-items-mixin'
import CTableWrapper from '@/components/common/Table.vue'

export default {
  name: 'Categories',

  title: 'Categories',

  components: {
    CTableWrapper
  },

  mixins: [testOnlyItemsMixin],

  data() {
    return {
      isLoading: false,

      sorterColumn: { column: 'name', asc: true },
      
      fields: [
        { key: 'status', label: 'Status', sortable: true },
        { key: 'parentCategoryStatus', label: 'Parent Status' },
        { key: 'id', label: 'ID', sortable: true },
        { key: 'parentCategory', label: 'Parent', sortable: true },
        { key: 'name', sortable: true },
        { key: 'subId', label: 'Sub ID', sortable: true },
        { key: 'languages', label: 'Languages', sorter: false },
        { key: 'Actions', filter: false }
      ]
    }
  },
  computed: {
    ...mapState('category', {
      items: 'categories'
    }),

    customSortedItems() {
      if (this.sorterColumn.column === 'parentCategory') {
        const items = [...this.filteredItems]

        return items.sort((itemA, itemB) => {
          return String.prototype.localeCompare.call(itemA.parentCategory.name, itemB.parentCategory.name) * (this.sorterColumn.asc ? 1 : -1)
        })
      }

      return this.filteredItems
    }
  },
  async created() {
    this.isLoading = true
    await this.list()
    this.isLoading = false
  },
  methods: {
    ...mapActions('category', ['list'])
  },
}
</script>
<style lang="scss" scoped></style>
