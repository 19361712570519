<template>
  <div>
    <CCard>
      <CCardHeader data-cy="header">
        Edit Language
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CInput
            data-cy="input-item-id"
            label="ID"
            :value="selectedRow.id"
            horizontal
            plaintext
          />
          <CInput
            data-cy="input-name"
            label="Name"
            :value="selectedRow.name"
            horizontal
            plaintext
          />
          <CInput
            data-cy="input-sub-id"
            label="SubId"
            :value="selectedRow.subId"
            horizontal
            plaintext
          />
          <CTextarea
            v-model="form.description"
            data-cy="input-description"
            label="Description"
            placeholder="Enter description"
            horizontal
            rows="3"
          />
          <DatePickerInput
            id="expiration-date"
            v-model="form.expirationDate"
            :min-date="new Date()"
            data-cy="input-expiration-date"
            label="Expiration Date"
            placeholder="Choose expiration date"
            horizontal
            readonly
          />

          <CSelect
            data-cy="input-status"
            label="Status"
            horizontal
            :value.sync="form.status"
            :options="statusCodes"
            placeholder="Please select"
          />
        </CForm>
      </CCardBody>
      <CCardFooter>
        <CButton
          :disabled="submitted"
          type="submit"
          data-cy="button-submit"
          size="sm"
          color="primary"
          @click.prevent="
            submitUpdateForm({ updateFn: update, entity: 'languages' })
          "
        >
          <CSpinner
            v-if="submitted"
            color="light"
            size="sm"
          />
          <CIcon
            v-else
            name="cil-check-circle"
          /> Submit
        </CButton>
        <CButton
          class="ml-2"
          :disabled="submitted"
          data-cy="button-back"
          type="reset"
          size="sm"
          color="danger"
          @click="backToListView('languages')"
        >
          <CIcon name="cil-arrow-circle-left" /> Back
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import formMixins from '@/mixins/form-mixins'
export default {
  name: 'LanguageEdit',
  mixins: [formMixins],
  data() {
    return {
      form: {
        description: '',
        expirationDate: null,
        status: null
      }
    }
  },
  computed: {
    ...mapState('language', ['selectedRow']),
    ...mapState(['statusCodes'])
  },
  async created() {
    this.isLoading = true
    await this.findOne(this.$route.params.id)
    Object.keys(this.form).forEach(key => {
      if (key === 'expirationDate') {
        this.form[key] = this.selectedRow[key]
          ? this.selectedRow[key].slice(0, 10)
          : null
      } else {
        this.form[key] = this.selectedRow[key]
      }
    })
    this.isLoading = false
  },
  methods: {
    ...mapActions('language', ['findOne', 'update'])
  },
}
</script>

<style lang="scss" scoped></style>
