<template>
  <CForm @submit.prevent>
    <div v-if="!internal">
      <h1>Login</h1>
      <p class="text-muted">
        Sign In to your account
      </p>
    </div>

    <CInput
      v-model="email"
      placeholder="Email"
      autocomplete="username email"
      type="email"
    >
      <template #prepend-content>
        <CIcon name="cil-user" />
      </template>
    </CInput>

    <CInput
      v-model="password"
      placeholder="Password"
      type="password"
      autocomplete="curent-password"
    >
      <template #prepend-content>
        <CIcon name="cil-lock-locked" />
      </template>
    </CInput>

    <CRow>
      <CCol
        col="6"
        class="text-left"
      >
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          type="submit"
          @click="onLogin"
        >
          Login
        </ButtonLoading>
      </CCol>

      <CCol
        v-if="!internal"
        col="6"
        class="text-right"
      >
        <CButton
          color="link"
          class="px-0"
          :to="`/login/forgot-password`"
        >
          Forgot password?
        </CButton>
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { MFA_STAGES } from '@/store/modules/auth.store'
import authViewMixin from '@/mixins/auth-view-mixin'

export default {
  name: 'LoginEmail',

  mixins: [authViewMixin],

  data() {
    return {
      email: '',
      password: '',
      isLoading: false
    }
  },

  computed: {
    ...mapState('auth', [
      'redirectToAfterLoginPath'
    ]),

    ...mapGetters('auth', [
      'currentAuthChallenge',
      'isAuthenticated'
    ])
  },

  created () {
    this.setRedirectToPathAfterLogin(this.$route.query.redirect)

    this.resetLoginStageState()

    if (!this.internal) {
      this.resetGlobalState()
      this.logout()
    }
  },

  methods: {
    ...mapActions('auth', [
      'login',
      'setRedirectToPathAfterLogin',
      'resetLoginStageState',
      'logout'
    ]),

    ...mapActions([
      'resetGlobalState'
    ]),

    async onLogin() {
      try {
        this.isLoading = true

        await this.login({ email: this.email, password: this.password })

        this.showNextStep()   
      } finally {
        this.isLoading = false
      }
    },

    showNextStep() {
      if (this.internal) {
        if (this.currentAuthChallenge === MFA_STAGES.ENTER_USER_CREDS) {
          this.$emit('success')
        }

        return
      }

      if (this.currentAuthChallenge === MFA_STAGES.SMS_MFA || this.currentAuthChallenge === MFA_STAGES.SOFTWARE_TOKEN_MFA) {
        this.$router.push({
          name: 'LoginMFA'
        })
      } else if (this.currentAuthChallenge === MFA_STAGES.SELECT_MFA_TYPE) {
        this.$router.push({
          name: 'LoginChooseMFAType'
        })
      } else if (this.currentAuthChallenge === MFA_STAGES.NEW_PASSWORD_REQUIRED) {
        this.$router.push({
          name: 'LoginEnterPermanentPassword'
        })
      } else {
        this.redirectToDashboard()
      }
    },

    redirectToDashboard() {
      if (this.redirectToAfterLoginPath) {
        this.$router.push(this.redirectToAfterLoginPath)
      } else {
        this.$router.push({
          name: 'Dashboard'
        })
      }
    }
  }
}
</script>

<style></style>
