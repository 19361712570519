<template>
  <CCard>
    <div
      v-if="isLoading"
      :style="{ height: '80vh' }"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <CSpinner
        style="width:4rem;height:4rem;"
        color="info"
        grow
      />
    </div>
    <div v-else>
      <CCardHeader
        data-cy="header"
      >
        <slot name="header">
          <CIcon :name="icon" /> {{ caption }}
          <CButton
            class="float-right"
            :data-cy="`button-create-${caption}`"
            size="sm"
            color="info"
            @click="
              $router.push({
                name: typeToComponentNamesMap[type].createComponent,
                exact: true
              })
            "
          >
            <CIcon
              size="sm"
              name="cil-plus"
            /> Create New
          </CButton>
        </slot>
      </CCardHeader>
      <CCardBody>
        <slot name="pre-table" />

        <CDataTable
          :data-cy="`table-${caption}`"
          :hover="hover"
          :striped="striped"
          :border="border"
          :fixed="fixed"
          :items="items"
          :fields="fields"
          :items-per-page="small ? 50 : 5"
          :dark="dark"
          :pagination="{align: 'center'}"
          :sorter-value.sync="sorterState"
          v-bind="$attrs"
        >
          <template #status="{item}">
            <td>
              <CBadge :color="getBadge(item.status)">
                {{ item.status }}
              </CBadge>
            </td>
          </template>

          <template #id="{item}">
            <td>
              {{ item.id }}
              <CBadge
                v-if="item.testOnly"
                color="danger"
                class="ml-2"
              >
                Test
              </CBadge>
            </td>
          </template>

          <template #createdAt="{item}">
            <td>
              {{ getSafe(() => `${formatDate(item.createdAt)}`) }}
            </td>
          </template>
          <template #updatedAt="{item}">
            <td>
              {{ getSafe(() => `${formatDate(item.updatedAt || item.createdAt)}`) }}
            </td>
          </template>
          <template #createdBy="{item}">
            <td>
              {{ getSafe(() => `${item.createdBy.name} ${item.createdBy.lastName}`) }}
            </td>
          </template>
          <template #updatedBy="{item}">
            <td>
              {{ getSafe(() => `${item.updatedBy.name} ${item.updatedBy.lastName}`) }}
            </td>
          </template>

          <template #Actions="{item}">
            <td>
              <router-link
                data-cy="link-edit-item"
                :to="{
                  name: typeToComponentNamesMap[type].editComponent,
                  params: { id: item.id },
                  exact: true
                }"
              >
                <CIcon
                  name="cil-pencil"
                />
              </router-link>
            </td>
          </template>

          <template #parentCategory="{item}">
            <td>
              {{ item.parentCategory.name }}
            </td>
          </template>

          <template #parentCategoryStatus="{item}">
            <td>
              <CBadge :color="getBadge(item.parentCategory.status)">
                {{ item.parentCategory.status }}
              </CBadge>
            </td>
          </template>

          <template #languages="{item}">
            <td>
              <span
                v-for="(ts, $idx) in item.languages"
                :key="ts.id"
              >
                {{ ts.name }}
                <span
                  v-if="$idx !== item.languages.length - 1"
                  class="mx-2"
                >|</span>
              </span>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </div>
  </CCard>
</template>

<script>
import { dateTimeFormat } from '@/utils'

export function getBadge(status) {
  switch (status) {
    case 'Active':
    case 'Approved':
      return 'success'

    case 'Disabled':
    case 'Paused':
      return 'secondary'

    case 'Expired':
    case 'Rejected':
    case 'ManuallyBlocked':
      return 'danger'

    case 'Pending':
      return 'warning'

    default:
      return 'primary'
  }
}

export default {
  name: 'Table',
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    fields: {
      type: Array,
      default() {
        return ['id', 'name']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    isLoading: Boolean,
    sorterValue: {
      type: Object,
      default() {
        return {}
      }
    },
    icon: {
      type: String,
      default: 'cil-grid'
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      typeToComponentNamesMap: {
        Category: {
          createComponent: 'CategoryCreate',
          editComponent: 'CategoryEdit'
        },
        ParentCategory: {
          createComponent: 'ParentCategoryCreate',
          editComponent: 'ParentCategoryEdit'
        },
        Country: {
          createComponent: 'CountryCreate',
          editComponent: 'CountryEdit'
        },
        TrafficSource: {
          createComponent: 'TrafficSourceCreate',
          editComponent: 'TrafficSourceEdit'
        },
        Language: {
          createComponent: 'LanguageCreate',
          editComponent: 'LanguageEdit'
        },
        SellerOffer: {
          createComponent: 'SellerOfferCreate',
          editComponent: 'SellerOfferEdit'
        }
      }
    }
  },

  computed: {
    sorterState: {
      get() {
        return this.sorterValue
      },

      set(value) {
        this.$emit('update:sorterValue', value)
      }
    }
  },
  methods: {
    getBadge,
    
    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    }
  }
}
</script>
