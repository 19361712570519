<template>
  <div class="d-flex flex-wrap">
    <div>
      <h5>
        All Buyers in Category
      </h5>
      <div class="d-flex flex-column seller-offer-registration-list mr-5 mb-3">
        <div v-if="allBuyers.length">
          <div
            v-for="buyer in allBuyers"
            :key="buyer.id"
            class="seller-offer-registration-list__item pl-3"
          >
            <div class="d-flex">
              <router-link
                target="_blank"
                title="View Account"
                class="text-truncate seller-offer-registration-list__item-account-name h5 mb-0"
                :to="{
                  name: 'BuyerAccountDetails',
                  params: { id: buyer.id },
                  exact: true,
                }"
              >
                {{ buyer.name }}
                <CIcon
                  name="cil-external-link"
                  size="custom"
                  class="ml-1"
                />
              </router-link>
              <a
                href
                title="Copy Account Id"
                class="ml-2"
                @click.prevent="
                  copyAccountIdToClipboard(buyer.id)
                "
              ><CIcon
                name="cil-copy"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'CategoryDemandAllBuyers',

  mixins: [copyToClipboardMixin],

  props: {
    allBuyers: {
      type: Array,
      default() {
        return []
      }
    },

  },

  data() {
    return {
    }
  },

  computed: {
  },

  methods: {
    copyAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Account ID copied to clipboard')
    },
  }
}
</script>
<style lang="scss" scoped>
</style>