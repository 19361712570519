import createHttpClient from './httpClient'
import BidModifierParentType from './../enums/BidModifierParentType.js'

const baseUrl = new URL('/api/v1/', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const BidModifiersAPI = {
  
    getBidModifiers(parentObjectType, parentObjectId) {
        let url = getEndPointByType(parentObjectType, parentObjectId)
        return httpClient.get(url)
    },

    setBidModifiers( bidModifiers, parentObjectType, parentObjectId ) {   
        let url = getEndPointByType(parentObjectType, parentObjectId)
        return httpClient.patch(url, { bidModifiers } )
    }
}

function getEndPointByType(parentObjectType, parentObjectId) {
    var endPoint = ''

    if (parentObjectType === BidModifierParentType.CATEGORY) {
        endPoint = `/resources/categories/${parentObjectId}/bidModifiers`
    }
    else if (parentObjectType === BidModifierParentType.SELLER_OFFER) {
        endPoint = `seller-offers/admin/${parentObjectId}/bidModifiers`
    }
    else if (parentObjectType === BidModifierParentType.SELLER_OFFER_REGISTRATION) {        
        endPoint = `seller-offers/applications/${parentObjectId}/bidModifiers`
    }

    return endPoint
}

export default BidModifiersAPI