<template>
  <div>
    <CTableWrapper
      :is-loading="isLoading"
      :items="countries"
      small
      fixed
      border
      sorter
      :sorter-value="{ column: 'name', asc: true }"
      column-filter
      :fields="fields"
      icon="cil-tags"
      type="Country"
      caption="Countries"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CTableWrapper from '@/components/common/Table.vue'
export default {
  name: 'Countries',

  title: 'Countries',

  components: {
    CTableWrapper
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'status', sortable: true },
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', sortable: true },
        { key: 'subId', label: 'Sub ID', sortable: true },
        { key: 'Actions', filter: false }
      ]
    }
  },
  computed: {
    ...mapState('country', ['countries'])
  },
  async created() {
    this.isLoading = true
    await this.list()
    this.isLoading = false
  },
  methods: {
    ...mapActions('country', ['list'])
  },
}
</script>
<style lang="scss" scoped></style>
