import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/checklist/registration/config', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const baseRegistrationRequestUrl = new URL('/api/v1/checklist/registration', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const RegistrationChecklistItemApi = {
  async getRegistrationChecklistItemsConfig(type) {
    return await httpClient.get(`${baseUrl}/${type}`)
  },

  async createRegistrationChecklistItemsConfig(config) {
    return await httpClient.post(baseUrl, config)
  },

  async updateRegistrationChecklistItemsConfig(config) {
    return await httpClient.patch(`${baseUrl}/${config.id}`, config)
  },

  async deleteRegistrationChecklistItemsConfig(id) {
    return await httpClient.delete(`${baseUrl}/${id}`)
  },

  async getRegistrationChecklistItemsForRegistration(registrationId) {
    return await httpClient.get(`${baseRegistrationRequestUrl}/${registrationId}/item`)
  },

  /**
   * Create checklist item with specified checklist item config
   * @param {string} registrationId Buyer/Seller registration Id
   * @param {{ registrationChecklistConfigId: string, status: string }} 
   * @returns 
   */
  async createRegistrationChecklistItemForRegistration(registrationId, { registrationChecklistConfigId, status }) {
    return await httpClient.post(`${baseRegistrationRequestUrl}/${registrationId}/item`, {
      registrationChecklistConfigId, 
      status
    })
  },

  /**
   * Set checklist item status
   * @param {string} registrationId 
   * @param {{checklistItemId: string, status: string}} param1 
   * @returns 
   */
  async setRegistrationChecklistItemStatusForRegistration(registrationId, { checklistItemId, status}) {
    return await httpClient.patch(`${baseRegistrationRequestUrl}/${registrationId}/item/${checklistItemId}/status`, {
      status
    })
  },

  async createUserIdentityCheckVerificationSession(registrationId) {
    return await httpClient.post(`${baseRegistrationRequestUrl}/${registrationId}/identity-check`)
  },

  async getUserIdentityCheckVerificationSessionDetails(registrationId) {
    return await httpClient.get(`${baseRegistrationRequestUrl}/${registrationId}/identity-check`)
  },

  async resendUserIdentityCheckVerificationSession(registrationId) {
    return await httpClient.post(`${baseRegistrationRequestUrl}/${registrationId}/identity-check/resend`)
  },
}

export default RegistrationChecklistItemApi