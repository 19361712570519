<template>
  <CCard>
    <CCardHeader>
      Edit Traffic Details
    </CCardHeader>

    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">Allowed Categories</label>

          <div class="col-sm-9">
            <v-multiselect-listbox
              v-if="categories.length"
              v-model="$v.allowedCategories.$model"
              class="msl-multi-select--full-width"
              :options="filteredCategories"
              :reduce-display-property="(option) => option.name"
              :reduce-value-property="(option) => option.id"
              :reduce-group-property="(option) => option.parentCategory.name"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search category"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No categories selected"
            />
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">Allowed Traffic Sources</label>

          <div class="col-sm-9">
            <v-multiselect-listbox
              v-if="trafficSources.length"
              v-model="$v.allowedTrafficSources.$model"
              class="msl-multi-select--full-width"
              :options="trafficSources"
              :reduce-display-property="(option) => option.name"
              :reduce-value-property="(option) => option.id"
              :reduce-group-property="(option) => option.trafficSourceType"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search source"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No source selected"
            />
          </div>
        </div>

        <div class="form-row">
          <CCol
            :md="{ offset: '3', size: '9' }"
            :sm="{ offset: '3', size: '9' }"
          >
            <ButtonLoading
              type="submit"
              size="sm"
              color="primary"
              :loading="isLoading"
              data-cy="submit-billing"
              @click="onFormSubmit"
            >
              Change
            </ButtonLoading>
          </CCol>
        </div>
      </CForm>
    </CCardBody>

    <CModal
      title="Confirm change traffic sources"
      color="success"
      data-cy="change-ts-modal"
      :show.sync="warningTrafficChangeModal"
    >
      Are you sure you want to edit the traffic sources configuration?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="onConfirmSubmit"
        >
          Change
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningTrafficChangeModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'

import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'AccountTrafficDetails',

  props: {
    account: {
      type: Object,
      default() {
        return {}
      },
    },

    type: {
      type: String,
      default: ''
    }
  },

  validations() {
    return {
        allowedCategories: {
          required,
        },

        allowedTrafficSources: {
          required,
        },
    }
  },

  data() {
    return {
      isLoading: false,

      warningTrafficChangeModal: false,

      accountLocal: {
        allowedTrafficSources: [],
        allowedCategories: []
      },

    }
  },

  computed: {
    ...mapState('category', ['categories']),
    
    ...mapGetters('trafficSource', {
      trafficSources: 'activeTrafficSources'
    }),

    filteredCategories() {
      return this.categories.filter(category => category.testOnly === this.accountLocal?.testOnly)
    },

    allowedCategories: {
      get() {
        return this.accountLocal.allowedCategories?.map(
          (category) => category.id
        ) || []
      },

      set(value) {
        this.accountLocal.allowedCategories = value.map((id) => {
          return {
            id,
          }
        })
      },
    },

    allowedTrafficSources: {
      get() {
        return this.accountLocal.allowedTrafficSources?.map(
          (ts) => ts.id
        )
      },

      set(value) {
        this.accountLocal.allowedTrafficSources = value.map((id) => {
          return {
            id,
          }
        })
      },
    },

    isValid() {
      return !this.$v.$invalid
    },
  },

  watch: {
    account: {
      immediate: true,
      handler(newValue) {
        this.accountLocal = newValue
          ? { ...newValue }
          : {
              allowedCategories: [],
              allowedTrafficSources: [],
            }
      },
    },
  },

  async created() {
    await this.getAllCategories()

    await this.getAllTrafficSources()
  },

  methods: {
    ...mapActions('category', {
      getAllCategories: 'list',
    }),

    ...mapActions('trafficSource', {
      getAllTrafficSources: 'list',
    }),

    ...mapActions('accounts', ['updateBuyerAccountTrafficConfig', 'updateSellerAccountTrafficConfig']),


    onFormSubmit() {
      this.$v.allowedCategories.$touch()
      this.$v.allowedTrafficSources.$touch()

      if (this.isValid) {
        this.warningTrafficChangeModal = true
      }
    },

    async onConfirmSubmit() {
      if (this.isValid) {
        this.isLoading = true

        try {
          if (this.type.toLowerCase() === 'buyer') {
            await this.updateBuyerAccountTrafficConfig({
              accountId: this.account.id,
              trafficConfig: {
                allowedCategories: this.accountLocal.allowedCategories,
                allowedTrafficSources: this.accountLocal.allowedTrafficSources,
              }
            })
          } else {
            await this.updateSellerAccountTrafficConfig({
              accountId: this.account.id,
              trafficConfig: {
                allowedCategories: this.accountLocal.allowedCategories,
                allowedTrafficSources: this.accountLocal.allowedTrafficSources,
              }
            })
          }

          this.warningTrafficChangeModal = false
          showSuccessMessage('Traffic configuration updated successfully')
        } catch (error) {
          //
        } finally {
          this.isLoading = false
        }
      } else {
        showErrorMessage('Please select traffic sources and categories both')
      }
    }
  },

}
</script>

<style>

</style>