import { render, staticRenderFns } from "./ReviewNotes.vue?vue&type=template&id=4fb09aa8&scoped=true&"
import script from "./ReviewNotes.vue?vue&type=script&lang=js&"
export * from "./ReviewNotes.vue?vue&type=script&lang=js&"
import style0 from "./ReviewNotes.vue?vue&type=style&index=0&id=4fb09aa8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fb09aa8",
  null
  
)

export default component.exports