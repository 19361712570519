<template>
  <date-picker
    v-model="state"
    :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
    :min-date="minDate"
    :popover="{ visibility: 'click', placement: 'auto' }"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <CInput
        :id="$attrs.id"
        :label="$attrs.label"
        :placeholder="$attrs.placeholder"
        :horizontal="$attrs.horizontal"
        :readonly="$attrs.readonly"
        :data-cy="$attrs['data-cy']"
        :value="inputValue"
        v-on="inputEvents"
      >
        <template #append-content>
          <span
            :style="{ 'cursor': 'pointer' }"
            @click="state = null"
          >
            <CIcon name="cil-x" />
          </span>
        </template>
      </CInput>
    </template>
  </date-picker>
</template>
<script>

export default {
  name: 'DatePickerInput',
  props: {
    value: {
      type: [String, Number, Object],
      default: null,
    },
    minDate: {
      type: Date,
      default: null
    },
  },
  computed: {
    state: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('change', value)
        this.$emit('input', value)
      }
    },
  }
}
</script>

<style lang="scss" scoped></style>
