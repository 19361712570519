<template>
  <div>
    <CForm
      novalidate
      @submit.prevent
    >
      <CInput
        id="email"
        v-model="email"
        name="email"
        label="Email"
        placeholder="Email"
        type="text"
        disabled
        required
      />

      <CInput
        id="new-password"
        v-model="$v.newPassword.$model"
        name="newPassword"
        label="New Password"
        placeholder="Enter your new password"
        type="password"
        vid="new-password"
        :is-valid="checkIfValid('newPassword')"
        :invalid-feedback="REQUIRED_FIELD_ERROR_MESSAGE"
        required
      />

      <CInput
        id="confirm-password"
        v-model="$v.confirmPassword.$model"
        name="confirmPassword"
        label="Confirm Password"
        placeholder="Re-enter password"
        type="password"
        :is-valid="checkIfValid('confirmPassword')"
        invalid-feedback="Passwords must match"
        required
      />

      <CInput
        id="confirmationCode"
        v-model="$v.confirmationCode.$model"
        name="confirmationCode"
        label="Confirmation Code"
        placeholder="Confirmation code"
        type="text"
        :is-valid="checkIfValid('confirmationCode')"
        :invalid-feedback="REQUIRED_FIELD_ERROR_MESSAGE"
        required
      />

      <div class="mt-3">
        <ButtonLoading
          class="custom-button--white-hover-grad pulse"
          type="submit"
          color="primary"
          data-cy="button-login"
          :loading="isLoading"
          @click="onSubmit"
        >
          Set Password
        </ButtonLoading>
      </div>
    </CForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, helpers, sameAs  } from 'vuelidate/lib/validators'

import { PASSWORD_REGEX } from '@/store/modules/auth.store'
import { showSuccessMessage } from '@/notification-utils'

export default {
  name: 'SetNewPassword',

  props: {
    email: {
      type: String,
      default: ''
    }
  },

  validations() {
    return {
      email: {
        required
      },
      newPassword: {
        required,
        passwordPattern: helpers.regex('password', PASSWORD_REGEX)
      },
      confirmPassword: {
        required,
        sameAs: sameAs('newPassword')
      },
      confirmationCode: {
        required
      }
    }
  },

  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      confirmationCode: '',
      isLoading: false
    }
  },

  computed: {
    isValid () { 
      return !this.$v.$invalid
    },
  },

  methods: {
    ...mapActions('auth', ['setNewPassword']),

    checkIfValid (fieldName) {
      const field = this.$v[fieldName]

      if (!field.$dirty) {
        return null
      }

      return !(field.$invalid || field.$model === '')
    },

    async onSubmit() {
      this.$v.$touch()

      if (!this.isValid) {
        return
      }

      try {
        this.isLoading = true

        await this.setNewPassword({
          email: this.email,
          newPassword: this.newPassword,
          confirmationCode: this.confirmationCode
        })

        showSuccessMessage('Successfully set new password')
        
        this.$router.push({
          name: 'LoginEmail',
        })
      } catch (error) {
        // Already showing error in interceptor
      } finally {
        this.isLoading = false
      }
    },
    goBack() {
      this.$emit('back')
    }
  }
}
</script>
<style lang="">
  
</style>