<template>
  <div>
    <div class="mb-4">
      <CButton
        v-if="!showAddAccessForm"
        color="success"
        @click="showAddAccessForm = true"
      >
        Add Seller Access
      </CButton>

      <SellerAccountAddAccessToOffer
        v-else
        :seller-offer-id="sellerOfferId"
        :accounts-with-access="sellerAccountsWithAccess"
        @added-access="onAddedAccess"
        @close="showAddAccessForm = false"
      />
    </div>

    <div
      v-if="isLoadingAccounts"
      class="d-flex justify-content-center"
    >
      <CSpinner
        
        class="text-center"
        style="width:3rem;height:3rem;"
        color="info"
        size="sm"
        grow
      />
    </div>

    <CDataTable
      v-else
      responsive
      small
      fixed
      border
      sorter
      column-filter
      :items="sellerAccountsWithAccess"
      :fields="fields"
      :items-per-page="10"
      :pagination="{ align: 'center' }"
    >
      <template #Actions="{item}">
        <td>
          <CButton
            color="info"
            size="sm"
            @click="removeAccountAccessToOffer(item)"
          >
            Remove Access
          </CButton>
        </td>
      </template>
    </CDataTable>

    <CModal
      title="Confirm remove access"
      color="danger"
      :show.sync="warningRemoveAccessModal"
    >
      Are you sure you want to remove access for this seller from the private offer?

      <p class="alert alert-warning mt-2">
        <strong>Note:</strong>
        Removing access means the seller can no longer view or participate in the private offer at all.
        Instead of removing access completely, consider pausing the seller's participation. 
        Pausing stops their calls traffic temporarily and it can be resumed later.
      </p>

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoadingAction"
          @click="confirmPauseUser"
        >
          Pause Participation
        </ButtonLoading>

        <ButtonLoading
          color="secondary"
          :loading="isLoadingAction"
          @click="onConfirmRemoveAccess"
        >
          Remove Access
        </ButtonLoading>
      </template>
    </CModal>
  </div>
</template>

<script>
import SellerOfferApi from '@/api/sellerOffer.api'
import { showSuccessMessage, showErrorMessage } from '@/notification-utils'
import SellerAccountAddAccessToOffer from './SellerAccountAddAccessToOffer.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SellerAccountsAccessPrivateOffer',

  title: 'Seller Offer',

  components: {
    SellerAccountAddAccessToOffer,
  },

  props: {
    sellerOfferId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      showAddAccessForm: false,

      sellerAccountsWithAccess: [],

      warningRemoveAccessModal: false,

      isLoadingAction: false,

      isLoadingAccounts: false,

      selectedAccount: null,

      fields: [
        { key: 'name', label: 'Company' },
        { key: 'Actions', filter: false }
      ]
    }
  },

  computed: {
    ...mapState('sellerOfferRegistration', ['approvedSellers']),
  },

  async created () {
    await this.loadAccountsWithAccess()
  },

  methods: {
    ...mapActions('sellerOfferRegistration', [
      'fetchApprovedSellers', 
    ]),

    removeAccountAccessToOffer(account) {
      this.selectedAccount = account

      this.warningRemoveAccessModal = true
    },

    async onConfirmRemoveAccess() {
      try {
        this.isLoadingAction = true

        await SellerOfferApi.removeSellerAccountAccessFromOffer(this.sellerOfferId, this.selectedAccount.id)

        showSuccessMessage('Successfully removed access from offer')

        this.warningRemoveAccessModal = false

        this.selectedAccount = null

        await this.loadAccountsWithAccess()
      } catch(e) {
        // Error handling already done
      } finally {
        this.isLoadingAction = false
      }
    },
    
    async confirmPauseUser() {
      try {
        this.isLoadingAction = true

        await this.fetchApprovedSellers(this.sellerOfferId)
        let participation = this.approvedSellers.find(x => x.account.id == this.selectedAccount.id)
        if (!participation){
          this.isLoadingAction = false
          showErrorMessage(`The seller ${this.selectedAccount.name} does not participate in this private offer`)
          return
        }

        await SellerOfferApi.pauseUserInOffer(participation.sellerOfferRegistration.id, 'Admin suspended participation')
        showSuccessMessage('Successfully paused seller participation')
        
        this.$emit('reload')
        
        this.warningRemoveAccessModal = false
        this.selectedAccount = null
      } finally {
        this.isLoadingAction = false
      }
    },

    async onAddedAccess() {
      this.showAddAccessForm = false

      await this.loadAccountsWithAccess()
    },

    async loadAccountsWithAccess() {
      this.isLoadingAccounts = true

      try {
        const response = await SellerOfferApi.getSellerAccountsWithAccessToOffer(this.sellerOfferId)

        this.sellerAccountsWithAccess = response.data
      } catch(e) {
        // done
      } finally {
        this.isLoadingAccounts = false
      }

    }
  },
}
</script>

<style>
  
</style>