var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[(_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",style:({ height: '80vh' })},[_c('CSpinner',{staticClass:"custom-spinner",attrs:{"color":"info","grow":""}})],1):_c('div',[_c('CCardHeader',[_vm._v(" KYC Applications ")]),_c('CCardBody',[_c('CDataTable',{attrs:{"data-cy":"table-kyc-requests","small":"","fixed":"","border":"","sorter":"","column-filter":"","items":_vm.kycRequestsFormatted,"fields":_vm.fields,"items-per-page":10,"pagination":{align: 'center'}},scopedSlots:_vm._u([{key:"accountId",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.account.id))])]}},{key:"accountName",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.account.name))])]}},{key:"representative",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getSafe(function () { return item.account.representative.firstName; }))+" "+_vm._s(_vm.getSafe(function () { return item.account.representative.lastName; })))])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getSafe(function () { return item.account.representative.email; })))])]}},{key:"expiration",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(((item.expMonth >= 10 ? item.expMonth : ("0" + (item.expMonth))) + " / " + (item.expYear))))])]}},{key:"Actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"info","size":"sm","to":("/dashboard/kyc-requests/" + (item.id))}},[_vm._v(" View Request ")])],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }