var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[(_vm.isLoading)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",style:({ height: '80vh' })},[_c('CSpinner',{staticClass:"custom-spinner",attrs:{"color":"info","grow":""}})],1):_c('div',[_c('CCardHeader',[_vm._v(" Creatives Screening "),_c('CButton',{staticClass:"ml-2",attrs:{"size":"sm","color":"info","variant":"outline"},on:{"click":_vm.onChangeAutoReload}},[_vm._v(" Auto Reload ")])],1),_c('CCardBody',[_c('CDataTable',{attrs:{"small":"","fixed":"","border":"","sorter":"","items":_vm.screeningPendingCreatives,"fields":_vm.fields,"items-per-page":10,"pagination":{align: 'center'}},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.id)+" "),_c('a',{staticClass:"ml-1",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.copyIdToClipboard(_vm.getSafe(function () { return item.id; }))}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)])]}},{key:"account",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{ name: 'SellerAccountDetails', params: { id: _vm.getSafe(function () { return item.account.id; })} },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.account.name; }))+" ")]),_c('a',{staticClass:"ml-1",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.copyAccountIdToClipboard(_vm.getSafe(function () { return item.account.id; }))}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1)],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"externalId",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.externalId)+" ")])]}},{key:"cmsId",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.cmsId)+" ")])]}},{key:"category",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.category.name; }))+" ")])]}},{key:"trafficSource",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.trafficSource.name; }))+" ")])]}},{key:"country",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.country.id; }))+" ")])]}},{key:"language",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.language.id; }))+" ")])]}},{key:"Actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"info","size":"sm","to":("/dashboard/creatives/review/" + (item.id))}},[_vm._v(" View Creative ")])],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }