import Vue from 'vue'
import uniqBy from 'lodash.uniqby'

import CampaignAPI from '@/api/campaign.api'
import SellerOfferApi from '@/api/sellerOffer.api'
import { sortAlphabeticallyComparison } from '@/utils'
import AccountsAPI from '@/api/accounts.api'

/**
 * 
 * @param {Set<string>} allTrafficSourcesIds 
 * @param {import('../buyer-campaign').BuyerCampaign} buyerCampaign 
 * @returns {Set<string>}
 */
function getTrafficSourcesFromBuyerCampaigns(allTrafficSourcesIds, buyerCampaign) {
  buyerCampaign.trafficSources?.allowedSources.forEach(t => allTrafficSourcesIds.add(t.id))

  return allTrafficSourcesIds
}

/**
 * 
 * @param {Object<string, boolean>} filterByTrafficSources 
 * @param {import('../buyer-campaign').BuyerCampaign} buyerCampaign 
 * @returns {boolean}
 */
function doesBuyerCampaignAcceptTrafficSources(filterByTrafficSources, buyerCampaign) {
  return Object.keys(filterByTrafficSources).length === 0 || buyerCampaign.trafficSources?.allowedSources.some(ts => !!filterByTrafficSources[ts.id])
}

/**
 * 
 * @param {Object<string, boolean>} filterByTrafficSources 
 * @param {import('../category').OfferRegistration} sellerOfferRegistration 
 * @returns {boolean}
 */
function isSellerOfferRegistrationApprovedForTrafficSources(filterByTrafficSources, sellerOfferRegistration) {
  return Object.keys(filterByTrafficSources).length === 0 || sellerOfferRegistration?.approvedTrafficSources?.some(ts => !!filterByTrafficSources[ts.id])
}

/**
 * 
 * @param {Array<string} filterBySellerAccountsIds 
 * @param {import('../category').OfferRegistrationWithAccountDetails} sellerOfferRegistration 
 * @returns {boolean}
 */
function isSellerOfferRegistrationOfFilteredAccounts(filterBySellerAccountsIds, sellerOfferRegistration) {
  return filterBySellerAccountsIds.length === 0 || filterBySellerAccountsIds.indexOf(sellerOfferRegistration.account.id) > -1
}

/**
 * 
 * @param {Array<string} filterBySellerOffersIds 
 * @param {string} sellerOfferId 
 * @returns {boolean}
 */
function isSellerOfferRegistrationOfFilteredOffers(filterBySellerOffersIds, sellerOfferId) {
  return filterBySellerOffersIds.length === 0 || filterBySellerOffersIds.indexOf(sellerOfferId) > -1
}

/**
 * 
 * @param {Array<string} filterByBuyerAccountsIds 
 * @param {import('../buyer-campaign').BuyerCampaign} buyerCampaign 
 * @returns {boolean}
 */
function isBuyerCampaignOfFilteredAccounts(filterByBuyerAccountsIds, buyerCampaign) {
  return filterByBuyerAccountsIds.length === 0 || filterByBuyerAccountsIds.indexOf(buyerCampaign.account.id) > -1
}

/**
 * 
 * @param {Array<string} filterByBuyerCampaignsIds 
 * @param {import('../buyer-campaign').BuyerCampaign} buyerCampaign 
 * @returns {boolean}
 */
function isBuyerCampaignOfFilteredCampaigns(filterByBuyerCampaignsIds, buyerCampaign) {
  return filterByBuyerCampaignsIds.length === 0 || filterByBuyerCampaignsIds.indexOf(buyerCampaign.id) > -1
}

/**
 * 
 * @param {Array<string>} trafficSourceIds 
 * @param {Array<import('../category').TrafficSource>} trafficSourcesData 
 * @returns {Array<import('../category').TrafficSourceDTO>}
 */
function getTrafficSourcesWithDetails(trafficSourceIds, trafficSourcesData) {
  const map = new Map(trafficSourcesData.map((ts) => [ts.id, ts]))

  return trafficSourceIds.map(id => {
    const ts = map.get(id)

    return  {
      id,
      name: ts.name,
      type: ts.trafficSourceType
    }
  })
}

/**
 * @param {Array<import('../category').SellerOffer>} sellerOffers 
 * @param {import('../seller-account').SellerAccount} seller 
 * @param {Object.<string, Array<import('../seller-account').SellerAccount>>} sellersWithAccessToPrivateOffers
 * @returns {Object.<string, string>}
 */
function getInactiveReasonForSeller(sellerOffers, seller, sellersWithAccessToPrivateOffers) {
  let inactiveSeller = {
    inacessibleOffers: [],
  }

  sellerOffers.forEach(so => {
    const sellerOfferReasons = []
    const matchingTrafficSourcesIds = seller?.allowedTrafficSources.filter(ts => so.allowedTrafficSources.some(tso => ts.id === tso.id))
    let notAccessible = false

    if (so.visibility === 'Private') {
      if (sellersWithAccessToPrivateOffers[so.id]?.findIndex(s => s.id === seller.id) < 0) {
        notAccessible = true
        sellerOfferReasons.push('No access to Private Offer')
      }
    }

    if (!matchingTrafficSourcesIds.length) {
      notAccessible = true
      sellerOfferReasons.push('No matching traffic sources')
    }
      
    if (notAccessible) {
      inactiveSeller.inacessibleOffers.push({
        id: so.id,
        name: so.name,
        visibility: so.visibility,
        sellerOfferReasons,
      })
    }
  })

  return inactiveSeller.inacessibleOffers.length ? inactiveSeller : undefined
}

const state = {
  /**
   * @type {Object.<string, Array<import('../category').SellerOfferWithRegistrations>>}
   */
  categoriesSupplyData: {

  },

  /**
   * @type {Object.<string, Array<import('../seller-account').SellerAccount>>}
   */
  categoriesSellers: {

  },

  /**
   * @type {Object.<string, Array<import('../seller-account').SellerAccount>>}
   */
  sellersWithAccessToPrivateOffers: {},

  /**
   * @type {Object.<string, Array<import('../buyer-campaign').BuyerCampaign>>}
   */
  categoriesDemandData: {

  },

  /**
   * @type {Object.<string, Array<import('../buyer-account').BuyerAccount>>}
   */
  categoriesBuyers: {

  },

  selectedCategoryId: '',
  selectedLanguage: '',
  selectedCountry: '',

  /**
   * @type {Object<string, import('../category').TrafficSourceDTO>}
   */
  filterByTrafficSources: {},

  /**
   * @type {Object<string, import('../seller-account').SellerAccount>}
   */
  filterBySellerAccounts: {},

  /**
   * @type {Object<string, import('../category').SellerOffer>}
   */
  filterBySellerOffers: {},

  /**
   * @type {Object<string, import('../buyer-account').BuyerAccount>}
   */
  filterByBuyerAccounts: {},

  /**
   * @type {Object<string, import('../buyer-campaign').BuyerCampaign>}
   */
  filterByBuyerCampaigns: {},

  showPausedSellerOfferRegistrations: true,

  showPausedBuyerCampaigns: true,

  hidePendingReviewBuyerCampaigns: false,

  hidePendingReviewSellerOfferRegistrations: false,
}


/**
* @type {import('vuex').GetterTree<typeof state>}
*/
const getters = {
  allSellers(state) {
    return state.categoriesSellers[state.selectedCategoryId]?.map(sa => ({...sa})).sort((sa1, sa2) => sortAlphabeticallyComparison(sa1.name, sa2.name)) ?? []
  },

  allSellerOfferRegistrationsForCategory(state) {
    const sellerOffers = state.categoriesSupplyData[state.selectedCategoryId]
      ?.filter(so => so.languageId === state.selectedLanguage 
      && so.countryCode === state.selectedCountry)

    /**
    * @type {Array<import('../category').OfferRegistrationWithAccountDetails>>}
    */
    return sellerOffers?.reduce((finalRegistrations, so) => {
      return finalRegistrations.concat(so.offerRegistrations
        .map(reg => ({ ...reg, sellerOfferRegistration: { ...reg.sellerOfferRegistration, sellerOffer: so } })))
    }, [])
  },

  /**
   * @returns {Array<import('../category').OfferRegistrationWithAccountDetails>>}
   */
  supplySideData(state, getters) {
    const filterBySellerAccountsIds = Object.keys(state.filterBySellerAccounts)
    const filterBySellerOffersIds = Object.keys(state.filterBySellerOffers)

    
    /**
    * @type {Array<import('../category').OfferRegistrationWithAccountDetails>>}
    */
    let sellerOfferRegistrationsWithAccountDetails = getters.allSellerOfferRegistrationsForCategory?.filter(registration => isSellerOfferRegistrationOfFilteredAccounts(filterBySellerAccountsIds, registration)
                                                                    && isSellerOfferRegistrationOfFilteredOffers(filterBySellerOffersIds, registration.sellerOfferRegistration.sellerOffer.id)
                                                                    && (!state.hidePendingReviewSellerOfferRegistrations || registration.sellerOfferRegistration.status !== 'Pending'))

    // Only show pending or active seller offer registration                                                                      
    let finalRegistrations = sellerOfferRegistrationsWithAccountDetails?.filter(sor => sor.sellerOfferRegistration.status === 'Approved' || sor.sellerOfferRegistration.status === 'Pending')

    finalRegistrations ??= []

    return finalRegistrations?.filter(sor => isSellerOfferRegistrationApprovedForTrafficSources(state.filterByTrafficSources, sor.sellerOfferRegistration))
      ?.sort((sor1, sor2) => sortAlphabeticallyComparison(sor1.account.name, sor2.account.name))
  },

  /**
   * @param {typeof state} state 
   * @param {{ allSellerOfferRegistrationsForCategory: Array<import('../category').OfferRegistrationWithAccountDetails>, sellerOffers: Array<import('../category').SellerOffer> }} getters 
   * @returns {Array<import('../seller-account').SellerAccount>}
   */
  sellersWithNoVisibilityToOffers(state, getters) {
    let inactiveSellers = getters.allSellers.reduce((filteredSellers, seller) => {
      const participatingOfferRegistration = getters.allSellerOfferRegistrationsForCategory.find(sor => sor.account.id === seller.id)

      if (!participatingOfferRegistration) {
        const inactiveSellerReason = getInactiveReasonForSeller(getters.sellerOffers, seller, state.sellersWithAccessToPrivateOffers)

        if (inactiveSellerReason) {
          filteredSellers.push({
            ...seller,
            ...inactiveSellerReason
          })
        }
      }

      return filteredSellers
    }, [])

    return inactiveSellers.sort((sa1, sa2) => sortAlphabeticallyComparison(sa1.name, sa2.name))
  },

  /**
   * @param {typeof state} state 
   * @param {{ activeOrPendingSellerOfferRegistrations: Array<import('../category').OfferRegistrationWithAccountDetails>, sellerOffers: Array<import('../category').SellerOffer> }} getters 
   * @returns {Array<import('../seller-account').SellerAccount>}
   */
  sellersNotParticipatingActively(state, getters) {
    let inactiveSellers = getters.allSellers.reduce((filteredSellers, seller) => {
      const participatingOfferRegistration = getters.activeOrPendingSellerOfferRegistrations.find(sor => sor.account.id === seller.id)

      if (!participatingOfferRegistration) {
        filteredSellers.push(seller)
      }
      return filteredSellers
    }, [])

    return inactiveSellers.sort((sa1, sa2) => sortAlphabeticallyComparison(sa1.name, sa2.name))
  },

  sellerOffers(state) {
    return state.categoriesSupplyData[state.selectedCategoryId]
      ?.filter(so => so.languageId === state.selectedLanguage 
      && so.countryCode === state.selectedCountry)
  },

  unfilteredSellerOffers(state) {
    return state.categoriesSupplyData[state.selectedCategoryId] ?? []
  },

  supplySideTrafficSources(state, getters, rootState, rootGetters) {
    const trafficSources = getters.supplySideData.reduce((allTrafficSources, offerRegistration) => {
      offerRegistration.sellerOfferRegistration?.approvedTrafficSources?.forEach(t => allTrafficSources.push(t))

      return allTrafficSources
    }, [])

    const uniqueTrafficSources = uniqBy(trafficSources, 'id')

    /**
     * @type {Map<string, import('../category').TrafficSource>}
     */
    const trafficSourcesMap = rootGetters['trafficSource/trafficSourcesIdMap']

    if (trafficSourcesMap.size === 0) {
      return []
    }

    return uniqueTrafficSources.map(ts => {
      const details = trafficSourcesMap.get(ts.id)

      return {
        id: details.id,
        type: details.trafficSourceType,
        name: details.name
      }
    })
  },

  activeOrPendingSellerOfferRegistrations(state, getters) {
    return getters.allSellerOfferRegistrationsForCategory?.filter(sor => sor.sellerOfferRegistration.status === 'Approved' || sor.sellerOfferRegistration.status === 'Pending')
  },

  pendingSellerOfferRegistrations(state, getters) {
    return getters.supplySideData?.filter(sor => sor.sellerOfferRegistration.status === 'Pending')?.length ?? 0
  },

  allBuyers(state) {
    return state.categoriesBuyers[state.selectedCategoryId]?.map(ba => ({...ba})).sort((ba1, ba2) => sortAlphabeticallyComparison(ba1.name, ba2.name)) ?? []
  },

  demandSideData(state) {
    const filterByBuyerAccountsIds = Object.keys(state.filterByBuyerAccounts)
    const filterByBuyerCampaignsIds = Object.keys(state.filterByBuyerCampaigns)

    const buyerCampaigns = state.categoriesDemandData[state.selectedCategoryId]
      ?.filter(bc => bc.language.id === state.selectedLanguage 
        && bc.country.id === state.selectedCountry 
        && (state.showPausedBuyerCampaigns || !bc.isPaused)
        && doesBuyerCampaignAcceptTrafficSources(state.filterByTrafficSources, bc)
        && isBuyerCampaignOfFilteredAccounts(filterByBuyerAccountsIds, bc)
        && isBuyerCampaignOfFilteredCampaigns(filterByBuyerCampaignsIds, bc)
        && (!state.hidePendingReviewBuyerCampaigns || bc.status !== 'InReview'))
      ?.sort((bc1, bc2) => sortAlphabeticallyComparison(bc1.account.name, bc2.account.name))

    return buyerCampaigns ?? []
  },

  /**
   * @param {typeof state} state 
   * @returns {Array<import('../buyer-account').BuyerAccount>}
   */
  buyersNotParticipatingActively(state, getters) {
    let inactiveBuyers = getters.allBuyers?.reduce((filteredBuyers, buyer) => {
      const foundCampaign = state.categoriesDemandData[state.selectedCategoryId]
        ?.some(bc => bc.account.id === buyer.id && (bc.status.toLowerCase() === 'active' || bc.status.toLowerCase() === 'inreview'))

      if (!foundCampaign) {
        filteredBuyers.push(buyer)
      }

      return filteredBuyers
    }, [])

    return inactiveBuyers?.sort((ba1, ba2) => sortAlphabeticallyComparison(ba1.name, ba2.name)) ?? []
  },

  /**
   * 
   * @param {typeof state} state 
   * @param {{ demandSideData: Array<import('../buyer-campaign').BuyerCampaign> }} getters 
   * @returns {Array<import('../category').TrafficSourceDTO>}
   */
  demandSideTrafficSources(state, getters, rootState, rootGetters) {
    /**
     * @type {Map<string, import('../category').TrafficSource>}
     */
    const trafficSourcesMap = rootGetters['trafficSource/trafficSourcesIdMap']

    /**
     * @type { Set<string> }
     */
    const ts = getters.demandSideData.reduce(getTrafficSourcesFromBuyerCampaigns, new Set())

    if (trafficSourcesMap.size === 0) {
      return []
    }

    return Array.from(ts).map(ts => {
      const details = trafficSourcesMap.get(ts)

      return {
        type: details?.trafficSourceType,
        name: details?.name,
        id: details?.id
      }
    })
  },

  filteredTrafficSourcesWithDetails(state, rootState, getters, rootGetters) {
    /**
     * @type {Map<string, import('../category').TrafficSource>}
     */
    const trafficSourcesMap = rootGetters['trafficSource/trafficSourcesIdMap']

    if (trafficSourcesMap.size === 0) {
      return []
    }

    return Array.from(Object.keys(state.filterByTrafficSources)).map(ts => {
      const details = trafficSourcesMap.get(ts)

      return {
        type: details?.trafficSourceType,
        name: details?.name,
        id: details?.id
      }
    })
  }
}


/**
* @type {import('vuex').ActionTree<typeof state>}
*/
const actions = {
  async getAllSellerOffersWithRegistrationsForCategory({ commit }, categoryId) {
    const response = await SellerOfferApi.getAllSellerOffers(categoryId, true)

    commit('SET_SELLER_OFFERS_WITH_REGISTRATIONS', { categoryId, sellerOfferWithRegistrations: response.data })

    return response
  },

  async getAllBuyerCampaignsForCategory({ commit }, categoryId) {
    const response = await CampaignAPI.getBuyerCampaigns({ categoryId: categoryId, detailed: true })

    commit('SET_BUYER_CAMPAIGNS', { categoryId, buyerCampaigns: response.data })

    return response
  },

  async getAllSellersForCategory({ commit }, categoryId) {
    const response = await AccountsAPI.getApprovedSellerAccounts(categoryId)
    
    commit('SET_SELLERS_FOR_CATEGORY', { categoryId, sellers: response.data})

    return response
  },

  async getAllBuyersForCategory({ commit }, categoryId) {
    const response = await AccountsAPI.getApprovedBuyerAccounts(categoryId)

    commit('SET_BUYERS_FOR_CATEGORY', { categoryId, buyers: response.data})

    return response
  },

  async getSellersWithAccessToPrivateOffer({ commit, state }, sellerOfferId) {
    if (state.sellersWithAccessToPrivateOffers[sellerOfferId]) {
      return
    }

    const response = await SellerOfferApi.getSellerAccountsWithAccessToOffer(sellerOfferId)

    commit('SET_SELLERS_WITH_ACCESS_TO_PRIVATE_OFFER', { sellerOfferId, sellers: response.data })

    return response
  },

  /**
   * @param {import('vuex').ActionContext}
   * @param {import('../buyer-campaign').BuyerCampaign} buyerCampaign 
   */
  addToBuyerCampaignFilter({ commit, rootState }, buyerCampaign) {
    commit('ADD_TO_BUYER_CAMPAIGN_FILTERING', buyerCampaign)

    commit('ADD_MULTIPLE_TO_TRAFFIC_SOURCE_FILTERING', getTrafficSourcesWithDetails(buyerCampaign.trafficSources.allowedSources.map(ts => ts.id), rootState['trafficSource'].trafficSources))
  },

  /**
   * @param {import('vuex').ActionContext}
   * @param {import('../buyer-campaign').BuyerCampaign} buyerCampaign 
   */
  removeFromBuyerCampaignFilter({ commit }, buyerCampaign) {
    commit('REMOVE_FROM_BUYER_CAMPAIGN_FILTERING', buyerCampaign.id)

    commit('REMOVE_MULTIPLE_FROM_TRAFFIC_SOURCE_FILTERING', buyerCampaign.trafficSources.allowedSources.map(ts => ts.id))
  }
}


/**
* @type {import('vuex').MutationTree<typeof state>}
*/
const mutations = {
  SET_CATEGORY(state, categoryId) {
    state.selectedCategoryId = categoryId
  },

  SET_LANGUAGE(state, language) {
    state.selectedLanguage = language
  },

  SET_COUNTRY(state, country) {
    state.selectedCountry = country
  },

  SET_HIDE_PENDING_REVIEW_BUYER_CAMPAIGNS(state, hide) {
    state.hidePendingReviewBuyerCampaigns = hide
  },

  SET_HIDE_PENDING_REVIEW_OFFER_REGISTRATIONS(state, hide) {
    state.hidePendingReviewSellerOfferRegistrations = hide
  },

  SET_SELLER_OFFERS_WITH_REGISTRATIONS(state, { categoryId, sellerOfferWithRegistrations }) {
    Vue.set(state.categoriesSupplyData, categoryId, sellerOfferWithRegistrations)
  },

  SET_SELLERS_FOR_CATEGORY(state, { categoryId, sellers }) {
    Vue.set(state.categoriesSellers, categoryId, sellers)
  },

  SET_SELLERS_WITH_ACCESS_TO_PRIVATE_OFFER(state, { sellerOfferId, sellers }) {
    Vue.set(state.sellersWithAccessToPrivateOffers, sellerOfferId, sellers)
  },

  SET_BUYER_CAMPAIGNS(state, { categoryId, buyerCampaigns }) {
    Vue.set(state.categoriesDemandData, categoryId, buyerCampaigns)
  },

  SET_BUYERS_FOR_CATEGORY(state, { categoryId, buyers }) {
    Vue.set(state.categoriesBuyers, categoryId, buyers)
  },

  ADD_TO_TRAFFIC_SOURCE_FILTERING(state, trafficSource) {
    Vue.set(state.filterByTrafficSources, trafficSource.id, trafficSource)
  },

  ADD_MULTIPLE_TO_TRAFFIC_SOURCE_FILTERING(state, trafficSources) {
    trafficSources.forEach(trafficSource => {
      Vue.set(state.filterByTrafficSources, trafficSource.id, trafficSource)
    })
  },

  REMOVE_FROM_TRAFFIC_SOURCE_FILTERING(state, trafficSourceId) {
    Vue.delete(state.filterByTrafficSources, trafficSourceId)
  },

  REMOVE_MULTIPLE_FROM_TRAFFIC_SOURCE_FILTERING(state, trafficSourceIds) {
    trafficSourceIds.forEach(trafficSourceId => {
      Vue.delete(state.filterByTrafficSources, trafficSourceId)
    })
  },

  CLEAR_TRAFFIC_SOURCE_FILTERING(state) {
    state.filterByTrafficSources = {}
  },

  ADD_TO_SELLER_ACCOUNT_FILTERING(state, sellerAccount) {
    Vue.set(state.filterBySellerAccounts, sellerAccount.id, sellerAccount)
  },

  REMOVE_FROM_SELLER_ACCOUNT_FILTERING(state, sellerAccountId) {
    Vue.delete(state.filterBySellerAccounts, sellerAccountId)
  },

  ADD_TO_SELLER_OFFER_FILTERING(state, sellerOffer) {
    Vue.set(state.filterBySellerOffers, sellerOffer.id, sellerOffer)
  },

  REMOVE_FROM_SELLER_OFFER_FILTERING(state, sellerOfferId) {
    Vue.delete(state.filterBySellerOffers, sellerOfferId)
  },

  ADD_TO_BUYER_ACCOUNT_FILTERING(state, buyerAccount) {
    Vue.set(state.filterByBuyerAccounts, buyerAccount.id, buyerAccount)
  },

  REMOVE_FROM_BUYER_ACCOUNT_FILTERING(state, buyerAccountId) {
    Vue.delete(state.filterByBuyerAccounts, buyerAccountId)
  },

  ADD_TO_BUYER_CAMPAIGN_FILTERING(state, buyerCampaign) {
    Vue.set(state.filterByBuyerCampaigns, buyerCampaign.id, buyerCampaign)
  },

  REMOVE_FROM_BUYER_CAMPAIGN_FILTERING(state, buyerCampaignId) {
    Vue.delete(state.filterByBuyerCampaigns, buyerCampaignId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}