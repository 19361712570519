<template>
  <div>
    <CCard>
      <CCardHeader>
        {{ typeTitle }} Application Checklist Items

        <CButton
          class="float-right"
          size="sm"
          color="info"
          @click="
            $router.push({
              name: 'RegistrationChecklistItemCreate',
              params: {
                type: type
              },
              exact: true
            })
          "
        >
          <CIcon
            size="sm"
            name="cil-plus"
          /> Create New
        </CButton>
      </CCardHeader>

      <CCardBody>
        <div
          v-if="isLoading"
          :style="{ height: '20vh' }"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <CSpinner
            class="custom-spinner"
            color="info"
            grow
          />
        </div>
        <RegistrationChecklistItemsList
          v-else
          :type="type"
          :checklist-items="checklistItems"
          @reload-checklist-items="loadChecklistItems"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import {capitlizeFirstLetter} from '@/utils'
import RegistrationChecklistItemsList from './RegistrationChecklistItemsList.vue'

export default {
  name: 'RegistrationChecklistItems',

  components: {
    RegistrationChecklistItemsList,
  },

  data() {
    return {
      type: 'seller',
      isLoading: false
    }
  },

  computed: {
    ...mapState('registrationChecklistItem', [
      'sellerRegistrationChecklistItemsConfig',
      'buyerRegistrationChecklistItemsConfig'
    ]),

    checklistItems() {
      return this.type?.toLowerCase() === 'seller' ? this.sellerRegistrationChecklistItemsConfig : this.buyerRegistrationChecklistItemsConfig
    },

    typeTitle() {
      return capitlizeFirstLetter(this.type)
    }
  },

  watch: {
    '$route.params.type': {
      immediate: true,
      async handler() {
        this.type = this.$route.params.type.toLowerCase()
        document.title = `${this.typeTitle} Application Checklist Items`
        await this.loadChecklistItems()
      }
    }
  },

  methods: {
    ...mapActions('registrationChecklistItem', [
      'getRegistrationChecklistItemsConfig'
    ]),

    async loadChecklistItems() {
      try {
        this.isLoading = true
        await this.getRegistrationChecklistItemsConfig(this.type)
      } catch {
        //
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  
</style>