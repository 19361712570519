<template>
  <div>
    <CCard>
      <CCardHeader>
        Seller Offer Application
      </CCardHeader>
      <CCardBody>
        <CForm>
          <div class="form-group form-row">
            <dt class="col-sm-3 text-right">
              Status
            </dt>
            <dd class="col-sm-9">
              <CBadge :color="statusVariant">
                {{ requestDetails.status }}
              </CBadge>
            </dd>
            <dt class="col-sm-3 text-right">
              Application ID
            </dt>
            <dd class="col-sm-9">
              {{ requestDetails.id }}
              <a
                href
                @click.prevent="
                  copyIdToClipboard(requestDetails.id)
                "
              ><CIcon
                name="cil-copy"
              /></a>
            </dd>

            <dt class="col-sm-3 text-right">
              Applied On
            </dt>
            <dd class="col-sm-9">
              {{ getSafe(() => formatDate(requestDetails.createdAt)) }}
            </dd>
          </div>


          <hr>


          <h4>
            Application Details
          </h4>
          <div class="form-group form-row">
            <dt class="col-sm-3 text-right">
              Will your traffic come from 3rd party affiliates?
            </dt>
            <div class="col-sm-3">
              <CSelect
                :value.sync="$v.requestDetailsCopy.thirdPartyTraffic.$model"
                data-cy="input-country"
                label=""
                horizontal
                :options="thirdPartyTrafficOptions"
                placeholder="Please select"
                :is-valid="checkIfValid(['requestDetailsCopy', 'thirdPartyTraffic'])"
                :invalid-feedback="pleaseSelect('third party traffic')"
                @change="handleThirdPartyTrafficChange"
              />
            </div>
          </div>

          <div
            v-if="requestDetailsCopy.thirdPartyTraffic !== 'No'"
            class="form-group form-row"
          >
            <dt class="col-sm-3 text-right">
              Allow Affiliate Traffic
            </dt>
            <div class="col-sm-9 col-xl-5">
              <CSwitch
                label-off="No"
                label-on="Yes"
                shape="pill"
                color="primary"
                horizontal
                add-label-classes=""
                add-input-classes="w-25"
                size="lg"
                :checked.sync="requestDetailsCopy.isAffiliateTrafficApproved"
              />
            </div>
          </div>

          <div class="form-group form-row">
            <dt class="col-sm-3 text-right">
              URLs for landing pages:
            </dt>
            <div class="col-sm-8 col-xl-5">
              <CTextarea
                v-model="requestDetailsCopy.landingPageUrls"
                placeholder="Enter URLs"
                horizontal
                rows="3"
              />
            </div>
          </div>

          <div class="form-group form-row">
            <dt class="col-sm-3 text-right">
              Specific details regarding your promotional methods:
            </dt>
            <div class="col-sm-9 col-xl-5">
              <CTextarea
                v-model="$v.requestDetailsCopy.promotionalMethodsDetails.$model"
                placeholder="Enter details"
                horizontal
                rows="3"
                :is-valid="checkIfValid(['requestDetailsCopy', 'promotionalMethodsDetails'])"
                :invalid-feedback="pleaseSelect('promotional methods details')"
              />
            </div>
          </div>

          <div class="form-group form-row">
            <dt class="col-sm-3 text-right">
              Traffic sources requested by seller
            </dt>
            <div class="col-sm-9 col-xl-5">
              <v-multiselect-listbox
                v-model="selectedTrafficSourcesForm"
                :options="requestDetails.sellerOffer.allowedTrafficSources"
                :reduce-display-property="(option) => option.name"
                :reduce-value-property="(option) => option.id"
                search-input-class="custom-form-input custom-form-input--sm"
                search-options-placeholder="Search source"
                selected-options-placeholder="Search selected"
                selected-no-options-text="No traffic source selected"
                hide-search-inputs
              />

              <div
                v-if="isFormEdited && $v.requestDetailsCopy.selectedTrafficSources.$invalid"
                class="invalid-feedback d-flex mb-3 mt-2"
              >
                Please select traffic source
              </div>
            </div>
          </div>

          <div class="form-group form-row">
            <div class="col-sm-9 offset-sm-3 col-xl-5">
              <ButtonLoading
                color="primary"
                :loading="isOfferChangeLoading"
                @click="submitOfferRegistrationForm"
              >
                Save
              </ButtonLoading>
            </div>
          </div>
        </CForm>


        <hr>

        <SellerAccountBasicDetails
          v-if="requestDetails.accountId"
          :account-id="requestDetails.accountId"
        />          
          
        <hr>

          
        <h4>
          Seller Offer Details
        </h4>
        <div class="form-group form-row">
          <dt class="col-sm-3 text-right">
            Name
          </dt>
          <div class="col-sm-9">
            {{ getSafe(() => requestDetails.sellerOffer.name) }}
          </div>
        </div>
        <div class="form-group form-row">
          <dt class="col-sm-3 text-right">
            Description
          </dt>
          <div class="col-sm-9">
            {{ getSafe(() => requestDetails.sellerOffer.description) }}
          </div>
        </div>
        <div class="form-group form-row">
          <dt class="col-sm-3 text-right">
            Category
          </dt>
          <div class="col-sm-9">
            {{ getSafe(() => requestDetails.categoryName) }}
          </div>
        </div>

        <div class="form-group form-row">
          <dt class="col-sm-3 text-right">
            Traffic Source Type
          </dt>
          <div class="col-sm-9">
            {{ getSafe(() => requestDetails.sellerOffer.allowedTrafficSourceType) }}
          </div>
        </div>

        <div class="form-group form-row">
          <CCol
            sm="3"
            class="pr-3 text-right font-weight-bold"
          >
            Affiliate Traffic Allowed
          </CCol>

          <CCol sm="3">
            {{ getSafe(() => requestDetails.sellerOffer.isAffiliateTrafficAllowed ? 'Yes' : 'No' ) }}
          </CCol>
        </div>
        

        <div class="form-group form-row">
          <label class="col-form-label col-sm-3 pr-4 text-right font-weight-bold">Allowed Traffic Sources</label>
          <div class="col-sm-9 col-lg-6">
            <v-multiselect-listbox
              v-model="allowedTrafficSources"
              :options="requestDetails.sellerOffer.allowedTrafficSources"
              :reduce-display-property="(option) => option.name"
              :reduce-value-property="(option) => option.id"
              search-input-class="custom-form-input custom-form-input--sm"
              search-options-placeholder="Search source"
              selected-options-placeholder="Search selected"
              selected-no-options-text="No traffic source selected"
              hide-search-inputs
              read-only
              class="mt-2"
            />
          </div>
        </div>        
      </CCardBody>
    </CCard>

    <BidModifiers
      :parent-object="bidModifiersParentObject"
    />

    <ReviewNotes
      :parent-object="requestDetails"
    />

    <CModal
      title="Confirm rejection"
      :show.sync="warningRejectModal"
    >
      Are you sure you want to reject this request? This action will stop pay per call traffic

      <div>
        <CTextarea
          v-model.trim="declineReason"
          label="Rejection Reason"
          placeholder="enter the reason for rejecting this request"
          horizontal
          rows="5"
          invalid-feedback="Please enter comments to save"
        />
      </div>

      <template #footer>
        <ButtonLoading
          color="danger"
          :loading="isLoading"
          @click="rejectRequest"
        >
          Yes, Reject
        </ButtonLoading>

        <CButton
          color="secondary"
          @click="warningRejectModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>
    <CModal
      title="Confirm approval"
      :show.sync="warningApproveModal"
    >
      Are you sure you want to approve this request? This action will run pay per call traffic 

      <template #footer>
        <ButtonLoading
          color="success"
          :loading="isLoading"
          @click="approveRequest"
        >
          Yes, Approve
        </ButtonLoading>

        <CButton
          color="secondary"
          @click="warningApproveModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <div
      v-if="!requestIsReviewed"
      class="pb-5"
    >
      <CButton 
        :disabled="!trafficSourcesSet"
        type="submit"
        class="mr-4"
        size="lg"
        color="success"
        @click="showConfirmApproveRequestModal"
      >
        Approve
      </CButton>

      <CButton
        type="submit"
        class="mr-4"
        size="lg"
        color="danger"
        @click="showConfirmRejectRequestModal"
      >
        Deny
      </CButton>
    </div>
  </div>
</template>

<script>
import { required  } from 'vuelidate/lib/validators'
import formGenericMixin from '@/mixins/form-generic-mixin'
import { dateTimeFormat } from '@/utils'
import { showSuccessMessage } from '@/notification-utils'
import { mapActions, mapState } from 'vuex'
import approveRejectModalMixin from '@/mixins/approve-reject-modal-mixin'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import ReviewNotes from '../ReviewNotes/ReviewNotes.vue'
import { thirdPartyTrafficOptions, offerRegistrationCssClassMapping } from '@/store/modules/sellerOfferRegistration.store'
import BidModifiers from '../BidModifiers/BidModifiers.vue'
import BidModifierParentType from '@/enums/BidModifierParentType.js'
import SellerAccountBasicDetails from '@/views/SellerAccountBasicDetails.vue'

export default {
  name: 'PendingReviewRequestDetails',

  title: 'Seller Offer Application Review',

  components: {
    ReviewNotes,
    BidModifiers,
    SellerAccountBasicDetails
  },
  
  mixins: [approveRejectModalMixin, copyToClipboardMixin, formGenericMixin],

  validations() {
    return {
      requestDetailsCopy: {
        thirdPartyTraffic: {
          required
        },

        selectedTrafficSources: {
          required
        },

        promotionalMethodsDetails: {
          required
        },
      },
    }
  },
  
  data() {
    return {
      declineReason: '',
      type: 'SellerOfferRegistration',
      isAffiliateTrafficAllowed: undefined,
      selectedTrafficSources: [], //{''},
      isLoading: false,
      isOfferChangeLoading: false,
      requestDetailsCopy: {},
    }
  },

  computed: {
    ...mapState('sellerOfferRegistration', ['requestDetails']),

    ...mapState('accounts', [
      'sellerAccountDetails'
    ]),

    thirdPartyTrafficOptions() {
      let options = [...thirdPartyTrafficOptions]

      options.unshift({
        value: null,
        label: 'Please select',
      })

      return options
    },

    requestIsReviewed() {
      return ['approved', 'rejected', 'withdrawn'].includes(
        this.requestDetails?.status?.toLowerCase()
      )
    },

    trafficSourcesSet() {
      return (
        this.selectedTrafficSources?.length
      )
    },

    statusVariant() {
      return offerRegistrationCssClassMapping[this.requestDetails?.status?.toLowerCase()]
    },

    selectedTrafficSourcesForm: {
      get() {
        return this.selectedTrafficSources?.map(
          (ts) => ts.id
        )
      },

      set(value) {
        this.selectedTrafficSources = value.map((id) => {
          return {
            id,
          }
        })

        this.requestDetailsCopy.selectedTrafficSources = this.selectedTrafficSources
      },
    },

    allowedTrafficSources() {
      return this.requestDetails.sellerOffer.allowedTrafficSources.map((ts) => ts.id)
    },

    bidModifiersParentObject() {
      return {
        parentObjectType: BidModifierParentType.SELLER_OFFER_REGISTRATION,
        id: this.requestDetails.id,
        alwaysOn: false
      }
    }
  },

  watch: {
    requestDetails: {
      immediate: true,
      async handler(newValue) {
        this.requestDetailsCopy = {...newValue}

        await this.handleRequestDetailsChanged()
      }
    }
  },

  async created() {
    this.resetRegistrationRequestDetails()
    
    const sellerOfferRegistrationId = this.$route.params.id

    await this.getRequestDetails(sellerOfferRegistrationId)
  },

  methods: {
    ...mapActions('sellerOfferRegistration', [
      'resetRegistrationRequestDetails',
      'getRequestDetails',
      'approvePendingRequest',
      'rejectPendingRequest',
      'updateOfferRegistration'
    ]),

    ...mapActions('accounts', [
      'getSellerAccountDetails',
    ]),

    handleThirdPartyTrafficChange() {
      if (this.requestDetailsCopy.thirdPartyTraffic === 'No') {
        this.requestDetailsCopy.isAffiliateTrafficApproved = false
      }
    },

    async approveRequest() {
      try {
        this.isLoading = true
        const payload = { 
          id: this.requestDetails.id,
          isAffiliateTrafficAllowed: this.requestDetailsCopy.isAffiliateTrafficApproved,
          approvedTrafficSources: this.selectedTrafficSources.map((value) => {
            return value.id
          }) 
        }
        await this.approvePendingRequest(payload)
        showSuccessMessage('Application successfully approved')
        this.$router.push({ name: 'SellerOfferPendingReviewRequests' })
      } finally {
        this.isLoading = false
        this.hideConfirmApproveRequestModal()
      }
    },

    async rejectRequest() {
      try {
        this.isLoading = true
        const payload = { 
          id: this.requestDetails.id,
          reason: this.declineReason
        }
        await this.rejectPendingRequest(payload)
        showSuccessMessage('Application successfully rejected')
        this.$router.push({ name: 'SellerOfferPendingReviewRequests' })
      } finally {
        this.isLoading = false
        this.hideConfirmRejectRequestModal()
      }
    },

    async submitOfferRegistrationForm() {
      this.submitted = true

      this.$v.requestDetailsCopy.$touch()

      if (!this.isValid) {
        return
      }

      try {
        this.isOfferChangeLoading = true

        await this.updateOfferRegistration({
          ...this.requestDetailsCopy,
          selectedTrafficSourcesIds: this.selectedTrafficSources.map((ts) => ts.id),
        })

        showSuccessMessage('Application successfully updated')
      } finally {
        this.isOfferChangeLoading = false
      }
    },

    async handleRequestDetailsChanged() {
      this.isAffiliateTrafficAllowed = this.requestDetails.sellerOffer.isAffiliateTrafficAllowed
    
      if (this.requestDetails.accountId) {
        await this.getSellerAccountDetails(this.requestDetails.accountId)
      }

      this.selectedTrafficSources = this.requestDetails.selectedTrafficSources
    },

    copyIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('ID copied to clipboard')
    },

    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    }
  }
}
</script>

<style lang="scss" scoped></style>
