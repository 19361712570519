<template>
  <CCol
    md="8"
    lg="6"
  >
    <CCardGroup>
      <CCard class="p-4">
        <CCardBody>
          <router-view />
        </CCardBody>
      </CCard>
    </CCardGroup>
  </CCol>
</template>

<script>
export default {
  name: 'Login',
}
</script>
