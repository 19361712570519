import PhoneNumber from 'awesome-phonenumber'

export const phoneNumberValidator = value => {
  let phone = new PhoneNumber(value)
  return phone.isValid()
}

export const mobileNumberValidator = value => {
  let phone = new PhoneNumber(value)
  return phone.isMobile()
}

export const USPhoneNumberValidator = value => {
  let phone = new PhoneNumber(value)
  return phone.isValid() && phone.getCountryCode() === 1
}


export default phoneNumberValidator

