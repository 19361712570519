<template>
  <div class="mt-5">
    <h3>
      Signed Documents
    </h3>
    <dl class="row">
      <dt class="col-sm-3 col-12 text-sm-right">
        Document Bundles
      </dt>
      <dd class="col-sm-5 col-12">
        <ul class="list-unstyled">
          <li
            v-for="bundle in sortedBundles"
            :key="bundle.id"
          >
            <SignedDocumentDetails
              :signed-bundle="bundle"
              :account-id="accountId"
            />
          </li>
        </ul>
      </dd>
    </dl>
  </div>
</template>

<script>
import SignedDocumentDetails from './SignedDocumentDetails.vue'
import { getDateSortOperator } from '@/utils'

export default {
  name: 'SignedDocumentBundles',

  components: {
    SignedDocumentDetails,
  },

  props: {
    accountType: {
      type: String,
      default: ''
    },

    accountId: {
      type: String,
      default: ''
    },

    signedBundles: {
      type: Array,

      default() {
        return []
      }
    }
        
  },

  computed: {
    sortedBundles() {
      const clonedBundles = [...this.signedBundles]
      return clonedBundles.sort(getDateSortOperator(b => b.signedOn),)
    }
  },

}
</script>

<style>

</style>