<template>
  <CCard v-if="campaignDetails && campaignDetails.id">
    <CCardHeader>
      Buyer Campaign :
      {{ campaignDetails.name }}
      <a
        href
        @click.prevent="copyIdToClipboard(campaignDetails.id)"
      ><CIcon
        name="cil-copy"
      /></a>
      <CBadge
        class="ml-5"
        :color="getStatusBadge(campaignDetails)"
      >
        {{ getStatus(campaignDetails) }}
      </CBadge>
    </CCardHeader>
    <CCardBody>
      <CForm
        novalidate
        @submit.prevent
      >
        <CInput
          v-model="$v.campaignDetailsLocal.name.$model"
          type="text"
          label="Name"
          add-label-classes="text-sm-right pr-4"
          name="Name"
          placeholder="Campaign Name"
          :is-valid="checkIfValid(['campaignDetailsLocal', 'name'], submitted)"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-6'}"
          invalid-feedback="Campaign name cannot be left blank"
        />

        <CSelect
          id="buyer-campaign-category-dropdown"
          v-model="$v.campaignDetailsLocal.categoryId.$model"
          :value.sync="campaignDetailsLocal.categoryId"
          add-label-classes="text-sm-right pr-4"
          data-cy="buyer-campaign-category"
          label="Category"
          :is-valid="checkIfValid(['campaignDetailsLocal', 'categoryId'], submitted)"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-6'}"
          :options="allowedCategoriesFormatted"
          placeholder="Please select"
          invalid-feedback="Please select a category"
          @change="onCategoryChange"
        />

        <CSelect
          id="buyer-campaign-language-dropdown"
          v-model="$v.campaignDetailsLocal.languageId.$model"
          :value.sync="campaignDetailsLocal.languageId"
          add-label-classes="text-sm-right pr-4"
          data-cy="buyer-campaign-language"
          label="Language"
          :is-valid="checkIfValid(['campaignDetailsLocal', 'languageId'], submitted)"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-6'}"
          :options="languageOptions"
          placeholder="Please select"
          invalid-feedback="Please select a language"
        />

        <CSelect
          id="buyer-campaign-country-dropdown"
          v-model="$v.campaignDetailsLocal.countryId.$model"
          :value.sync="campaignDetailsLocal.countryId"
          add-label-classes="text-sm-right pr-4"
          data-cy="buyer-campaign-country-dropdown"
          label="Country"
          :is-valid="checkIfValid(['campaignDetailsLocal', 'countryId'], submitted)"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-6'}"
          :options="countriesFormatted"
          placeholder="Please select"
          invalid-feedback="Please select a country"
        />

        <CInput
          v-model="lastReviewer.email"
          label="Reviewed By"
          horizontal
          plaintext
          readonly     
          add-label-classes="text-right pr-4"
          add-input-classes="w-50"               
        />

        <CInput
          v-model="lastReviewer.date"
          label="Reviewed At"
          horizontal
          plaintext
          readonly     
          add-label-classes="text-right pr-4"
          add-input-classes="w-50"   
        />

        <CInput
          v-model="lastReviewer.decisionRemarks"
          label="Review Notes"
          horizontal
          plaintext
          readonly     
          add-label-classes="text-right pr-4"
          add-input-classes="w-50"
        />
      </CForm>

      <div class="row">
        <div class="col-sm-3 pr-4" />
        <div class="col-sm-9">
          <ButtonLoading
            color="primary"
            :loading="isLoading"
            :disabled="!isFormEdited"
            horizontal
            size="sm"
            label=""
            @click="updateDetails"
          >
            Update
          </ButtonLoading>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import { required } from 'vuelidate/lib/validators'
import { showSuccessMessage } from '@/notification-utils'
import formMixins from '@/mixins/form-mixins'
import formGenericMixin from '@/mixins/form-generic-mixin'
import { dateTimeFormat } from '@/utils'
import buyerCampaignStatusMixin from '@/views/Campaigns/buyer/mixins/buyer-campaign-status-mixin'

export default {
  name: 'MainSection',

  mixins: [copyToClipboardMixin, formMixins, formGenericMixin, buyerCampaignStatusMixin],

  props: {
    campaignDetails: {
      type: Object,
      default: () => {}
    },
    allowedCategories: {
      type: Array,
      default: () => []
    }
  },
  validations() {
    return {
      campaignDetailsLocal: {
        name: {
          required
        },

        categoryId: {
          required
        },

        languageId: {
          required
        },

        countryId: {
          required
        }
      }
    }
  },
  data() {
    return {
      isLoading: false,
      campaignDetailsLocal: {
        name: '',
        categoryId: null,
        countryId: null
      },
    }
  },

  computed: {
    ...mapState('country', ['countries']),

    lastReviewer(){
      const lastReviewDetails = this.campaignDetailsLocal?.lastReview
      if (!lastReviewDetails){
        return {
          email: "N/A"
        }
      }

      return {
        email: lastReviewDetails.decidedByUserEmail,
        date: this.formatDate(lastReviewDetails.decisionDT),
        remarks: lastReviewDetails.decisionRemarks
      }
    },

    countriesFormatted() {
      return this.countries.map(country => {
        return {
          value: country.id,
          label: country.name
        }
      })
    },
    
    allowedCategoriesFormatted() {
      return this.allowedCategories.map(category => {
        return {
          value: category.id,
          label: category.name
        }
      })
    },

    languageOptions() {
      return this.allowedCategories?.find(c => c.id === this.campaignDetailsLocal.categoryId)?.languages.map(l => ({value: l.id, label: l.name})) ?? []
    }
  },
  watch: {
    campaignDetails: {
      immediate: true,
      handler(newValue) {
        this.campaignDetailsLocal = {
          ...newValue,
          categoryId: newValue.category.id,
          countryId: newValue.country.id,
          languageId:  newValue.language.id,
          lastReview: newValue.lastReview
        }
      }
    }
  },

  async created() {
    if (this.countries.length === 0) {
      await this.list()
    }
  },

  methods: {
    ...mapActions('country', ['list']),
    ...mapActions('campaign', ['updateCampaignDetails']),

    async updateDetails() {
      this.submitted = true

      if (this.$v.$invalid) {
        return
      }

      const campaignId = this.campaignDetailsLocal.id
      const updateObject = {
        name: this.campaignDetailsLocal.name,
        category: this.campaignDetailsLocal.categoryId,
        country: this.campaignDetailsLocal.countryId,
        language: this.campaignDetailsLocal.languageId,
      }
      try {
        this.isLoading = true
        await this.updateCampaignDetails({id: campaignId, updateObject})
        showSuccessMessage('Updated campaign details')
      } catch(error) {
        console.error(error.message)
      } finally {
        this.isLoading = false
      }
    },

    copyIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Campaign ID copied to clipboard')
    },

    onCategoryChange() {
      this.campaignDetailsLocal.languageId = null
    },

    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    }
  }
}
</script>

<style lang="scss" scoped></style>
