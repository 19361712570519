<template>
  <div v-if="requestDetails">
    <CCardBody>
      <dl class="row">
        <dt class="col-sm-3 text-right">
          Applied On
        </dt>
        <dd class="col-sm-9 d-flex align-items-center">
          {{ requestedOn }}
        </dd>
        
        <dt class="col-sm-3 text-right">
          Status
        </dt>
        <dd class="col-sm-9">
          <CBadge :color="statusVariant">
            {{ requestDetails.status }}
          </CBadge>
        </dd>

        <dt class="col-sm-3 text-right">
          Application ID
        </dt>
        <dd class="col-sm-9 d-flex align-items-center">
          {{ requestDetails.id }}

          <a
            href
            class="ml-2"
            @click.prevent="copyIdToClipboard(requestDetails.id)"
          ><CIcon
            name="cil-copy"
          /></a>
        </dd>
      </dl>

      <h3>
        Company
      </h3>

      <dl class="row">
        <dt class="col-sm-3 text-right">
          Name
        </dt>
        <dd class="col-sm-9 d-flex align-items-center">
          {{ requestDetails.companyName }}
        </dd>

        <dt class="col-sm-3 text-right">
          Ringba Account Id
        </dt>
        <dd class="col-sm-9 d-flex align-items-center">
          {{ requestDetails.ringbaAccountId }}

          <a
            v-if="requestDetails.ringbaAccountId"
            href
            class="ml-1"
            @click.prevent="
              copyRingbaAccountIdToClipboard(requestDetails.ringbaAccountId)
            "
          ><CIcon
            name="cil-copy"
          /></a>
        </dd>

        <dt class="col-sm-3 text-right">
          Street Address
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.companyAddress.addressLine1) }}
        </dd>

        <dt class="col-sm-3 text-right">
          Address 2
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.companyAddress.addressLine2) }}
        </dd>


        <dt class="col-sm-3 text-right mt-4">
          City
        </dt>
        <dd class="col-sm-9 mt-4">
          {{ getSafe(() => requestDetails.companyAddress.city) }}
        </dd>

        <dt class="col-sm-3 text-right">
          State
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.companyAddress.state) }}
        </dd>

        <dt class="col-sm-3 text-right">
          Zipcode
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.companyAddress.zip) }}
        </dd>

        <dt class="col-sm-3 text-right">
          Country
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.companyAddress.countryCode) }}
        </dd>


        <dt class="col-sm-3 text-right mt-4">
          Phone
        </dt>
        <dd class="col-sm-9 mt-4">
          <a :href="`tel:${requestDetails.companyPhone}`">
            {{ requestDetails.companyPhone }}
          </a>
        </dd>

        <dt class="col-sm-3 text-right">
          Website
        </dt>
        <dd class="col-sm-9">
          <a
            :href="companyWebsite"
            target="_blank"
            rel="noopener"
          >
            {{ requestDetails.companyWebsite }}
          </a>
        </dd>

        <dt class="col-sm-3 text-right">
          LinkedIn
        </dt>
        <dd class="col-sm-9">
          <a
            v-if="Boolean(requestDetails.companyLinkedIn)"
            :href="companyLinkedIn"
            target="_blank"
          >
            View
          </a>
        </dd>

        <dt class="col-sm-3 text-right">
          Twitter
        </dt>
        <dd class="col-sm-9">
          <a
            v-if="Boolean(requestDetails.companyTwitter)"
            :href="companyTwitter"
            target="_blank"
          >
            View
          </a>
        </dd>

        <dt class="col-sm-3 text-right">
          Facebook
        </dt>
        <dd class="col-sm-9">
          <a
            v-if="Boolean(requestDetails.companyFacebook)"
            :href="companyFacebook"
            target="_blank"
          >
            View
          </a>
        </dd>

        <dt class="col-sm-3 text-right mt-4">
          Tracking Technology
        </dt>
        <dd class="col-sm-9 mt-4">
          {{ requestDetails.callTechnology }}
        </dd>
      </dl>


      <h3>
        Representative
      </h3>

      <dl
        v-if="requestDetails.representative"
        class="row"
      >
        <dt class="col-sm-3 text-right">
          Name
        </dt>
        <dd class="col-sm-9">
          {{ requestDetails.representative.firstName }} {{ requestDetails.representative.lastName }}
        </dd>
        <dt
          class="col-sm-3 text-right"
        >
          Email
        </dt>
        <dd class="col-sm-9">
          <a
            data-cy="representative-email"
            :href="`mailto:${requestDetails.representative.email}`"
          >
            {{ requestDetails.representative.email }}
          </a>
        </dd>
        <dt class="col-sm-3 text-right">
          Phone
        </dt>
        <dd class="col-sm-9">
          <a :href="`tel:${requestDetails.representative.cellPhone}`">
            {{ requestDetails.representative.cellPhone }}
          </a>
        </dd>
        <dt class="col-sm-3 text-right">
          Skype
        </dt>
        <dd class="col-sm-9">
          <a :href="`skype:${requestDetails.representative.skype}?chat`">{{ requestDetails.representative.skype }}</a>
        </dd>

        <dt
          class="col-sm-3 text-right"
        >
          LinkedIn
        </dt>
        <dd class="col-sm-9">
          <a
            v-if="Boolean(requestDetails.representative.linkedIn)"
            data-cy="representative-linkedin"
            target="_blank"
            :href="`//${requestDetails.representative.linkedIn}`"
          >
            View
          </a>
        </dd>
      </dl>
      <h3>
        Other Details
      </h3>
      <dl class="row">
        <dt
          class="col-sm-3 text-right"
        >
          Time Zone
        </dt>
        <dd
          class="col-sm-9"
        >
          {{ getSafe(() => timezonesMap[requestDetails.timeZoneId].label) }}
        </dd>
        
        <dt
          v-if="type === 'buyer'"
          class="col-sm-3 text-right"
        >
          Categories
        </dt>
        <dd
          v-if="type === 'buyer'"
          class="col-sm-9"
        >
          <span
            v-for="(category, $idx) in requestDetails.categories"
            :key="category.id"
          >
            {{ category.name }}
            <span v-if="$idx !== requestDetails.categories.length - 1">|</span>
          </span>
        </dd>
        <dt
          v-if="type === 'buyer'"
          class="col-sm-3 text-right"
        >
          No. of Employees
        </dt>
        <dd
          v-if="type === 'buyer'"
          class="col-sm-9"
        >
          {{ requestDetails.numberOfEmployeesRange }}
        </dd>
        <dt
          v-if="type === 'buyer'"
          class="col-sm-3 text-right"
        >
          Call Purchase Volume
        </dt>
        <dd
          v-if="type === 'buyer'"
          class="col-sm-9"
        >
          {{ requestDetails.callPurchaseVolumeRange }}
        </dd>

        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Categories
        </dt>
        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          <span
            v-for="(category, $idx) in requestDetails.categories"
            :key="category.id"
          >
            {{ category.name }}
            <span v-if="$idx !== requestDetails.categories.length - 1">|</span>
          </span>
        </dd>

        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Inbound Traffic Sources
        </dt>
        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          <span
            v-for="(ts, $idx) in requestDetails.inboundTrafficSources"
            :key="ts.id"
          >
            {{ ts.name }}
            <span v-if="$idx !== requestDetails.inboundTrafficSources.length - 1">|</span>
          </span>
        </dd>

        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Outbound Traffic Sources
        </dt>

        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          <span
            v-for="(ts, $idx) in requestDetails.outboundTrafficSources"
            :key="ts.id"
          >
            {{ ts.name }}
            <span v-if="$idx !== requestDetails.outboundTrafficSources.length - 1">|</span>
          </span>
        </dd>

        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Transfer Traffic Sources
        </dt>

        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          <span
            v-for="(ts, $idx) in requestDetails.transferTrafficSources"
            :key="ts.id"
          >
            {{ ts.name }}
            <span v-if="$idx !== requestDetails.transferTrafficSources.length - 1">|</span>
          </span>
        </dd>


        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Monthly Calls Generated
        </dt>
        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          {{ requestDetails.monthlyCallsGeneratedRange }}
        </dd>

        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Annual PPC Revenue
        </dt>
        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          {{ requestDetails.annualPPCRevenueRange }}
        </dd>

        <dt
          v-if="type === 'seller'"
          class="col-sm-3 text-right"
        >
          Works with Affiliates
        </dt>
        <dd
          v-if="type === 'seller'"
          class="col-sm-9"
        >
          {{ requestDetails.worksWithAffiliates ? 'Yes' : 'No' }}
        </dd>

        <dt class="col-sm-3 text-right mt-4">
          IP Address
        </dt>
        <dd class="col-sm-9 mt-4">
          <a
            target="_blank"
            :href="`https://www.ip2location.com/demo/${requestDetails.geoIpInfo && requestDetails.geoIpInfo.ipAddress}`"
          >
            {{ getSafe(() => requestDetails.geoIpInfo.ipAddress ) }}
          </a>
        </dd>

        <dt class="col-sm-3 text-right">
          IP Country
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.geoIpInfo.ipCountry ) }}
        </dd>

        <dt class="col-sm-3 text-right">
          IP City
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.geoIpInfo.ipCity ) }}
        </dd>

        <dt class="col-sm-3 text-right">
          IP ISP
        </dt>
        <dd class="col-sm-9">
          {{ getSafe(() => requestDetails.geoIpInfo.ipisp ) }}
        </dd>
      </dl>
    </CCardBody>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { dateTimeFormat } from '@/utils'
import { showSuccessMessage } from '@/notification-utils'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'

const cssClassMapping = {
  'pending': 'warning',
  'approved': 'success',
  'rejected': 'danger',
  'waitlisted': 'secondary'
}

export default {
  name: 'RegistrationRequestCommonInfoReadOnly',

  mixins: [copyToClipboardMixin],

  props: {
    requestDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'buyer'
    }
  },

  computed: {
    ...mapGetters('timezones', [
      'timezonesMap'
    ]),
    
    statusVariant() {
      return cssClassMapping[this.requestDetails?.status?.toLowerCase()]
    },

    requestedOn() {
      return !isNaN(Date.parse(this.requestDetails.requestedOn)) && dateTimeFormat.format(new Date(this.requestDetails.requestedOn))
    },

    companyWebsite() {
      return this.handleURLProtocol(this.requestDetails.companyWebsite)
    },

    companyTwitter() {
      return this.handleURLProtocol(this.requestDetails.companyTwitter)
    },

    companyLinkedIn() {
      return this.handleURLProtocol(this.requestDetails.companyLinkedIn)
    },

    companyFacebook() {
      return this.handleURLProtocol(this.requestDetails.companyFacebook)
    }
  },

  methods: {
    copyIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Application ID copied')
    },

    copyRingbaAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Ringba Account ID copied to clipboard')
    }
  },
}
</script>

<style lang="scss" scoped>

</style>