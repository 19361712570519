import { render, staticRenderFns } from "./BuyerCampaignsList.vue?vue&type=template&id=1461da03&scoped=true&"
import script from "./BuyerCampaignsList.vue?vue&type=script&lang=js&"
export * from "./BuyerCampaignsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1461da03",
  null
  
)

export default component.exports