import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import VuexReset from '@ianwalter/vuex-reset'
import createCache from 'vuex-cache'

import category from '@/store/modules/category.store.js'
import trafficSource from '@/store/modules/trafficSource.store.js'
import country from '@/store/modules/country.store.js'
import language from '@/store/modules/language.store.js'
import parentCategory from '@/store/modules/parentCategory.store.js'
import auth from '@/store/modules/auth.store.js'
import registrationRequests from '@/store/modules/registration-requests.store'
import kyc from '@/store/modules/kyc.store.js'
import users from '@/store/modules/users.store.js'
import accounts from '@/store/modules/accounts.store.js'
import campaign from '@/store/modules/campaign.store.js'
import timezones from '@/store/modules/timezones.store.js'
import user from '@/store/modules/user.store.js'
import notes from '@/store/modules/notes.store.js'
import sellerOffer from '@/store/modules/sellerOffer.store.js'
import sellerOfferRegistration from '@/store/modules/sellerOfferRegistration.store.js'
import billing from '@/store/modules/billing.store.js'
import globalSettings from '@/store/modules/globalSettings.store'
import registrationChecklistItem from '@/store/modules/registration-checklist-item.store'
import bidModifiers from '@/store/modules/bidmodifiers.store.js'
import salesChannel from '@/store/modules/salesChannel.store.js'
import categorySupplyDemand from '@/store/modules/categorySupplyDemand.store.js'
import creative from '@/store/modules/creative.store.js'
import callLogs from '@/store/modules/call-logs.store'


Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  darkMode: false,
  asideShow: false,
  statusCodes: ['Active', 'Pending', 'Paused', 'Expired', 'Deleted'],
  sellerOfferVisibilityOptions: ['Public', 'Private'],
  statusCodesForNewEntry: ['Active', 'InReview', 'Paused'],
  billingCycles: [{ label: 'Monthly', value: 0 }, { label: 'Weekly', value: 7 }, { label: 'Bi-Weekly', value: 14 }],
}

const debug = process.env.NODE_ENV !== 'production'

if (debug){
  state.billingCycles.push({label: 'Daily (test only)', value: 1})
}

// TODO: Create actions for these, never call mutations directly
const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  toggle(state, variable) {
    state[variable] = !state[variable]
  },

  reset() {
    return {}
  }
}

const actions = {
  resetGlobalState({ commit }) {
    commit('reset')
  }
}

const getters = {}

const store = new Vuex.Store({
  modules: {
    category,
    trafficSource,
    country,
    language,
    parentCategory,
    registrationRequests,
    auth,
    kyc,
    users,
    accounts,
    campaign,
    timezones,
    user,
    notes,
    billing,
    sellerOffer,
    sellerOfferRegistration,
    globalSettings,
    registrationChecklistItem,
    bidModifiers,
    salesChannel,
    categorySupplyDemand,
    creative,
    callLogs
  },
  strict: debug,
  plugins: debug ? [createLogger(), VuexReset(), createCache()] : [VuexReset(), createCache()],
  state,
  mutations,
  actions,
  getters
})

if (window.Cypress) {
  window.__store__ = store
}

export default store
