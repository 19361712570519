import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/notes', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const END_POINT = 'admin'

const NotesAPI = {
  
  async getReviewNotes(noteListId) {
    const url = `${END_POINT}/${noteListId}`
    return await httpClient.get(url)
  },

  addReviewNotes({ noteListId, description, attachment, parentObjectId }) {
    const formData = new FormData()
    formData.append('noteListId', noteListId)
    formData.append('parentObjectId', parentObjectId)
    formData.append('description', description)
    formData.append('attachment', attachment)

    const url = `${END_POINT}`
    return httpClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  async deleteReviewNote({ noteListId, noteId }) {
    const url = `${END_POINT}/${noteListId}/${noteId}`
    return httpClient.delete(url)
  },

  async updateReviewNote({ noteListId, noteId, description, attachment, changeAttachment }) {
    const formData = new FormData()
    formData.append('description', description)
    formData.append('attachment', attachment)
    formData.append('changeAttachment', changeAttachment)

    const url = `${END_POINT}/${noteListId}/${noteId}`
    return httpClient.patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })  }
}

export default NotesAPI
