<template>
  <div>
    <KYCRequestsList
      :kyc-requests="pendingKYCRequests"
      :is-loading="isLoading"
      @change-auto-reload="onChangeAutoReload"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import KYCRequestsList from './KYCRequestsList.vue'

const AUTO_RELOAD_INTERVAL = 120 * 1000 // 2 mins interval

let autoReloadTimer

export default {
  name: 'KYCRequests',

  title: 'KYC Review',

  components: {
    KYCRequestsList
  },

  data() {
    return {
      type: '',
      isLoading: false
    }
  },

  computed: {
    ...mapState('kyc', ['pendingKYCRequests'])
  },

  async created() {
    try {
      this.isLoading = true
      await this.fetchPendingKYCRequests()
    } finally {
      this.isLoading = false
    }
  },

  beforeDestroy() {
    this.clearAutoReloadInterval()
  },

  methods: {
    ...mapActions('kyc', ['fetchPendingKYCRequests']),

    onChangeAutoReload(enableAutoreload) {
      if (enableAutoreload) {
        autoReloadTimer = setInterval(async () => {
          await this.fetchPendingKYCRequests()
        }, AUTO_RELOAD_INTERVAL)
      } else {
        this.clearAutoReloadInterval()
      }
    },

    clearAutoReloadInterval() {
      if (autoReloadTimer) {
        clearInterval(autoReloadTimer)
        autoReloadTimer = null
      }
    }

  }
}
</script>

<style></style>
