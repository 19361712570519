<template>
  <div class="card card-sm py-2 mb-2 border">
    <dl class="row mb-0">
      <dt class="col-sm-3 col-12 text-sm-right">
        Bundle Id
      </dt>

      <dd class="col-sm-9 col-12 col-12">
        {{ signedBundle.id }}

        <a
          v-if="!isLoading"
          href="javascript:;"
          class="ml-1"
          @click="downloadFile(signedBundle.id)"
        >
          <CIcon name="cilCloudDownload" />
        </a>

        <CSpinner
          v-if="isLoading"
          class="ml-1"
          size="sm"
        />
      </dd>

      <dt
        class="col-sm-3 col-12 text-sm-right"
      >
        Signed By
      </dt>

      <dd
        class="col-sm-9 col-12"
      >
        {{ getSafe(() => signedBundle.signedBy.name) }} {{ getSafe(() => signedBundle.signedBy.lastName) }}
      </dd>

      <dt class="col-sm-3 col-12 text-sm-right">
        Signed On
      </dt>

      <dd class="col-sm-9 col-12">
        {{ formatDate(signedBundle.signedOn) }}
      </dd>

      <dt class="col-sm-3 col-12 text-sm-right">
        Docs Signed
      </dt>

      <dd class="col-sm-9 col-12">
        <span
          v-for="(doc, $idx) in signedBundle.documents"
          :key="doc"
        >
          {{ doc }}
          <span v-if="$idx !== signedBundle.documents.length - 1">|</span>
        </span>
      </dd>
    </dl>
  </div>
</template>

<script>
import { showErrorMessage } from '@/notification-utils'
import { mapActions } from 'vuex'

import { dateTimeFormat } from '@/utils'

export default {
  name: 'SignedDocumentDetails',

  props: {
    signedBundle: {
      type: Object,
      default() {
        return {}
      }
    },
    accountId: {
      type: String,
      default: ''
    },

  },

  data() {
    return {
      isLoading: false
    }
  },

  methods: {
    ...mapActions('accounts', [
      'getSellerAccountSignedDocumentBundleFiles',
      'getBuyerAccountSignedDocumentBundleFiles'
    ]),

    formatDate(date) {
      if (!date) {
        return ''
      }

      return dateTimeFormat.format(new Date(date))
    },

    async downloadFile(bundleId) {
      try {
        this.isLoading = true

        if (this.accountType === 'seller') {
          await this.getSellerAccountSignedDocumentBundleFiles({ accountId: this.accountId, bundleId })
        } else {
          await this.getBuyerAccountSignedDocumentBundleFiles({ accountId: this.accountId, bundleId })
        }
      } catch (error) {
        showErrorMessage(error)
      } finally {
        this.isLoading = false
      }
      
    }
  },
}
</script>
<style lang="">
  
</style>