<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol sm="2">
            Pay an invoice #
          </CCol>
          <CCol>
            <CButton
              size="sm"
              color="info"
              variant="outline"
              @click="getDownloadLink(accountId, invoiceId)"
            >
              {{ invoiceId }}
            </CButton>
          </CCol>
        </CRow>
        <CBadge :color="getBadge(paymentDetails.paymentStatus)">
          {{ paymentDetails.paymentStatus }}
        </CBadge>
      </CCardHeader>

      <CCardBody>
        <CRow>
          <CCol lg="6">
            <CWidgetProgress
              :header="formatAmount(paymentDetails.amountPaid)"
              text="Paid Amount"
              :footer="`Last updated at: ${lastUpdatedAt}`"
              color="success"
              :value="paidAmountPercentage"
            />
          </CCol>
          <CCol lg="6">
            <CWidgetProgress
              :header="formatAmount(remainingAmount)"
              text="Remaining Amount"
              :footer="`Due date: ${formatDate(paymentDetails.dateDue)}`"
              color="varning"
              :value="remainingAmountPercentage"
            />
          </CCol>
        </CRow>
        <CForm
          novalidate
          @submit.prevent
        >
          <CInput
            v-model="$v.updatePaymentViewModel.amountPaid.$model"
            type="number"
            label="Amount to Pay"
            name="amount"
            :disabled="true"
            placeholder="Amount to be added"
            :is-valid="checkIfValid(['updatePaymentViewModel', 'amountPaid'])"
            invalid-feedback="Invalid amount"
          />
          <div
            v-if="isFormEdited && $v.updatePaymentViewModel.amountPaid.$invalid"
            class="invalid-feedback d-flex mb-3 mt-2"
          >
            Please enter paid amount (greater than zero)
          </div>

          <CTextarea
            id="notes"
            v-model="$v.updatePaymentViewModel.description.$model"
            label="Description"
            placeholder="Enter description. For example: received money by wire. Transaction id: 3CW-123456789"
            :is-valid="checkIfValid(['updatePaymentViewModel', 'description'])"
            rows="3"
            invalid-feedback="Please enter description for reference"
          />
          <div
            v-if="isFormEdited && $v.updatePaymentViewModel.description.$invalid"
            class="invalid-feedback d-flex mb-3 mt-2"
          >
            Please enter description for reference
          </div>
          <div class="form-row">
            <CCol>
              <CButton
                v-if="paymentDetails.paymentStatus !== 'Paid'"
                type="submit"
                size="sm"
                color="primary"
                @click="onFormSubmit"
              >
                Submit payment
              </CButton>
            </CCol>
          </div>
        </CForm>
      </CCardBody>
      <CModal
        title="Confirm payment"
        color="success"
        :show.sync="confirmPaymentModal"
      >
        Are you sure you want to update the payment?
        <template #footer>
          <ButtonLoading
            color="primary"
            :loading="isLoading"
            @click="onSubmit"
          >
            Confirm
          </ButtonLoading>
          <CButton
            color="danger"
            @click="confirmPaymentModal = false"
          >
            Cancel
          </CButton>
        </template>
      </CModal>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { minValue, required, minLength } from 'vuelidate/lib/validators'
import formGenericMixin from '@/mixins/form-generic-mixin'
import { formatDate, formatAmount } from '@/utils'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

export default {
  name: 'AdvancePaymentDetails',

  mixins: [formGenericMixin],

  validations() {
    return {
      updatePaymentViewModel: {
        amountPaid: {
          required,
          minValue: minValue(1)
        },

        description: {
          required,
          minLength: minLength(2)
        }
      }
    }
  },

  data() {
    return {
      isLoading: false,
      confirmPaymentModal: false,
      updatePaymentViewModel:{
        amountPaid: 0,
        description: ''
      }
    }
  },

  computed: {
    ...mapState('billing', ['paymentDetails']),

    remainingAmount(){
      return this.paymentDetails.amountDue - this.paymentDetails.amountPaid
    },
    remainingAmountPercentage(){
      return 100 * Math.round(this.remainingAmount/this.paymentDetails.amountDue, 2)
    },
    paidAmountPercentage(){
      return 100 * Math.round(this.paymentDetails.amountPaid/this.paymentDetails.amountDue, 2)
    },
    lastUpdatedAt(){
      if (!this.paymentDetails.creationDate){
        return "N/A"
      }

      return formatDate(this.paymentDetails.creationDate)
    }
  },

  async created() {
    this.accountId = this.$route.params.accountId
    this.invoiceId = this.$route.params.invoiceId

    if (this.accountId && this.invoiceId) {      
      this.isLoading = true
      try {
        await this.getPaymentDetails({accountId: this.accountId, invoiceId: this.invoiceId})

        //bind data
        this.updatePaymentViewModel.amountPaid = this.paymentDetails.amountDue
        this.updatePaymentViewModel.description = this.paymentDetails.description
      }
      finally {
        this.isLoading = false
      }
    }
  },

  methods: {
    ...mapActions('billing', [
      'generateDownloadLink',
      'getPaymentDetails',
      'updatePayment'
    ]),


    onFormSubmit() {
      this.$v.updatePaymentViewModel.$touch()

      if (this.isValid) {
        this.confirmPaymentModal = true
      }
    },

    getBadge(status) {
      switch (status) {
        case 'Outstanding':
          return 'primary'

        case 'Paid':
          return 'success'

        case 'PartiallyPaid':
          return 'secondary'

        case 'Overdue':
          return 'danger'

        case 'NotApplicable':
          return 'secondary'

        default:
          return 'warning'
      }
    },
     async getDownloadLink(accountId, invoiceId) {
      const downloadURL = await this.generateDownloadLink({ accountId, invoiceId })
      window.open(downloadURL)
    },
    formatDate,
    formatAmount,

    async onSubmit() {
      this.$v.updatePaymentViewModel.$touch()

      if (this.isValid) {
        this.isLoading = true

        try {
          await this.updatePayment({
            accountId: this.accountId,
            invoiceId: this.invoiceId,
            data: this.updatePaymentViewModel
          })
          this.resetForm()
          showSuccessMessage('The invoice has been successfully paid.')
          this.$router.go(-1)
        } catch (error) {
          showErrorMessage(error)
        } finally {
          this.isLoading = false
          this.confirmPaymentModal = false
        }
      }
    },

    resetForm() {
      this.amount = 0
      this.description = ''
      
      this.resetFormDirtyState()
    },
  }
}
</script>

<style></style>
