import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/review', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const END_POINT = 'kyc-requests'

const KYCApi = {
  getPendingKYCRequests() {
    const url = `${END_POINT}`
    return httpClient.get(url)
  },
  approveKYCRequest({ paymentMethodId }) {
    const url = `${END_POINT}/${paymentMethodId}/approve`
    return httpClient.post(url)
  },
  rejectKYCRequest({ paymentMethodId, declineReason }) {
    const url = `${END_POINT}/${paymentMethodId}/reject`
    return httpClient.post(url, { declineReason })
  }
}

export default KYCApi
