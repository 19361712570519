<template>
  <div>    
    <CCard>
      <CCardHeader>
        Bid Modifiers
      </CCardHeader>
      <CCardBody>
        <CForm
          novalidate
          @submit.prevent
        >
          <CRow 
            v-if="!parentObjectAlwaysOn"
            class="form-group"
          >
            <CCol
              sm="3"
              class="text-right"
            >
              Use bid modifiers
            </CCol>
            <CCol>          
              <CSwitch                
                shape="pill"   
                :checked.sync="bidModifiersLocal.useBidModifiers"
                color="primary"           
              />            
            </CCol>
          </CRow>
          <CInput
            v-if="bidModifiersLocal.useBidModifiers"
            v-model="bidModifiersLocal.minMarginPercent"
            type="number"
            label="Minimum Margin Percent"
            name="Minimum Margin Percent"
            placeholder="Minimum Margin Percent"  
            add-label-classes="col-sm-3 text-right pr-3"
            horizontal
            :is-valid="checkIfValid(['bidModifiersLocal','minMarginPercent'])"
            :invalid-feedback="minMarginPercentInvalid"
          />
  
          <CInput
            v-if="bidModifiersLocal.useBidModifiers"
            v-model="bidModifiersLocal.maxMarginPercent"
            type="number"
            label="Maximum Margin Percent"
            name="Maximum Margin Percent"
            placeholder="Maximum Margin Percent"  
            add-label-classes="col-sm-3 text-right pr-3"        
            horizontal
            :is-valid="checkIfValid(['bidModifiersLocal','maxMarginPercent'])"
            :invalid-feedback="maxMarginPercentInvalid"
          />
    
          <div class="form-group form-row">
            <div class="col-sm-9 offset-sm-3 col-xl-5">
              <ButtonLoading
                color="primary"
                :loading="isLoading"
                @click="submitBidModifiers"
              >
                Save Bid Modifiers
              </ButtonLoading>
            </div>
          </div>
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { required, between } from 'vuelidate/lib/validators'
import formGenericMixin from '@/mixins/form-generic-mixin'
import formMixins from '@/mixins/form-mixins'
import { MARGIN_PERCENT_MINIMUM } from '@/store/modules/bidmodifiers.store'

export default {
  name: 'BidModifiers',

  mixins: [formGenericMixin, formMixins],  

  props: {
    parentObject: {
      type: Object,
      default() {
        return {}
      }
    },

    type: {
      type: String,
      default: ''
    }
  },

  validations() {
    return {
      bidModifiersLocal: {
       
        minMarginPercent: {
          required,
          between: between(MARGIN_PERCENT_MINIMUM, 100)
        },

        maxMarginPercent: {
          required,
          between: between(this.bidModifiersLocal?.minMarginPercent != null ? parseInt(this.bidModifiersLocal?.minMarginPercent) : MARGIN_PERCENT_MINIMUM, 100)
        },
      }
    }
  },  

  data() {
    return {
      isLoading: false,     
      bidModifiersLocal: {
        useBidModifiers: false,
        minMarginPercent: MARGIN_PERCENT_MINIMUM,
        maxMarginPercent: MARGIN_PERCENT_MINIMUM
      },

      minMarginPercentInvalid: `Margin percent should be between ${MARGIN_PERCENT_MINIMUM} and 100%`,

      maxMarginPercentInvalid: `Max margin percent should be between minimum margin percent and 100%`,
    }
  },

  computed: {
    ...mapState('bidModifiers', ['bidModifiersState']),
    
    hasParentObjectType () {
      return Boolean(this.parentObjectType)
    },

    parentObjectType() {
      return this.parentObject?.parentObjectType
    },

    parentObjectId() {
      return this.parentObject?.id
    },

    parentObjectAlwaysOn() {
      return Boolean(this.parentObject?.alwaysOn)
    },

    isValid() {      
      return !this.$v.$invalid
    },
  },

  watch: {
    parentObject: {
      immediate: true,
      async handler() {       

        await this.fetchBidModifiers()
      }
    },

    bidModifiersState: {
      immediate: true,
      async handler(newValue) {       

        this.bidModifiersLocal = newValue
          ? { ...newValue }
          : {
            useBidModifiers: false,
            minMarginPercent: 0,
            maxMarginPercent: 0
          }
      }
    },    
  
  },

  async created() {       
    await this.fetchBidModifiers()
  },

  

  methods: {   

    ...mapGetters('bidModifiers', ['getBidModifiersState']),    
    
    ...mapActions('bidModifiers', [
      'getBidModifiers', 
      'setBidModifiers',
      'resetBidModifiers'
    ]),

    async fetchBidModifiers() {
      this.isLoading = true      

      try {
        await this.getBidModifiers({        
          parentObjectType: this.parentObjectType,
          parentObjectId: this.parentObjectId})

          this.bidModifiersLocal = {...this.getBidModifiersState()}
          
          if (this.parentObjectAlwaysOn) {
            this.bidModifiersLocal.useBidModifiers = true
          }  
       
      } catch (error) {
        // Handle error
      } finally {
        this.isLoading = false
      }
    },

    async submitBidModifiers() {
      this.submitted = true  

      if (this.isValid) {
        this.isLoading = true      
        
        let setData = {
          useBidModifiers: this.bidModifiersLocal.useBidModifiers,
          minMarginPercent: this.bidModifiersLocal.minMarginPercent,
          maxMarginPercent: this.bidModifiersLocal.maxMarginPercent,
        }

        try {
          await this.setBidModifiers({
            bidModifiers: setData,
            parentObjectType: this.parentObjectType,
            parentObjectId: this.parentObjectId})            
        } catch (error) {
          // Handle error
        } finally {
          this.isLoading = false
        }
      }
    },    
  }
}
</script>