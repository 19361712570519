<template>
  <CForm @submit.prevent>
    <h1>Multi-factor Authentication</h1>
    <p class="text-muted">
      Enter OTP to complete login
    </p>

    <CInput
      v-model="mfaCode"
      placeholder="MFA Code"
    />

    <CRow>
      <CCol
        col="8"
        class="text-left"
      >
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          type="submit"
          @click="onSubmit"
        >
          Submit
        </ButtonLoading>

        <ButtonLoading
          color="secondary"
          class="ml-3"
          type="button"
          @click="goBackToLoginPage"
        >
          Retry Login
        </ButtonLoading>
      </CCol>
    </CRow>
  </CForm>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import loginMixin  from '@/mixins/login-mixin'

import authViewMixin from '@/mixins/auth-view-mixin'

export default {
  name: 'LoginMFA',

  mixins: [authViewMixin, loginMixin],

  data() {
    return {
      mfaCode: '',
      isLoading: false
    }
  },

  computed: {
    ...mapGetters('auth', [
      'isAuthenticated'
    ]),
    ...mapState('auth', [
      'redirectToAfterLoginPath'
    ])
  },

  methods: {
    ...mapActions('auth', ['submitMFACode']),
    
    async onSubmit() {
      try {
        this.isLoading = true

        await this.submitMFACode(this.mfaCode)

        if (!this.isAuthenticated) {
          this.$emit('error')
          return
        }

        if (!this.internal) {
          if (this.redirectToAfterLoginPath) {
            this.$router.push(this.redirectToAfterLoginPath)
          } else {
            this.$router.push({
              name: 'Dashboard'
            })
          }
        }

        this.$emit('success')
      } catch {
        this.$emit('error')
      } finally {
        this.isLoading = false
      }
    },

  }
}
</script>

<style></style>
