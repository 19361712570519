<template>
  <div>
    <CModal
      :title="`Confirm ${action}`"
      :color="modalHeaderColor"
      data-cy="approve-reject-modal"
      :show.sync="warningActionModal"
    >
      Please confirm that you want to {{ action }} this campaign?

      <CTextarea
        v-model="remarks"
        class="mt-2"
        name="Remarks"
        label="Remarks"
        :is-valid="areRemarksValid"
        invalid-feedback="Remarks are mandatory for rejection"
        small
        required
        rows="3"
        :debounce="500"
      />

      <template #footer>
        <ButtonLoading
          v-if="action === 'reject'"
          color="primary"
          :loading="isApproveRejectButtonLoading"
          :disabled="!areRemarksValid"
          @click="confirmRejectCampaign"
        >
          Reject
        </ButtonLoading>
        <ButtonLoading
          v-if="action === 'approve'"
          color="primary"
          :loading="isApproveRejectButtonLoading"
          @click="confirmApproveCampaign"
        >
          Approve
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningActionModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CCard v-if="buyerAccountDetails && buyerAccountDetails.id">
      <CCardHeader>
        Buyer Account
      </CCardHeader>
      <CCardBody>
        <dl class="row">
          <dt class="col-sm-3 text-right">
            Account Name
          </dt>
          <dd class="col-sm-9 d-flex align-items-center">
            <router-link
              :to="{ name: 'BuyerAccountDetails', params: { id: buyerAccountDetails.id} }"
              target="_blank"
            >
              {{ buyerAccountDetails.name }}
            </router-link>

            <a
              href
              class="ml-1"
              @click.prevent="
                copyAccountIdToClipboard(buyerAccountDetails.id)
              "
            ><CIcon
              name="cil-copy"
            /></a>
          </dd>
        </dl>
      </CCardBody>
    </CCard>

    <MainSection
      v-if="
        campaignDetails &&
          campaignDetails.id &&
          buyerAccountDetails &&
          buyerAccountDetails.id
      "
      :campaign-details="campaignDetails"
      :allowed-categories="buyerAccountDetails.allowedCategories"
    />
    <Destination
      v-if="campaignDetails && campaignDetails.id"
      :campaign-id="campaignDetails.id"
      :routing-details="campaignDetails.routing"
      :budget-details="campaignDetails.budget"
    />
    <Scheduling
      v-if="campaignDetails && campaignDetails.id"
      :campaign-id="campaignDetails.id"
      :routing-details="campaignDetails.routing"
      :account-time-zone-id="getSafe(() => buyerAccountDetails.timeZoneId)"
    />
    <TrafficSources
      v-if="
        campaignDetails &&
          campaignDetails.id &&
          buyerAccountDetails &&
          buyerAccountDetails.id
      "
      :campaign-id="campaignDetails.id"
      :traffic-sources="buyerAccountDetails.allowedTrafficSources"
      :traffic-sources-details="campaignDetails.trafficSources"
    />
    <Audience
      v-if="campaignDetails && campaignDetails.id"
      :campaign-id="campaignDetails.id"
      :audience-details="campaignDetails.audience"
    />
    <Budget
      v-if="campaignDetails && campaignDetails.id"
      :campaign-id="campaignDetails.id"
      :campaign-routing-type="campaignDetails.routing.type"
      :budget-details="campaignDetails.budget"
    />

    <ReviewNotes
      v-if="buyerAccountDetails && campaignDetails && campaignDetails.id"
      :parent-object="campaignDetails"
    />

    <CRow
      v-if="isInReview"
      class="mb-3"
    >
      <CCol sm="1">
        <ButtonLoading
          color="success"
          @click="approveCampaign"
        >
          Approve
        </ButtonLoading>
      </CCol>

      <CCol sm="1">
        <ButtonLoading
          color="danger"
          class="ml-2"
          @click="rejectCampaign"
        >
          Reject
        </ButtonLoading>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import MainSection from './MainSection.vue'
import Destination from './Destination.vue'
import Scheduling from './Scheduling.vue'
import TrafficSources from './TrafficSources.vue'
import Audience from './Audience.vue'
import Budget from './Budget.vue'
import ReviewNotes from '../../ReviewNotes/ReviewNotes.vue'

import { showSuccessMessage } from '@/notification-utils'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'

export default {
  name: 'BuyerCampaignDetails',

  title: 'Buyer Campaign',

  components: {
    MainSection,
    Destination,
    Scheduling,
    TrafficSources,
    Audience,
    Budget,
    ReviewNotes
  },

  mixins: [copyToClipboardMixin],
  
  data() {
    return {
      isApproveRejectButtonLoading: false,
      warningActionModal: false,
      action: '',
      remarks: ''
    }
  },
  computed: {
    ...mapGetters('campaign', ['buyerCampaignById']),
    ...mapState('accounts', ['buyerAccountDetails']),

    campaignDetails() {
      return this.buyerCampaignById(this.campaignId)
    },
    modalHeaderColor() {
      const colorMap = { reject: 'danger', approve: 'success' }
      return colorMap[this.action]
    },
    areRemarksValid() {
      if (this.action === 'approve') {return true}
      if (this.action === 'reject' && this.remarks.length) {return true}
      return false
    },

    isInReview() {
      return this.campaignDetails?.status?.toLowerCase() === 'inreview'
    }
  },
  validations() {
    return {
      campaignDetailsLocal: {
        number: {
          required
        }
      }
    }
  },

  async created() {
    this.campaignId = this.$route.params.id

    await this.fetchBuyerCampaign(this.campaignId)

    document.title = this.campaignDetails.name

    const { accountId } = this.campaignDetails

    this.accountDetails = await this.getBuyerAccountDetails(accountId)
  },

  methods: {
    ...mapActions('campaign', [
      'fetchBuyerCampaigns',
      'approveBuyerCampaign',
      'rejectBuyerCampaign',
      'fetchBuyerCampaign'
    ]),

    ...mapActions('accounts', ['getBuyerAccountDetails']),

    approveCampaign() {
      this.action = 'approve'
      this.warningActionModal = true
    },

    rejectCampaign() {
      this.action = 'reject'
      this.warningActionModal = true
    },

    async confirmApproveCampaign() {
      try {
        this.isApproveRejectButtonLoading = true
        await this.approveBuyerCampaign({
          id: this.campaignId,
          remarks: this.remarks,
          version: this.campaignDetails.version
        })
        this.warningActionModal = false
        this.isApproveRejectButtonLoading = false
        this.$router.push({
          name: 'PendingApprovalBuyerCampaigns'
        })
      } catch (error) {
        // As of now, the interceptor shows the error popup but
        // if we want to do some custom handling in future we
        // can do it here
      } finally {
        this.isApproveRejectButtonLoading = false
      }
    },

    async confirmRejectCampaign() {
      try {
        this.isApproveRejectButtonLoading = true
        await this.rejectBuyerCampaign({
          id: this.campaignId,
          remarks: this.remarks,
          version: this.campaignDetails.version
        })
        this.isApproveRejectButtonLoading = false
        this.warningActionModal = false
        this.$router.push({
          name: 'PendingApprovalBuyerCampaigns'
        })
      } catch(error) {
        // As of now, the interceptor shows the error popup but
        // if we want to do some custom handling in future we
        // can do it here
      } finally {
        this.isApproveRejectButtonLoading = false
      }
    },

    copyAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Account ID copied to clipboard')
    }
  }
}
</script>

<style lang="scss" scoped></style>
