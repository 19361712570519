<template>
  <div>
    <CCard>
      <CCardHeader>
        <div class="d-flex align-items-center mb-3">
          <CSwitch
            variant="3d"
            color="primary"
            horizontal
            size="sm"
            class="mr-2"
            :checked.sync="showActiveOnly"
          />
          <h5 class="m-1">
            Show Pending Only
          </h5>
        </div>
        <CRow>
          <CCol 
            :xs="4" 
            class="px-2"
          >
            <CWidgetIcon
              color="primary"
              text="Outstanding"
              :header="formatAmount(outstandingAmount)"
            >
              <CIcon 
                name="cil-task" 
                size="xl" 
              /> 
              <CButton
                size="sm"
                color="link"
                disabled
                style="color: #fff"
              >
                {{ outstandingCount }} Invoices
              </CButton>
            </CWidgetIcon>
          </CCol>
          <CCol 
            :xs="4" 
            class="px-2"
          >
            <CWidgetIcon 
              color="danger" 
              text="Overdue"
              :header="formatAmount(overdueAmount)"
            >
              <CIcon 
                name="cil-warning" 
                size="xl" 
              /> 
              <CButton
                size="sm"
                color="link"
                disabled
                style="color: #fff"
              >
                {{ overdueCount }} Invoices
              </CButton>
            </CWidgetIcon>
          </CCol>
          <CCol 
            :xs="4" 
            class="px-2"
          >
            <CWidgetIcon
              color="info"
              text="Nearest Payout"
              :header="formatDateInclusive(nearestPayoutInvoice.dateDue)"
            >
              <CIcon
                name="cil-calendar"
                size="xl"
              />
              <CButton
                v-if="nearestPayoutInvoice.invoiceId"
                size="sm"
                color="link"
                style="color: #fff"
                @click="getDownloadLink(nearestPayoutInvoice.accountId, nearestPayoutInvoice.invoiceId)"
              >
                {{ nearestPayoutInvoice.invoiceId }}
              </CButton>
              <div v-if="!nearestPayoutInvoice.invoiceId">
                N/A
              </div>
            </CWidgetIcon>
          </CCol>
          <CCol 
            :xs="4" 
            class="px-2"
          >
            <CWidgetIcon
              text="Highest Payout"
              color="gradient-warning"
              :header="formatAmount(highestAmountInvoice.totalInvoicedAmount)"
            >
              <CIcon name="cil-moon" />
              <CButton
                v-if="highestAmountInvoice.invoiceId"
                size="sm"
                color="link"
                style="color: #fff"
                @click="getDownloadLink(highestAmountInvoice.accountId, highestAmountInvoice.invoiceId)"
              >
                {{ highestAmountInvoice.invoiceId }}
              </CButton>
              <div v-if="!highestAmountInvoice.invoiceId">
                N/A
              </div>
            </CWidgetIcon>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <div
          v-if="isLoading"
          :style="{ height: '80vh' }"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <CSpinner
            style="width: 4rem; height: 4rem"
            color="info"
            grow
          />
        </div>

        <div v-else>
          <div class="d-flex align-items-center mb-3">
            <CSwitch
              variant="3d"
              color="primary"
              horizontal
              size="sm"
              class="mr-2"
              :checked.sync="includeTestOnly"
            />
            <h5 class="m-1">
              Show Test-Only Accounts
            </h5>
          </div>

          <CDataTable
            responsive
            small
            fixed
            border
            sorter
            column-filter
            :items="filteredInvoices"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ align: 'center' }"
            :column-filter-value.sync="columnFilterValues"
          >
            <template #paymentStatusId-filter>
              <select
                v-model="paymentStatusIdFilter"
                class="form-control form-control-sm"
              >
                <option :value="undefined" />
                <option value="Overdue">
                  Overdue
                </option>
                <option value="Outstanding">
                  Outstanding
                </option>
                <option value="Paid">
                  Paid
                </option>
              </select>
            </template>

            <template #billingCycle="{ item }">
              <td>
                {{
                  getSafe(
                    () =>
                      `${formatDate(item.billingCycleStartDate)} - ${formatDateInclusive(item.billingCycleEndDate)}`
                  )
                }}
              </td>
            </template>

            <template #dateDue="{ item }">
              <td>
                {{ getSafe(() => `${formatDateInclusive(item.dateDue)}`) }}
              </td>
            </template>

            <template #paymentDate="{ item }">
              <td>
                {{
                  getSafe(() =>
                    item.paymentDate ? `${formatDate(item.paymentDate)}` : 'N/A'
                  )
                }}
              </td>
            </template>

            <template #totalInvoicedAmount="{ item }">
              <td>
                <strong>{{
                  getSafe(() => `${formatAmount(item.totalInvoicedAmount)}`)
                }}</strong>
              </td>
            </template>

            <template #paymentMethod="{ item }">
              <td>
                {{ getSafe(() => `${item.paymentMethod || 'N/A'}`) }}
              </td>
            </template>

            <template #accountName="{ item }">
              <td>
                <router-link
                  class="align-text-bottom mr-3"
                  target="_blank"
                  :to="{
                    name:
                      accountType === 'buyer'
                        ? 'BuyerAccountDetails'
                        : 'SellerAccountDetails',
                    params: { id: item.accountId },
                    exact: true,
                  }"
                >
                  {{ getSafe(() => `${item.accountName}`) }}

                  <CIcon
                    name="cil-external-link"
                    size="custom"
                  />
                </router-link>

                <CButton
                  size="sm"
                  :to="`/dashboard/accounts/seller/${item.accountId}/payments`"
                  target="_blank"
                  title="View Payment History"
                >
                  <CIcon
                    name="cil-history"
                    size="custom"
                  />
                </CButton>
              </td>
            </template>

            <template #paymentStatusId="{ item }">
              <td>
                <CBadge :color="getBadge(item.paymentStatusId)">
                  {{ item.paymentStatusId }}
                </CBadge>
              </td>
            </template>

            <template #invoiceId="{ item }">
              <td class="text-center">
                <CButton
                  size="sm"
                  color="info"
                  variant="outline"
                  @click="getDownloadLink(item.accountId, item.invoiceId)"
                >
                  {{ item.invoiceId }}

                  <CIcon
                    name="cilCloudDownload"
                    class="ml-1"
                  />
                </CButton>
              </td>
            </template>

            <template #Actions="{ item }">
              <td>
                <CButton
                  v-if="item.paymentStatusId === 'Outstanding' || item.paymentStatusId === 'Overdue'"
                  color="info"
                  class="mr-2"
                  size="sm"
                  @click="showPayInvoiceModal(item)"
                >
                  Pay Invoice
                </CButton>
                <CButton
                  v-if="item.paymentStatusId !== 'Outstanding' && item.paymentStatusId !== 'Overdue'"
                  color="success"
                  size="sm"
                  @click="showPayInvoiceModal(item)"
                >
                  Details
                </CButton>
                <!-- NOTE: disabled, we are going to revise or remove this functionality -->
                <!-- <CButton
                  v-if="item.paymentStatusId === 'Outstanding' || item.paymentStatusId === 'Overdue'"
                  color="dark"
                  size="sm"
                  @click="confirmMarkInvoiceAsPaid(item)"
                >
                  Paid
                </CButton> -->
              </td>
            </template>
          </CDataTable>
        </div>
      </CCardBody>
    </CCard>
    
    <!-- Login Modal START -->
    <CModal
      title="Please login again to verify its you"
      color="primary"
      data-cy="login-modal"
      :show.sync="showLoginModal"
    >
      <LoginInternal
        v-if="showLoginModal"
        @success="onLoginSuccess"
        @error="onLoginFailed"
      />

      <template #footer-wrapper>
        <div />
      </template>
    </CModal>
    <!-- Login Modal END -->

    <CModal
      :title="`Payment of ${formatAmount(invoiceDetails.totalInvoicedAmount)} for ${invoiceDetails.accountName}`"
      color="info"
      :show.sync="showInvoiceDetails"
    >
      <CForm
        novalidate
        @submit.prevent
      >
        <CInput
          type="number"
          label="Billing Period"
          name="amount"
          :placeholder="`${formatDate(invoiceDetails.billingCycleStartDate)} - ${formatDateInclusive(invoiceDetails.billingCycleEndDate)}`"
          horizontal
          plaintext
        />
        <CInput
          v-if="invoiceDetails.totalInvoicedAmount != invoiceDetails.amountDue"
          type="number"
          label="Amount Due"
          name="amount"
          :placeholder="formatAmount(invoiceDetails.amountDue)"
          horizontal
          plaintext
        />
        <CInput
          v-if="invoiceDetails.openingBalance"
          type="number"
          label="Opening Balance"
          name="amount"
          :placeholder="formatAmount(invoiceDetails.openingBalance)"
          horizontal
          plaintext
        />
        <CInput
          v-if="invoiceDetails.adjustmentAmount"
          type="number"
          label="Adjustments"
          name="amount"
          :placeholder="formatAmount(invoiceDetails.adjustmentAmount)"
          horizontal
          plaintext
        />
        <CInput
          type="number"
          label="Total"
          name="amount"
          :placeholder="formatAmount(invoiceDetails.totalInvoicedAmount)"
          horizontal
          plaintext
        />
        <CRow
          form
          class="form-group"
        >
          <CCol sm="3">
            View Invoice
          </CCol>
          <CCol>
            <CButton
              size="sm"
              color="info"
              variant="outline"
              @click="getDownloadLink(invoiceDetails.accountId, invoiceDetails.invoiceId)"
            >
              {{ invoiceDetails.invoiceId }}
            </CButton>
          </CCol>
        </CRow>
        <CTextarea
          v-if="!paymentDetails.description"
          id="notes"
          v-model="notes"
          label="Description"
          placeholder="Enter payment details"
          horizontal
          rows="3"
        />
        <CInput
          v-if="paymentDetails.description"
          label="Payment Notes"
          :placeholder="paymentDetails.description"
          horizontal
          plaintext
        />
        <CInput
          v-if="paymentDetails.userId"
          label="Paid On"
          :placeholder="formatDate(paymentDetails.paidOn)"
          horizontal
          plaintext
        />
        <CInput
          v-if="paymentDetails.user"
          label="Paid By"
          :placeholder="paymentDetails.user.name"
          horizontal
          plaintext
        />
      </CForm>
      <ReviewNotes
        :parent-object="invoiceDetails"
      />
      <template #footer>
        <ButtonLoading
          v-if="invoiceDetails.paymentStatusId === 'Outstanding' || invoiceDetails.paymentStatusId === 'Overdue'"
          color="primary"
          :loading="isLoading"
          @click="onConfirmSubmit"
        >
          Submit Payment
        </ButtonLoading>
        <div v-if="invoiceDetails.paymentStatusId !== 'Outstanding' && invoiceDetails.paymentStatusId !== 'Overdue'" />
      </template>
    </CModal>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapState, mapActions, mapGetters } from 'vuex'
import Vue from 'vue'

import ReviewNotes from '../ReviewNotes/ReviewNotes.vue'
import booleanBadgeMixin from '@/mixins/boolean-badge-mixin'
import { formatDate, formatDateInclusive, formatAmount } from '@/utils'
import LoginInternal from '../Login/LoginInternal.vue'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'

const urlMapping = {
  paymentStatusIdFilter: 'paymentStatus',
  accountIdFilter: 'accountId',
  invoiceIdFilter: 'invoiceId',
  accountNameFilter: 'accountName',
  showActiveInvoices: 'active'
}

export default {
  name: 'AccountPayments',
  components: { 
    LoginInternal,
    ReviewNotes
   },
  mixins: [booleanBadgeMixin],

  props: {
    payments: {
      type: Object,
      default() {
        return {
          invoices: [],
          summary: {},
        }
      },
    },
    accountType: {
      type: String,
      default: '',
    },
    isLoading: Boolean,
  },

  data() {
    return {
      includeTestOnly: false,
      showActiveOnly: true,
      paymentStatusIdFilter: '',
      accountIdFilter: '',
      accountNameFilter: '',
      invoiceIdFilter: '',
      fields: [
        {
          key: 'billingCycle',
          label: 'Billing Period',
          filter: true,
        },
        { key: 'dateDue', label: 'Due', sortable: true, filter: true },
        {
          key: 'paymentStatusId',
          label: 'Status',
          sortable: true,
          filter: true,
        },
        { key: 'accountName', label: 'Account', sortable: true, filter: true },
        { key: 'totalInvoicedAmount', label: 'Amount', sortable: true, filter: true },
        { key: 'invoiceId', label: 'Invoice #', filter: true, _classes: 'text-center' },
        { key: 'Actions', filter: false },
      ],
      showLoginModal: false,
      showInvoiceDetails: false,
      invoiceDetails: {},
      notes: '',
      columnFilterValuesState: {}
    }
  },

  url: urlMapping,

  computed: {
    ...mapState('billing', ['paymentDetails']),
    
    ...mapGetters('auth', ['isUserLoggedInWithinLastXMinutes']),

    columnFilterValues: {
      set: debounce(function (value) {
        this.columnFilterValuesState = value

        for (const columnName in value) {
          value[columnName] = value[columnName] || ''
          const dataPropName = Object.keys(urlMapping).find(k=> urlMapping[k] === columnName)

          if (!value[columnName] && !this[dataPropName]) {
            continue
          }
          
          if (dataPropName in this) {
            Vue.set(this, dataPropName, value[columnName])
          }
        }
      }, 500),

      get() {
        return {
          ...this.columnFilterValuesState,
          accountName: this.accountNameFilter || '',
          invoiceId: this.invoiceIdFilter || ''
        }
      }
    },

    isUserLoggedInWithinLast5Minutes() {
      return this.isUserLoggedInWithinLastXMinutes(5)
    },
    
    outstandingCount() {
      return this.filteredInvoices.filter(x => x.paymentStatusId === 'Outstanding').length
    },

    outstandingAmount() {
      const result = this.filteredInvoices
        .filter(x => x.paymentStatusId === 'Outstanding')
        .reduce((accumulator, currentItem) => accumulator + currentItem.totalInvoicedAmount, 0)
      return result
    },

    overdueCount() {
      if (!this.filteredInvoices || this.filteredInvoices.length === 0) {
        return 0
      }
      const result = this.filteredInvoices.filter(x => x.paymentStatusId === 'Overdue').length
      return result
    },

    overdueAmount() {
      if (!this.filteredInvoices || this.filteredInvoices.length === 0) {
        return []
      }

      const result = this.filteredInvoices
        .filter(x => x.paymentStatusId === 'Overdue')
        .reduce((accumulator, currentItem) => accumulator + currentItem.totalInvoicedAmount, 0)
      return result ?? []
    },

    nearestPayoutInvoice(){
      let dummyResult = {dateDue: "N/A"}
      if (!this.filteredInvoices || this.filteredInvoices.length === 0) {
        return dummyResult
      }

      const result = this.filteredInvoices.reduce((prev, current) => {
        if (!prev){
          return current
        }
        const result = prev.dateDue <= current.dateDue ? prev : current
        return result
      })
      return result ?? dummyResult
    },

    highestAmountInvoice(){
      let dummyResult = {}
      if (!this.filteredInvoices || this.filteredInvoices.length === 0) {
        return dummyResult
      }

      const result = this.filteredInvoices.reduce((prev, current) => {
        if (!prev){
          return current
        }
        const result = prev.totalInvoicedAmount > current.totalInvoicedAmount ? prev : current
        return result
      })      
      return result ?? dummyResult
    },

    filteredInvoices() {
      if (!this.payments || this.payments.length === 0) {
        return this.payments
      }

      let result = this.payments.invoices.filter(x => x.testOnly == this.includeTestOnly)

      if (this.paymentStatusIdFilter) {
        result = result.filter(x => x.paymentStatusId === this.paymentStatusIdFilter)
      }

      if (this.accountIdFilter) {
        result = result.filter(x => x.accountId === this.accountIdFilter)
      }

      if (this.showActiveOnly) {
        const overdueInvoices = result
          .filter(x => x.paymentStatusId === 'Overdue')
          .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate)) || []

        const outstandingInvoices = result
          .filter(x => x.paymentStatusId === 'Outstanding') || []

        //bring the overdue invoices on top (sorted by the nearest due date)
        result = overdueInvoices.concat(outstandingInvoices)
      }
      
      return result
    },

    showActiveInvoices: {
      get() {
        return this.showActiveOnly ? 'yes' : 'no'
      },

      set(value) {
        this.showActiveOnly = value === 'yes'
      }
    }

  },

  watch: {
    paymentStatusIdFilter: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue === 'Paid') {
          this.showActiveOnly = false
        }
      }
    },

    showActiveOnly: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.paymentStatusIdFilter === 'Paid') {
          setTimeout(() => {
            this.paymentStatusIdFilter = undefined
          }, 0)
        }
      }
    }
  },

  methods: {
    ...mapActions('billing', [
      'generateDownloadLink', 
      'getPaymentDetails',
      'updatePayment'
    ]),

    ...mapActions('auth', ['logout']),

    getBadge(status) {
      switch (status) {
        case 'Outstanding':
          return 'primary'

        case 'Paid':
          return 'success'

        case 'PartiallyPaid':
          return 'secondary'

        case 'Overdue':
          return 'danger'

        default:
          return 'warning'
      }
    },

    resetForm() {
      this.invoiceDetails = {}
      this.notes = ''
    },

    async submitInvoicePayment() {
      this.showLoginModal = false
      this.showInvoiceDetails = false

        try {
          await this.updatePayment({
            accountId: this.invoiceDetails.accountId,
            invoiceId: this.invoiceDetails.invoiceId,
            data: {description: this.notes}
          })
          showSuccessMessage('The invoice has been successfully paid.')
          this.$emit('reload-seller-payments')
        } catch (error) {
          showErrorMessage(error)
        } finally {
          this.resetForm()
        }
    },

    onLoginSuccess() {
      this.submitInvoicePayment()
    },

    onLoginFailed() {
      this.onLogout()
    },

    async showPayInvoiceModal(item) {
      await this.getPaymentDetails({accountId: item.accountId, invoiceId: item.invoiceId})
      
      this.invoiceDetails = item
      this.showInvoiceDetails = true
    },

    async onConfirmSubmit() {
      
      if (!this.isUserLoggedInWithinLast5Minutes) {
        this.showInvoiceDetails = false
        this.showLoginModal = true
        return
      }
      
      await this.submitInvoicePayment()
    },

    async getDownloadLink(accountId, invoiceId) {
      const downloadURL = await this.generateDownloadLink({
        accountId,
        invoiceId,
      })
      window.open(downloadURL)
    },

    formatDate,
    formatDateInclusive,
    formatAmount,
  },
}
</script>

<style></style>
