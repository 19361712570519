<template>
  <div>
    <h3>
      Bank Info
    </h3>
    <dl
      v-if="sellerAccountDetails.billingConfiguration && sellerAccountDetails.billingConfiguration.bankInfo"
      class="row"
    >
      <dt class="col-sm-3 text-right">
        Beneficiary Name
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.beneficiaryName }}
      </dd>

      <dt class="col-sm-3 text-right">
        Beneficiary Address
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.address.address1 }}
      </dd>

      <dt class="col-sm-3 text-right">
        Address 2 (Optional)
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.address.address2 }}
      </dd>

      <dt class="col-sm-3 text-right">
        City
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.address.city }}
      </dd>
      
      <dt class="col-sm-3 text-right">
        State
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.address.state }}
      </dd>

      <dt class="col-sm-3 text-right">
        Zip
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.address.zip }}
      </dd>

      <dt class="col-sm-3 text-right">
        Beneficiary Contact Name
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.contactPerson }}
      </dd>
      <dt class="col-sm-3 text-right">
        Beneficiary Contact Email
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.email }}
      </dd>
      <dt class="col-sm-3 text-right">
        Beneficiary Contact Phone
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.cellPhone }}
      </dd>
     
      <dt class="col-sm-3 text-right">
        Beneficiary EIN
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.ein }}
      </dd>
      <dt class="col-sm-3 text-right">
        Bank Name
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.bankName }}
      </dd>
      <dt class="col-sm-3 text-right">
        Account Number
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.accountNumber ? sellerAccountDetails.billingConfiguration.bankInfo.accountNumber : sellerAccountDetails.billingConfiguration.bankInfo.maskedAccountNumber }}
        <ButtonLoading
          v-if="!sellerAccountDetails.billingConfiguration.bankInfo.accountNumber"
          class="ml-2"
          color="primary"
          :loading="isLoading"
          size="sm"
          @click="getUnmaskedAccountNumber"
        >
          Get Account Number
        </ButtonLoading>
      </dd>
      <dt class="col-sm-3 text-right">
        Routing Number
      </dt>
      <dd class="col-sm-9">
        {{ sellerAccountDetails.billingConfiguration.bankInfo.routingNumber }}
      </dd>
    </dl>
    <h5
      v-else
      class="text-muted mb-4"
    >
      <i>Not available</i>
    </h5>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { showErrorMessage } from '@/notification-utils'

export default {
  name: 'SellerBankInfo',

  props: {
    sellerAccountDetails: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState(['billingCycles'])
  },

  methods: {
    ...mapActions('accounts', [
      'getSellerAccountBankInfoNotRedacted',
    ]),

    async getUnmaskedAccountNumber() {
      try {
        this.isLoading = true
        await this.getSellerAccountBankInfoNotRedacted(this.sellerAccountDetails.id)
      } catch (e) {
        showErrorMessage(e.response.data.title)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style>

</style>