<template>
  <div>
    <h3>
      Account Requirements
    </h3>

    <dl
      v-if="!hasNoRequirements"
      class="row"
    >
      <template
        v-for="(value, key) in accountRequirements.requirements"
      >
        <dt
          :key="`${key}-label`"
          class="col-sm-3 col-12 text-sm-right"
        >
          {{ startCase(key) }}
        </dt>

        <dd
          :key="`${key}-value`"
          class="col-sm-9 col-12"
        >
          {{ value ? 'Satisfied' : 'Not satisfied' }}

          <CIcon
            v-if="!value"
            name="cil-bell-exclamation"
            class="text-danger ml-2"
          />

          <CIcon
            v-if="value"
            name="cil-check"
            class="text-success ml-2"
          />

          <div
            v-if="requirementLogs[key]"
            class="mt-3"
          >
            <AccountRequirementLog
              v-for="(log, $idx) in requirementLogs[key]"
              :key="$idx"
              class="col-sm-10 col-lg-8 col-xl-6"
              :log="log"
            />
          </div>
        </dd>
      </template>
    </dl>

    <div
      v-else
      class="pl-2"
    >
      <i>No Requirements for this account</i>
    </div>
  </div>
</template>

<script>
import startCase from 'lodash.startcase'
import isEmpty from 'lodash.isempty'

import AccountRequirementLog from './AccountRequirementLog.vue'

export default {
  name: 'AccountRequirements',

  components: {
    AccountRequirementLog,
  },

  props: {
    accountRequirements: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  computed: {
    hasNoRequirements() {
      return isEmpty(this.accountRequirements?.requirements)
    },

    requirementLogs() {
      if (this.hasNoRequirements) {
        return {}
      }

      let requirementLogs = {}

      this.accountRequirements.requirementApprovals?.forEach(log => {
        requirementLogs[log.requirement] ??= []

        requirementLogs[log.requirement].push(log)
      })

      return requirementLogs
    }
  },

  methods: {
    startCase
  },
}
</script>

<style>

</style>