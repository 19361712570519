import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/users/super-admins', process.env.VUE_APP_PUBLIC_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const END_POINT = ''

const UserAPI = {
  getUsers(role) {
    const url = `${END_POINT}?role=${role}`

    return httpClient.get(url)
  },

  createUser(user) {
    const url = `${END_POINT}`

    return httpClient.post(url, user)
  },

  deleteUser(userID) {
    const url = `${END_POINT}/${userID}`

    return httpClient.delete(url)
  },

  disableUser(userID) {
    const url = `${END_POINT}/${userID}/disable`

    return httpClient.post(url, {})
  },

  enableUser(userID) {
    const url = `${END_POINT}/${userID}/enable`

    return httpClient.post(url, {})
  },
}

export default UserAPI