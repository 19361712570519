import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/billing', process.env.VUE_APP_INTERNAL_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const BillingAPI = {

  getBuyerAccountPayments() {
    const url = `admin/payments/buyer`
    const result = httpClient.get(url)
    return result
  },

  getSellerAccountPayments() {
    const url = `admin/payments/seller`
    const result = httpClient.get(url)
    return result
  },

  async getSellersPaymentHistory(accountId = null) {
    const url = `admin/payments/paid/seller?${!accountId ? '' : `accountId=${accountId}`}`
    return await httpClient.post(url, {})
  },

  generateDownloadLink({ accountId, invoiceId }) {
    const url = `admin/payments/invoice/${accountId}/${invoiceId}`
    return httpClient.get(url)
  },

  async getPaymentDetails({ accountId, invoiceId }) {
    const url = `admin/payments/${accountId}/${invoiceId}`
    return await httpClient.get(url)
  },

  updatePayment({ accountId, invoiceId, data }) {
    const url = `admin/payments/${accountId}/${invoiceId}/pay`
    return httpClient.post(url, data)
  },

  markInvoiceAsPaid({ data }) {
    const url = `admin/payments/${data.accountId}/${data.invoiceId}/mark-paid`
    return httpClient.post(url, {notes: data.notes})
  },

  getSystemAccountSummary({includeTestOnly}) {
    const url = `admin/accounts/summary/?includeTestOnly=${includeTestOnly}`
    return httpClient.get(url)
  },

  getSystemAccountGrossMargin({startDate, endDate, includeTestOnly}) {
    const url = `admin/accounts/gross-margin/?startDate=${startDate}&endDate=${endDate}&includeTestOnly=${includeTestOnly}`
    return httpClient.get(url)
  }
}

export default BillingAPI
