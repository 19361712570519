export default {
  methods: {
    getStatusBadge(campaign) {
      if (campaign.isPaused) {
        return 'warning'
      }

      switch (campaign.status) {
      case 'Active':
        return 'success'

      case 'Rejected':
        return 'danger'

      case 'InReview':
      default:
        return 'warning'
      }
    },

    getStatus(campaign) {
      if (campaign.isPaused) {
        return 'Paused'
      }

      if (campaign.status.toLowerCase() === 'inreview') {
        return 'Review Pending'
      }

      return campaign.status
    },

    canCampaignBeResumed(campaign) {
      return campaign.status.toLowerCase() === 'paused' || campaign.isPaused
    },
  }
}