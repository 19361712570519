<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex">
        Seller Payment History for

        <router-link
          :to="{ name: 'SellerAccountDetails', params: { id: getSafe(() => sellerAccountDetails.id)} }"
          target="_blank"
          class="ml-1"
        >
          <strong>{{ getSafe(() => sellerAccountDetails.name) }}</strong>

          <CIcon
            name="cil-external-link"
            size="custom"
            class="ml-1"
          />
        </router-link>

        <CButton
          color="info"
          class="ml-auto"
          size="sm"
          :to="{
            name: 'SellerPaymentsList',
            query: { accountId: accountId }
          }"
          target="_blank"
        >
          View Pending Invoices
          <CIcon
            name="cil-external-link"
            size="custom"
            class="ml-1"
          />
        </CButton>

        <CButton
          color="info"
          class="ml-2"
          size="sm"
          :to="{
            name: 'SellerPaymentsList',
            query: { accountId: accountId, paymentStatus: 'Paid' }
          }"
          target="_blank"
        >
          View Paid Invoices
          <CIcon
            name="cil-external-link"
            size="custom"
            class="ml-1"
          />
        </CButton>
      </CCardHeader>

      <CCardBody>
        <div
          v-if="isLoading"
          :style="{ height: '80vh' }"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <CSpinner
            style="width: 4rem; height: 4rem"
            color="info"
            grow
          />
        </div>

        <div v-else>
          <SellerPaymentHistoryList :payments="paymentHistory" />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import startCase from 'lodash.startcase'

import BillingAPI from '@/api/billing.api'
import SellerPaymentHistoryList from './SellerPaymentHistoryList.vue'
import { dateTimeFormat } from '@/utils'

function getPaidByFromDescriptionForManualAdjustment(description) {
  return description.split('(')?.[0]
}

export default {
  name: 'SellerAccountPaymentHistory',

  components: {
    SellerPaymentHistoryList,
  },

  data() {
    return {
      accountId: '',
      isLoading: false,

      paymentHistory: []
    }
  },

  computed: {
    ...mapState('accounts', [
      'sellerAccountDetails',
    ]),
  },

  async created () {
    this.accountId = this.$route.params.id

    if (this.accountId && this.sellerAccountDetails !== this.accountId) {
      await this.getSellerAccountDetails(this.accountId)
    }

    document.title = `Payment History for ${this.sellerAccountDetails.name}`

    await this.getSellerAccountPaymentHistory(this.accountId)
  },

  methods: {
    ...mapActions('accounts', [
      'getSellerAccountDetails',
    ]),

    async getSellerAccountPaymentHistory(accountId) {
      try {
        this.isLoading = true
        const response = await BillingAPI.getSellersPaymentHistory(accountId)
  
        this.paymentHistory = response.data.map(payment => {
          return {
            ...payment,
            paidByName: payment.transactionType === 'ManualAdjustment' ? getPaidByFromDescriptionForManualAdjustment(payment.paymentDescription) : payment.paidBy?.name,
            formattedPaymentDate: dateTimeFormat.format(new Date(payment.paymentDate)),
            transactionType: startCase(payment.transactionType)
          }
        })
      } catch (error) {
        //
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
<style>
  
</style>