<template>
  <CCard>
    <div
      v-if="isLoading"
      :style="{ height: '80vh' }"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <CSpinner
        class="custom-spinner"
        color="info"
        grow
      />
    </div>

    <div v-else>
      <CCardHeader>
        {{ caption }}
        <CButton
          class="ml-2"
          size="sm"
          color="info"
          variant="outline"
          :pressed="enableAutoreload"
          @click="toggleAutoReload"
        >
          Auto Reload
        </CButton>
      </CCardHeader>

      <CCardBody>
        <CDataTable
          small
          fixed
          border
          sorter
          :items="items"
          :fields="fields"
          :items-per-page="10"
          :pagination="{align: 'center'}"
        >
          <template #sellerOffer="{item}">
            <td>
              {{ item.id }}
            </td>
          </template>
          <template #status="{item}">
            <td>
              <CBadge :color="getStatusBadge(item.status)">
                {{ item.status }}
              </CBadge>
            </td>
          </template>
        
          <template #updatedAt="{item}">
            <td>
              {{ getSafe(() => `${formatDate(item.updatedAt)}`) }}
            </td>
          </template>
          <template #createdBy="{item}">
            <td>
              {{ getSafe(() => `${item.createdBy.name} ${item.createdBy.lastName}`) }}
            </td>
          </template>
          <template #createdAt="{item}">
            <td>
              {{ getSafe(() => `${formatDate(item.createdAt)}`) }}
            </td>
          </template>
          <template #Actions="{item}">
            <td>
              <CButton
                color="info"
                size="sm"
                :to="`${actionUri}/${item.id}`"
              >
                View Application
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </div>
  </CCard>
</template>

<script>
import { dateTimeFormat } from '@/utils'

export default {
  name: 'GenericList',

  props: {
    caption: {
      type: String,
      default: ''
    },

    items: {
      type: Array,
      default() {
        return []
      }
    },

    fields: {
      type: Array,
      default() {
        return []
      }
    },

    actionUri: {
      type: String,
      default: ''
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      enableAutoreload: false
    }
  },

  computed: {
    
  },

  methods: {
    toggleAutoReload() {
      this.enableAutoreload = !this.enableAutoreload

      this.$emit('change-auto-reload', this.enableAutoreload)
    },

    getStatusBadge(status) {
      switch (status) {
        case 'Active':
        case 'Approved':
          return 'success'

        case 'Rejected':
        case 'ManuallyBlocked':
          return 'danger'

        case 'Disabled':
        case 'Pending':
        default:
          return 'warning'
      }
    },

    formatDate(date) {
      return dateTimeFormat.format(new Date(date))
    }
  },
}
</script>

<style lang="scss" scoped>

</style>