
import createHttpClient from './httpClient'

const baseUrl = new URL('/api/v1/customerresources', process.env.VUE_APP_PUBLIC_SERVICE_BASE_URL).href
const httpClient = createHttpClient(baseUrl)

const END_POINT = '/countries'

const PublicCountriesAPI = {
    getAvailableCountries() {
        return httpClient.get(END_POINT)
    },
}

export default PublicCountriesAPI

