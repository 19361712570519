<template>
  <CButton
    class="c-loading-button"
    :block="block"
    :class="{'c-loading-button-loading': loading}"
    :color="color"
    :disabled="disabled || loading"
    :pressed="pressed"
    :shape="shape"
    :size="size"
    :type="type"
    :variant="variant"
    data-coreui="loading-button"
    @click="$emit('click')"
  >
    <CSpinner
      v-if="loading"
      size="sm"
      class="c-loading-button-spinner"
    />

    <slot />
  </CButton>
</template>

<script>

const props = {
  spinnerType: {
    type: String,
    default: 'border' //grow
  },
  loading: Boolean,
  progress: {
    type: [Boolean, String],
    default: false
  },
  //waitOnEnd: Boolean,
  timeout: {
    type: [String, Number],
    default: 1000
  },
  percent: {
    type: [Number],
    default: 0
  },
  onChange: Function,
  //variant: String, //['left-to-right'] //double use
  spinner: {
    type: Boolean,
    default: true
  },

  //###############################################
  // button props
  //###############################################
  block: Boolean,
  shape: {
    type: String,
    validator: shape => ['', 'pill', 'square'].includes(shape)
  },
  variant: {
    type: String,
    validator: val => ['', 'ghost', 'outline'].includes(val)
  },
  size: {
    type: String,
    validator: value => ['', 'sm', 'lg'].includes(value)
  },
  color: String,
  type: {
    type: String,
    default: 'button'
  },
  pressed: {
    type: Boolean,
    default: null
  },
  disabled: Boolean
}

export default {
  name: 'ButtonLoading',

  props,

}
</script>

<style>

</style>