<template>
  <div>
    <h4>Seller Payments</h4>
    <CCard>
      <AccountPayments
        :is-loading="isLoading"
        :payments="sellerAccountPayments"
        account-type="seller"
        @reload-seller-payments="getSellerAccountPayments"
      />
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AccountPayments from './AccountPayments.vue'

export default {
  name: 'SellerPayments',

  title: 'Seller Payments',

  components: {
    AccountPayments
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapState('billing', [
      'sellerAccountPayments'
    ]),

  },

  async created() {
    try {
      this.isLoading = true
      await this.getSellerAccountPayments()
    }
    finally {
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions('billing', [
      'getSellerAccountPayments'
    ]),
  },
}
</script>
<style></style>
