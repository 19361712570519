import { render, staticRenderFns } from "./RegistrationRequestDetails.vue?vue&type=template&id=0431f7ba&scoped=true&"
import script from "./RegistrationRequestDetails.vue?vue&type=script&lang=js&"
export * from "./RegistrationRequestDetails.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationRequestDetails.vue?vue&type=style&index=0&id=0431f7ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0431f7ba",
  null
  
)

export default component.exports