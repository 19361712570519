<template>
  <div>
    <h4>Seller Accounts</h4>
    <CCard>
      <CInput
        v-model="filterString"
        class="mt-2 ml-2"
        placeholder="Type to search accounts by company name, representative name or email address"
        add-input-classes="col-6"
        :lazy="500"
        @update:value="searchSellerAccountsWrapper"
      />

      <AccountsList
        v-if="sellerAccounts && sellerAccounts.length > 0"
        :is-loading="isLoading"
        :accounts="sellerAccounts"
        type="seller"
      />
    </CCard>

    
    <h4 class="mt-5">
      Inactive Seller Accounts
    </h4>
  
    <InactiveAccountsList
      type="seller"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

import AccountsList from './AccountsList.vue'
import InactiveAccountsList from  '@/views/Accounts/InactiveAccountsList.vue'

export default {
  name: 'SellerAccounts',

  title: 'Seller Accounts',

  components: {
    AccountsList,
    InactiveAccountsList
  },

  props: {
    type: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState('accounts', [
      'sellerAccounts',
      'sellerAccountsSearchString',
    ]),

    filterString: {
      get() {
        return this.sellerAccountsSearchString
      },
      set(value) {
        this.SET_SELLER_ACCOUNT_SEARCH_STRING(value)
      }
    }
  },

  async created () {
    this.isLoading = true
    try {
      await this.getApprovedSellerAccounts()
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions('accounts', [
      'searchSellerAccounts',
      'getApprovedSellerAccounts'
    ]),

    ...mapMutations('accounts', [
      'SET_SELLER_ACCOUNT_SEARCH_STRING'
    ]),

    async searchSellerAccountsWrapper() {
      this.isLoading = true
      try {

        if (this.filterString) {
          await this.searchSellerAccounts(this.filterString)
        }
        else {
          await this.getApprovedSellerAccounts()
        }

      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    }

  },
}
</script>

<style lang="scss" scoped>
</style>