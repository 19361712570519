<template>
  <div>
    <CCard>
      <CCardHeader>
        Channel name search
      </CCardHeader>

      <CCardBody>
        <CForm
          novalidate
          @submit.prevent
        >
          <div
            v-if="isStaticDataLoading"
            :style="{ height: '80vh' }"
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <CSpinner
              style="width:4rem;height:4rem;"
              color="info"
              grow
            />
          </div>

          <CFormGroup
            class="form-row form-group"
          >
            <template #label>
              <label
                for="Buyer Account"
                class="col-form-label col-sm-3"
              >
                Buyer Account
              </label>
            </template>

            <template #input>
              <CCol
                md="4"
                sm="4"
              >
                <v-select
                  id="buyer-account-search"
                  v-model="channelNameSearchRequest.buyerAccount"
                  label="label"
                  :horizontal="{ label: 'col-sm-3', input: 'col-sm-3 col-12' }"
                  :filterable="false"
                  :options="buyerAccountsFormatted"
                  placeholder="Search buyer account"
                  :class="{'is-invalid': submitted && !checkIfValid(['channelNameSearchRequest', 'buyerAccount'])}"
                  required
                  @search="searchBuyerAccountsWrapper"
                />

                <div
                  v-if="submitted && !checkIfValid(['channelNameSearchRequest', 'buyerAccount'])"
                  class="invalid-feedback"
                >
                  Buyer Account is empty
                </div>
              </CCol>
            </template>
          </CFormGroup>

          <CFormGroup
            class="form-row form-group"
          >
            <template #label>
              <label
                for="Seller Account"
                class="col-form-label col-sm-3"
              >
                Seller Account
              </label>
            </template>

            <template #input>
              <CCol
                md="4"
                sm="4"
              >
                <v-select
                  id="seller-account-search"
                  v-model="channelNameSearchRequest.sellerAccount"                
                  label="label"
                  :horizontal="{ label: 'col-sm-3', input: 'col-sm-3 col-12' }"
                  :filterable="false"
                  :options="sellerAccountsFormatted"
                  placeholder="Search seller account"
                  :class="{'is-invalid': submitted && !checkIfValid(['channelNameSearchRequest', 'sellerAccount'])}"
                  required
                  @search="searchSellerAccountsWrapper"
                />

                <div
                  v-if="submitted && !checkIfValid(['channelNameSearchRequest', 'sellerAccount'])"
                  class="invalid-feedback"
                >
                  Seller Account is empty
                </div>
              </CCol>
            </template>
          </CFormGroup>

          <CSelect
            v-if="categories"
            id="category-dropdown"
            :value.sync="channelNameSearchRequest.categoryId"
            data-cy="category-dropdown"
            label="Category"
            :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }"
            :options="categoriesFormatted"
            placeholder="Please select"
            :is-valid="checkIfValid(['channelNameSearchRequest', 'categoryId'])"
            invalid-feedback="Please select a category"
            :is-loading="isStaticDataLoading"
          />

          <CSelect
            v-if="trafficSources"
            id="traffic-source-dropdown"
            :value.sync="channelNameSearchRequest.trafficSourceId"
            data-cy="traffic-source-dropdown"
            label="Traffic source"
            :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }"
            :options="trafficSourcesFormatted"
            placeholder="Please select"
            :is-valid="checkIfValid(['channelNameSearchRequest', 'trafficSourceId'])"
            invalid-feedback="Please select a traffic source"
            :is-loading="isStaticDataLoading"
          />

          <CSelect
            v-if="countries"
            id="country-dropdown"
            :value.sync="channelNameSearchRequest.country"
            data-cy="traffic-source-dropdown"
            label="Country"
            :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }"
            :options="countriesFormatted"
            placeholder="Please select"
            :is-valid="checkIfValid(['channelNameSearchRequest', 'country'])"
            invalid-feedback="Please select a country"
            :is-loading="isStaticDataLoading"
          />

          <CSelect
            v-if="languages"
            id="language-dropdown"
            :value.sync="channelNameSearchRequest.language"
            data-cy="language-dropdown"
            label="Language"
            :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }"
            :options="languagesFormatted"
            placeholder="Please select"
            :is-valid="checkIfValid(['channelNameSearchRequest', 'language'])"
            invalid-feedback="Please select a language"
            :is-loading="isStaticDataLoading"
          />

          <div class="form-row">
            <CCol
              :md="{ offset: '3', size: '9' }"
              :sm="{ offset: '3', size: '9' }"
            >
              <CAlert
                v-if="showNameResultModal"
                color="success"
                class="mt-1"
                close-button
              >
                Channel name is <strong>{{ salesChannelName }} </strong>
                <a
                  href
                  @click.prevent="copyNameToClipboard(salesChannelName)"
                >
                  <CIcon name="cil-copy" />
                </a>
                <CButton
                  v-if="showNameResultModal"
                  size="sm"
                  color="danger"
                  class="ml-2"                
                  @click="showBlockChannelModal = true"
                >
                  Block
                </CButton>
              </CAlert>
        
              <ButtonLoading
                type="submit"
                size="sm"
                color="primary"
                :loading="isNameSearchLoading"
                @click="onNameSearchSubmit"
              >
                Get channel name
              </ButtonLoading>              
            </CCol>
          </div>
        </CForm>
      </CCardBody>
    </CCard>

    <CCard>      
      <CCardHeader>
        Channel search by channel name / call ID / caller ID 
      </CCardHeader>     
      <CCardBody>
        <CForm
          novalidate
          @submit.prevent
        >
          <CFormGroup
            class="form-row form-group"
          >
            <template #label>
              <label
                for="Call ID"
                class="col-form-label col-sm-3"
              >
                Channel name or call ID or caller ID
              </label>
            </template>

            <template #input>
              <CCol>
                <CInput
                  v-model="searchId"
                  type="text"                 
                  horizontal               
                />
              </CCol>
            </template>
          </CFormGroup>

          <div class="form-row">
            <CCol
              :md="{ offset: '3', size: '9' }"
              :sm="{ offset: '3', size: '9' }"
            >
              <CAlert
                v-if="showComponentsResultModal"
                color="success"
                class="mt-1"
                close-button
              >
                <p>
                  Channel: <strong>{{ blockedChannel.encodedName }} </strong><br>                         
                  Buyer: <strong>{{ blockedChannel.buyerAccountName }} </strong><br>
                  Seller: <strong>{{ blockedChannel.sellerAccountName }} </strong><br>
                  Category: <strong>{{ blockedChannel.categoryName }} </strong><br>
                  Traffic source: <strong>{{ blockedChannel.trafficSourceName }}</strong> {{ blockedChannel.trafficSourceType }}<br>
                  Locale: <strong>{{ blockedChannel.countryCode }}-{{ blockedChannel.languageCode }}</strong>
                </p>                         
              </CAlert>

              <ButtonLoading
                type="submit"
                size="sm"
                color="primary"
                :loading="isComponentsSearchLoading"
                @click="onComponentsSearchSubmit"
              >
                Get channel components
              </ButtonLoading>              
            </CCol>
          </div>  
        </CForm>
      </CCardBody>
    </CCard>

    <CModal      
      title="Confirm channel block"
      color="warning"
      :show.sync="showBlockChannelModal"
    >
      <p class="alert alert-warning">
        Are you sure you want to block <strong>{{ salesChannelName }}</strong> channel for buyer <strong>{{ channelNameSearchRequest.buyerAccount.label }}</strong> ?
      </p>

      <template #footer>
        <CButton
          color="light"
          @click="showBlockChannelModal = false"
        >
          Cancel
        </CButton>

        <ButtonLoading
          color="danger"
          @click="confirmBlockChannel"
        >
          Block
        </ButtonLoading>        
      </template>
    </CModal>
  </div>
</template>
  
<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import formGenericMixin from '@/mixins/form-generic-mixin'
import { required } from 'vuelidate/lib/validators'
import { showErrorMessage, showSuccessMessage } from '@/notification-utils'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import debounce from 'lodash.debounce'

export default {
  name: 'ChannelNameSearch',

  title: 'Channel Name Search',

  mixins: [copyToClipboardMixin, formGenericMixin],

  validations() {
    return {
      channelNameSearchRequest: {
        buyerAccount: {
          required
        },

        sellerAccount: {
          required
        },

        categoryId: {
          required
        },

        trafficSourceId: {
          required
        },

        country: {
          required
        },

        language: {
          required
        },
      }
    }
  },

  data() {
    return {
      isStaticDataLoading: false,
      isNameSearchLoading: false,
      isComponentsSearchLoading: false,     
      showNameResultModal: false,
      showComponentsResultModal: false,
      showBlockChannelModal: false,
      channelNameSearchRequest: {
        language: 'en',
        country: 'US',
        buyerAccount: {}
      },
      searchId: ''
    }
  },

  computed: {
    ...mapState('accounts', [
      'buyerAccounts',
      'buyerAccountsSearchString',
      'sellerAccounts',
      'sellerAccountsSearchString'
    ]),

    ...mapState('category', ['categories']),

    ...mapState('trafficSource', ['trafficSources']),

    ...mapState('language', ['languages']),

    ...mapState('country', ['countries']),

    ...mapState('salesChannel', ['salesChannelName', 'blockedChannel']),

    buyerFilterString: {
      get() {
        return this.buyerAccountsSearchString
      },
      set(value) {
        this.SET_BUYER_ACCOUNT_SEARCH_STRING(value)
      }
    },

    sellerFilterString: {
      get() {
        return this.sellerAccountsSearchString
      },
      set(value) {
        this.SET_SELLER_ACCOUNT_SEARCH_STRING(value)
      }
    },

    buyerAccountsFormatted() {
      return this.formatAccountListForDropdown(this.buyerAccounts)
    },

    sellerAccountsFormatted() {
      return this.formatAccountListForDropdown(this.sellerAccounts)
    },    

    categoriesFormatted() {
      return this.formatNamedListForDropdown(this.categories)
    },

    trafficSourcesFormatted() {
      return this.formatNamedListForDropdown(this.trafficSources)
    },

    countriesFormatted() {
      return this.formatNamedListForDropdown(this.countries)
    },

    languagesFormatted() {
      return this.formatNamedListForDropdown(this.languages)
    }
  },  

  async created() {
    this.isStaticDataLoading = true
    try {
      this.RESET_SALES_CHANNEL_NAME()
      await this.categoriesList()
      await this.trafficSourcesList()
      await this.languagesList()
      await this.countriesList()
    }
    catch (error) {
      showErrorMessage(error)
    }
    finally {
      this.isStaticDataLoading = false
    }
  },

  async destroyed() {
    this.RESET_SALES_CHANNEL_NAME()
    this.SET_BUYER_ACCOUNT_SEARCH_STRING('')
    this.SET_SELLER_ACCOUNT_SEARCH_STRING('')
  },  

  methods: {
    ...mapActions('accounts', [
      'searchBuyerAccounts',
      'searchSellerAccounts'
    ]),

    ...mapActions('category', { categoriesList: 'list' }),

    ...mapActions('trafficSource', { trafficSourcesList: 'list' }),

    ...mapActions('language', { languagesList: 'list' }),

    ...mapActions('country', { countriesList: 'list' }),

    ...mapActions('salesChannel', ['getEncodedName', 'block', 'getChannel']),

    ...mapMutations('accounts', [
      'SET_BUYER_ACCOUNT_SEARCH_STRING',
      'SET_SELLER_ACCOUNT_SEARCH_STRING'
    ]),

    ...mapMutations('salesChannel', [
      'RESET_SALES_CHANNEL_NAME'
    ]),

    searchBuyerAccountsWrapper: debounce(async function(searchText) {
      if(!searchText) {
        return
      }

      try {        
        await this.searchBuyerAccounts(searchText)        
      } catch(error) {
        //
      }
    }, 500),

    searchSellerAccountsWrapper: debounce(async function(searchText) {
      if(!searchText) {
        return
      }

      try {        
        await this.searchSellerAccounts(searchText)        
      } catch(error) {
        showErrorMessage(error)
      }
    }, 500),    

    formatAccountListForDropdown(list) {
      return list.map(x => {
        return {
          id: x.id,
          label: x.name
        }
      })
    },

    formatNamedListForDropdown(list) {
      return list.map(x => {
        return {
          value: x.id,
          label: x.name
        }
      })
    },

    async onNameSearchSubmit() {
      this.submitted = true

      if (this.isValid) {
        this.isNameSearchLoading = true
        try {
          this.showNameResultModal = false
          let request = {
            ...this.channelNameSearchRequest,
            buyerAccountId: this.channelNameSearchRequest.buyerAccount.id,
            sellerAccountId: this.channelNameSearchRequest.sellerAccount.id
          }
          await this.getEncodedName(request)
          this.showNameResultModal = true
        }
        catch (error) {
          this.RESET_SALES_CHANNEL_NAME()
          showErrorMessage(error)
        }
        finally {
          this.isNameSearchLoading = false
          this.hasInputChanged = false
        }
      }
    },

    async onComponentsSearchSubmit() {
      this.showComponentsResultModal = false      

      if (this.searchId) {
        this.isComponentsSearchLoading = true
        try {
          this.showNameResultModal = false          
          await this.getChannel(this.searchId)
          this.showComponentsResultModal = true
        }
        catch (error) {
          this.RESET_SALES_CHANNEL_NAME()
          showErrorMessage("Failed to find a blocked channel")
        }
        finally {
          this.isComponentsSearchLoading = false          
        }
      }
    },

    copyNameToClipboard(name) {
      this.copyTextToClipboard(name)
      showSuccessMessage('Channel name copied to clipboard')
    },    

    async confirmBlockChannel() {      
      try { 
        let request = {
            ...this.channelNameSearchRequest,
            buyerAccountId: this.channelNameSearchRequest.buyerAccount.id,
            sellerAccountId: this.channelNameSearchRequest.sellerAccount.id
        }
        await this.block({ payload: request })
        showSuccessMessage('Successfully blocked channel')
      } catch(error) {
        showErrorMessage(error)
      }
      finally {
        this.showBlockChannelModal = false
      }
    },   
  }
}
</script>
  
<style></style>